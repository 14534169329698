import React, { Component } from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import { inviteNewTeamMember, inviteReset } from "../ducks/actions";
import { successMessage } from "../../Common/ducks/actions";

import Card from "../../Common/components/Card";
import Input from "../../Common/components/formComponents/Input";
import PhoneInput from "../../Common/components/formComponents/PhoneInput";
import GraphQLSelect from "../../Common/components/formComponents/GraphQLSelect";
import { PrimaryButton } from "../../Common/components/Button";
import Loading from "../../Common/components/Loading";

/**
 * InviteTeamMember renders a form that allows company admins to invite new users to the web app.
 *
 */

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  permissionId: "",
  errors: [],
  inviting: false,
};

export class InviteTeamMember extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.inviteComplete === false && this.props.inviteComplete) {
      this.setState({ inviting: false });
    }
  }

  componentWillUnmount() {
    this.props.inviteReset();
  }

  resetForm = () => {
    this.setState({ ...initialState }, () => this.props.inviteReset());
  };

  handleChange = (id, value) => {
    const { errors } = this.state;

    const newErrors = [...errors];

    if (newErrors.indexOf(id) >= 0 && value.length > 0) {
      newErrors.splice(newErrors.indexOf(id), 1);
    }

    this.setState({
      [id]: value,
      errors: [...newErrors],
    });
  };

  generateFields = (array) =>
    array.map((field) => {
      const { type, label, name, inputType, ...rest } = field;

      const isError = this.state.errors.indexOf(field.name) >= 0;

      const inputProps = {
        key: name,
        id: name,
        inputType: "input",
        type: inputType,
        title: label,
        error: isError,
        helperText: isError ? "This field is required!" : field.helperText,
        value: this.state[name],
        handleChange: this.handleChange,
        ...rest,
      };

      switch (type) {
        case "select":
          return <GraphQLSelect {...inputProps} />;
        case "phone":
          return <PhoneInput {...inputProps} />;
        default:
          return <Input {...inputProps} />;
      }
    });

  handleSubmit = (event) => {
    event.preventDefault();

    const { inviteNewTeamMember, user } = this.props;
    const { firstName, lastName, email, permissionId } = this.state;

    this.setState({ inviting: true });

    const newUser = {
      firstName,
      lastName,
      email,
      permissionId,
      companyId: user.company.uuid,
    };

    inviteNewTeamMember(newUser, user);
  };

  render() {
    const { inviting, firstName, lastName, email } = this.state;
    const { inviteComplete, user } = this.props;

    return (
      <>
        <h1>Invite a New Team Member</h1>
        {!inviting && !inviteComplete && (
          <Card id="inviteTeamMemberWrapper">
            <h2>Invite a New Team Member</h2>
            <form onSubmit={this.handleSubmit}>
              {this.generateFields(formFields)}
              <PrimaryButton>Invite Team Member</PrimaryButton>
            </form>
          </Card>
        )}
        {inviting && <Loading />}
        {inviteComplete && (
          <>
            <p>
              {firstName} {lastName} ({email}) has been successfully invited to
              join the {user.company.name} team! They will receive an email with
              instructions on completing their account from team@rebatebus.com.
            </p>
            <PrimaryButton handleClick={this.resetForm}>
              Invite another Team Member
            </PrimaryButton>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  inviteComplete: state.team.inviteComplete,
  user: state.account.user,
});

export default connect(mapStateToProps, {
  successMessage,
  inviteNewTeamMember,
  inviteReset,
})(InviteTeamMember);

const formFields = [
  { name: "firstName", label: "First Name", required: true },
  { name: "lastName", label: "Last Name", required: true },
  {
    name: "email",
    label: "Email",
    inputType: "email",
    autocomplete: "new-email",
    required: true,
  },
  {
    name: "permissionId",
    label: "User Permissions",
    type: "select",
    required: true,
    optionsQuery: gql`
      {
        permissionsForRegularAccount {
          value
          uuid
        }
      }
    `,
  },
];
