import { gql } from "apollo-boost";

const stringifyArray = (array) =>
  array ? JSON.stringify(array).replace(/"([^(")"]+)":/g, "$1:") : null;

export const ASYNC_SEARCH = gql`
  query SearchResults($value: String!, $limit: Int) {
    productsSearchByModelNumber(modelNumber: $value, limit: $limit) {
      uuid
      modelNumber
    }
    zipCodesSearchByValue(value: $value, limit: $limit) {
      uuid
      value
    }
  }
`;

export const GET_PAGE = (query) => {
  switch (query.type || query.pageType) {
    case "Country":
    case "country":
      return gql`
        {
            country(uuid: "${query.uuid}") {
                name
                uuid
                isHidden
                description
               states {name uuid}
            }
        }
        `;
    case "State/Territory":
    case "state-territory":
    case "State":
    case "state":
      return gql`
              {
                state(uuid: "${query.uuid}") {
                  name
                  uuid
                  description
                  efficiencyScore
                  averageRetailPrice
                  netSummerCapacity
                  netGeneration
                  totalRetailSales
                  totalIncentiveSpending
                  totalEfficiencyBudget
                  region {name uuid}
                  country {name uuid}
                  utilities {name uuid}
                  zipCodes {value uuid}
                }
              }
              `;
    case "Utility":
    case "utility":
      return gql`
              {
                utility(uuid: "${query.uuid}") {
                  name
                  uuid
                  description
                  logoUrl
                  state {name uuid}
                  utilityOwnershipType {value uuid}
                  websites {linkText url uuid}
                  socialMediaLinks {
                    url 
                    uuid
                    SocialMediaPlatform {name uuid}
                  }
                  contacts {name title phone1 phone2 email uuid}
                  reports {name description url uuid}
                  programs {name uuid}
                  zipCodes {value uuid}
                  programTypes {
                    name
                    displayName
                  }
                }
              }
          `;
    case "Product":
    case "product":
      return gql`
              {
                product(uuid: "${query.uuid}") {
                  uuid
                  imageUrl
                  modelNumber
                  QPLIdentifier
                  isHidden
                  specSheetUrl
                  QPL { uuid value}
                  brand {uuid value}
                  newProductCategory {uuid name}
                  productSubCategory {uuid value}
                  productType {uuid value}
                  manufacturer {uuid url name}
                  technologyType {uuid value}
                  productSpecs {
                    uuid 
                    value 
                    productSpecType {
                      uuid 
                      value 
                    }
                  }
                }
              }
      `;
    case "Zip Code":
    case "zip-code":
    case "zipcode":
      return gql`
              {
              zipCode(uuid: "${query.uuid}") {
                value
                uuid
                states {name uuid}
                utilities {name uuid}
              }
            }
            `;
    case "Program":
    case "program":
      const getOtherTypes = query.editing
        ? `customerTypes {uuid}
      offeringTypes {uuid}`
        : ``;
      return gql`
              {
                program(uuid: "${query.uuid}") {
                  name
                  uuid
                  isHidden
                  isActive
                  statusNotes
                  description
                  logoUrl
                  startDate
                  endDate
                  websites {linkText url uuid}
                  contacts {name title phone1 phone2 email uuid}
                  reports {name description url uuid}
                  applicationLinks {linkText url uuid}
                  requirements
                  maximumAnnualIncentive
                  budget {totalAvailable totalUtilized uuid}
                  projectCap
                  laborCap
                  materialCap
                  utilities {name uuid state{uuid}}
                  customerTypes {value uuid}
                  offeringTypes {value uuid}
                  downstreamProgram {
                    isPreApprovalRequired
                    isPostAuditRequired
                    isPreInspectionRequired
                  }
                  technologyTypes {
                    value
                    uuid
                  }

                }
                ${getOtherTypes}
              }
            `;
    case "technologyType":
      return gql`
              {
                program(uuid: "${query.programId}"){
                  name
                  uuid
                  technologyTypes {
                    value
                    uuid
                  }
                }
              }
            `;
    case "rebate":
      return gql`
              {
                rebate(uuid: "${query.rebateId}"){
                  uuid
                  rebateName {name uuid}
                  notes
                  cap
                  code
                  minimumCustomerContribution
                  rebateType {value uuid}
                  QPLs {value uuid}
                  projectTypes {value uuid}
                  rebateRates {
                    value
                    uuid
                    rebateRateUnit {value uuid}
                  }
                  technologyType {uuid}
                  rebateRequirements {
                    value
                    uuid
                    rebateRequirementType {
                      value uuid
                      productSpecType {value uuid}
                    }
                  }
                  newProductCategories {name uuid}
                  oldProductCategories {name uuid}
                  program {
                    name
                    uuid
                    applicationLinks {url linkText}
                  }
                  updated
                }
                QPLs {
                  uuid
                  value
                }
                projectTypes {
                  uuid
                  value
                }
              }
      `;
    default:
      return;
  }
};

export const createMutations = (query) => {
  switch (query.type || query.pageType) {
    case "utility":
      return gql`
          mutation {
            createUtility(
              name: "${query.name}",
              stateId: "${query.stateId}",
              description: "${
                query.description
                  ? query.description
                  : "No description available."
              }",
              logoUrl: "${query.logoUrl}",
              utilityOwnershipTypeId: "${query.utilityOwnershipTypeId}",
              address: {
                address: "1234 Main Street",
                stateId: "${query.stateId}",
                city: "Somewhere",
                zipcode: "99999"
              }
            ){
              name
              uuid
              websitesBridgeId
              socialMediaLinksBridgeId
              contactsBridgeId
              reportsBridgeId
            }
          }
          `;
    case "program":
      return gql`
          mutation {
            createProgram(
              isHidden: ${query.isHidden},
              name: "${query.name}",
              startDate: "${new Date(query.startDate).getTime()}",
              endDate: "${new Date(query.endDate).getTime()}",
              description: "${
                query.description
                  ? query.description
                  : "No description available."
              }",
              logoUrl: "${query.logoUrl}",
              isActive: ${query.isActive},
              statusNotes: "${query.statusNotes}",
              requirements: "${
                query.requirements
                  ? query.requirements
                  : "No additional requirements."
              }",
              maximumAnnualIncentive: ${
                query.maximumAnnualIncentive === ""
                  ? null
                  : query.maximumAnnualIncentive
              },
              projectCap: ${query.projectCap === "" ? null : query.projectCap},
              laborCap: ${query.laborCap === "" ? null : query.laborCap},
              materialCap: ${
                query.materialCap === "" ? null : query.materialCap
              },
              totalAvailable: ${
                query.totalAvailable === "" ? null : query.totalAvailable
              },
              totalUtilized: ${
                query.totalUtilized === "" ? null : query.totalUtilized
              },
              utilities: ${stringifyArray(query.utilities)},
              customerTypes: ${stringifyArray(query.customerTypes)},
              offeringTypes: ${stringifyArray(query.offeringTypes)},
              downstreamProgram: {
                isPreApprovalRequired: ${
                  query.downstreamProgram.isPreApprovalRequired
                },
                isPreInspectionRequired: ${
                  query.downstreamProgram.isPreInspectionRequired
                },
                isPostAuditRequired: ${
                  query.downstreamProgram.isPostAuditRequired
                }
              }
            ){
              name
              uuid
              websitesBridgeId
              applicationLinksBridgeId
              contactsBridgeId
              reportsBridgeId
            }
          }
      `;
    case "websites":
      return gql`
        mutation {
          createWebsite(
            websitesBridgeId: "${query.websitesBridgeId}",
            url: "${query.url}",
            linkText: "${query.linkText}"
          ){
            linkText
            url
          }
        }
      `;
    case "socialMediaLinks":
    case "applicationLinks":
      return gql`
          mutation {
            createSocialMediaLink(
              socialMediaLinksBridgeId: "${query.socialMediaLinksBridgeId}",
              socialMediaLinkPlatformId: "${query.socialMediaLinkPlatformId}",
              url: "${query.url}"
            ){
              uuid
              url
            }
          }
        `;
    case "product":
      return gql`
          mutation {
            createProduct(
              isHidden: "${query.isHidden}",
              modelNumber: "${query.modelNumber}",
              imageUrl: "${query.imageUrl}",
              QPLId: "${query.QPLId}",
              QPLIdentifier: "${query.QPLIdentifier}",
              newProductCategoryId: "${query.newProductCategoryId}",
              technologyTypeId: "${query.technologyTypeId}",
              brandId: "${query.brandId}",
              manufacturerId: "${query.manufacturerId}",
              productTypeId: "${query.productTypeId}",
              productSubCategoryId: "${query.productSubCategoryId}",
              productSpecs: ${stringifyArray(query.productSpec)}
            ){
              modelNumber
              uuid
            }`;
    case "contacts":
      return gql`
          mutation {
            createContact(
              contactsBridgeId: "${query.contactsBridgeId}",
              name: "${query.name}",
              title: "${query.title}",
              email: "${query.email}",
              phone1: "${query.phone1}",
              phone2: "${query.phone2}"
            ){
              uuid
              name
            }
          }
      `;
    case "reports":
      return gql`
          mutation {
            createReport(
              reportsBridgeId: "${query.reportsBridgeId}",
              name: "${query.name}",
              url: "${query.url}",
              description: "${query.description}"
            ){
              uuid
              name
            }
          }
      `;
    case "rebateRequirementType":
      return gql`
          mutation {
            addRebateRequirementType(
              value: "${query.value}",
              technologyTypeId: "${query.technologyTypeId}",
              productSpecTypeId: "${query.productSpecTypeId}"
            ){
              uuid
            }
          }
      `;
    case "rebate":
      return gql`
          mutation {
            createRebate(
              rebateNameId: "${query.rebateName}",
              programId: "${query.programId}",
              technologyTypeId: "${query.technologyTypeId}",
              rebateTypeId: "${query.rebateType}",
              notes: "${query.notes ? query.notes : "No additional notes."}",
              cap: ${query.cap || null},
              minimumCustomerContribution: ${
                query.minimumCustomerContribution || null
              },
              projectTypes: ${stringifyArray(query.projectTypes)},
              QPLs: ${stringifyArray(query.QPLs)},
              rebateRates: ${stringifyArray(query.rebateRates)},
              rebateRequirements: ${stringifyArray(query.rebateRequirements)},
              newProductCategories: ${stringifyArray(
                query.newProductCategories
              )},
              oldProductCategories: ${stringifyArray(
                query.oldProductCategories
              )}
            ){
              uuid
              rebateName {name}
            }
          }
      `;
    default:
      return;
  }
};

export const updateMutations = (query) => {
  switch (query.type || query.pageType) {
    case "utility":
      return gql`
          mutation {
            updateUtility(
              uuid: "${query.uuid}",
              name: "${query.name}",
              stateId: "${query.stateId}",
              description: "${
                query.description
                  ? query.description
                  : "No description available."
              }",
              logoUrl: "${query.logoUrl}",
              utilityOwnershipTypeId: "${query.utilityOwnershipTypeId}"
            ){
              name
              uuid
              websitesBridgeId
              socialMediaLinksBridgeId
              contactsBridgeId
              reportsBridgeId
            }
          }
          `;
    case "program":
      return gql`
          mutation {
            updateProgram(
              uuid: "${query.uuid}",
              isHidden: ${query.isHidden},
              name: """${query.name}""",
              startDate: """${new Date(query.startDate).getTime()}""",
              endDate: """${new Date(query.endDate).getTime()}""",
              description: """${
                query.description
                  ? query.description
                  : "No description available."
              }""",
              logoUrl: """${query.logoUrl}""",
              isActive: ${query.isActive},
              statusNotes: """${query.statusNotes}""",
              requirements: """${
                query.requirements
                  ? query.requirements
                  : "No additional requirements."
              }""",
              maximumAnnualIncentive: ${
                query.maximumAnnualIncentive === ""
                  ? null
                  : query.maximumAnnualIncentive
              },
              projectCap: ${query.projectCap === "" ? null : query.projectCap},
              laborCap: ${query.laborCap === "" ? null : query.laborCap},
              materialCap: ${
                query.materialCap === "" ? null : query.materialCap
              },
              totalAvailable: ${
                query.totalAvailable === "" ? null : query.totalAvailable
              },
              totalUtilized: ${
                query.totalUtilized === "" ? null : query.totalUtilized
              }
            ){
              name
              uuid
              websitesBridgeId
              applicationLinksBridgeId
              contactsBridgeId
              reportsBridgeId
            }
          }
      `;
    case "websites":
      return gql`
          mutation {
            updateWebsite(
              uuid: "${query.uuid}",
              url: "${query.url}",
              linkText: "${query.linkText}"
            ){
              url
              linkText
            }
          }
      `;
    case "socialMediaLinks":
      return gql`
        mutation {
          updateSocialMediaLink(
            uuid: "${query.uuid}",
            socialMediaLinkPlatformId: "${query.socialMediaLinkPlatformId}",
            url: "${query.url}"
          ){
            uuid
            url
          }
        }
      `;
    case "contacts":
      return gql`
        mutation {
          updateContact(
            uuid: "${query.uuid}",
            name: "${query.name}",
            title: "${query.title}",
            phone1: "${query.phone1}",
            phone2: "${query.phone2}",
            email: "${query.email}"
          ){
            uuid
            name
          }
        }
      `;
    case "reports":
      return gql`
        mutation {
          updateReport(
            uuid: "${query.uuid}",
            name: "${query.name}",
            description: "${
              query.description
                ? query.description
                : "No description available."
            }",
            url: "${query.url}"
          ){
            uuid
            name
          }
        }
      `;
    case "state":
      return gql`
        mutation {
          updateState(
            uuid: "${query.uuid}",
            name: "${query.name}", 
            countryId: "${query.countryId}",
            description: "${
              query.description
                ? query.description
                : "No description available."
            }",
            regionId: "${query.regionId}",
            averageRetailPrice: ${query.averageRetailPrice},
            netSummerCapacity: ${query.netSummerCapacity},
            netGeneration: ${query.netGeneration},
            totalRetailSales: ${query.totalRetailSales}
          ){
            name
            uuid
          }
        }
      `;
    case "country":
      return gql`
        mutation {
          updateCountry(
            uuid: "${query.uuid}",
            isHidden: ${query.isHidden},
            name: "${query.name}", 
            description: "${query.description}"
          ){
            name
            uuid
          }
        }
      `;
    case "product":
      return gql`
          mutation {
            updateProduct(
              uuid: "${query.uuid}"
              isHidden: ${query.isHidden},
              modelNumber: "${query.modelNumber}",
              imageUrl: "${query.imageUrl}",
              QPLId: "${query.QPLId}",
              QPLIdentifier: "${query.QPLIdentifier}",
              newProductCategoryId: "${query.newProductCategoryId}",
              technologyTypeId: "${query.technologyTypeId}",
              brandId: "${query.brandId}",
              manufacturerId: "${query.manufacturerId}",
              productTypeId: "${query.productTypeId}",
              productSubCategoryId: "${query.productSubCategoryId}"
            ){
              modelNumber
              uuid
            }
          }
      `;
    case "productSpecs":
      return gql`
          mutation {
            updateProductSpec(
              uuid: "${query.uuid}"
              value: "${query.value}"
              productSpecTypeId: "${query.productSpecTypeId}"
            ){
              uuid
              value
              productSpecType{
                uuid
                value
              }
            }
          }
    `;
    case "rebate":
      return gql`
        mutation {
          updateRebate(
            uuid: "${query.uuid}",
            rebateNameId: "${query.rebateName}",
            programId: "${query.programId}",
            technologyTypeId: "${query.technologyTypeId}",
            rebateTypeId: "${query.rebateType}",
            notes: "${query.notes}",
            cap: ${query.cap || null},
            code: "${query.code}",
            rebateRates: ${stringifyArray(query.rebateRates)},
            rebateRequirements: ${stringifyArray(query.rebateRequirements)},
            minimumCustomerContribution: ${
              query.minimumCustomerContribution || null
            },
          ){
            uuid
            rebateName {name}
          }
        }
        `;
    case "oldProductCategories":
    case "newProductCategories":
      const categories = {
        newProductCategories: query.categories.filter((category) => {
          const key = Object.keys(category)[0];
          return key === "newProductCategoryId";
        }),
        oldProductCategories: query.categories.filter((category) => {
          const key = Object.keys(category)[0];
          return key === "oldProductCategoryId";
        }),
      };
      return gql`
        mutation {
          addRebateProductCategories(
            uuid: "${query.rebateId}",
            newProductCategories: ${stringifyArray(
              categories.newProductCategories
            )},
            oldProductCategories: ${stringifyArray(
              categories.oldProductCategories
            )}
          )
        }
      `;
    case "QPLs":
      return gql`
        mutation {
          addRebateQPLs(
            uuid: "${query.rebateId}",
            QPLs: ${stringifyArray(query.QPLs)}
          )
        }
      `;
    case "projectTypes":
      return gql`
        mutation {
          addRebateProjectTypes(
            uuid: "${query.rebateId}",
            projectTypes: ${stringifyArray(query.projectTypes)}
          )
        }
      `;
    case "requirements":
      return query.uuid
        ? gql`
        mutation {
          updateRebateRequirement(
            uuid: "${query.uuid}",
            value: "${query.value}",
            rebateId: "${query.rebateId}",
            rebateRequirementTypeId: "${query.rebateRequirementTypeId}"
          ){
            uuid
          }
        }
    `
        : gql`mutation{
      addRebateRequirement(value: "${query.value}", rebateId: "${query.rebateId}",
      rebateRequirementTypeId: "${query.rebateRequirementTypeId}" ){
        uuid
      }
    }`;
    case "rebateRates":
      return gql`
        mutation {
          updateRebateRate(
            uuid: "${query.uuid}",
            rebatesId: "${query.rebateId}",
            rebateRateUnitId: "${query.unit}",
            value: ${query.value}
          ){
            uuid
          }
        }
      `;

    case "customerTypes":
      return gql`
        mutation {
          addProgramCustomerType(
            uuid: "${query.programId}",
            customerTypes: ${stringifyArray(query.customerTypes)}
          ){
            uuid
          }
        }
      `;
    case "offeringTypes":
      return gql`
        mutation {
          addProgramOfferingType(
            uuid: "${query.programId}",
            offeringTypes: ${stringifyArray(query.offeringTypes)}
          ){
            uuid
          }
        }
      `;
    case "downstreamProgram":
      return gql`
        mutation {
          updateOrCreateDownstreamProgram(
            uuid: "${query.programId}",
            downstreamProgram: {
              isPreApprovalRequired: ${query.isPreApprovalRequired},
              isPreInspectionRequired: ${query.isPreInspectionRequired},
              isPostAuditRequired: ${query.isPostAuditRequired}
            }
          ){
            uuid
          }
        }
      `;
    case "programUtilities":
      return gql`
        mutation {
          addUtilityPrograms(
            uuid: "${query.programId}",
            utilities: ${stringifyArray(query.utilities)}
          ){
            uuid
          }
        }
      `;
    default:
      return;
  }
};

export const deleteMutations = ({ type, ...query }) => {
  switch (type) {
    case "productSpecs":
      return gql`
        mutation {
          deleteProductSpec(uuid: "${query.productSpecs.uuid}")
        }
      `;
    case "contacts":
      return gql`
        mutation {
          deleteContact(uuid: "${query.contacts.uuid}")
        }
      `;
    case "reports":
      return gql`
        mutation{
          deleteReport(uuid: "${query.reports.uuid}")
        }
      `;
    case "websites":
    case "applicationLinks":
      return gql`
        mutation{
          deleteWebsite(uuid: "${query[type].uuid}")
        }
      `;
    case "socialMediaLinks":
      return gql`
        mutation{
          deleteSocialMediaLink(uuid: "${query.socialMediaLinks.uuid}")
        }
      `;
    case "calculatedRebateValue":
    case "prescriptiveRebateValue":
      return gql`
          mutation {
            deleteRebateRate(uuid: "${query[type].uuid}")
          }
        `;
    case "requirements":
      return gql`
            mutation {
              deleteRebateRequirement(uuid: "${query.requirements.uuid}")
            }
          `;
    case "newProductCategories":
    case "oldProductCategories":
      const categories = {
        newProductCategories: query.categories.filter((category) => {
          const key = Object.keys(category)[0];
          return key === "newProductCategoryId";
        }),
        oldProductCategories: query.categories.filter((category) => {
          const key = Object.keys(category)[0];
          return key === "oldProductCategoryId";
        }),
      };
      return gql`
            mutation {
              deleteRebateProductCategories(
                uuid: "${query.rebateId}",
                newProductCategories: ${stringifyArray(
                  categories.newProductCategories
                )},
                oldProductCategories: ${stringifyArray(
                  categories.oldProductCategories
                )}
                )
            }
      `;
    case "QPLs":
      return gql`
            mutation {
              deleteRebateQPLs(
                uuid: "${query.rebateId}",
                QPLs: ${stringifyArray(query.QPLs)}
              )
            }
      `;
    case "projectTypes":
      return gql`
            mutation {
              deleteRebateProjectTypes(
                uuid: "${query.rebateId}",
                projectTypes: ${stringifyArray(query.projectTypes)}
              )
            }
      `;
    case "customerTypes":
      return gql`
            mutation {
              deleteProgramCustomerType(
                uuid: "${query.programId}",
                customerTypes: ${stringifyArray(query.customerTypes)}
              )
            }
      `;
    case "offeringTypes":
      return gql`
            mutation {
              deleteProgramOfferingType(
                uuid: "${query.programId}",
                offeringTypes: ${stringifyArray(query.offeringTypes)}
              )
            }
      `;
    case "downstreamProgram":
      return gql`
            mutation {
              deleteDownstreamProgram(
                uuid: "${query.programId}"
              )
            }
      `;
    case "programUtilities":
      return gql`
            mutation {
              deleteUtilityPrograms(
                uuid: "${query.programId}",
                utilities: ${stringifyArray(query.utilities)}
              )
            }
      `;
    default:
      return;
  }
};
