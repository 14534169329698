import React, {useEffect, useRef, useReducer, useState} from "react";
import {useSelector} from "react-redux";
import {useForm, Controller} from "react-hook-form";
import {useQuery, useMutation, useLazyQuery} from "@apollo/react-hooks";
import {Label} from '@react-spectrum/label';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import Select from "react-select";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import Checkmark from '@spectrum-icons/workflow/Checkmark';

import {
    TextField,
    Provider as SpectrumProvider,
    defaultTheme,
    Flex,
    View,
    ActionButton,
} from "@adobe/react-spectrum";
import {
    GET_FORM_OPTIONS,
    GET_UTILITIES_COUNT,
    GET_UTILITIES,
    GET_ESTIMATOR_VALUES,
    SUBMIT_SIMPLE_REQUEST,
    SUBMIT_PROCESS_REBATE_REQUEST,
} from "./queries";

const LIMIT_100 = 100;

const message = (message) => (
    <div style={{
        display: "flex",
        alignItems: "center",
    }}>{message.includes("success") && <div style={{color: "#16a888"}}><Checkmark/></div>}{message}
    </div>
)

const toDollars = (num) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(num).split('.')[0];
}

const Header = ({children}) => {
    return (
        <div style={{fontWeight: "bold", fontSize: "1.5em", marginLeft: 29}}>{children}</div>
    );
}

const Subheader = ({children}) => {
    return (
        <div style={{fontWeight: "bold", fontSize: "1.1em", color: "var(--accent)", marginTop: 20}}>{children}</div>
    );
}

const ErrorMessageWrapper = ({message}) => (
    <View UNSAFE_style={{color: "var(--spectrum-global-color-red-700)"}}>
        {message}
    </View>
);


export const ErrorMessages = ({errors, name}) => {
    return (
        <HookErrorMessage
            errors={errors}
            name={name}
            render={({messages, message, ...rest}) => {
                if (message !== undefined) {
                    return <ErrorMessageWrapper message={message}/>
                }

                if (messages !== undefined) {
                    Object.entries(messages).map(([type, message]) => (
                        <ErrorMessageWrapper key={type} message={message?.toString() || ""}/>
                    ))
                }

                return null;
            }}
        />
    )
}

export const narrowReactSelectStyles = {
    indicatorSeparator: (provided) => ({
        ...provided,
        margin: "7px 0",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "4px",
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    control: (provided, {isFocused}) => {
        const borderColor = isFocused ? "var(--accent)" : "var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400))";
        return {
            ...provided,
            minHeight: "32px",
            maxHeight: "32px",
            '&:hover': {borderColor},
            borderColor,
            boxShadow: borderColor,
        }
    },
};

const ResultWindow = ({header, children}) => {
    return (
        <View marginTop="size-300">
            <div style={{fontWeight: "500", marginBottom: "1em"}}>{header}</div>
            <Flex
                direction="column"
                gap="size-100"
                justifyContent="center"
                height="100%">
                {children}
            </Flex>
        </View>
    );
}

export const FormGroup = ({children, label, id}) => (
    <View id={id}>
        {label !== undefined ? <Label>{label}</Label> : null}
        {children}
    </View>
);

const APPEND_UTILITIES = "APPEND_UTILITIES";
const RESET_BELOW_STATE = "RESET_BELOW_STATE";
const RESET_ESTIMATE = "RESET_ESTIMATE";
const SET_ESTIMATE = "SET_ESTIMATE";
const SET_ESTIMATOR_VALUES = "SET_ESTIMATOR_VALUES";
const SET_PROGRAM = "SET_PROGRAM";
const SET_RESPONSE_MESSAGE = "SET_RESPONSE_MESSAGE";
const SET_UTILITIES = "SET_UTILITIES";
const SET_UTILITY_OFFSET = "SET_UTILITY_OFFSET";
const SUBMIT = "SUBMIT";
const UPDATE_UTILITY_QUERY = 'UPDATE_UTILITY_QUERY';

function reducer(state, action) {
    switch (action.type) {
        case UPDATE_UTILITY_QUERY:
            return {
                ...state,
                utility: {
                    query: action.payload.query,
                    offset: 0,
                }
            }
        case SET_UTILITY_OFFSET:
            return {
                ...state,
                utility: {
                    ...state.utility,
                    offset: action.payload.offset,
                }
            }
        case SET_UTILITIES:
            return {
                ...state,
                utilities: action.payload.utilities
            }
        case APPEND_UTILITIES:
            return {
                ...state,
                utilities: [...state.utilities, ...action.payload.utilities],
            }
        case SET_ESTIMATOR_VALUES:
            return {
                ...state,
                estimatorValues: action.payload.value
            }
        case SET_PROGRAM:
            return {
                ...state,
                program: action.payload.program
            }
        case SET_ESTIMATE:
            return {
                ...state,
                estimate: action.payload.estimate,
                cap: action.payload.cap,
            }
        case RESET_ESTIMATE:
            return {
                ...state,
                estimate: null,
                requestType: null,
                responseMessage: null,
            }
        case RESET_BELOW_STATE:
            return {
                ...state,
                estimate: null,
                requestType: null,
                responseMessage: null,
                program: null,
                utility: {
                    query: "",
                    offset: 0,
                },
                estimatorValues: null,
                utilities: []
            }
        case SET_RESPONSE_MESSAGE:
            return {
                ...state,
                responseMessage: action.payload.responseMessage,
                isSubmitting: false,
            }
        case SUBMIT:
            return {
                ...state,
                isSubmitting: true,
                requestType: action.payload.requestType,
            }
        default:
            throw new Error(`Invalid action type: ${JSON.stringify(action, null, 2)}`);
    }
}

const initialState = {
    utility: {
        query: "",
        offset: 0,
    },
    isSubmitting: false,
    utilities: [],
    program: null,
    estimate: null,
    cap: {
        program: 0,
        project: 0,
        type: 0,
    },
    estimatorValues: null,
    requestType: null,
    responseMessage: null,
}

const ProjectEstimator = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {control, errors, watch, formState, getValues, trigger, setValue} = useForm({
        defaultValues: {
            requestingCompany: {
                company: "",
                email: "",
            },
            customerInformation: {
                company: "",
                zipCode: "",
                state: null,
                city: "",
                utility: null,
                program: null,
            },
            projectInformation: {
                projectCost: "",
                kwSavings: "",
                kwhSavings: "",
            }
        },
        mode: "all",
    });
    const utilityInputValueSubject = useRef(new Subject());
    const {
        user: {email},
    } = useSelector((state) => state.account);
    const [rerenderValue, setRerenderValue] = useState(0);

    const watchState = watch("customerInformation.state");
    const watchUtility = watch("customerInformation.utility");
    const watchProgram = watch("customerInformation.program");
    const watchKwSavings = watch("projectInformation.kwSavings");
    const watchKwhSavings = watch("projectInformation.kwhSavings");
    const watchProjectCost = watch("projectInformation.projectCost");

    useEffect(() => {
        if (!email) {
            return;
        }
        if ((formState.touched.requestingCompany && formState.touched.requestingCompany.email)) {
            return;
        }
        setValue('requestingCompany.email', email)
    }, [email]);

    const {error: formOptionsError, loading: isLoadingFormOptions, data: formOptions} = useQuery(GET_FORM_OPTIONS);

    const {error: utilitiesCountError, data: utilitiesCountResponse} = useQuery(GET_UTILITIES_COUNT, {
        variables: {
            name: state.utility.query,
            stateId: watchState && watchState.value,
        },
    });
    const utilitiesCount = (utilitiesCountResponse && utilitiesCountResponse.getUtilitiesCount.count) || 0;

    const {error: utilitiesError, loading: isLoadingUtilities, data: utilitiesResponse} = useQuery(GET_UTILITIES, {
        variables: {
            name: state.utility.query,
            offset: state.utility.offset,
            stateId: watchState && watchState.value,
            limit: LIMIT_100,
        },
    });

    const [getEstimatorValues, {error: estimatorValuesError, loading: isLoadingEstimatorValues, data: estimatorData}]
        = useLazyQuery(GET_ESTIMATOR_VALUES);

    const programs = (
        !isLoadingEstimatorValues &&
        !estimatorValuesError &&
        estimatorData &&
        estimatorData.estimatorValues &&
        estimatorData.estimatorValues.programs
    ) ? estimatorData.estimatorValues.programs.map((item) => item.program) : [];

    const programInfo = (
        !isLoadingEstimatorValues &&
        !estimatorValuesError &&
        estimatorData &&
        estimatorData.estimatorValues &&
        estimatorData.estimatorValues.programs
    ) ? estimatorData.estimatorValues.programs.reduce((acc, item) => ({...acc, [item.program.value]: item}), {}) : {};

    const requestOnCompleted = ({data}) => {
        if (data.status === 'OK') {
            dispatch({
                type: SET_RESPONSE_MESSAGE,
                payload: {
                    responseMessage: 'Request has been successfully submitted',
                }
            })
        } else if (data.status === 'ERROR') {
            dispatch({
                type: SET_RESPONSE_MESSAGE,
                payload: {
                    responseMessage: data.reason,
                }
            })
        } else {
            dispatch({
                type: SET_RESPONSE_MESSAGE,
                payload: {
                    responseMessage: "Sorry, there was an error",
                }
            })
        }
    }

    const [submitSimpleRequest]
        = useMutation(SUBMIT_SIMPLE_REQUEST, {
        onCompleted: requestOnCompleted
    });

    const [submitProcessRebateRequest]
        = useMutation(SUBMIT_PROCESS_REBATE_REQUEST, {
        onCompleted: requestOnCompleted
    });

    useEffect(() => {
        if (!state.isSubmitting) {
            return;
        }

        const values = getValues();
        const userData = {
            requestingCompany: {
                company: values.requestingCompany.company,
                email: values.requestingCompany.email,
            },
            customerInformation: {
                company: values.customerInformation.company,
                city: values.customerInformation.city,
                zipCode: values.customerInformation.zipCode,
                state: {
                    name: values.customerInformation.state.label,
                    id: values.customerInformation.state.value,
                },
                utility: {
                    name: values.customerInformation.utility.label,
                    id: values.customerInformation.utility.value,
                },
            }
        };
        if (['CONTACT_UTILITY', 'NOTIFY_WHEN_PROGRAM_LAUNCHES'].includes(state.requestType)) {
            submitSimpleRequest({
                variables: {
                    requestType: state.requestType,
                    userData,
                }
            })
        } else if (['PROCESS_REBATE'].includes(state.requestType)) {
            userData.customerInformation.program = {
                id: values.customerInformation.program.value,
                name: values.customerInformation.program.label,
            }

            userData.projectInformation = {
                totalProjectCost: values.projectInformation.projectCost,
            }

            for (const field of ['kwSavings', 'kwhSavings']) {
                if (values.projectInformation[field]) {
                    userData.projectInformation[field] = values.projectInformation[field];
                }
            }

            const errors = [];
            const estimates = [];

            for (const program of estimatorData.estimatorValues.programs) {
                if (program.program.value !== values.customerInformation.program.value) {
                    continue;
                }
                switch (program.__typename) {
                    case "EstimatorData": {
                        const item = {
                            ...program,
                            program: {
                                id: program.program.value,
                                name: program.program.label,
                            },
                            estimate: calculateIncentive(program.program.value, calculateProjectCap(program.program.value).project),
                        };

                        delete item.__typename;
                        estimates.push(item)
                        break
                    }
                    case "CannotCalculateOnProgramLevel": {
                        const item = {
                            ...program,
                            program: {
                                id: program.program.value,
                                name: program.program.label,
                            }
                        }
                        delete item.__typename;
                        errors.push(item)
                        break
                    }
                }
            }

            submitProcessRebateRequest({
                variables: {
                    errors,
                    estimates,
                    userData,
                }
            })
        }
    }, [state.isSubmitting]);

    const calculateProjectCap = (programId) => {
        const {projectInformation} = getValues();
        const info = programInfo[programId];
        if (projectInformation.projectCost === "") {
            setValue("projectInformation.projectCost", "0");
        }

        const projectCost = parseInt(projectInformation.projectCost, 10);
        const programCap = info.program.projectCap.value;
        const projectCap = info.program.projectCap.type === 'PERCENTAGE'
            ? (programCap / 100) * projectCost
            : projectCost < programCap ? projectCost : programCap;

        return {
            program: programCap,
            type: info.program.projectCap.type,
            project: projectCap,
        }
    }

    const calculateIncentive = (programId, projectCap) => {
        const {projectInformation} = getValues();
        const info = programInfo[programId];

        let uncappedIncentive = 0;

        let kwSavings;
        const amountPaidPerKwSaved = info.kw;
        if (amountPaidPerKwSaved) {
            if (projectInformation.kwSavings === "") {
                setValue("projectInformation.kwSavings", "0");
            }
            kwSavings = parseInt(projectInformation.kwSavings, 10) || 0;
            uncappedIncentive += kwSavings * amountPaidPerKwSaved;
        }

        let kwhSavings;
        const amountPaidPerKwhSaved = info.kwh;
        if (amountPaidPerKwhSaved) {
            if (projectInformation.kwhSavings === "") {
                setValue("projectInformation.kwhSavings", "0");
            }
            kwhSavings = parseInt(projectInformation.kwhSavings, 10) || 0;
            uncappedIncentive += kwhSavings * amountPaidPerKwhSaved;
        }

        let estimate = uncappedIncentive > projectCap ? projectCap : uncappedIncentive;

        return Math.floor(estimate);
    }


    useEffect(() => {
        if (!watchUtility) {
            return;
        }
        setValue('customerInformation.program', null);
        getEstimatorValues({variables: {utilityId: watchUtility.value}})
    }, [watchUtility]);

    useEffect(() => {
        dispatch({
            type: "RESET_ESTIMATE",
        })
    }, [watchProgram, watchUtility, watchKwSavings, watchKwhSavings, watchProjectCost])

    useEffect(() => {
        if (watchState === null) {
            return;
        }
        dispatch({
            type: "RESET_BELOW_STATE",
        })
        setValue('customerInformation', {
            utility: null,
            program: null,
            city: "",
        });
        setValue('projectInformation', {
            projectCost: "",
            kwSavings: "",
            kwhSavings: "",
        });
    }, [watchState])

    useEffect(() => {
        if (utilitiesResponse !== undefined) {
            const payload = {
                utilities: [...utilitiesResponse.utilities]
            };

            if (state.utility.offset === 0) {
                dispatch({
                    type: SET_UTILITIES,
                    payload,
                })
            } else {
                dispatch({
                    type: APPEND_UTILITIES,
                    payload,
                })
            }
        }
    }, [utilitiesResponse]);

    useEffect(() => {
        if (utilitiesResponse !== undefined) {
            dispatch({
                type: SET_UTILITIES,
                payload: {
                    utilities: [...utilitiesResponse.utilities]
                }
            })
        }
    }, [rerenderValue]);

    useEffect(() => {
        const subscription = utilityInputValueSubject.current.pipe(debounceTime(500)).subscribe((value) => {
            dispatch({
                type: UPDATE_UTILITY_QUERY,
                payload: {
                    query: value,
                }
            })
        });

        return () => subscription.unsubscribe();
    }, [utilityInputValueSubject]);

    const onSubmit = (requestType) => {
        trigger()
            .then((isValid) => {
                if (!isValid) {
                    return
                }
                dispatch({
                    type: "SUBMIT",
                    payload: {requestType}
                })
            })
    }

    if (formOptionsError || utilitiesError || utilitiesCountError || estimatorValuesError) {
        formOptionsError && console.error({formOptionsError});
        utilitiesCountError && console.error({utilitiesCountError});
        utilitiesError && console.error({utilitiesError});
        estimatorValuesError && console.error({estimatorValuesError});
        return <p>Sorry, there was an error</p>
    }

    return (
        <SpectrumProvider theme={defaultTheme} colorScheme="light" UNSAFE_style={{backgroundColor: "white"}}>
            <Header>Incentive Estimator</Header>
            <form style={{marginBottom: "30px"}}>
                <Flex direction="column" margin="0 30px" maxWidth="650px" width="100%" gap="size-100">
                    <Subheader>Requesting Company</Subheader>
                    <Controller
                        control={control}
                        name={`requestingCompany.company`}
                        rules={{
                            required: "Company is required",
                            minLength: 2
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Company"
                                UNSAFE_style={{width: "100%"}}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="requestingCompany.company"/>

                    <Controller
                        control={control}
                        name={`requestingCompany.email`}
                        rules={{
                            validate: {
                                required: (value) => {
                                    if (value === "") {
                                        return "Email is required.";
                                    }

                                    return true;
                                },
                                email: (value) => {
                                    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    const isValidEmail = re.test(value);

                                    if (!isValidEmail) {
                                        return "Email must be valid"
                                    }

                                    return true;
                                },
                            },
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Email"
                                UNSAFE_style={{width: "100%"}}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="requestingCompany.email"/>

                    <Subheader>Project Information</Subheader>

                    <Controller
                        control={control}
                        name={`customerInformation.company`}
                        rules={{
                            required: "Company is required"
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Customer company"
                                UNSAFE_style={{width: "100%"}}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="customerInformation.company"/>

                    <Controller
                        control={control}
                        name={`customerInformation.zipCode`}
                        rules={{
                            validate: {
                                required: (value) => {
                                    if (value === "") {
                                        return "Zip code is required.";
                                    }

                                    return true;
                                },
                                zipCode: (value) => {
                                    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                                    const isValidZipCode = re.test(value);

                                    if (!isValidZipCode) {
                                        return "Zip code must be valid"
                                    }

                                    return true;
                                },
                            },
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Zip Code"
                                UNSAFE_style={{width: "100%"}}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="customerInformation.zipCode"/>

                    <FormGroup label="State" id="stateSelect">
                        <Controller
                            control={control}
                            name={`customerInformation.state`}
                            rules={{
                                required: "State is required"
                            }}
                            render={(props) => (
                                <Select
                                    {...props}
                                    placeholder=""
                                    styles={narrowReactSelectStyles}
                                    isLoading={isLoadingFormOptions}
                                    options={isLoadingFormOptions ? [] : formOptions.states}
                                />
                            )}
                        />
                    </FormGroup>

                    <ErrorMessages errors={errors} name="customerInformation.state"/>


                    <Controller
                        control={control}
                        name={`customerInformation.city`}
                        rules={{
                            required: "City is required"
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="City"
                                UNSAFE_style={{width: "100%"}}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="customerInformation.city"/>

                    <FormGroup label={watchState === null ? "Utility" : `Utility (${watchState.label})`}
                               id="stateSelect">
                        <Controller
                            control={control}
                            name={`customerInformation.utility`}
                            render={(props) => (
                                <Select
                                    {...props}
                                    placeholder=""
                                    isLoading={isLoadingUtilities}
                                    options={state.utilities}
                                    onFocus={() => {
                                        setRerenderValue(rerenderValue + 1);
                                    }}
                                    styles={narrowReactSelectStyles}
                                    onInputChange={(value, {action}) => {
                                        if (action === "input-change") {
                                            utilityInputValueSubject.current.next(value);
                                        }
                                        if (action === "menu-close") {
                                            utilityInputValueSubject.current.next("");
                                        }
                                    }}
                                    onMenuScrollToBottom={() => {
                                        if (!isLoadingUtilities && state.utilities.length < utilitiesCount) {
                                            dispatch({
                                                type: SET_UTILITY_OFFSET,
                                                payload: {
                                                    offset: state.utility.offset + LIMIT_100,
                                                }
                                            });
                                        }
                                    }}
                                />
                            )}
                        />
                    </FormGroup>

                    <View
                        isHidden={watchUtility === null || !estimatorData || isLoadingEstimatorValues || programs.length === 0}>
                        <FormGroup label="Program" id="programSelect">
                            <Controller
                                control={control}
                                name={`customerInformation.program`}
                                render={(props) => (
                                    <Select
                                        {...props}
                                        styles={narrowReactSelectStyles}
                                        options={programs}
                                    />
                                )}
                            />
                        </FormGroup>
                    </View>

                    {
                        (watchUtility !== null &&
                            estimatorData &&
                            !isLoadingEstimatorValues &&
                            estimatorData.estimatorValues.__typename === "CannotCalculateOnUtilityLevel" &&
                            estimatorData.estimatorValues.reason === "NO_COMMERCIAL_PROGRAMS") && (
                            <View>
                                <ResultWindow header="No Commercial Programs">
                                    <div style={{marginBottom: "15px"}}>Unfortunately, no commercial programs are
                                        available for the selected utility at the moment. We can tell your utility that
                                        you would like to get a rebate for energy efficiency projects.
                                    </div>
                                    {
                                        state.responseMessage === null && !state.isSubmitting && (
                                            <ActionButton
                                                onPress={() => onSubmit("CONTACT_UTILITY")}
                                                width="size-1700"
                                                UNSAFE_className="cta"
                                                UNSAFE_style={{
                                                    backgroundColor: "var(--accent)",
                                                    borderColor: "var(--accent)",
                                                    color: "white"
                                                }}>Yes, please</ActionButton>
                                        )
                                    }
                                    {
                                        state.isSubmittingisSubmitting && (<div>Loading...</div>)
                                    }
                                    {
                                        state.responseMessage !== null && message(state.responseMessage)
                                    }
                                </ResultWindow>
                            </View>
                        )
                    }

                    {
                        (watchUtility !== null &&
                            estimatorData &&
                            !isLoadingEstimatorValues &&
                            estimatorData.estimatorValues.__typename === "CannotCalculateOnUtilityLevel" &&
                            estimatorData.estimatorValues.reason === "ALL_PROGRAMS_CLOSED") && (
                            <View>
                                <ResultWindow header="No active commercial programs">
                                    <div style={{marginBottom: "15px"}}>Unfortunately, no commercial programs are
                                        available for the selected utility at the moment. We can notify you when a new
                                        program has been launched or an old one is available again.
                                    </div>

                                    {
                                        state.responseMessage === null && !state.isSubmitting && (
                                            <ActionButton
                                                onPress={() => onSubmit("NOTIFY_WHEN_PROGRAM_LAUNCHES")}
                                                UNSAFE_className="cta"
                                                width="size-1700"
                                                UNSAFE_style={{
                                                    backgroundColor: "var(--accent)",
                                                    borderColor: "var(--accent)",
                                                    color: "white"
                                                }}>Yes, notify me</ActionButton>
                                        )
                                    }
                                    {
                                        state.isSubmitting && (<div>Loading...</div>)
                                    }
                                    {
                                        state.responseMessage !== null && message(state.responseMessage)
                                    }
                                </ResultWindow>
                            </View>
                        )
                    }

                    {
                        (watchProgram !== null && estimatorData && !isLoadingEstimatorValues) && (
                            <Flex gap="size-300">
                                <Flex direction="column" margin="0 30px 0 0" maxWidth="150px" width="100%"
                                      gap="size-100">
                                    <Controller
                                        control={control}
                                        name={`projectInformation.projectCost`}
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (watchProgram === null) {
                                                        return true;
                                                    }

                                                    if (value === "" || value === null) {
                                                        return "Project cost is required";
                                                    }

                                                    return true;
                                                }
                                            }
                                        }}
                                        render={(props) => (
                                            <TextField
                                                {...props}
                                                label="Project cost ($)"
                                                type="number"
                                                UNSAFE_style={{width: "100%"}}
                                            />
                                        )}
                                    />
                                    <ErrorMessages errors={errors} name="projectInformation.projectCost"/>

                                    {
                                        programInfo[watchProgram.value].kw && (
                                            <Controller
                                                control={control}
                                                name={`projectInformation.kwSavings`}
                                                render={(props) => (
                                                    <TextField
                                                        {...props}
                                                        label="kW savings"
                                                        type="number"
                                                        UNSAFE_style={{width: "100%"}}
                                                    />
                                                )}
                                            />
                                        )
                                    }

                                    {
                                        programInfo[watchProgram.value].kwh && (
                                            <Controller
                                                control={control}
                                                name={`projectInformation.kwhSavings`}
                                                render={(props) => (
                                                    <TextField
                                                        {...props}
                                                        label="kWh savings"
                                                        type="number"
                                                        UNSAFE_style={{width: "100%"}}
                                                    />
                                                )}
                                            />
                                        )
                                    }
                                </Flex>

                                {
                                    state.estimate && (
                                        <View>
                                            <ResultWindow header="Estimated incentive">
                                                <div style={{marginBottom: "15px"}}>Incentive is capped at {
                                                    state.cap.type === "PERCENTAGE"
                                                        ? `${state.cap.program}% of project cost (${toDollars(state.cap.project)})`
                                                        : `${toDollars(state.cap.program)}`

                                                }. Your estimated incentive is <span
                                                    style={{
                                                        color: "var(--accent)",
                                                        fontWeight: "500"
                                                    }}>{state.estimate}</span>.
                                                    Contact our team to have rebate processed.
                                                </div>
                                            </ResultWindow>
                                        </View>
                                    )
                                }

                                {
                                    programInfo[watchProgram.value].__typename === "CannotCalculateOnProgramLevel" && (
                                        <View>
                                            <ResultWindow header="Additional information required">
                                                <div style={{marginBottom: "15px"}}>
                                                    Please contact our team to have rebate processed.
                                                </div>
                                            </ResultWindow>
                                        </View>
                                    )
                                }
                            </Flex>
                        )
                    }

                    {
                        (watchProgram !== null && estimatorData && !isLoadingEstimatorValues) && (
                            <Flex gap="size-300" marginTop="size-300">
                                {
                                    programInfo[watchProgram.value].__typename === "EstimatorData" && (
                                        <ActionButton
                                            maxWidth="150px"
                                            width="100%"
                                            onPress={() => {
                                                const cap = calculateProjectCap(watchProgram.value);
                                                dispatch({
                                                    type: "SET_ESTIMATE",
                                                    payload: {
                                                        cap,
                                                        estimate: toDollars(calculateIncentive(watchProgram.value, cap.project)),
                                                    }
                                                })
                                            }}
                                        >Calculate</ActionButton>
                                    )
                                }
                                {
                                    programInfo[watchProgram.value].__typename === "CannotCalculateOnProgramLevel" && (
                                        <View maxWidth="150px"
                                              width="100%">
                                            <input type="hidden"/>
                                        </View>
                                    )
                                }
                                {
                                    (((programInfo[watchProgram.value].__typename === "EstimatorData" && state.estimate !== null) || (programInfo[watchProgram.value].__typename === "CannotCalculateOnProgramLevel")) &&
                                        state.requestType === null && (
                                            <ActionButton
                                                onPress={() => onSubmit("PROCESS_REBATE")}
                                                width="size-1700"
                                                UNSAFE_style={{
                                                    backgroundColor: "var(--accent)",
                                                    borderColor: "var(--accent)",
                                                    color: "white",
                                                    cursor: "pointer"
                                                }}
                                                UNSAFE_className="cta"
                                            >Process rebate</ActionButton>
                                        ))
                                }
                                {
                                    state.isSubmitting && (<div>Loading...</div>)
                                }
                                {
                                    state.responseMessage !== null && message(state.responseMessage)
                                }

                            </Flex>
                        )
                    }
                </Flex>
            </form>
        </SpectrumProvider>
    )
}

export default ProjectEstimator;
