import defaults from "./defaults";

export default {
  colors: {
    text: "#00251c",
    border: "#e8e8e8",
    boxShadow: "1px 1px 20px #e8e8e8",
    primary: "#16a888",
    backgroundMain: "white",
    backgroundAccent: "#16a888",
    backgroundAccentLight: "#f1faf8"
  },
  ...defaults
};
