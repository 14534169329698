import { SUCCESS, ERROR, DISMISS } from "./types";

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  const { messages } = state;
  const { message } = action;

  switch (action.type) {
    case SUCCESS:
      return {
        messages: [
          ...messages.filter((message) => message.type === "success"),
          { type: "success", text: message, id: Date.now() },
        ],
      };
    case ERROR:
      return {
        messages: [
          ...messages,
          { type: "error", text: message, id: Date.now() },
        ],
      };
    case DISMISS:
      return {
        messages: [
          ...messages.filter((existingMessage) => existingMessage !== message),
        ],
      };
    default:
      return state;
  }
};
