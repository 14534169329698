import React from "react";
import CreatableSelect from "react-select/creatable";
import { Query } from "react-apollo";
import SvgIcon from "@material-ui/core/SvgIcon";

const GraphQLCreatableSelect = props => {
  const styles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      borderRadius: 0,
      borderBottom: state.isFocused
        ? "2px solid #16a888"
        : "1px solid rgba(0, 0, 0, 0.42)",
      boxShadow: state.isFocused ? "none" : "none",
      cursor: "pointer",
      color: state.isFocused ? "black" : "rgba(0, 0, 0, 0.42)",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        borderBottom: state.isFocused ? "2px solid #16a888" : "1px solid black"
      }
    }),

    singleValue: base => ({
      ...base,
      marginLeft: "-8px",
      fontFamily: "proxima-nova, sans-serif",
      color: "black"
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? "black" : "black",
      fontFamily: "proxima-nova, sans-serif"
    })
  };

  return (
    <Query query={props.optionsQuery} key={props.name}>
      {({ loading, error, data }) => {
        if (error)
          return (
            <>
              <label htmlFor={props.id}>{props.title}</label>
              <p>Error loading options</p>
            </>
          );
        let options = [];

        if (data) {
          options = data.rebateRequirementTypes.map(
            ({ uuid, value, productSpecType, technologyType }) => ({
              value: uuid,
              label: value,
              productSpecTypeId: productSpecType.uuid,
              technologyTypeId: technologyType.uuid
            })
          );
        }

        return (
          <CreatableSelect
            onChange={value =>
              props.handleChange(props.name, value, props.parent)
            }
            {...props}
            value={props.value}
            options={options}
            styles={styles}
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "rgba(0,0,0,0.08)",
                primary25: "rgba(0,0,0,0.04)"
              }
            })}
          />
        );
      }}
    </Query>
  );
};

export default GraphQLCreatableSelect;

const DropdownIndicator = props => (
  <SvgIcon {...props}>
    <path d="M7 10l5 5 5-5z"></path>
  </SvgIcon>
);
