import defaults from "./defaults";

export default {
  colors: {
    text: "#c2c2c2",
    border: "#808080",
    boxShadow: "none",
    primary: "#16a888",
    backgroundMain: "#0e0e0e",
    backgroundAccent: "#0e0e0e",
    backgroundAccentLight: "#1a1a1a"
  },
  ...defaults
};
