import {SelectOption, UUID} from "../../types";

export enum RebateApplicationStage {
    NEW = "NEW",
    ESTIMATE = "ESTIMATE",
    NO_REBATE = "NO_REBATE",
    PRE_APPROVAL_APPLICATION = "PRE_APPROVAL_APPLICATION",
    INSTALLATION = "INSTALLATION",
    POST_APPROVAL_APPLICATION = "POST_APPROVAL_APPLICATION",
    PAYMENT_PROCESS = "PAYMENT_PROCESS",
    CLOSED = "CLOSED",
}

export interface RebateApplicationCompany {
    id: UUID,
    name: string,
}

export interface RebateApplicationFulfillmentManager {
    id: UUID,
    name: string,
}

export interface RebateApplicationCountry {
    id: UUID,
    name: string,
}

export interface RebateApplicationState {
    id: UUID,
    name: string,
}

export interface RebateApplicationCanadianArea {
    id: number,
    name: string,
}

export interface RebateApplicationTechnologyType {
    id: number,
    name: string,
}

export interface RebateApplicationUtility {
    id: UUID,
    name: string,
}

export interface RebateApplication {
    applicationId: string,
    budgetYear: number | null,
    canadianArea: RebateApplicationCanadianArea | null,
    city: string,
    company: RebateApplicationCompany,
    country: RebateApplicationCountry,
    createdAt: string,
    customer: string,
    finalRebateAmount: number | null,
    fulfillmentCharge: string,
    fulfillmentManager: RebateApplicationFulfillmentManager | null,
    id: string,
    managementFee: number | null,
    notes: string,
    preApprovedRebateAmount: number | null,
    projectCost: number,
    projectDeadline: string | null,
    projectName: string,
    rebateAmountPaid: number | null,
    rebateEstimate: number | null,
    stage: RebateApplicationStage,
    technologyType: RebateApplicationTechnologyType,
    updatedAt: string,
    usState: RebateApplicationState | null,
    utility: RebateApplicationUtility | null,
    zipCode: string,
}

export const RebateApplicationStageDisplayNames = new Map<RebateApplicationStage, string>([
    [RebateApplicationStage.NEW, "New"],
    [RebateApplicationStage.ESTIMATE, "Estimate"],
    [RebateApplicationStage.NO_REBATE, "No rebate"],
    [RebateApplicationStage.PRE_APPROVAL_APPLICATION, "Pre-approval application"],
    [RebateApplicationStage.INSTALLATION, "Installation"],
    [RebateApplicationStage.POST_APPROVAL_APPLICATION, "Post-approval application"],
    [RebateApplicationStage.PAYMENT_PROCESS, "Payment process"],
    [RebateApplicationStage.CLOSED, "Closed"],
]);

export const stageOptions: Array<SelectOption> = Array.from(RebateApplicationStageDisplayNames.entries()).map(([value, label]) => ({
    value,
    label
}))

export interface RebateApplicationInput {
    applicationId: string,
    budgetYear: number | null,
    canadianArea?: RebateApplicationCanadianArea,
    city: string,
    company: RebateApplicationCompany,
    country: RebateApplicationCountry,
    customer: string,
    finalRebateAmount: number | null,
    fulfillmentCharge: string,
    fulfillmentManager?: RebateApplicationFulfillmentManager,
    id: string,
    managementFee: number | null,
    notes: string,
    preApprovedRebateAmount: number | null,
    projectCost: number | null,
    projectDeadline: string | null,
    projectName: string,
    rebateAmountPaid: number | null,
    rebateEstimate: number | null,
    stage?: RebateApplicationStage,
    technologyType: RebateApplicationTechnologyType,
    usState: RebateApplicationState,
    utility?: RebateApplicationUtility | null,
    zipCode: string,
}
