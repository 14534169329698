import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signoutUser } from "../../Account/ducks/actions";

import styled from "styled-components";
import MediaQuery from "react-responsive";
import UserBadge from "./UserBadge";

import { Button } from "./Button";
import WikiSearch from "../../Wiki/components/WikiSearch";
import MenuItem from "./MenuItem";

const SearchLogo = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512.005 512.005"
      style={{
        enableBackground: "new 0 0 512.005 512.005",
        width: "1em",
        position: "absolute",
        zIndex: "1",
        left: "0.6em",
        fill: "#808080",
        top: "0.5em",
      }}
    >
      <path
        d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
            S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
            c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
             M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"
      />
    </svg>
  );
};

const TopMenu = ({ isLoggedIn, user, user: { company }, signoutUser }) => {
  const iconUrl = "https://s3.amazonaws.com/v2.rebatebus/images/icons/";

  const shouldUseCompanyLogo = company && company.logo;

  const Logo = (
    <LogoWrapper height={shouldUseCompanyLogo ? "2em" : null}>
      <Link to="/">
        <img
          src={
            shouldUseCompanyLogo
              ? company.logo
              : "https://s3.amazonaws.com/v2.rebatebus/images/logos/logoWhite.svg"
          }
          alt={
            shouldUseCompanyLogo ? `${company.name} Logo` : "Rebate Bus Logo"
          }
        />
      </Link>
    </LogoWrapper>
  );

  const menuLeft = Logo;

  const { token } = localStorage;
  const menuRight =
    isLoggedIn || token ? (
      <LoggedInIcons>
        {user.permissions >= 3 && (
          <MenuItem
            isTopMenu
            url="/search/add"
            imgSrc={`${iconUrl}addWikiPageIcon.svg`}
            imgAlt="Add Page to Wiki"
          />
        )}
        <MediaQuery minDeviceWidth={769}>
          <MenuItem
            isTopMenu
            url="/profile"
            imgSrc={`${iconUrl}profileIcon.svg`}
            imgAlt="User Profile"
          />
          <MenuItem
            isTopMenu
            url="/"
            imgSrc={`${iconUrl}logoutIcon.svg`}
            imgAlt="Sign Out"
            handleClick={() => signoutUser()}
          />
        </MediaQuery>
      </LoggedInIcons>
    ) : (
      <SigninButtons>
        <Link to="/signup">
          <TopMenuButton>sign up</TopMenuButton>
        </Link>
        <Link to="/signin">
          <TopMenuButton>sign in</TopMenuButton>
        </Link>
      </SigninButtons>
    );

  return (
    <TopMenuWrapper>
      <StyledTopMenu>
        {menuLeft}

        <MediaQuery minDeviceWidth={769}>
          <SearchWrapper>
            <SearchLogo />
            <WikiSearch isMobile={false} />
          </SearchWrapper>
        </MediaQuery>

        {menuRight}
      </StyledTopMenu>
    </TopMenuWrapper>
  );
};

export default connect(null, { signoutUser })(TopMenu);

TopMenu.propTypes = {
  /** Determines what to show */
  isLoggedIn: PropTypes.bool.isRequired,
  /** Used to show user badge */
  user: PropTypes.object.isRequired,
  /** Function from Redux to signout user */
  signoutUser: PropTypes.func.isRequired,
};

const TopMenuWrapper = styled.div`
  background-color: #16a888;
  height: 3em;
  z-index: 10;
  padding-left: 1.25em;
  padding-right: 1.25em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTopMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TopMenuButton = styled(Button)`
  background-color: inherit;
  border: 1px solid white;
  color: white;
  width: 6em;
  padding: 0.5em;
  font-weight: 700;
  border-radius: 7px;
  &:last-of-type {
    margin-left: 1em;
  }
`;

const LoggedInBadge = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div.attrs((props) => ({
  height: props.height ? props.height : "0.8em",
}))`
  height: ${(props) => props.height};
  width: 9.75em;
  img {
    height: 100%;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  flex: 0 0 50%;
  flex-grow: 2;
`;

const LoggedInIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  * {
    color: white;
  }
  a {
    margin: 0;
    margin-left: 1em;

    &:last-of-type {
      margin-right: 1em;
    }
    &:first-of-type {
      margin: 0;
    }
  }
  img {
    width: 1.5em;
  }
`;

const SigninButtons = styled.div`
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: center;
    & > a {
      margin-bottom: 0;
    }
  }
`;
