import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import GrayBanner from "../../Common/components/GrayBanner.jsx";

const Announcement = ({ imgUrl, imgAlt, title, announcement, ...props }) => {
  return (
    <GrayBanner imgUrl={imgUrl} imgAlt={imgAlt} {...props}>
      <Wrapper>
        <div className="title">
          <h2>{title}</h2>
        </div>
        {announcement}
      </Wrapper>
    </GrayBanner>
  );
};

export default Announcement;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Button = styled.p`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-self: flex-end;
  border: 1.2px solid #18a888;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  width: 11em;
  background-color: #0ea788;
  color: white;
`;

Announcement.propTypes = {
  imgUrl: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  announcement: PropTypes.node.isRequired
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  a {
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
  }
  h2 {
    color: black;
  }
  p {
    line-height: 1.2em;

    &.description {
      color: ${props => props.theme.rbGreen.regular};
      margin-bottom: 0;
      font-weight: 300;
    }

    &.footer {
      font-size: 0.8em;
      color: #686868;
      margin: 0;
    }
  }
`;

export const announcements = [
  {
    noImg: true,
    title: "Incentive Estimate",
    announcement: (
        <>
          <p style={{margin: 0, padding: 0}}>
            Easily request incentive estimate for your project.
            <br/>
            <br/>
            <StyledLink to="/services/request-estimate">
              <Button>Request Estimate</Button>
            </StyledLink>
          </p>

        </>
    )
  },
  {
    noImg: true,
    title: "Rebate Search",
    announcement: (
        <>
          <p style={{margin: 0, padding: 0}}>
            We've substantially redesigned and enhanced our <Link to="/search/rebates/lighting">Lighting Rebate Search</Link>. Now you
            can easily filter by new products, states, utilities, programs, project types, etc. More is coming soon!
            <br/>
            <br/>
            <StyledLink to="/search/rebates/lighting">
              <Button>Search Rebates</Button>
            </StyledLink>
          </p>

        </>
    )
  },
  {
    noImg: true,
    title: "Lighting Incentive Estimator",
    announcement: (
        <>
          <p style={{margin: 0, padding: 0}}>
            We’re pleased to introduce our brand new <Link to="/services/incentive-estimator">Lighting Incentive Estimator</Link>,
              which automatically calculates lighting incentive for you project based on its cost and energy savings.
              <br/>
              <br/>
            <StyledLink to="/services/incentive-estimator">
              <Button>Try it out now!</Button>
            </StyledLink>
          </p>

        </>
    )
  },
  {
    noImg: true,
    title: "Updating Programs in Response to Covid-19",
    announcement: (
      <>
        <p>
          Rebate Bus is aware of utility companies adapting to Covid-19. We have
          also responded to those changes by incorporating the latest program
          updates in our offerings.
        </p>
      </>
    )
  },
];
