import { GET_WIKI, SHOW_NEW, SHOW_OPTIONS } from "./types";
import apolloClient from "../../helpers/apolloClient";
import { gql } from "apollo-boost";
import { successMessage, errorMessage } from "../../Common/ducks/actions";
import { formatUrl } from "../helpers/urls";
import convertToSearchOptions from "../helpers/convertToSearchOptions";
import {
  createMutations,
  updateMutations,
  deleteMutations,
} from "../helpers/graphQL";
import sanitizeHtml from "../helpers/htmlSanitization";

// If sitemap needs to be updated
// import "cross-fetch/polyfill";

export const getWiki = () => (dispatch) => {
  apolloClient
    .query({
      query: gql`
        {
          countries {
            name
            uuid
          }
          states {
            name
            uuid
          }
          utilities {
            name
            uuid
          }
          programs {
            name
            uuid
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
    .then((res) => dispatch(displayWiki(res)));
};

const displayWiki = ({
  data: { countries, states, utilities, programs, products },
}) => ({
  type: GET_WIKI,
  countries: convertToSearchOptions(countries),
  states: convertToSearchOptions(states),
  utilities: convertToSearchOptions(utilities),
  programs: convertToSearchOptions(programs),
});

const mutationNames = {
  utility: {
    add: "createUtility",
    update: "updateUtility",
  },
  state: {
    update: "updateState",
  },
  country: {
    update: "updateCountry",
  },
  program: {
    add: "createProgram",
    update: "updateProgram",
  },
  product: {
    add: "createProduct",
    update: "updateProduct",
  },
  productSpecs: {
    delete: "deleteProductSpec",
  },
};

export const addPage = (submittedPage) => (dispatch) => {
  if (submittedPage.pageType === "program") {
    // * add utilities, customerTypes, offeringTypes, & downstreamProgram to program
    const {
      customerTypes,
      offeringTypes,
      hasDownstreamProgram,
      downstreamProgram,
      totalAvailable,
      totalUtilized,
    } = submittedPage;

    submittedPage.utilities = formatUtilities(submittedPage);

    // * get keys (uuids) of customerTypes, filter for true, then turn into an array of objects
    submittedPage.customerTypes = Object.keys(customerTypes)
      .filter((key) => customerTypes[key])
      .map((key) => ({ customerTypeId: key }));
    // * repeat for offeringTypes
    submittedPage.offeringTypes = Object.keys(offeringTypes)
      .filter((key) => offeringTypes[key])
      .map((key) => ({ offeringTypeId: key }));

    submittedPage.downstream = hasDownstreamProgram ? downstreamProgram : null;

    submittedPage.budget = { totalAvailable, totalUtilized };
  }

  ["notes", "description", "reports", "requirements"].forEach((item) => {
    if (submittedPage[item] && submittedPage[item].length > 0) {
      if (item === "reports") {
        const sanitizedReports = submittedPage.reports.map((report) => ({
          ...report,
          description: sanitizeHtml(report.description),
        }));
        submittedPage.reports = sanitizedReports;
      } else {
        submittedPage[item] = sanitizeHtml(submittedPage[item]);
      }
    }
  });

  const mutationName = mutationNames[submittedPage.pageType].add;
  const mutation = createMutations(submittedPage);
  apolloClient
    .mutate({
      mutation,
    })
    .then(({ data }) => {
      const possibleKeys = [
        { key: "websites", mutation: addNewWebsites },
        { key: "applicationLinks", mutation: addNewWebsites },
        { key: "socialMediaLinks", mutation: addNewSocialMedia },
        { key: "reports", mutation: addNewReport },
        { key: "contacts", mutation: addNewContact },
      ];

      possibleKeys.forEach(({ key, mutation }) => {
        if (submittedPage[key] && submittedPage[key].length > 0) {
          const keyName = key === "applicationLinks" ? "websites" : key;
          const bridgeId = `${keyName}BridgeId`;

          const returnedBridgeId =
            key === "applicationLinks"
              ? data[mutationName].applicationLinksBridgeId
              : data[mutationName][bridgeId];

          return mutation({
            [keyName]: submittedPage[key],
            [bridgeId]: returnedBridgeId,
          });
        }
      });

      return dispatch(
        showNewUpdatePage({
          type: submittedPage.pageType,
          uuid: data[mutationName].uuid,
          name: data[mutationName].name || data[mutationName].modelNumber,
        })
      );
    })
    .then(() => dispatch(successMessage("Page saved!")))
    .then(() => dispatch(getWiki()))
    .catch((err) => {
      console.log(err);
      return dispatch(errorMessage("Sorry, there was an error."));
    });
};

export const showNewUpdatePage = (page) => ({
  type: SHOW_NEW,
  page,
});

const formatUtilities = (program) => {
  let addedUtilities = [];
  if (program.hasMultipleUtilities) {
    // * loop through utilities and add to array if not empty string & not a duplicate
    program.multipleUtilities.forEach(({ utility }) =>
      utility && utility.length > 0 && addedUtilities.indexOf(utility) < 0
        ? addedUtilities.push(utility)
        : null
    );
    // * map over array to turn into array of objects
    addedUtilities = addedUtilities.map((utility) => ({
      utilityId: utility,
    }));
  } else {
    addedUtilities.push({ utilityId: program.singleUtility });
  }
  return addedUtilities;
};

const addNewWebsites = ({ websitesBridgeId, websites }) =>
  Promise.all(
    websites.map((website) => {
      website.url = formatUrl(website.url);

      const mutation = createMutations({
        ...website,
        pageType: "websites",
        websitesBridgeId,
      });
      return apolloClient.mutate({
        mutation,
      });
    })
  );

const addNewSocialMedia = ({ socialMediaLinksBridgeId, socialMediaLinks }) =>
  Promise.all(
    socialMediaLinks.map((link) => {
      link.url = formatUrl(link.url);
      // * link.linkText comes in as the uuid for the socialMediaLinkPlatformId from Select
      link.socialMediaLinkPlatformId = link.linkText;
      link.linkText = null;

      const mutation = createMutations({
        ...link,
        pageType: "socialMediaLinks",
        socialMediaLinksBridgeId,
      });

      return apolloClient.mutate({
        mutation,
      });
    })
  );

const addNewReport = ({ reportsBridgeId, reports }) =>
  Promise.all(
    reports.map((report) => {
      report.url = formatUrl(report.url);
      const mutation = createMutations({
        ...report,
        pageType: "reports",
        reportsBridgeId,
      });
      return apolloClient.mutate({
        mutation,
      });
    })
  );

const addNewContact = ({ contactsBridgeId, contacts }) =>
  Promise.all(
    contacts.map((contact) => {
      const mutation = createMutations({
        ...contact,
        pageType: "contacts",
        contactsBridgeId,
      });
      return apolloClient.mutate({
        mutation,
      });
    })
  );

export const updatePage = (
  submittedPage,
  updatedArrays,
  originalPage
) => async (dispatch) => {
  // * make sure there's no undefined/null/empty html
  for (const propToCheck in submittedPage) {
    // * if it's 'undefined' or null, set it to empty string
    if (
      submittedPage[propToCheck] === "undefined" ||
      submittedPage[propToCheck] === null
    ) {
      submittedPage[propToCheck] = "";
    }
  }

  ["notes", "description", "reports", "requirements"].forEach((item) => {
    if (submittedPage[item] && submittedPage[item].length > 0) {
      if (item === "reports") {
        const sanitizedReports = submittedPage.reports.map((report) => ({
          ...report,
          description: sanitizeHtml(report.description),
        }));
        submittedPage.reports = sanitizedReports;
      } else {
        submittedPage[item] = sanitizeHtml(submittedPage[item]);
      }
    }
  });

  // * Get the keys of the updatedArrays to see what needs to be updated
  const itemsToCheck = Object.keys(updatedArrays);

  // * check for changed items that have to be handled separately due to linked tables
  if (submittedPage.pageType === "program") {
    const programId = submittedPage.uuid;

    const itemsToAddUpdate = [];
    const itemsToDelete = [];

    // * format utilities to make them easier to see what's new, plus to get ready to add if necessary
    const updatedUtilities = formatUtilities(submittedPage);

    // * arrays for added/deleted utilities
    const newUtilities = [];
    const deletedUtilities = [];

    itemsToCheck.forEach((item) => {
      switch (item) {
        case "customerTypes":
        case "offeringTypes":
          const idName = {
            customerTypes: "customerTypeId",
            offeringTypes: "offeringTypeId",
          };

          const changedItems = Object.keys(updatedArrays[item].changed);

          changedItems
            .filter((key) => !updatedArrays[item].changed[key])
            .forEach((key) =>
              itemsToDelete.push({
                type: item,
                programId,
                [item]: {
                  [idName[item]]: key,
                },
              })
            );

          const newItems = changedItems
            .filter((key) => updatedArrays[item].changed[key])
            .map((key) => ({ [idName[item]]: key }));

          itemsToAddUpdate.push({
            type: item,
            programId,
            [item]: newItems,
          });
          break;
        case "downstreamProgram":
          if (
            !submittedPage.hasDownstreamProgram &&
            originalPage.hasDownstreamProgram
          ) {
            itemsToDelete.push({
              type: "downstreamProgram",
              programId,
            });
          } else {
            const {
              isPostAuditRequired,
              isPreApprovalRequired,
              isPreInspectionRequired,
            } = submittedPage.downstreamProgram;
            itemsToAddUpdate.push({
              type: "downstreamProgram",
              programId,
              isPostAuditRequired,
              isPreApprovalRequired,
              isPreInspectionRequired,
            });
          }
          break;
        case "singleUtility":
        case "multipleUtilities":
          // * if the arrays have already been updated don't run again
          if (newUtilities.length > 0 || deletedUtilities.length > 0) {
            break;
          }

          // * check for new utilities that have been added
          updatedUtilities.forEach((potentialNew) => {
            if (
              originalPage.utilities.every(
                (original) => original.utility !== potentialNew.utilityId
              )
            ) {
              newUtilities.push(potentialNew);
            }
          });

          if (newUtilities.length > 0) {
            itemsToAddUpdate.push({
              type: "programUtilities",
              programId,
              utilities: newUtilities,
            });
          }

          // * check for deleted utilities
          originalPage.utilities.forEach((original) => {
            if (
              updatedUtilities.every(
                (updated) => updated.utilityId !== original.utility
              )
            ) {
              deletedUtilities.push({ utilityId: original.utility });
            }
          });

          if (deletedUtilities.length > 0) {
            itemsToDelete.push({
              type: "programUtilities",
              programId,
              utilities: deletedUtilities,
            });
          }
          break;
        default:
          break;
      }
    });

    const addItems = async () =>
      itemsToAddUpdate.length > 0
        ? itemsToAddUpdate.map((item) => dispatch(addToUpdateProgram(item)))
        : null;

    await addItems();

    const deleteItems = async () =>
      itemsToDelete.length > 0
        ? itemsToDelete.map((item) => dispatch(deleteObject(item)))
        : null;

    await deleteItems();
  }

  const mutationName = mutationNames[submittedPage.pageType].update;
  const mutation = updateMutations(submittedPage);

  apolloClient
    .mutate({
      mutation,
    })
    .then(({ data }) => {
      itemsToCheck.forEach(async (item) => {
        switch (item) {
          case "websites":
          case "applicationLinks":
            const newLinks = potentialNewItems(submittedPage[item]);
            if (newLinks.length > 0) {
              await addNewWebsites({
                websitesBridgeId: data[mutationName][`${item}BridgeId`],
                websites: newLinks,
              });
            }

            const updatedLinks = potentialUpdatedItems(
              submittedPage[item],
              originalPage[item]
            );
            if (updatedLinks.length > 0) {
              await updateWebsites(updatedLinks);
            }
            break;
          case "reports":
            const newReports = potentialNewItems(submittedPage.reports);
            if (newReports.length > 0) {
              await addNewReport({
                reportsBridgeId: data[mutationName].reportsBridgeId,
                reports: newReports,
              });
            }

            const updatedReports = potentialUpdatedItems(
              submittedPage.reports,
              originalPage.reports
            );
            if (updatedReports.length > 0) {
              await updateReports(updatedReports);
            }
            break;
          case "contacts":
            const newContacts = potentialNewItems(submittedPage.contacts);
            if (newContacts.length > 0) {
              await addNewContact({
                contactsBridgeId: data[mutationName].contactsBridgeId,
                contacts: newContacts,
              });
            }

            const updatedContacts = potentialUpdatedItems(
              submittedPage.contacts,
              originalPage.contacts
            );
            if (updatedContacts.length > 0) {
              await updateContacts(updatedContacts);
            }
            break;
          case "socialMediaLinks":
            const newSocialMediaLinks = potentialNewItems(
              submittedPage.socialMediaLinks
            );
            if (newSocialMediaLinks.length > 0) {
              await addNewSocialMedia({
                socialMediaLinksBridgeId:
                  data[mutationName].socialMediaLinksBridgeId,
                socialMediaLinks: newSocialMediaLinks,
              });
            }

            const updatedSocialMediaLinks = potentialUpdatedItems(
              submittedPage.socialMediaLinks,
              originalPage.socialMediaLinks
            );
            if (updatedSocialMediaLinks.length > 0) {
              await updateSocialMedia(updatedSocialMediaLinks);
            }
            break;
          case "productSpecs":
            const updatedProductSpecs = potentialUpdatedItems(
              submittedPage.productSpecs,
              originalPage.productSpecs
            );
            if (updatedProductSpecs.length > 0) {
              await updateProductSpecs(updatedProductSpecs);
            }
            break;
          default:
            break;
        }
      });
      return dispatch(
        showNewUpdatePage({
          type: submittedPage.pageType,
          uuid: data[mutationName].uuid,
          name: data[mutationName].name || data[mutationName].modelNumber,
        })
      );
    })
    .then(() => dispatch(successMessage("Page updated!")))
    .catch((err) => {
      console.log("update error", err);
      return dispatch(errorMessage("Sorry, there was an error."));
    });
};

// * Get new items that don't have a uuid
const potentialNewItems = (newArray) =>
  newArray.filter((potentialNew) => !potentialNew.uuid);

// * Get updated items
const potentialUpdatedItems = (newArray, existingArray) =>
  newArray.filter((potentialUpdate) =>
    existingArray.some(
      (original) =>
        original.uuid === potentialUpdate.uuid &&
        JSON.stringify(original) !== JSON.stringify(potentialUpdate)
    )
  );

const addToUpdateProgram = (objectToAdd) => (dispatch) => {
  const mutation = updateMutations(objectToAdd);
  apolloClient.mutate({ mutation }).catch((err) => console.log(err));
};

const updateWebsites = (websites) =>
  Promise.all(
    websites.map((website) => {
      website.url = formatUrl(website.url);

      const mutation = updateMutations({
        ...website,
        pageType: "websites",
      });
      return apolloClient.mutate({
        mutation,
      });
    })
  );

const updateSocialMedia = (socialMediaLinks) =>
  Promise.all(
    socialMediaLinks.map((link) => {
      link.url = formatUrl(link.url);
      // * link.linkText comes in as the uuid for the socialMediaLinkPlatformId from Select
      link.socialMediaLinkPlatformId = link.linkText;
      link.linkText = null;

      const mutation = updateMutations({
        ...link,
        pageType: "socialMediaLinks",
      });

      return apolloClient.mutate({
        mutation,
      });
    })
  );

const updateContacts = (contacts) =>
  Promise.all(
    contacts.map((contact) => {
      const mutation = updateMutations({
        ...contact,
        pageType: "contacts",
      });

      return apolloClient.mutate({
        mutation,
      });
    })
  );

const updateReports = (reports) =>
  Promise.all(
    reports.map((report) => {
      const mutation = updateMutations({
        ...report,
        pageType: "reports",
      });

      return apolloClient.mutate({
        mutation,
      });
    })
  );

const updateProductSpecs = (productSpecs) =>
  Promise.all(
    productSpecs.map((productSpec) => {
      const mutation = updateMutations({
        ...productSpec,
        pageType: "productSpecs",
      });

      return apolloClient
        .mutate({
          mutation,
        })
        .catch((error) => console.log("new error", error));
    })
  );

export const deleteObject = (objectToDelete) => (dispatch) => {
  const mutation = deleteMutations(objectToDelete);

  apolloClient.mutate({ mutation }).catch((err) => console.log(err));
};

export const getOptions = (route) => (dispatch) => {
  apolloClient
    .query({ query: gql`{ ${route} {uuid}}` })
    .then((res) => dispatch(showOptions(res.data)));
};

export const showOptions = (options) => ({
  type: SHOW_OPTIONS,
  options,
});
