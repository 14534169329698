import store from "../../createStore";
import axios from "axios";
import apolloClient from "../../helpers/apolloClient";
import { gql } from "apollo-boost";
import { successMessage, errorMessage } from "../../Common/ducks/actions";
import { attemptSignin } from "../../Account/ducks/actions";

import { TEMP_COMPANY, COMPANY_RESULT, GET_ACCOUNT_TYPES } from "./types";

const url = {
  user: "/api/v2/user",
  subscription: "/api/v2/stripe/subscription",
};

export const getAccountTypes = () => async (dispatch) => {
  const accountTypes = await apolloClient.query({
    query: gql`
      {
        accountTypes {
          uuid
          name
          pricePerMonth
          stripeId
        }
      }
    `,
  });

  return dispatch(loadAccountTypes(accountTypes.data.accountTypes));
};

const loadAccountTypes = (plans) => ({
  type: GET_ACCOUNT_TYPES,
  plans,
});

export const tempSaveCompany = (company) => ({
  type: TEMP_COMPANY,
  company,
});

export const createNewCompanyAdmin = (user, password) => async (dispatch) => {
  const submission = {
    user: {
      ...user,
      // * temp set companyId to "None" in order to create user, then update w/ companyId later
      companyId: "aa972dad-22af-11ea-8b5f-06319dbc535a",
    },
    password: password,
    permissions: "Admin",
  };

  return await axios
    .post(url.user, submission)
    .then(({ data }) =>
      dispatch(showCompanyResult({ admin: data.uuid, adminSuccess: true }))
    )
    .then(() => {
      // * remove token in cases of testing, otherwise token shouldn't exist
      localStorage.removeItem("token");
      return dispatch(attemptSignin(user.email, password));
    })
    .then(() => dispatch(successMessage("Admin account created.")))
    .catch(({ response }) => {
      dispatch(errorMessage(response.data.message));
      dispatch(showCompanyResult({ adminSuccess: false }));
    });
};

export const createCustomerAndSubscription = (
  result,
  plan,
  billingContact
) => async (dispatch) => {
  if (result.error) {
    // do error message action? mostly should be handled on front
  } else {
    const { name, phone, email } = result.paymentMethod.billing_details;

    const customer = {
      name,
      phone,
      email,
      payment_method: result.paymentMethod.id,
    };

    // * this is only necessary if using testing mode on Stripe as the "real" plan IDs won't work, otherwise can be commented out
    switch (plan.name) {
      case "Standard":
        plan.stripeId = "plan_H4GzFdk7RNNYXK";
        break;
      case "Pro":
        plan.stripeId = "plan_H4H21HaOssSF6d";
        break;
      case "Enterprise":
        plan.stripeId = "plan_H4H3746WXIH0tT";
        break;
      default:
        break;
    }

    const newCustomerSubscription = await axios
      .post(url.subscription, {
        customer,
        payment_method: result,
        plan,
      })
      .then(({ data }) => {
        const tempCompany = store.getState().signup.tempCompany;

        const company = {
          ...tempCompany,
          accountTypeId: plan.uuid,
          subscriptionId: data.subscriptionId,
          stripeCustomerId: data.customerId,
          billingContact,
          monthlyBill: plan.pricePerMonth,
        };

        return dispatch(submitCompany(company));
      })
      .then(
        async ({
          data: {
            createCompany: { name, uuid },
          },
        }) => {
          dispatch(addCompanyToUser(billingContact, uuid));
          dispatch(
            showCompanyResult({
              success: true,
              companyName: name,
              companyId: uuid,
            })
          );
          dispatch(
            successMessage(
              `Subscription account for ${name} successfully created.`
            )
          );
        }
      )
      .catch((err) => console.log(err));

    return newCustomerSubscription;
  }
};

const addCompanyToUser = (userId, companyId) => (dispatch) => {
  const userToUpdate = {
    uuid: userId,
    companyId,
  };

  const axiosConfig = {
    headers: {
      Authorization: localStorage.token,
    },
  };

  return axios
    .put(url.user, userToUpdate, axiosConfig)
    .catch((error) => console.log(error));
};

const showCompanyResult = (companyResult) => ({
  type: COMPANY_RESULT,
  companyResult,
});

export const submitCompany = async (company) => {
  return await apolloClient
    .mutate({
      mutation: gql`
        mutation {
          createCompany(
            name: "${company.company}"
            mailingAddress: {
              address: "${company.address}"
              city: "${company.city}"
              stateId: "${company.state}"
              zipcode: "${company.zipcode}"
            }
            isActive: true
            industryTypeId: "${company.industryType}"
            stripeCustomerId: "${company.stripeCustomerId}"
            accountTypeId: "${company.accountTypeId}"
            billing: {
              billingAddress: {
                address: "${company.billingAddress}"
                city: "${company.billingCity}"
                stateId: "${company.billingState}"
                zipcode: "${company.billingZipcode}"
              }
              billingContactId: "${company.billingContact}"
              monthlyBill: ${company.monthlyBill}
              subscriptionId: "${company.subscriptionId}"
            }
          ){
            uuid
            name
          }
        }
      `,
    })
    .then((res) => res)
    .catch((err) => console.log(err));
};
