import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import PhoneInput from "../../Common/components/formComponents/PhoneInput";
import Input from "../../Common/components/formComponents/Input";

const ProfileField = ({
  componentType,
  id,
  value,
  handleChange,
  readOnly,
  title
}) => {
  if (componentType === "input") {
    if (id === "phone") {
      return (
        <PhoneInput
          id={id}
          value={value || ""}
          handleChange={handleChange}
          title={title}
        />
      );
    }
    return (
      <Input
        id={id}
        value={value || ""}
        handleChange={handleChange}
        readOnly={readOnly}
        title={title}
        inputType="input"
        required={true}
      />
    );
  } else {
    return (
      <StyledProfileField>
        <span>{title}</span>
        <span>{value}</span>
      </StyledProfileField>
    );
  }
};

export default ProfileField;

ProfileField.propTypes = {
  /** Designates whether the input should be read only.  */
  readOnly: PropTypes.bool,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The value from state. Cannot be required as it returns undefined when the component  */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** The function to update state when rendering an input. */
  handleChange: PropTypes.func
};

const StyledProfileField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  &:last-of-type {
    margin-bottom: 0;
  }

  span:first-of-type {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
`;
