import {useRouteMatch} from "react-router-dom";
import {Flex, ProgressCircle, Text} from "@adobe/react-spectrum";
import React from "react";
import AdminRebateApplicationForm from "./AdminRebateApplicationForm";
import {useQuery} from "react-apollo";
import {
    GET_REBATE_APPLICATION_BY_ID,
    GetRebateApplicationByIdParams,
    GetRebateApplicationByIdResponse
} from "./queries";
import {captureMessage} from "@sentry/react";
import {TableSelfLink} from "./TableLink";
import ChevronRightIcon from "@spectrum-icons/workflow/ChevronRight";
import {RebateApplication} from "./types";
import RebateAttachmentsPanel from "./RebateAttachmentsPanel";
import {useMediaQuery} from "react-responsive";

function AdminManageProjectView() {
    const isNarrowLayout = useMediaQuery({query: `(max-width: 1000px)`});

    const match = useRouteMatch<{
        internalID: string
    }>({
        path: '/services/rebates/manage/:internalID'
    });

    const {
        loading,
        data,
        error
    } = useQuery<GetRebateApplicationByIdResponse, GetRebateApplicationByIdParams>(GET_REBATE_APPLICATION_BY_ID, {
        variables: {
            id: match?.params.internalID || "",
        }
    });

    if (error) {
        captureMessage(error.message);
        console.error(error.message);
        return <p>"Sorry there was an error"</p>;
    }

    let app: RebateApplication | null = null;
    if (data) {
        if (data.response.applications[0]) {
            app = data.response.applications[0];
        }
    }

    return (
        <>
            <Flex alignItems="center" gap="size-50">
                <TableSelfLink href="/services/rebates/list" style={{margin: 0}}>Projects</TableSelfLink>
                <ChevronRightIcon/>
                {loading ? <ProgressCircle aria-label="Loading…" isIndeterminate/> : <Text>{app?.applicationId}</Text>}
            </Flex>
            <Flex
                direction={isNarrowLayout ? "column" : "row"}
                justifyContent={isNarrowLayout ? "start" : "center"}
                alignItems={isNarrowLayout ? "center" : "baseline"}
                UNSAFE_style={{
                    height: "calc(100vh - 11em)",
                    maxHeight: "calc(100vh - 11em)",
                    overflowY: "auto"
                }}>
                <AdminRebateApplicationForm/>
                {loading || !app ? <ProgressCircle aria-label="Loading…" isIndeterminate/> : (
                    <RebateAttachmentsPanel
                        style={{
                            width: "size-5000",
                            margin: "size-200",
                        }}
                        clientId={app.company.id}
                        applicationId={app.id}
                    />
                )}
            </Flex>
        </>
    )
}

export default AdminManageProjectView;
