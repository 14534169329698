import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Not working as expected
// import LazyLoad from "react-lazyload";
// import ImageLoader from "../../../Common/helpers/imageLoader";
const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 4em 0 4em 0;

  h3 {
    color: #0ea788;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => (props.flip === true ? "0 0 0 2em" : "0 2em 0 0")};
  width: 49%;
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 2em 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => (props.flip === true ? "0 2em 0 0" : "0 0 0 2em")};
  order: ${props => (props.flip === true ? "-1" : "0")};
  width: 49%;

  @media (max-width: 768px) {
    order: 0;
    width: 100%;
    margin: 0;
  }

  p {
    font-size: 0.9em;
  }

  div.button-container {
    display: flex;
    justify-content: space-between;
    width: 23em;

    @media (max-width: 768px) {
      width: 19em;
    }
  }
`;
const Button = styled.p`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-self: flex-end;
  border: 1.2px solid #18a888;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  width: 11em;
  background-color: ${props => (props.first ? "#0ea788" : null)};
  color: ${props => (props.first ? "white" : "#0ea788")};

  @media (max-width: 768px) {
    width: 9em;
  }
`;
const Section = ({
  section: { image, title, content, flip, button = null, content2 = null }
}) => {
  const loggedIn = useSelector(state => state.account.loggedIn);

  const singleButtonContainer =
    loggedIn && button && button.title === "sign up for free";
  // if logged in & button title == 'sign up...' hide first button & add 'single-button' classname
  const buttons = button && (
    <div className={"button-container"}>
      {singleButtonContainer ? null : (
        <StyledLink to={button.link1}>
          <Button first>{button.title}</Button>
        </StyledLink>
      )}
      <StyledLink to={button.link2}>
        <Button>Read More</Button>
      </StyledLink>
    </div>
  );

  return (
    <SectionContainer>
      <ImageContainer flip={flip}>
        <img alt="section" src={image} />
      </ImageContainer>
      <ContentContainer flip={flip}>
        <h3>{title}</h3>
        <div>
          <p>
            {content} <br />
            <br />
            {content2 ? content2 : null}
          </p>
        </div>
        {button && buttons}
      </ContentContainer>
    </SectionContainer>
  );
};



export default Section;





// Workaround for the home page
const HomeSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 1em 0;

  h3 {
    color: #0ea788;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HomeImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => (props.flip === true ? "0 0 0 2em" : "0 2em 0 0")};
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 2em 0;
  }
`;

const HomeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => (props.flip === true ? "0 2em 0 0" : "0 0 0 2em")};
  order: ${props => (props.flip === true ? "-1" : "0")};

  @media (max-width: 768px) {
    order: 0;
    width: 100%;
    margin: 0;
  }

  div.button-container {
    display: flex;
    justify-content: space-between;
    width: 23em;

    @media (max-width: 768px) {
      width: 19em;
    }
  }
`;

export const HomeSection = ({
                     section: { image, title, content, flip, button = null, content2 = null }
                 }) => {
    const loggedIn = useSelector(state => state.account.loggedIn);

    const singleButtonContainer =
        loggedIn && button && button.title === "sign up for free";
    // if logged in & button title == 'sign up...' hide first button & add 'single-button' classname
    const buttons = button && (
        <div className={"button-container"}>
            {singleButtonContainer ? null : (
                <StyledLink to={button.link1}>
                    <Button first>{button.title}</Button>
                </StyledLink>
            )}
            <StyledLink to={button.link2}>
                <Button>Read More</Button>
            </StyledLink>
        </div>
    );

    return (
        <HomeSectionContainer>
            <HomeImageContainer>
                <img alt="section" src={image} />
            </HomeImageContainer>
            <HomeContentContainer>
                <h3 style={{color: "#0c0c0c"}}>{title}</h3>
                <div>
                    <p style={{height: "12em"}}>
                        {content} <br />
                        <br />
                        {content2 ? content2 : null}
                    </p>
                </div>
                {button && buttons}
            </HomeContentContainer>
        </HomeSectionContainer>
    );
};