import React, { Component } from "react";
import PropTypes from "prop-types";

import { gql } from "apollo-boost";

import { PrimaryButton } from "../../../Common/components/Button";
import GraphQLSelect from "../../../Common/components/formComponents/GraphQLSelect";
import Select from "../../../Common/components/formComponents/Select";
import TableComponent from "../../../Common/components/formComponents/Table";
import GraphQLCreatableSelect from "./GraphQLCreatableSelect";
import GraphQLMultiSelect from "./GraphQLMultiSelect";
import Input from "../../../Common/components/formComponents/Input";
import LongText from "../../../Common/components/formComponents/LongText";
import PhoneInput from "../../../Common/components/formComponents/PhoneInput";
import WikiToggle from "./WikiToggle";
import MultipleInputs from "./MultipleInputs";
import DatePicker from "../../../Common/components/formComponents/DatePicker";
import MultiCheckbox from "./MultiCheckbox";
import GraphQLMultiCheckbox from "./GraphQLMultiCheckbox";
import Checkbox from "../../../Common/components/formComponents/Checkbox";
import Requirements from "./Requirements.jsx";
import RebateStep from "./RebateStep";

import { formInputs, emptyFormState } from "../../helpers/wikiForm";

let requiredFields = [];

class WikiForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
      removedItems: [],
      requiredFields,
      errors: [],
      QualData: [],
    };
  }

  async componentDidMount() {
    const { editing, pageType, wikiPage, isCopy } = this.props;
    if (!editing && !isCopy) {
      if (["program", "rebate"].indexOf(pageType) < 0) {
        this.setState({
          formValues: {
            ...emptyFormState[pageType],
            pageType,
          },
        });
      }
    } else if (isCopy) {
      this.setState({
        formValues: { ...wikiPage },
        pageType,
      });
    } else {
      this.setState({
        formValues: { ...wikiPage },
        pageType,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      pageType,
      formOptions,
      editing,
      wikiPage,
      initialProgram,
      isCopy,
    } = this.props;

    const programUtilities =
      pageType === "program"
        ? {
          utilities: [],
        }
        : null;

    if (prevState.pageType !== pageType) {
      requiredFields = [];
    }

    if (prevProps.formOptions !== formOptions) {
      const types = Object.keys(formOptions);
      const options = {};
      types.forEach((type) => {
        options[type] = {};
        formOptions[type].map(
          (option) =>
            (options[type] = { ...options[type], [option.uuid]: false })
        );
      });

      if (editing || isCopy) {
        this.setState({
          formValues: {
            ...options,
            ...wikiPage,
            ...programUtilities,
            pageType,
            requiredFields,
          },
        });
      } else {
        this.setState({
          formValues: {
            ...options,
            ...emptyFormState[pageType],
            ...programUtilities,
            pageType,
            requiredFields,
            programId: initialProgram || "",
          },
        });
      }
    }
  }

  handleChangeQPLs = (value) => {
    this.setState({ QualData: value });
  }

  handleChange = (name, value, parent) => {
    const { formValues, errors } = this.state;
    const newErrors = [...errors];

    if (newErrors.indexOf(name) >= 0 && value.length > 0) {
      newErrors.splice(newErrors.indexOf(name), 1);
    }

    if(parent && parent.name === "requirementsTable") {

      let RootValues = formValues[parent.name];
      let newValues = RootValues[parent.index];

      if(value && value.length > 0) {
        newValues.requirementType.active = true;
      }
      RootValues[parent.index] = newValues;
      this.setState({
        formValues: {
          ...formValues,
          [parent.name]: RootValues,
        }
      });
    }

    this.setState({
      formValues: { ...formValues, [name]: value },
      errors: newErrors,
    });
  };

  handleCheckboxChange = (name, parent) => {
    const { formValues } = this.state;
    if (parent) {
      let RootValues = this.state.formValues[parent.name];
      let newValues = RootValues[parent.index];
      newValues[name].active = !newValues[name].active
      if(parent.name === "requirementsTable" && !newValues[name].active) {
        let keys = Object.keys(newValues);
        for(let key of keys) {
          if(key === name) continue;
          newValues[key] = "";
        }
      }

      RootValues[parent.index] = newValues;
      this.setState({
        formValues: {
          ...formValues,
          [parent.name]: RootValues,
        }
      });
    } else {

    }
    this.setState({
      formValues: { ...formValues, [name]: !formValues[name] },
    });
  };

  /**
   * @function handleMultiInputChange -- update state for the nested value handled by a MultipleInput. Does not mutate state.
   * @param {string} name -- the name of the input, which is also its name in the state object
   * @param {string} newValue -- the value coming from the input
   * @param {object} parent -- the parent, which is the parent property in state, plus the index of the input to change
   */

  handleMultiInputChange = (name, newValue, parent) => {
    const { formValues, errors } = this.state;
    // * Remove the index from the prop name
    const propToUpdate = name.replace(parent.name + parent.index, "");
    // * Get a copy of the current value of the array of objects
    const arrayToUpdate = [...formValues[parent.name]];
    // * If the prop is "requirementType" and it has a productSpecType, update the requirementUnit to match
    const updatedRequirementUnit =
      name === "requirementType" && newValue.productSpecTypeId
        ? { requirementUnit: newValue.productSpecTypeId }
        : null;
    // * Create a copy of the object being changed plus the property changing
    const objectToUpdate = {
      ...arrayToUpdate[parent.index],
      [propToUpdate]: newValue,
      ...updatedRequirementUnit,
    };
    // * Splice in the new object in the old one's place
    arrayToUpdate.splice(parent.index, 1, objectToUpdate);

    // * check for errors
    const newErrors = [...errors];

    if (newErrors.indexOf(name) >= 0 && newValue.length > 0) {
      newErrors.splice(newErrors.indexOf(name), 1);
    }

    this.setState({
      formValues: {
        ...this.state.formValues,
        [parent.name]: [...arrayToUpdate],
      },
      errors: newErrors,
    });
  };

  /**
   * @function createInput -- a switch statement that returns an input based on the object received
   * @param {object} field -- the data for the input to be created
   * @param {object} parent -- the parent, which is the parent property in state, plus the index
   * of the input
   */

  createInput = (field, parent) => {
    const { pageType, editing, isCopy } = this.props;
    const { formValues, errors } = this.state;

    if (!field) {
      return;
    }

    if (field.dependent) {
      if (!formValues[field.dependentOn]) {
        return;
      }
    }

    if (field.required && requiredFields.indexOf(field.name) < 0) {
      const ignoreFields = [
        "utility",
        "value",
        "unit",
        "prescriptiveValue",
        "prescriptiveUnit",
      ];
      if (ignoreFields.indexOf(field.name) < 0) {
        requiredFields.push(field.name);
      }
    }

    const isError = parent
      ? errors.indexOf(field.name + parent.name + parent.index) >= 0
      : errors.indexOf(field.name) >= 0;

    const inputProps = {
      id: field.name,
      key: `${field.name}-${pageType}`,
      handleChange: this.handleChange,
      error: isError,
      helperText: isError ? "This field is required!" : field.helperText,
    };

    if (parent && field.inputType !== "multiCheck" && field.inputType !== "checkbox") {
      inputProps.handleChange = (name, value) =>
        this.handleMultiInputChange(name, value, parent);
      inputProps.id = field.name + parent.name + parent.index;
      inputProps.key = field.name + parent.index;
    }
    switch (field.inputType) {
      case "table": case "requirements":
        return <TableComponent
          {...inputProps}
          {...field}
          createInput={this.createInput}
          handleAddNewMulti={this.addNewMulti}
          handleCheckboxChange={this.handleCheckboxChange}
          handleDeleteMulti={this.deleteMulti}
          isEditPage={this.props.editing}
          formValues={formValues[field.name]} />;
      case "select":
        return <Select {...field} {...inputProps} isParent={parent} />;
      case "gqlSelect":
        inputProps.value = !parent
          ? formValues[field.name]
          : formValues[parent.name][parent.index][field.name];
        return <GraphQLSelect {...inputProps} {...field} isEditPage={this.props.editing} />;
      case "creatableSelect":
        return (
          <GraphQLCreatableSelect
            {...inputProps}
            {...field}
            value={[formValues.requirements[parent.index].requirementType]}
          />
        );
      case "multiSelect":
        return (
          <GraphQLMultiSelect
            {...field}
            {...inputProps}
            selectedItems={formValues[field.name] || []}
            formValues={formValues || []}
            handleChangeQPLs={this.handleChangeQPLs}
            QualData={this.state.QualData}
            isEditPage={this.props.editing}
            handleChange={(selectedItems) =>
              this.setState({
                formValues: {
                  ...formValues,
                  [field.name]: selectedItems,
                },
              })
            }
          />
        );
      case "multi":
        return (
          <MultipleInputs
            {...inputProps}
            {...field}
            createInput={this.createInput}
            handleAddNewMulti={this.addNewMulti}
            handleDeleteMulti={this.deleteMulti}
            formValues={formValues[field.name]}
          />
        );
      case "datepicker":
        inputProps.handleChange = (id, date) =>
          this.setState({ formValues: { ...formValues, [id]: date } });
        inputProps.value = formValues[inputProps.id];
        return <DatePicker title={field.title} {...inputProps} />;
      case "toggle":
        return (
          <WikiToggle
            {...inputProps}
            {...field}
            valueFromState={formValues[field.name] || false}
            description={field.title}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        );
      case "phone":
        inputProps.value = !parent
          ? formValues[field.name]
          : formValues[parent.name][parent.index][field.name];
        return <PhoneInput {...inputProps} {...field} />;
      case "textarea":
        if (parent) {
          inputProps.value = formValues[parent.name][parent.index][field.name];
          inputProps.handleChange =
            (editing || isCopy) && !formValues[parent.name][parent.index][field.name]
              ? null
              : (value) =>
                this.handleMultiInputChange(field.name, value, parent);
        } else {
          inputProps.value = formValues[field.name];

          inputProps.handleChange =
            (editing || isCopy) && JSON.stringify(formValues) === JSON.stringify({})
              ? null
              : (value) =>
                this.setState({
                  formValues: {
                    ...formValues,
                    [field.name]: value,
                  },
                });
        }

        return <LongText {...inputProps} {...field} />;
      case "input":
        inputProps.value = !parent
          ? formValues[field.name]
          : formValues[parent.name][parent.index][field.name];

        return <Input {...inputProps} {...field} isParent={parent} />;
      case "checkbox":
        return (
          <Checkbox
            {...inputProps}
            {...field}
            valueFromState={parent ? (formValues[parent.name][parent.index][field.name].active || false) : (formValues[field.name] || false)}
            description={field.title}
            handleCheckboxChange={this.handleCheckboxChange}
            parent={parent}
          />
        );
      case "graphQLMultiCheckbox":
        return (
          <GraphQLMultiCheckbox
            {...inputProps}
            {...field}
            createInput={this.createInput}
            handleChange={this.handleChange}
            checkedData={formValues[inputProps.title]}
            handleChangeQPLs={this.handleChangeQPLs}
            QualData={this.state.QualData}
          />
        );
      case "multiCheckbox":
        return (
          <MultiCheckbox
            {...inputProps}
            {...field}
            createInput={this.createInput}
          />
        );
      case "multiCheck":
        if (formValues[parent.name]) {
          return (
            <Checkbox
              {...inputProps}
              {...field}
              valueFromState={formValues[parent.name][field.name]}
              handleCheckboxChange={
                field.description === "Downstream"
                  ? (name) =>
                    this.setState({
                      formValues: {
                        ...formValues,
                        [parent.name]: {
                          ...formValues[parent.name],
                          [name]: !formValues[parent.name][name],
                        },
                        hasDownstreamProgram: !formValues.hasDownstreamProgram,
                      },
                    })
                  : (name) =>
                    this.setState({
                      formValues: {
                        ...formValues,
                        [parent.name]: {
                          ...formValues[parent.name],
                          [name]: !formValues[parent.name][name],
                        },
                      },
                    })
              }
            />
          );
        } else {
          return;
        }
      case "downstreamProgram":
        if (!formValues.hasDownstreamProgram) {
          return;
        }
        return (
          <MultiCheckbox
            {...inputProps}
            {...field}
            createInput={this.createInput}
          />
        );
      case "programUtility":
        if (!formValues.stateId) {
          return;
        }

        inputProps.value = !parent
          ? formValues[field.name]
          : formValues[parent.name][parent.index][field.name];

        const query = gql`
          {
            state(uuid: "${formValues.stateId}") {
              utilities {name uuid}
            }
          }
        `;

        if (
          !formValues.hasMultipleUtilities &&
          field.name === "singleUtility"
        ) {
          field.optionsQuery = query;
          return <GraphQLSelect {...inputProps} {...field} />;
        } else if (
          formValues.hasMultipleUtilities &&
          field.name === "multipleUtilities"
        ) {
          field.inputs[0].optionsQuery = query;

          return (
            <MultipleInputs
              {...inputProps}
              {...field}
              createInput={this.createInput}
              handleAddNewMulti={this.addNewMulti}
              handleDeleteMulti={this.deleteMulti}
              formValues={formValues[field.name]}
            />
          );
        }
        break;
      case "rebate-step":
        return (
          <RebateStep key={field.name} name={field.name} step={field.step} />
        );
      case "rebateValue":
        const { rebateTypeId } = formValues;
        if (!rebateTypeId) {
          return;
        }
        const [id, name] = rebateTypeId.split(" ");

        if (
          name === "Prescriptive" &&
          field.name === "prescriptiveRebateValue"
        ) {
          field.inputs[1].rebateTypeId = id;

          return (
            <MultipleInputs
              {...inputProps}
              {...field}
              createInput={this.createInput}
              handleAddNewMulti={false}
              handleDeleteMulti={false}
              singleSet
              formValues={formValues[field.name]}
            />
          );
        } else if (
          field.name !== "prescriptiveRebateValue" &&
          name !== "Prescriptive"
        ) {
          return (
            <MultipleInputs
              {...inputProps}
              {...field}
              createInput={this.createInput}
              handleAddNewMulti={this.addNewMulti}
              handleDeleteMulti={this.deleteMulti}
              formValues={formValues[field.name]}
            />
          );
        }
        return;
      // case "requirements":
      //   console.log(formValues[field.name]);
      //   return (
      //     <Requirements
      //       {...inputProps}
      //       {...field}
      //       createInput={this.createInput}
      //       handleAddNewMulti={this.addNewMulti}
      //       handleDeleteMulti={this.deleteMulti}
      //       formValues={formValues[field.name]}
      //     />
      //   );
      case "label":
        let styleClass = field.advansedStyles ? field.advansedStyles : null;
        let label = field.value ? field.value : field.title;
        return (<span className={styleClass} >{label}</span>);
      default:
        return;
    }
  };

  /**
   * @function addNewMulti -- creates a new set of inputs by adding them to state
   * @param {string} sectionName -- the name of the input set, also the name in state
   * @param {object} inputs -- the empty inputs to be added
   */

  addNewMulti = (sectionName, inputs, count, editedValues) => {
    const { formValues } = this.state;
    var newValue = {};

    if (sectionName == "requirementsTable" && count && count > 0) {
      newValue = [];

      for (let i = 0; i < count; i++) {
        var foundType = {};
        inputs.forEach((cell) => {
          if (!newValue[i]) {
            newValue[i] = {};
          }
          if (cell.name === "requirementType") {
            if (editedValues) {
              foundType = editedValues.find((type) => {
                return type.requirementType.value === cell.options[i].uuid;
              });
            }
            newValue[i][cell.name] = {
              value: cell.options[i].value,
              uuid: cell.options[i].uuid,
              productSpecType: cell.options[i].productSpecType,
              active: false
            };
          } else {
            newValue[i][cell.name] = "";
          }
        });

        if (foundType && foundType.requirementOperator) {
          newValue[i]["requirementOperator"] = foundType.requirementOperator;
          newValue[i]["requirementValue"] = foundType.requirementValue;
          newValue[i]["requirementUnit"] = foundType.requirementUnit;
          newValue[i]["uuid"] = foundType.uuid;
          newValue[i].requirementType.active = true;

          foundType = {};
        }
      }

      this.setState({
        formValues: {
          ...formValues,
          [sectionName]: newValue,
        },
      });
    } else {
      inputs.forEach(({ name }) => (newValue[name] = ""));
      this.setState({
        formValues: {
          ...formValues,
          [sectionName]: [...formValues[sectionName], newValue],
        },
      });
    }
  };

  /**
   * @function deleteMulti -- deletes a set of inputs by removing them from state
   * @param {string} sectionName -- the name of the input set, also name in state
   * @param {number} index -- the index of the set of inputs to remove
   */

  deleteMulti = (sectionName, index) => {
    const { formValues, removedItems } = this.state;

    const deletedItem = [...formValues[sectionName]][index];
    const newArray = [...formValues[sectionName]];
    newArray.splice(index, 1);

    // * mutipleUtilities is handled through updatePage action -- it requires the programId to delete from linked table
    if (sectionName === "multipleUtilities") {
      this.setState({
        formValues: {
          ...formValues,
          [sectionName]: newArray,
        },
      });
    } else {
      this.setState({
        formValues: {
          ...formValues,
          [sectionName]: newArray,
        },
        removedItems: [
          ...removedItems,
          {
            type: sectionName,
            [sectionName]: deletedItem,
          },
        ],
      });
    }
  };

  generateInputs = () =>
    formInputs[this.props.pageType].map((input) => {
      return this.createInput(input);
    });

  onSubmit = (event) => {
    const { formValues, requiredFields } = this.state;
    const { handleSubmit, wikiPage, editing } = this.props;
    const noUpdates = JSON.stringify(formValues) === JSON.stringify(wikiPage);

    event.preventDefault();

    const newErrors = [];

    requiredFields.forEach((field) => {
      // * ignore single if multiple & vice versa
      if (field === "singleUtility" && formValues.hasMultipleUtilities) {
        return;
      } else if (field === "multipleUtilities") {
        if (!formValues.hasMultipleUtilities) {
          return;
        } else {
          formValues.multipleUtilities.forEach(({ utility }, index) => {
            if (utility.length < 1) {
              newErrors.push(`utilitymultipleUtilities${index}`);
            }
          });
          return;
        }
      }

      const rebateType =
        formValues.pageType === "rebate"
          ? formValues.rebateTypeId.split(" ")[1]
          : null;

      const { prescriptiveRebateValue, calculatedRebateValue } = formValues;

      if (rebateType === "Prescriptive") {
        if (field === "prescriptiveRebateValue") {
          for (const key in prescriptiveRebateValue[0]) {
            if (prescriptiveRebateValue[0][key].length < 1) {
              newErrors.push(`${key}prescriptiveRebateValue0`);
            }
          }
        } else if (field === "calculatedRebateValue") {
          return;
        }
      } else if (rebateType === "Custom" || rebateType === "Calculated") {
        if (field === "calculatedRebateValue") {
          calculatedRebateValue &&
            calculatedRebateValue.forEach((value, index) => {
              for (const key in value) {
                if (!value[key] || value[key].length < 1) {
                  newErrors.push(`${key}calculatedRebateValue${index}`);
                }
              }
            });
        }
      }
      if (!formValues[field] || formValues[field].length < 1) {
        newErrors.push(field);
      }
    });

    if (newErrors.length > 0) {
      this.setState({ errors: newErrors }, () =>
        this.props.handleError("Please complete the required fields!")
      );
      return null;
    }

    if (editing && noUpdates) {
      return handleSubmit(null);
    }

    return this.removeEmptyObjects();
  };

  /**
   * @function removeEmptyObjects -- removes empty objects from arrays before sending to database.
   * This is called by onSubmit and handles the submit function as a callback to updating state with
   * the newly cleaned arrays.
   */

  removeEmptyObjects = () => {
    const { editing, handleSubmit, wikiPage } = this.props;
    const { formValues, removedItems } = this.state;
    const propsToCheck = [
      "websites",
      "contacts",
      "socialMediaLinks",
      "applicationLinks",
      "productSpecs",
      "applicationLinks",
      "reports",
      "newProductCategories",
      "oldProductCategories",
      "requirements",
      "requirementsTable",
      "prescriptiveRebateValue",
      "calculatedRebateValue",
    ];

    const cleanedObjects = {};

    propsToCheck.forEach((item) => {
      // * ignore program.requirements, this is for rebate.requirements only!
      if (item === "requirements" && formValues.pageType === "program") {
        return;
      }

      const itemToCheck = formValues[item];
      // TODO: Make sure all existing properties have length -> first field could be done but not the rest
      // * check if property exists & isn't already empty
      if (itemToCheck && itemToCheck.length > 0) {
        // * get the first key in the object (only need to check one)
        const key =
          item === "requirements"
            ? "requirementOperator"
            : Object.keys(itemToCheck[0])[0];
        // * make a new array with objects that aren't empty strings
        const updatedArray = itemToCheck.filter(
          (arrayItem) => arrayItem[key].length !== 0
        );
        cleanedObjects[item] = [...updatedArray];
      }
    });

    const otherKeys = Object.keys(formValues);
    otherKeys.forEach((key) => {
      if (propsToCheck.indexOf(key) < 0 && formValues[key] === ("null" || "")) {
        cleanedObjects[key] = null;
      }
    });

    this.setState(
      {
        formValues: {
          ...formValues,
          ...cleanedObjects,
        },
      },
      () => {
        // * if new page, submit!
        if (!editing) {
          return handleSubmit(this.state.formValues);
        } else {
          // * otherwise, check page for updates
          const updatedItems = {};

          [
            ...propsToCheck,
            "QPLs",
            "projectTypes",
            "customerTypes",
            "offeringTypes",
            "downstreamProgram",
            "singleUtility",
            "multipleUtilities",
          ].forEach((property) => {
            if (
              // * if property hasn't changed
              JSON.stringify(wikiPage[property]) ===
              JSON.stringify(formValues[property])
            ) {
              return;
            } else {
              updatedItems[property] = { updated: true };
              if (Array.isArray(formValues[property])) {
                return;
              } else {
                if (property === "singleUtility") {
                  return;
                }

                const original = Object.keys(wikiPage[property]);
                original.forEach((key) => {
                  if (wikiPage[property][key] === formValues[property][key]) {
                    // * if property is the same and existing already exists
                    // * add the existing property to it
                    if (updatedItems[property].existing) {
                      return (updatedItems[property].existing[key] =
                        formValues[property][key]);
                    }
                    // * else create existing and add the property to it
                    updatedItems[property].existing = {};
                    return (updatedItems[property].existing[key] =
                      formValues[property][key]);
                  } else {
                    // * else properties DON'T match. repeat for changed
                    if (updatedItems[property].changed) {
                      return (updatedItems[property].changed[key] =
                        formValues[property][key]);
                    }
                    updatedItems[property].changed = {};
                    return (updatedItems[property].changed[key] =
                      formValues[property][key]);
                  }
                });
              }
            }
          });

          return handleSubmit(
            this.state.formValues,
            updatedItems,
            removedItems,
            wikiPage
          );
        }
      }
    );
  };

  render() {
    const { editing, history } = this.props;

    const inputs = this.state.formValues === {} ? null : this.generateInputs();

    return (
      <>
        {!editing && (
          <form>
            {inputs}
            <PrimaryButton handleClick={(event) => this.onSubmit(event)}>
              Add New Page
            </PrimaryButton>
          </form>
        )}
        {editing && (
          <form>
            {inputs}
            <PrimaryButton handleClick={(event) => this.onSubmit(event)}>
              Save Edits
            </PrimaryButton>
            <PrimaryButton
              className="destroy"
              type="button"
              handleClick={() =>
                history.push(history.location.pathname.replace("/edit", ""))
              }
            >
              Cancel Edit
            </PrimaryButton>
          </form>
        )}
      </>
    );
  }
}

export default WikiForm;

WikiForm.propTypes = {
  /** The function to submit the data */
  handleSubmit: PropTypes.func.isRequired,
  /** The page to edit */
  wikiPage: PropTypes.object,
  /** Whether the page is being edited */
  editing: PropTypes.bool,
  /** The pageType to get the proper inputs */
  pageType: PropTypes.string.isRequired,
};
