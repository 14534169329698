import React from "react";
import {useMediaQuery} from "react-responsive";

import {leftHandMenuWidth, tabPanelWidth, toolBarPanelWidth} from "./constants";
import {HasChildren} from "../../types";

interface BodyProps extends HasChildren {
    isToolbarCollapsed: boolean,
}

export const Body = ({children, isToolbarCollapsed}: BodyProps) => {
    const isNotDesktop = useMediaQuery({ maxWidth: 1024 });
    const isShowLeftHandMenu = useMediaQuery({ query: `(min-width: 769px)` });
    const widthToSubtract = (isShowLeftHandMenu ? leftHandMenuWidth : 0) + (isNotDesktop ? 0 : (isToolbarCollapsed ? tabPanelWidth : (toolBarPanelWidth + tabPanelWidth)) + 3.3);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            minHeight: isNotDesktop ? "unset" : "100%",
            maxWidth: `calc(100vw - ${widthToSubtract}em)`,
            padding: "2em 1em 7em 0",
            width: "100%",
        }}>
            {children}
        </div>
    )
}

