import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const StyledCheckbox = styled.label`
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  input {
    position: relative;
    display: none;
    cursor: pointer;
    max-width: 3em;
  }
  input:checked ~ span:first-of-type {
    background-color: #16a888;
  }
  span:first-of-type {
    position: relative;
    height: 1em;
    width: 1em;
    background-color: transparent;
    border-radius: 3px;
    border: 1.5px solid #16a888;
    cursor: pointer;
  }
  span:not(:first-of-type) {
    margin-left: 1em;
    font-weight: normal;
  }
`;

const Checkbox = ({
  id,
  name,
  valueFromState,
  handleCheckboxChange,
  required,
  description,
  advancedStyles,
  parent
}) => {
  let styleClass = advancedStyles ? advancedStyles : null;
  let checkboxformValueName = (id || name);
  return (
    <StyledCheckbox className={styleClass}>
      <input
        id={id || name}
        checked={valueFromState}
        onChange={() => parent ? handleCheckboxChange(checkboxformValueName, parent) : handleCheckboxChange(id || name)}
        type="checkbox"
        required={required}
      />
      <span></span>
      <span>{description}</span>
    </StyledCheckbox>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  /** The id of the checkbox. */
  id: PropTypes.string.isRequired,
  /** The description of the setting. */
  description: PropTypes.string.isRequired,
  /** Tells the component what state it should show. */
  valueFromState: PropTypes.bool,
  /** Handles the onChange for the element. */
  handleCheckboxChange: PropTypes.func.isRequired
};
