import React from 'react';
import {Flex, TextField} from '@adobe/react-spectrum'
import ChevronLeft from '@spectrum-icons/workflow/ChevronLeft';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import {ActionButton} from '@adobe/react-spectrum'

export type PaginationPanelOnChange = (page: number) => void

export interface PaginationPanelParams {
    page: number,
    maxPage: number,
    onChange: PaginationPanelOnChange,
}

export default function PaginationPanel({
                                            maxPage,
                                            page,
                                            onChange,
                                        }: PaginationPanelParams) {


    return (
        <Flex alignItems="center">
            <ActionButton
                aria-label="Go back a page"
                isQuiet={true}
                UNSAFE_style={{cursor: page === 1 ? "default" : "pointer"}}
                marginEnd="size-50"
                marginStart="size-50"
                isDisabled={page === 1}
                onPress={() => onChange(page - 1)}
            >
                <ChevronLeft/>
            </ActionButton>
            <TextField
                aria-label="page"
                type="number"
                inputMode="decimal"
                width="size-700"
                value={page.toString()}
                onChange={(value) => {
                    const newPage = parseInt(value);

                    if (!Number.isSafeInteger(newPage)) {
                        return onChange(page);
                    }

                    if (newPage > maxPage) {
                        return onChange(maxPage);
                    }

                    if (newPage < 1) {
                        return onChange(1);
                    }

                    return onChange(newPage);
                }}
            />
            <p style={{
                margin: "0 0.5em",
                color: "var(--spectrum-global-color-gray-800)",
                fontFamily: "adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
                fontSize: "1em",
            }}>of {maxPage} pages</p>
            <ActionButton
                aria-label="Go forward a page"
                isQuiet={true}
                UNSAFE_style={{cursor: page === maxPage ? "default" : "pointer"}}
                marginEnd="size-50"
                marginStart="size-50"
                isDisabled={page === maxPage}
                onPress={() => onChange(page + 1)}
            >
                <ChevronRight/>
            </ActionButton>
        </Flex>
    )
}
