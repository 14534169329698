import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import IconMenu from "./IconMenu";

const Wrapper = styled.div`
  width: 11em;
  min-width: 11em;
  max-width: 11em;
  height: 100%;
  border-right: solid 1px #cecece;
  padding: 0 1em;
`;

const StyledDesktopMenu = styled.div`
  height: 100%;
  width: 100%;
  padding: 3em 0.25em 0 0.25em;
  p {
    font-weight: bold;
  }
  hr {
    border-top: 2px solid #cacaca;
  }
`;

/**
 * This returns the DesktopMenu on the left side of the app. It is wrapped with `<MediaQuery minDeviceWidth={769}>`.
 * The menu only returns items that should be visible on the Desktop version of the site, as well as those the user
 * has permission to access. Example: Dev Docs icon only shows up to users with permission >= 4.
 */

const DesktopMenu = props => {
  return (
    <Wrapper>
      <StyledDesktopMenu>
        <IconMenu isDesktop {...props} />
      </StyledDesktopMenu>
    </Wrapper>
  );
};

export default DesktopMenu;

DesktopMenu.propTypes = {
  /** Determines what the user can see */
  userPermissions: PropTypes.number.isRequired,
  /** Determines what the user can see */
  isLoggedIn: PropTypes.bool.isRequired
};
