import { ADD_STORY, DELETE_STORY } from "./types";

export const addStory = story => ({
  type: ADD_STORY,
  story
});

export const deleteStory = storyId => ({
  type: DELETE_STORY,
  storyId
});
