import {gql} from "apollo-boost";

export const GET_FORM_OPTIONS = gql`
    query {
        states {
            value: uuid
            label: name
        }
    }
`;

export interface CreateEstimateRequestUserData {
    userData: {
        fullName: string,
        email: string,
        order?: string | null,
        notes: string,
        state: string,
        city: string,
        streetAddress?: string | null,
        zipCode: string,
    }
}

export interface CreateEstimateRequestResponse {
    data: {
        status: "OK" | "ERROR"
    }
}

export const SUBMIT_SIMPLE_REQUEST = gql`
    mutation CreateEstimateRequest($userData: CreateEstimateRequestUserData!) {
        data: createEstimateRequest(
            userData: $userData
        ) {
            status
        }
    }
`;
