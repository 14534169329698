import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import UserImage from "./UserImage";

/**
 * The UserBadge shows on the TopMenu to display the current user's name, photo, company, and Bus Miles.
 * @param {object} props -- The user object will be sent through props
 * @returns {UserBadge Component}
 */

const UserBadge = ({ name, settings }) => {
  const imgUrl = settings.photo
    ? settings.photo
    : "https://rebatebus-assets.s3.amazonaws.com/assets/development/V2/profile_pics/user.jpg";

  return (
    <StyledUserBadge>
      <UserImage img={imgUrl} name={name} />
    </StyledUserBadge>
  );
};

export default UserBadge;

UserBadge.propTypes = {
  /** The current user's name */
  name: PropTypes.string.isRequired,
  /** The current user's image. Image is not required, we will use a generic icon should the user not have one uploaded. */
  settings: PropTypes.shape({
    photo: PropTypes.string
  })
};

const StyledUserBadge = styled.div`
  display: flex;
  align-items: center;
  img {
    border-radius: 50%;
    cursor: pointer;
  }

  .userBadgeInfoWrapper * {
    color: white;
    font-size: 0.9em;
    font-weight: 200;
  }
  .userBadgeName {
    font-weight: 600;
  }
`;
