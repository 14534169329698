import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import IconWithText from "../IconWithText";
import { linkIcons } from "../../../Wiki/components/pageTypes/PageComponents";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavoriteIcon from "@material-ui/icons/Favorite";

const Support = () => {

  const [isContactUs, setIsContactUs] = useState(false);
  const ConnectUsRef = useRef(null);
  const sections = supportSections.map((section) => (
    <SupportSection {...section} key={section.title} />
  ));

  const socialLinks = links.map(({ linkText, url }) => {
    const iconType = linkText;
    return (
      <IconWithText key={linkText}>
        <img
          src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/${
            linkIcons[iconType] || linkIcons.link
          }`}
          alt={`${linkText} Icon`}
        />
        <a href={url}>{linkText}</a>
      </IconWithText>
    );
  });

  const location = useLocation();

  useEffect(() => {
    if (window.location.hash === "#connect-with-us") {
      ConnectUsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(window.location.hash.split("#")[1])
        ?.scrollIntoView();
    }, 1000);
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/contact-us')) {
      setIsContactUs(true);
    }
  }, [location]);

  return (
    <main id={!isContactUs && "mainContentContainer"}>
      <div id={!isContactUs && "mainContentWindowContainer"}>
        <h1>Help and Support</h1>
        <p>
          If you need help throughout your Rebate Bus experience or would like
          to share a question or comment, please reach out to us! We would be
          more than happy to assist. Also, if you are enjoying your new Rebate
          Bus experience, please let us know! It would make our mothers very
          proud. <Heart />
        </p>
        <h2>Frequently Asked Questions</h2>
        <FaqSection />

        <StyledSection>
          <h2 ref={ConnectUsRef}>Connect with us!</h2>
          <p>
            Connect with us on social media to hear more about the latest
            updates and industry news.
          </p>
          {socialLinks}
        </StyledSection>
        {sections}
      </div>
    </main>
  );
};

export default Support;

const Heart = styled(FavoriteIcon)`
  font-size: 0.9em !important;
  color: #ff004e;
`;

const links = [
  {
    linkText: "Facebook",
    url: "https://www.facebook.com/rebatebus/",
  },
  { linkText: "Twitter", url: "https://twitter.com/rebatebus/" },
  {
    linkText: "LinkedIn",
    url: " https://www.linkedin.com/company/rebate-bus/",
  },
  {
    linkText: "Instagram",
    url: "https://www.instagram.com/rebatebus/",
  },
  {
    linkText: "YouTube",
    url: "https://www.youtube.com/channel/UCVqjpizoEOt7aW1tnGMlyPQ",
  },
];

const supportSections = [
  {
    title: "Fulfillment Projects",
    id: "commercial",
    description:
      "The contact information below is best for topics related to fulfillment projects.",
    email: "commercial@rebatebus.com",
  },
  {
    title: "Sales",
    id: "sales",
    description:
      "The contact information below is best for topics related to purchasing basic, pro, or enterprise accounts.",
    email: "sales@rebatebus.com",
  },
  {
    title: "Technical",
    description:
      "The contact information below is best for topics related to our web application. If you notice a performance issue, bug, or would like to suggest a new feature, our team would be happy to assist. Further, if you need to merge individual accounts to a corporate account or deactivate an account, reach out to our development team below.",
    email: "support@rebatebus.com",
  },
  {
    title: "Data",
    description:
      "The contact information below is best for topics related to the quality and scope of our rebate data. If you notice an issue with the content on our website or would like to make a suggestion, please reach out to our data team below.",
    email: "data@rebatebus.com",
  },
  {
    title: "Advertising",
    description:
      "The contact information below is best for topics related to advertising on Rebate Bus or marketing inquiries",
    email: "marketing@rebatebus.com",
  },
  {
    title: "General",
    description:
      "The contact information below is best for topics related to general comments or inquiries. If you're not sure where you direct your question, start with the contact below and we will direct you to the appropriate department.",
    email: "team@rebatebus.com",
  },
];

const StyledSection = styled.div`
  h2 {
    margin-top: 2em;
  }
  h3 {
    margin-top: 2em;
    color: ${(props) => props.theme.rbGreen.regular};
  }
  a {
    color: black;
    font-size: inherit;
    text-decoration: none;
    font-style: normal;
  }
`;

const SupportSection = ({ title, description, email, id }) => (
  <StyledSection>
    <h3 id={id || title}>For {title} Questions or Comments</h3>
    <p>{description}</p>
    <p>
      Email: <a href={`mailto:${email}`}>{email}</a>
    </p>
    {title === "General" ? <p>Phone: (833) 287-2739</p> : null}
  </StyledSection>
);

const StyledFaqSection = styled.div`
  ol {
    list-style: decimal inside;
    font-size: 1.1em;
    margin: 0 0 0.9em 1em;
    li {
      margin-bottom: 0.3em;
    }
  }
  a {
    font-size: inherit;
    font-style: normal;
  }
  h3 {
    margin: 0;
    font-weight: normal;
    color: #16a888;
  }
`;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  panelWithList: {
    display: "flex",
    flexDirection: "column",
  },
}));

const FaqSection = () => {
  const classes = useStyles();

  return (
    <StyledFaqSection>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>Is this a new version of Rebate Bus?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Yes! We are proud to announce the launch of Rebate Bus V2. We have
            greatly improved our user interface and search capabilities. More
            exciting features are on the way, so sign up for a free account to
            be notified!
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>
            I already have a Rebate Bus account and I can’t log in. Do I need to
            make a new account?
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            No, you don't! You can finish migrating your account{" "}
            <Link to="/signup/existing">here</Link>.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>Will the previous version of Rebate Bus still be accessible?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Yes. It will be available at{" "}
            <a
              href="https://v1.rebatebus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              v1.rebatebus.com
            </a>{" "}
            until June of 2020. Your existing account email, password, and
            subscription will remain the same.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>How many accounts can my business have?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            A single organization can have as many accounts as they like. Have
            your team admin sign up for a company account first, and then have
            everyone else sign up as individuals. Email{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a> to
            merge individual accounts to your company account.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>
            What should I do if I want to report a bug/issue in the software?
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelWithList}>
          <p>
            Please email the following to{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a>:
          </p>
          <ol>
            <li>A screenshot of the issue</li>
            <li>
              A thorough description of what you were trying to do and what
              actually happened
            </li>
            <li>The url of the web page you are having issues with</li>
          </ol>
          <p>Our development team will respond shortly!</p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>What should I do if I want to make a suggestion?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Please email your suggestion to{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a>. We
            would love to hear your thoughts!
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>What should I do if I find a mistake in the data or content?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Please copy the URL of the page and send it to{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a>
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>Is my existing enterprise account affected by Rebate Bus V2?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            No. All existing enterprise packages and contracts remain the same.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>I have a V1 API subscription. Can I still use it?</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Yes. The V1 API will be available until August 2020. The data has
            been updated for 2020 programs. The V1 API will be receiving less
            data support as we launch our new V2 API. Eventually, your
            organization will need to switch over to the V2 API. Please contact{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a> to
            set up a consultation.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>
            I am interested in switching to the new V2 API. Where can I learn
            more?
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Please contact{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a> to
            set up a consultation.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>
            I have an iframe/web tool on my website that references Rebate Bus
            V1. Will it still work?
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p>
            Yes. You may notice that as of 2/24/20, your existing iframe may not
            work. This is to be expected and only needs a small correction. You
            will need to modify your iframe reference to include “v1” in the
            url, for example: v1.rebatebus.com/content. Please contact{" "}
            <a href="mailto:support@rebatebus.com">support@rebatebus.com</a> for
            questions or support.
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </StyledFaqSection>
  );
};
