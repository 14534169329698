import React from "react";
import Section, {HomeSection} from "../pageComponents/section";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import ImageLoader from "../../../Common/helpers/imageLoader";

import Announcement, {
  announcements
} from "../../../Dashboard/components/Announcement";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Button = styled.p`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-self: flex-end;
  border: 1.2px solid #18a888;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  width: 11em;
  background-color: ${props => (props.first ? "#0ea788" : null)};
  color: ${props => (props.first ? "white" : "#0ea788")};

  @media (max-width: 768px) {
    width: 9em;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  h1 {
    margin: 0;
  }
  @media (max-width: 540px) {
    h1 {
      font-size: 1.8em;
    }
  }
  div.top-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 2em;
    span {
      font-size: 1.4em;
      font-weight: 200;
    }
  }

  div.image-container {
    width: 100%;
    margin-bottom: 5em;
  }

  div.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.fixed-width {
      width: 23em;

      @media all and (max-width: 768px) {
        width: 100%;
      }
    }

    &.single-button {
      width: auto;
      justify-content: center;
    }
  }
`;

const Home = props => {
  const sections = [
      {
          image:
              "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Home/Home_5.svg",
          title: "Fulfillment services",
          flip: false,
          content:
              "Let our experienced commercial fulfillment team manage the utility incentive application process for your projects. Free up time for you and your team to do what they do best: sell product and close deals! Rebate Bus will facilitate the paperwork from start to finish and maximize rebate dollars for your customers.",
          button: {
              title: "Get Started",
              link1: "/contact-us#commercial",
              link2: "/fulfillment-services"
          }
      },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Home/Home_4.svg",
      title: "Use our data (API)",
      flip: true,
      content:
        "Want to bring Rebate Bus data to your application? Using our new GraphQL API, selecting the exact data you need is easier than ever. We’ve also completely restructured our data meaning things are more organized and aligned with industry standards.",
      button: {
        title: "Get Started",
        link1: "/contact-us#sales",
        link2: "/rebate-bus-api"
      }
    }
  ];
  return (
    <Container singleButton={props.loggedIn}>
      <Helmet>
        <meta name="description" content="Rebate Bus Home page" />
        <title>Rebate Bus | Home</title>
      </Helmet>
      <div className="top-section">
        <h1 style={{textAlign: "center"}}>The Most Comprehensive Rebate Database</h1>
        <h1 style={{textAlign: "center"}}>on the Planet</h1>
      </div>
        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <StyledLink to="/search/rebates/lighting">
                <Button first>Search Rebates</Button>
            </StyledLink>
        </div>
      <div
        className={
          props.loggedIn
            ? "single-button button-container"
            : "button-container fixed-width"
        }
      >
      </div>
      <div style={{display: "flex", gap: "4em", flexWrap: "wrap", justifyContent: "center"}}>
         {sections.map((section, i) => (
             <HomeSection key={`home-section-${i}`} section={section} />
         ))}
      </div>
    </Container>
  );
};

export default Home;
