import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// If sitemap needs to be generated comment out line below
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { InputWrapper } from "./StyledFormComponents";

// If sitemap needs to be updated
// const LongText = props => {
//   const modules = { toolbar: ["bold", "italic", "underline", "strike"] };

//   const ReactQuill =
//     typeof window === "object" ? require("react-quill") : () => false;

//   return (
//     <QuillWrapper>
//       <label htmlFor={props.name}>{props.title}</label>
//       <ReactQuill
//         id={props.name}
//         name={props.name}
//         value={props.value || ""}
//         onChange={props.handleChange}
//         modules={modules}
//       />
//     </QuillWrapper>
//   );
// };

const LongText = props => {
  const modules = { toolbar: ["bold", "italic", "underline", "strike"] };

  return (
    <QuillWrapper>
      <label htmlFor={props.name}>{props.title}</label>
      <ReactQuill
        id={props.name}
        name={props.name}
        value={props.value || ""}
        onChange={props.handleChange}
        modules={modules}
      />
    </QuillWrapper>
  );
};

export default LongText;

LongText.propTypes = {
  /** The name of the field in state */
  name: PropTypes.string.isRequired,
  /** The title of the field */
  title: PropTypes.string.isRequired,
  /** The function to handle change. It *cannot* be required as it must be null when the form generates
   * (only on editing), otherwise it overwrites the form data with an empty object. */
  handleChange: PropTypes.func,
  /** The value from state */
  value: PropTypes.string
};

const QuillWrapper = styled(InputWrapper)`
  margin: 2em 0;

  label {
    color: #16a888;
    font-size: 1.3em;
    font-weight: normal;
  }

  div.quill {
    margin-top: 0.5em;
  }

  div.ql-toolbar {
    display: flex;
    width: 100%;
    border: 1px solid #c0c0c0;
    padding: 0.5em 1em;
    border-radius: 5px 5px 0 0;
  }

  span.ql-formats {
    display: flex;
  }

  div.ql-container {
    width: 100%;
    height: 20em;
    border: 1px solid #c0c0c0;
    border-top: 0;
    border-radius: 0 0 5px 5px;
  }

  div.ql-editor {
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
  }
`;
