import React from "react";
import { Link } from "react-router-dom";
import { showMessages } from "../Message";
import { dismissMessage } from "../../ducks/actions";

import MainContentWrapper from "../layout/MainContentWindowWrapper";
import Signin from "../../../Account/Signin";

const Authenticating = ({ messages, location }) => {
  const redirectRoute = location.state ? location.state.prevPath : null;
  return (
    <main id="mainContentContainer">
      <div id="mainContentWindowContainer">
        {showMessages(messages, dismissMessage)}
        <MainContentWrapper>
          <Signin redirectRoute={redirectRoute} />
        </MainContentWrapper>
      </div>
    </main>
  );
};

export default Authenticating;
