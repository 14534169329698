import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getPrograms } from "../../ducks/searchActions";

import styled from "styled-components";
import Loading from "../../../Common/components/Loading";
import CardlessCard from "../../../Common/components/CardlessCard";
import { PreviewButton } from "../../../Common/components/Button";

import { BoldKeyValue, Checkmark } from "../pageTypes/StyledPageComponents";
import { HtmlSection } from "../pageTypes/PageComponents";

import techTypeIcons from "../../helpers/techTypeIcons";
import { textToUrl } from "../../helpers/urls";
import { InfoModal, UtilitySpan } from "../../../Common/components/InfoModal";

const ProgramsByState = () => {
  const [loading, setLoading] = useState(true);
  const { stateId, stateName } = useParams();
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.wiki.search.filteredPrograms);
  const stateInRedux = useSelector(
    (state) => state.wiki.search.programsByState.state
  );

  useEffect(() => {
    dispatch(getPrograms(stateId));
  }, [dispatch, stateId]);

  useEffect(() => {
    if (programs && stateInRedux !== stateId) {
      setLoading(true);
    } else if (programs && stateInRedux === stateId) {
      setLoading(false);
    }
  }, [stateInRedux, stateId, programs]);

  const programsToShow = programs
    ? programs.map((program) => (
        <ProgramCardless key={program.uuid} {...program} stateId={stateId}/>
      ))
    : null;

  return (
    <>
      <h1>Programs in {stateName}</h1>
      {loading ? <Loading /> : null}
      {!loading && programs.length > 0 && programsToShow}
      {!loading && programs.length === 0 ? <p>No programs found.</p> : null}
    </>
  );
};

export default ProgramsByState;

const formatDate = (date) => {
  date.setDate(date.getDate() + 1);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const ProgramCardless = (program) => {
  const programUrl = `/search/articles/${program.uuid}/program/${textToUrl(
    program.name
  )}`;

  const utilitiesEnd = program.utilities.length;
  const utilities = program.utilities.map((utility, i) =>
    i !== utilitiesEnd - 1 ? `${utility.name}, ` : `${utility.name}`
  );
  console.log(program.utilities)

  return (
    <CardlessCard name={program.name} url={programUrl}>
      <ProgramHeader>
        <span className="title">Program Offerings</span>
        <div className="techTypes">
          {program.technologyTypes.map(({ value, uuid }) => (
            <Link
              key={uuid}
              to={`/search/rebates/${value.toLowerCase()}?programIds=${program.uuid}&stateIds=${program.stateId}${(program.utilities && program.utilities.length === 1) ? `&utilityIds=${program.utilities[0].uuid}` : 0}`}
            >
              <img
                key={value}
                src={techTypeIcons[value].icon}
                alt={techTypeIcons[value].alt}
              />
            </Link>
          ))}
        </div>
      </ProgramHeader>
      <ProgramMiddle>
        <div className="programUtilities">
          {utilities.length === 1 ? (
            <UtilitySpan>{program.utilities[0].name}</UtilitySpan>
          ) : (
            <InfoModal utilities={utilities} />
          )}
        </div>
        <HtmlSection
          className="programDescription"
          longText={program.description}
        />
        <div className="programStatus">
          <BoldKeyValue>
            <span>Program Status: </span>
            {program.isActive ? "Open" : "Closed"}
          </BoldKeyValue>
          <BoldKeyValue>
            <span>Opens: </span>
            {program.startDate}
          </BoldKeyValue>
          <BoldKeyValue>
            <span>Closes: </span>
            {program.endDate}
          </BoldKeyValue>
        </div>
        <div className="customerTypes">
          {program.customerTypes.map(({ value }) => (
            <Checkmark key={value}>
              <img
                src="https://s3.amazonaws.com/v2.rebatebus/images/icons/checkmarkIcon.svg"
                alt="Checkmark"
              />
              <span>{value}</span>
            </Checkmark>
          ))}
        </div>
      </ProgramMiddle>
      <ProgramFooter>
        <Link to={programUrl}>
          <PreviewButton>view program</PreviewButton>
        </Link>
      </ProgramFooter>
    </CardlessCard>
  );
};

const ProgramHeader = styled.div`
  display: flex;
  flex-direction: column;
  span.title {
    font-weight: bold;
  }
  div.techTypes {
    display: flex;
    margin: 1.5em 0.5em;
    a {
      width: 3em;
      img {
        max-height: 3em;
      }
    }
  }
`;

const ProgramMiddle = styled.div`
  display: flex;
  justify-content: space-around;

  .programUtilities {
    flex: 0 0 15%;
    margin-right: 1em;
    p {
      font-size: 0.8em;
    }
  }

  .programDescription {
    flex: 0 0 20%;
    color: #cacaca;
    font-size: 1em;
    line-height: 120%;
    margin-right: 1em;
    p {
      color: #cacaca;
      font-size: 1em;
      line-height: 120%;
    }
  }

  div.programStatus {
    flex: 0 0 24%;
    margin-top: -0.2em;
    p {
      margin-bottom: 0.5em;
    }
    @media all and (max-width: 768px) {
      margin-top: 0;
    }
  }

  div.customerTypes {
    flex: 0 0 21%;
    @media all and (max-width: 768px) {
      margin-top: 1em;
    }
  }

  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProgramFooter = styled.div`
  margin: 2em 0 1em;
  display: flex;
  justify-content: flex-end;
  a {
    margin: 0;
  }
  button {
    font-size: 1em;
  }
`;
