import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { TextField } from "@material-ui/core";
import { InputWrapper } from "./StyledFormComponents";

const PhoneMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
};

PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const PhoneInput = (props) => {
  return (
    <InputWrapper>
      <TextField
        id={props.id}
        label={props.title}
        value={props.value}
        onChange={({ target }) => props.handleChange(target.id, target.value)}
        fullWidth
        InputProps={{
          inputComponent: PhoneMask,
        }}
        helperText={props.helperText}
        required={props.required}
      />
    </InputWrapper>
  );
};

PhoneInput.propTypes = {
  /** The icon if the input should have one. It will default to end adornment. */
  icon: PropTypes.string,
  /** If the input is required. Originally took a string of "required". (Can't remember why I set it up like that...) */
  required: PropTypes.bool,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The value from state. Cannot be required as it returns undefined when the component  */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** The function to update state. */
  handleChange: PropTypes.func.isRequired,
  /** Determines whether an input or textarea is rendered. Type of textarea creates a multiline input. */
};

export default PhoneInput;
