import {
  LOAD_PROGRAMS,
  CLEAR_PROGRAMS,
  SORT_PROGRAMS,
  FILTER_PROGRAMS
} from "./types";

const intialState = {
  programsByState: { state: null, programs: [] },
  filteredPrograms: null
};

export default (state = intialState, action) => {
  switch (action.type) {
    case CLEAR_PROGRAMS:
      return {
        ...state,
        programsByState: { state: null, programs: [] },
        filteredPrograms: null
      };
    case LOAD_PROGRAMS:
      return {
        ...state,
        programsByState: action.programsByState,
        filteredPrograms: action.programsByState.programs
      };
    case SORT_PROGRAMS:
      let sortedPrograms = [...state.filteredPrograms];

      switch (action.sortBy) {
        case "az":
          sortedPrograms.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          break;
        case "za":
          sortedPrograms.sort((a, b) => {
            if (a.name > b.name) {
              return -1;
            }
            if (a.name < b.name) {
              return 1;
            }
            return 0;
          });
          break;
        default:
          break;
      }
      return {
        ...state,
        filteredPrograms: sortedPrograms
      };
    case FILTER_PROGRAMS:
      const { isProgramStatus, eligibility } = action.filter;

      let filteredPrograms = state.programsByState.programs;

      if (action.filter === "reset") {
        return {
          ...state,
          filteredPrograms
        };
      }

      if (isProgramStatus) {
        filteredPrograms = filteredPrograms.filter(program => program.isActive);
      }

      if (eligibility.length > 0) {
        filteredPrograms = filteredPrograms.filter(program =>
          eligibility.some(eligibilityType =>
            program.customerTypes.some(
              customerType => customerType.value === eligibilityType
            )
          )
        );
      }

      return {
        ...state,
        filteredPrograms
      };
    default:
      return state;
  }
};
