import {Flex, Text} from "@adobe/react-spectrum";
import React from "react";
import {Product} from "./queries";
import {QPL} from "./qpl";

type ProductSpec = Record<string, string>;

export interface ProductSpecCardProps {
    product: Product;
}

const qplLabels = new Map([
    [QPL.ENERGY_STAR, "Energy Star"],
    [QPL.DLC_PREMIUM, "DLC Premium"],
    [QPL.DLC_STANDARD, "DLC Standard"],
]);

function getQplIdLabel(qpl: QPL) {
    if (qpl === QPL.ENERGY_STAR) {
        return "Energy Star ID"
    }

    return "DLC ID"
}

function Label({children}: {children: any}) {
    return <Text UNSAFE_style={{fontWeight: 400}}>{children}</Text>
}

function ListItem({children}: {children: any}) {
    return <li style={{lineHeight: "1em", marginBottom: "1em"}}>{children}</li>;
}

export function ProductSpecCard({product}: ProductSpecCardProps) {
    const mappedSpecs = product.specs.reduce(
        (acc, spec) => ({
            ...acc,
            [spec.spec]: spec.value,
        }),
        {} as ProductSpec
    );

    return (
        <Flex direction="column">
            <Text UNSAFE_style={{fontWeight: 600, marginBottom: "0.5em"}}>Specs</Text>
            <ul
                style={{
                    fontSize: "0.9em",
                    listStyleType: "none",
                    paddingInlineStart: "0",
                }}
            >
                {mappedSpecs.wattage && <ListItem><Label>Wattage</Label>: {mappedSpecs.wattage} w</ListItem>}
                {mappedSpecs.lumens && <ListItem><Label>Lumens</Label>: {mappedSpecs.lumens} l</ListItem>}
                {mappedSpecs.powerFactor && (
                    <ListItem><Label>Power factor</Label>: {mappedSpecs.powerFactor}</ListItem>
                )}
                {mappedSpecs.efficacy && <ListItem><Label>Efficacy</Label>: {mappedSpecs.efficacy} l/w</ListItem>}
                {mappedSpecs.cct && (
                    <ListItem><abbr title="Correlated Color Temperature"><Label>CCT</Label></abbr>: {mappedSpecs.cct} K</ListItem>
                )}
                {mappedSpecs.cri && (
                    <ListItem><abbr title="Color Rendering Index"><Label>CRI</Label></abbr> {mappedSpecs.cri}</ListItem>
                )}
            </ul>

            <Text UNSAFE_style={{fontWeight: 600, marginBottom: "0.5em"}}>Info</Text>
            <ul
                style={{
                    fontSize: "0.9em",
                    listStyleType: "none",
                    paddingInlineStart: "0",
                }}
            >
                <ListItem><Label>Model number</Label>: { product.modelNumber }</ListItem>
                <ListItem><Label>Qualification</Label>: { qplLabels.get(product.QPL) || "" }</ListItem>
                <ListItem><Label>{getQplIdLabel(product.QPL)}</Label>: { product.QPLIdentifier }</ListItem>
                <ListItem><Label>Manufacturer</Label>: { product.manufacturer.name }</ListItem>
                <ListItem><Label>Brand</Label>: { product.brand.name }</ListItem>
                <ListItem><Label>Category</Label>: { product.newProductCategory.name }</ListItem>
            </ul>
        </Flex>
    );
}

export default ProductSpecCard;
