export default {
  pageTypes: {
    Country: { regular: "#c43171", light: "rgba(196, 49, 113, 0.2)" },
    "State/Territory": { regular: "#ff004e", light: "rgba(255, 0, 78, 0.2)" },
    State: { regular: "#ff004e", light: "rgba(255, 0, 78, 0.2)" },
    Utility: { regular: "#ff7800", light: "rgba(255, 120, 0, 0.2)" },
    "Zip Code": { regular: "#ffcd00", light: "rgba(255, 205, 0, 0.2)" },
    Program: { regular: "#56dc00", light: "rgba(86, 220, 0, 0.2)" },
    "Rebates by Technology Type": {
      regular: "#00dc90",
      light: "rgba(0, 220, 144, 0.2)"
    },
    Product: { regular: "#af00dc", light: "rgba(199,76,230, 0.8)" },
    Rebate: { regular: "#00a3d9", light: "rgba(25,172,223, 0.8)" }
  },
  messages: {
    error: { background: "rgba(255, 0, 78, 0.1)", icon: "#ff004e" },
    success: { background: "rgba(86, 220, 0, 0.1)", icon: "#56dc00" },
    info: { background: "rgba(0, 163, 220, 0.1)", icon: "#00a3dc" }
  },
  rbGreen: { regular: "#16a888", light: "rgba(22, 168, 136, 0.4)" }
};
