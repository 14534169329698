import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { changePermissions } from "../ducks/actions";

import Loading from "../../Common/components/Loading";
import TeamMember from "./TeamMember";

/**
 * ViewTeam is the default component for the Team route.
 */

const ViewTeam = ({ team, deactivating, loading }) => {
  const dispatch = useDispatch();

  if (loading) {
    return (
      <>
        <h1>My Team</h1>
        <Loading />
      </>
    );
  } else {
    const handleAdjustAdmin = (user) => dispatch(changePermissions(user));

    const possibleAdminRoles = ["Admin", "RebateBusDeveloper"];

    const admins = team.filter(
      (tm) => possibleAdminRoles.indexOf(tm.permissions.value) > -1
    );

    const regularTms = team.filter(
      (tm) => possibleAdminRoles.indexOf(tm.permissions.value) < 0
    );

    const generateTeamMembers = (array) =>
      array.map((tm) => (
        <TeamMember
          user={tm}
          key={tm.uuid}
          deactivating={deactivating}
          handleDeactivate={() => {}}
          handleAdjustAdmin={handleAdjustAdmin}
        />
      ));

    return (
      <>
        <h1>My Team</h1>
        <h2>Admins</h2>
        <hr />
        <p>
          Admins are able to view and change company information, such as
          subscriptions or API keys.
        </p>
        {generateTeamMembers(admins)}
        <h2>Team Members</h2>
        <hr />
        {generateTeamMembers(regularTms)}
      </>
    );
  }
};

export default ViewTeam;

ViewTeam.propTypes = {
  /** An array of users that is generated with `TeamMember.js`. */
  team: PropTypes.array.isRequired,
  /** State from Team that determines whether to show the button that renders DeactivateAccount.  */
  deactivating: PropTypes.bool.isRequired,
};
