import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const RebateStep = ({ step, name }) => {
  return (
    <h2>{name}</h2>
  );
};

export default RebateStep;

RebateStep.propTypes = {
  /** The step number for adding rebates */
  step: PropTypes.string.isRequired,
  /** The name of the step */
  name: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  margin: 3em 0 2em 0;
  border-bottom: 2px solid #cacaca;
  span {
    color: #cacaca;
  }
`;
