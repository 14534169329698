import {Provider as SpectrumProvider} from "@react-spectrum/provider";
import {
    ActionButton,
    AlertDialog,
    Button,
    defaultTheme,
    DialogContainer,
    Flex,
    ProgressCircle,
    TextArea,
    TextField,
    View
} from "@adobe/react-spectrum";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import Select, {Styles} from "react-select";
import React, {useEffect, useReducer, useState} from "react";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {Label} from "@react-spectrum/label";
import {useHistory, useLocation} from "react-router-dom";
import Helmet from "react-helmet";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    CreateEstimateRequestResponse,
    CreateEstimateRequestUserData,
    GET_FORM_OPTIONS,
    SUBMIT_SIMPLE_REQUEST
} from "./queries";

export const narrowReactSelectStyles: Styles = {
    indicatorSeparator: (provided) => ({
        ...provided,
        margin: "7px 0",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "4px",
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    control: (provided, {isFocused}) => {
        const borderColor = isFocused ? "var(--accent)" : "var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400))";
        return {
            ...provided,
            minHeight: "32px",
            maxHeight: "32px",
            '&:hover': {borderColor},
            borderColor,
            boxShadow: borderColor,
        }
    },
};

interface FormGroupParams {
    children: any,
    label: string,
    id: string,
}

export const FormGroup = ({children, label, id}: FormGroupParams) => (
    <View id={id}>
        {label !== undefined ? <Label>{label}</Label> : null}
        {children}
    </View>
);

const Header = ({children}: any) => {
    return (
        <div style={{fontWeight: "bold", fontSize: "1.5em", marginLeft: 29}}>{children}</div>
    );
}

const Subheader = ({children}: any) => {
    return (
        <div style={{fontWeight: "bold", fontSize: "1.1em", color: "var(--accent)", marginTop: 20}}>{children}</div>
    );
}

const ErrorMessageWrapper = ({message}: { message: string }) => (
    <View UNSAFE_style={{color: "var(--spectrum-global-color-red-700)"}}>
        {message}
    </View>
);

interface ErrorMessagesParams {
    errors: any,
    name: string,
}

export const ErrorMessages = ({errors, name}: ErrorMessagesParams) => {
    return (
        <HookErrorMessage
            errors={errors}
            name={name}
            render={({messages, message, ...rest}) => {
                if (message !== undefined) {
                    return <ErrorMessageWrapper message={message}/>
                }

                if (messages !== undefined) {
                    Object.entries(messages).map(([type, message]) => (
                        <ErrorMessageWrapper key={type} message={message?.toString() || ""}/>
                    ))
                }

                return null;
            }}
        />
    )
}

interface ComponentState {
}

type Action = any;

function reducer(state: ComponentState, action: Action) {

}

const initialState: ComponentState = {};

interface SelectOption {
    value: string,
    label: string,
}

interface FormValues {
    clientInformation: {
        fullName: string,
        email: string,
    },
    productInformation: {
        order: string | null,
        notes: string,
    },
    installationAddress: {
        state: SelectOption | null,
        city: string,
        streetAddress: string,
        zipCode: string,
    }
}

function RequestEstimateForm() {
    // @ts-ignore
    const [state, dispatch] = useReducer(reducer, initialState);
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const {control, errors, formState, getValues, trigger, setValue} = useForm<FormValues>({
        defaultValues: {
            clientInformation: {
                fullName: "",
                email: "",
            },

            productInformation: {
                order: "",
                notes: "",
            },
            installationAddress: {
                state: null,
                city: "",
                streetAddress: "",
                zipCode: "",
            },
        },
        mode: "onChange",
    });

    const {error: formOptionsError, loading: isLoadingFormOptions, data: formOptions} = useQuery(GET_FORM_OPTIONS);


    const location = useLocation();
    const history = useHistory();
    const locationSearch = location.search;
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get("order_id");

    const {
        user,
    } = useSelector((state) => {
        // @ts-ignore
        return state.account
    });

    const email = user.email;
    const firstName = user.firstName;
    const lastName = user.lastName;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const clientInformation: Partial<FormValues["clientInformation"]> = {};

        const fullNameFromSearch = searchParams.get('username');
        let fullName;
        if (firstName) {
            fullName = firstName
            if (lastName) {
                fullName = `${firstName} ${lastName}`
            }
        }

        if (fullNameFromSearch !== null) {
            clientInformation.fullName = fullNameFromSearch;
        } else if (fullName !== undefined) {
            clientInformation.fullName = fullName;
        }

        clientInformation.email = searchParams.get('email') || email || '';

        let order;
        const orderId = searchParams.get('order_id');
        if (orderId) {
            order = orderId;
            const vendor = searchParams.get('vendor');
            if (vendor) {
                order = `${orderId} at ${vendor}`;
            }
        }
        const productInformation: Partial<FormValues["productInformation"]> = {};
        if (order) {
            productInformation.order = order;
        }

        setValue('clientInformation', clientInformation);
        setValue('productInformation', productInformation);
    }, [locationSearch, email, firstName, lastName]);

    const [createEstimateRequest] = useMutation<CreateEstimateRequestResponse, CreateEstimateRequestUserData>(SUBMIT_SIMPLE_REQUEST)

    const onSubmit = async () => {
        setHasBeenSubmitted(true);
        const isValid = await trigger();
        if (!isValid) {
            return;
        }
        setIsSubmitting(true);
        try {
            const data = getValues();
            const response = await createEstimateRequest({
                variables: {
                    userData: {
                        fullName: data.clientInformation.fullName,
                        email: data.clientInformation.email,
                        order: data.productInformation.order,
                        notes: data.productInformation.notes,
                        // At this point the form has been validated so state can not null
                        state: data.installationAddress.state!.label,
                        city: data.installationAddress.city,
                        streetAddress: data.installationAddress.streetAddress,
                        zipCode: data.installationAddress.zipCode,
                    }
                }
            })
            setIsSubmitting(false);
            if (response?.data?.data.status === "OK") {
                setIsSuccessDialogOpen(true);
            } else {
                setIsErrorDialogOpen(true);
            }
        } catch (e) {
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    return (
        <SpectrumProvider theme={defaultTheme} colorScheme="light" UNSAFE_style={{backgroundColor: "white"}}>
            <Helmet>
                <meta name="description" content="Request incentive estimate for your project."/>
                <title>Request Incentive Estimate | Rebate Bus</title>
            </Helmet>
            <Header>Request Incentive Estimate</Header>
            <form style={{marginBottom: "30px"}}>
                <Flex direction="column" margin="0 30px" maxWidth="650px" width="100%" gap="size-100">
                    <Subheader>Client Information</Subheader>
                    <Controller
                        control={control}
                        name='clientInformation.fullName'
                        rules={{
                            required: "Full name is required",
                            minLength: 2,
                            maxLength: 100,
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Full name"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={100}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="clientInformation.fullName"/>

                    <Controller
                        control={control}
                        name="clientInformation.email"
                        rules={{
                            validate: {
                                required: (value) => {
                                    if (value === "") {
                                        return "Email is required.";
                                    }

                                    return true;
                                },
                                maxLength: (value) => {
                                    if (value.length > 100) {
                                        return "Max length is 100";
                                    }

                                    return true;
                                },
                                email: (value) => {
                                    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    const isValidEmail = re.test(value);

                                    if (!isValidEmail) {
                                        return "Email must be valid"
                                    }

                                    return true;
                                },
                            },
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Email"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={100}
                            />
                        )}
                    />
                    <ErrorMessages errors={errors} name="clientInformation.email"/>

                    <Subheader>Products</Subheader>
                    <Controller
                        control={control}
                        name="productInformation.order"
                        render={(props) => (
                            <TextField
                                isHidden={orderId === null}
                                {...props}
                                isReadOnly
                                label="Order"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={500}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="productInformation.notes"
                        render={(props) => (
                            <TextArea
                                {...props}
                                label="Notes"
                                placeholder="Optionally enter product part numbers, urls to products, and any other comments..."
                                UNSAFE_style={{width: "100%"}}
                                maxLength={2000}
                            />
                        )}
                    />
                    <Subheader>Installation Address</Subheader>
                    <FormGroup label="State" id="stateSelect">
                        <Controller
                            control={control}
                            name={`installationAddress.state`}
                            rules={{
                                required: "State is required"
                            }}
                            render={(props) => (
                                <Select
                                    {...props}
                                    placeholder=""
                                    styles={narrowReactSelectStyles}
                                    isLoading={isLoadingFormOptions}
                                    options={isLoadingFormOptions ? [] : formOptions.states}
                                />
                            )}
                        />
                    </FormGroup>
                    {
                        (!formState.touched.installationAddress?.state && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="installationAddress.state"/>
                        )
                    }
                    <Controller
                        control={control}
                        name="installationAddress.city"
                        rules={{
                            required: "City is required"
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="City"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={500}
                            />
                        )}
                    />
                    {
                        (!formState.touched.installationAddress?.city && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="installationAddress.city"/>
                        )
                    }
                    <Controller
                        control={control}
                        name="installationAddress.streetAddress"
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Street Address"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={500}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="installationAddress.zipCode"
                        rules={{
                            validate: {
                                required: (value) => {
                                    if (value === "") {
                                        return "Zip code is required.";
                                    }

                                    return true;
                                },
                                zipCode: (value) => {
                                    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                                    const isValidZipCode = re.test(value);

                                    if (!isValidZipCode) {
                                        return "Zip code must be valid"
                                    }

                                    return true;
                                },
                            },
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Zip code"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={500}
                            />
                        )}
                    />
                    {
                        (!formState.touched.installationAddress?.zipCode && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="installationAddress.zipCode"/>
                        )
                    }

                    {
                        isSubmitting ? (
                            <ProgressCircle aria-label="Loading…" isIndeterminate/>
                        ) : (
                            <Button
                                marginTop="size-300"
                                onPress={() => onSubmit()}
                                variant="cta"
                                width="11em"
                                UNSAFE_style={{
                                    backgroundColor: "var(--accent)",
                                    borderColor: "var(--accent)",
                                    color: "white",
                                    cursor: "pointer"
                                }}
                                UNSAFE_className="cta"
                            >Request Estimate</Button>
                        )
                    }
                    <DialogContainer onDismiss={() => {
                        history.push('/fulfillment-services');
                    }}>
                        {isSuccessDialogOpen && (
                            <AlertDialog
                                title="Success"
                                variant="confirmation"
                                primaryActionLabel="OK">
                                Thank you! Your request has been successfully submitted. We will email you within 2
                                business days.
                            </AlertDialog>
                        )}
                    </DialogContainer>
                    <DialogContainer onDismiss={() => {
                        setIsErrorDialogOpen(false);
                    }}>
                        {isErrorDialogOpen && (
                            <AlertDialog
                                title="Error"
                                variant="error"
                                primaryActionLabel="OK">
                                Sorry. There was an error. Please try again later.
                            </AlertDialog>
                        )}
                    </DialogContainer>
                </Flex>
            </form>
        </SpectrumProvider>
    )
}

export default RequestEstimateForm;
