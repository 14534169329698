import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { verifyV1User, updateV1Password } from "../ducks/userActions";

import styled from "styled-components";
import Input from "../../Common/components/formComponents/Input";
import { PrimaryButton } from "../../Common/components/Button";
import Loading from "../../Common/components/Loading";
import ChangePassword from "../../Account/components/ChangePassword";

const V1UserSignup = ({ verifyV1User, updateV1Password }) => {
  const existingV1Account = useSelector(
    state => state.signup.existingV1Account
  );

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleEmailSubmit = event => {
    event.preventDefault();
    verifyV1User(email);
    setLoading(true);
  };

  if (existingV1Account && loading) {
    setLoading(false);
  }

  const user = existingV1Account && existingV1Account !== "unable to reset";

  const handlePasswordSubmit = (event, password) => {
    event.preventDefault();
    updateV1Password({ uuid: existingV1Account, password });
    setRedirect(true);
  };

  return (
    <>
      {!loading && !existingV1Account && (
        <>
          <h1>Existing Users</h1>
          <p>
            Please enter the email address associated with your Rebate Bus
            account.
          </p>
          <form onSubmit={handleEmailSubmit}>
            <Input
              id="v1Email"
              title="Email address"
              required
              inputType="input"
              value={email}
              handleChange={(_, value) => setEmail(value)}
            />
            <PrimaryButton>Submit Email Address</PrimaryButton>
          </form>
        </>
      )}
      {loading && <Loading />}
      {user && <ChangePassword handleSubmit={handlePasswordSubmit} />}
      {existingV1Account && !user && (
        <NoResetWrapper>
          This account has already been updated. If you think this is an error,
          please email{" "}
          <a href="mailto:support@rebatebus.com?subject=V1 account transfer">
            support@rebatebus.com
          </a>
          .
        </NoResetWrapper>
      )}
      {redirect && <Redirect to="/signin" />}
    </>
  );
};

export default connect(null, { verifyV1User, updateV1Password })(V1UserSignup);

const NoResetWrapper = styled.p`
  a {
    font-size: inherit;
  }
`;
