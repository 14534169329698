import { GET_WIKI, SHOW_NEW, SHOW_OPTIONS } from "./types";

const initialState = {
  wikiArticles: [],
  wikiPage: {},
  newPage: {},
  formOptions: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WIKI:
      const { countries, states, utilities, programs } = action;
      const searchOptions = [
        { label: "Country", options: countries },
        { label: "State/Territory", options: states },
        { label: "Utility", options: utilities },
        { label: "Program", options: programs }
      ];
      return { ...state, wikiArticles: searchOptions };

    case SHOW_NEW:
      return {
        ...state,
        newPage: action.page
      };

    case SHOW_OPTIONS:
      return {
        ...state,
        formOptions: {
          ...state.formOptions,
          ...action.options
        }
      };

    default:
      return state;
  }
};
