import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { attemptSignin } from "./ducks/actions";
import { showMessages } from "../Common/components/Message";
import { successMessage, dismissMessage } from "../Common/ducks/actions";

import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

import MainContentWrapper from "../Common/components/layout/MainContentWindowWrapper";
import { PreviewButton, PrimaryButton } from "../Common/components/Button";

import Deactivate from "./components/Deactivate";

/**
 * Renders the sign in screen. It takes no props other than what is passed by redux.
 */

class Signin extends Component {
  static propTypes = {
    /** The array of messages passed down from the App component */
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    ),
    /** If the user is directed to sign in before viewing a page, the page is passed along to redirect there after signing in */
    redirectRoute: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      deactivated: false,
      forgotPassword: false,
      email: "",
      password: ""
    };
  }

  handleChange = ({ target }) => {
    this.setState({ [target.id]: target.value });
  };

  handleSubmit = async event => {
    const { email, password } = this.state;
    event.preventDefault();
    const status = await this.props.attemptSignin(email, password);

    if (
      status.message ===
      "This account is inactive.  Refer to company administrator to reactivate."
    ) {
      this.setState({ deactivated: !this.state.deactivated });
    }
  };

  render() {
    const { messages, dismissMessage, loggedIn, redirectRoute } = this.props;
    const { forgotPassword } = this.state;

    if (this.state.deactivated) {
      return <Deactivate signIn />;
    }

    if (loggedIn && redirectRoute) {
      window.location.assign(redirectRoute);
    } else if (loggedIn) {
      window.location.assign("/");
    }

    return (
      <main id="mainContentContainer">
        {loggedIn ? <Redirect to={redirectRoute || "/"} /> : null}
        <div id="mainContentWindowContainer">
          {showMessages(messages, dismissMessage)}
          <MainContentWrapper>
            {!forgotPassword && (
              <>
                <h1>Sign In</h1>
                <form onSubmit={this.handleSubmit}>
                  <InputWrapper>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      onChange={this.handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextField
                      id="password"
                      label="Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                      onChange={this.handleChange}
                    />
                  </InputWrapper>
                  <PrimaryButton>Sign In</PrimaryButton>
                </form>
                <p>
                  Forgot your password? Email{" "}
                  <ForgotPasswordButton>
                    <a href="mailto:support@rebatebus.com?subject=Forgot password">
                      support@rebatebus.com
                    </a>
                  </ForgotPasswordButton>{" "}
                  from the email address you use to login.
                </p>
                <Link to="/signup">
                  <button style={{
                    border: "2px solid #16a888",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    padding: "0.5em 1.1em",
                    fontSize: "0.9em",
                    cursor: "pointer"
                  }}>Create New Account</button>
                </Link>
              </>
            )}
          </MainContentWrapper>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.account.loggedIn
  };
};

export default connect(mapStateToProps, {
  attemptSignin,
  successMessage,
  dismissMessage
})(Signin);

const InputWrapper = styled.div`
  margin: 1em 0;
`;

const ForgotPasswordButton = styled(PreviewButton)`
  margin-top: 2em;
`;
