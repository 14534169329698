import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getRebates, clearProducts } from "../../ducks/rebateActions";
import { filterByTechType, setLoading } from "../../ducks/filterActions";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { PageHeader, RebateRequirements } from "./PageComponents";
import CardlessCard from "../../../Common/components/CardlessCard";
import { PreviewButton } from "../../../Common/components/Button";
import Loading from "../../../Common/components/Loading";
import deepEqual from "deep-equal";

import {
  BoldKeyValue,
  RebateCardMiddle,
  RebateCardFooter,
  RebateCardFooterId,
} from "./StyledPageComponents";

import textTrim from "../../helpers/textTrim";
import { textToUrl } from "../../helpers/urls";
import { update } from "../../../Team/ducks/actions";

class TechnologyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      modelNumber: null,
    };
  }

  componentDidMount() {
    const { getRebates, programId, uuid, technologyTypeId } = this.props;
    getRebates(
      programId
        ? programId
        : this.props.product.uuid
        ? this.props.product.uuid
        : uuid,
      0,
      50,
      this.props.product.uuid ? this.props.product.uuid : uuid ? true : false
    );
  }

  componentWillUnmount() {
    this.props.clearProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      uuid,
      rebatesByProgram,
      rebatesByProduct,
      technologyTypeId,
      filterByTechType,
      filteredRebates,
      getRebates,
      programId,
      product,
      loading,
    } = this.props;
    const { offset } = this.state;

    if (prevProps.loading !== loading) {
      return true;
    }

    if (!deepEqual(prevProps.product.uuid, product.uuid)) {
      return true;
    }

    if (
      // * if new rebates have been fetched, add to filter
      !deepEqual(
        rebatesByProduct.totalCount
          ? rebatesByProduct.totalCount
          : rebatesByProgram.totalCount,
        prevProps.rebatesByProduct.totalCount
          ? prevProps.rebatesByProduct.totalCount
          : prevProps.rebatesByProgram.totalCount
      )
    ) {
      this.props.setLoading(true);
      filterByTechType(technologyTypeId);
    }

    if (loading && filteredRebates) {
      // * if rebates have been filtered, set loading to false
      this.props.setLoading(false);
    }

    if (filteredRebates !== null) {
      // * if rebates have been filtered, check if there are more
      if (
        !deepEqual(prevProps.filteredRebates, filteredRebates) &&
        (filteredRebates.length < rebatesByProduct.totalCount
          ? rebatesByProduct.totalCount
          : rebatesByProgram.totalCount)
      ) {
        const remainingRebates =
          (rebatesByProduct.totalCount
            ? rebatesByProduct.totalCount
            : rebatesByProgram.totalCount) - filteredRebates.length;

        const limit = remainingRebates > 50 ? 50 : remainingRebates;

        this.setState({ offset: offset + 50 }, () =>
          getRebates(
            programId ? programId : product.uuid ? product.uuid : uuid,
            this.state.offset,
            limit,
            uuid ? true : false
          )
        );
      }
    }
  }

  render() {
    const {
      program,
      filteredRebates,
      technologyTypeId,
      permissions,
      product,
      modelNumber,
    } = this.props;

    var sortedFilteredRebates = [];

    if (filteredRebates) {
      sortedFilteredRebates = filteredRebates.sort(function (a, b) {
        if (a.updated < b.updated) return 1;
        if (a.updated > b.updated) return -1;
        return 0;
      });
    }

    const truncateModelNumber = (str) => {
      if (str.length > 15) {
        return str.slice(0, 15) + "...";
      }
      return str;
    };

    const showRebates =
      filteredRebates && filteredRebates.length > 0 ? (
        sortedFilteredRebates.map((rebate) => (
          <RebateCardless
            key={rebate.uuid}
            {...rebate}
            permissions={permissions}
          />
        ))
      ) : filteredRebates && filteredRebates.length === 0 ? (
        <p>There are no rebates that match the criteria</p>
      ) : null;

    const parentProgram = program
      ? {
          name: program.name,
          url: `/search/articles/${program.uuid}/program/${textToUrl(
            program.name
          )}`,
        }
      : null;

    const techType = program
      ? program.technologyTypes.filter(
          (type) => type.uuid === technologyTypeId
        )[0].value
      : null;

    return (
      <>
        <Helmet>
          <meta name="description" content="Rebate Utility Programs" />
          <title>{`Rebate Bus | ${techType ? techType : "Lighting"} Rebates | ${
            program
              ? program.name
              : product.modelNumber
              ? product.modelNumber
              : modelNumber
          }`}</title>
        </Helmet>
        <PageHeader
          name={`${
            techType
              ? techType
              : product.modelNumber
              ? truncateModelNumber(product.modelNumber)
              : truncateModelNumber(modelNumber)
          } Rebates`}
          type="Technology Type"
          parentProgram={parentProgram ? parentProgram : ""}
          url=""
          permissions={permissions}
          programId={program ? program.uuid : "product"}
        />
        {this.props.loading ? <Loading /> : showRebates}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rebatesByProduct: state.wiki.rebates.rebatesByProduct,
  rebatesByProgram: state.wiki.rebates.rebatesByProgram,
  filteredRebates: state.wiki.rebates.filteredRebates,
  product: state.wiki.rebates.product,
  loading: state.wiki.rebates.loading,
});

export default connect(mapStateToProps, {
  getRebates,
  filterByTechType,
  clearProducts,
  setLoading,
})(TechnologyType);

TechnologyType.propTypes = {
  /** The uuid of the technology type which will help get hte value, i.e. Lighting, HVAC, etc. */
  technologyTypeId: PropTypes.string.isRequired,
  /** The parent program offering the rebates. Might switch to an object to include the program URL. */
  program: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  /** The array of rebates, which can be an empty array if there are none based on filtering or program! */
  // rebatesByProgram: PropTypes.object.isRequired,
};

const RebateCardless = ({
  rebateName,
  notes,
  uuid,
  projectTypes,
  rebateType,
  QPLs,
  cap,
  code,
  rebateRates,
  rebateRequirements,
  updated,
  permissions,
}) => {
  const headline =
    rebateRates &&
    rebateRates
      .map((rate) => {
        const number = Number.isInteger(rate.value)
          ? rate.value
          : rate.value.toFixed(2);

        const rateAmount =
          rate.rebateRateUnit.value.indexOf("Percentage") >= 0
            ? `${number}%`
            : `$${number}`;

        return `${rateAmount} / ${rate.rebateRateUnit.value}`;
      })
      .join(", ");

  const rebateUrl = `/search/rebate/${uuid}/${textToUrl(rebateName.name)}`;
  let rebateLastModified = null;
  if (updated && permissions) {
    let updatedDate = parseInt(updated);
    let modifiedDate =
      new Date(updatedDate).toLocaleDateString() +
      " " +
      new Date(updatedDate).toLocaleTimeString();
    rebateLastModified = (
      <RebateCardFooter>
        <RebateCardFooterId>
          <span>Last modified: </span>
          {modifiedDate}
        </RebateCardFooterId>
        <Link to={rebateUrl}>
          <PreviewButton>view rebate</PreviewButton>
        </Link>
      </RebateCardFooter>
    );
  }

  return (
    <CardlessCard
      name={rebateName.name}
      rebateId={uuid}
      type="Rebate"
      headline={headline}
    >
      <RebateCardMiddle>
        {notes && notes !== "undefined" && (
          <p className="description">{textTrim(notes, 200)}</p>
        )}
        <div>
          <BoldKeyValue>
            <span>Project Type: </span>
            {projectTypes.map((type) => type.value).join(", ")}
          </BoldKeyValue>
          <BoldKeyValue>
            <span>Rebate Type: </span>
            {rebateType.value}
          </BoldKeyValue>
          <BoldKeyValue>
            <span>QPL: </span>
            {QPLs.map((qpl) => qpl.value).join(", ")}
          </BoldKeyValue>
          <BoldKeyValue>
            <span>Cap: </span>
            {cap !== null ? `$${cap}` : "None"}
          </BoldKeyValue>
          {code && (
            <BoldKeyValue>
              <span>Code: </span>
              {code}
            </BoldKeyValue>
          )}
        </div>
        <RebateRequirements rebateRequirements={rebateRequirements} />
      </RebateCardMiddle>
      <RebateCardFooter>
        <RebateCardFooterId>
          <span>RBID: </span>
          {uuid}
        </RebateCardFooterId>
        {permissions && updated ? (
          ""
        ) : (
          <Link to={rebateUrl}>
            <PreviewButton>view rebate</PreviewButton>
          </Link>
        )}
      </RebateCardFooter>
      {rebateLastModified}
    </CardlessCard>
  );
};

RebateCardless.propTypes = {
  /** The card title, might be different than the name, could be nonexistant! */
  rebateName: PropTypes.object.isRequired,
  /** The notes for the rebate */
  notes: PropTypes.string.isRequired,
  /** The uuid of the rebate for reporting issues/creating the URL for the full rebate page */
  uuid: PropTypes.string.isRequired,
};
