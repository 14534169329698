import React from 'react';
import {ActionButton, Flex, ProgressCircle, Text} from "@adobe/react-spectrum";
import AddIcon from '@spectrum-icons/workflow/Add';
import RemoveIcon from '@spectrum-icons/workflow/Remove';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import {Label} from "@react-spectrum/label";
import "./style.css"
import {Column, useTable} from "react-table";
import {FlexProps} from "@react-types/layout";
import GarbageBinIcon from '../../../../icons/Remove';


export interface AttachmentFile {
    displayName: string,
    name: string,
    createdAt: string,
}

/**
 * @param key S3 Object Key
 */
export type AttachmentsPanelParamsOnDeleteFile = (key: string) => void;
export type AttachmentsPanelParamsOnDownloadFile = (key: string) => void;

export interface AttachmentsPanelParams {
    files: Array<AttachmentFile>,
    style?: Partial<FlexProps>,
    isFetching?: boolean,
    onDelete: AttachmentsPanelParamsOnDeleteFile,
    onDownload: AttachmentsPanelParamsOnDownloadFile,
    onUpload: () => void,
}

export default function AttachmentsPanel({
                                             isFetching = false,
                                             files,
                                             style = {},
                                             onDelete,
                                             onDownload,
                                             onUpload,
                                         }: AttachmentsPanelParams) {

    const columns = React.useMemo<Array<Column<AttachmentFile>>>(
        () => [
            {
                Header: () => <Label>Name</Label>,
                accessor: 'displayName',
                maxWidth: 100,
                Cell: ({value}) => {
                    return (
                        <span title={value}>{value}</span>
                    );
                }
            },
            {
                Header: () => <Label>Date added</Label>,
                accessor: 'createdAt',
                Cell: ({value: createdAt}) => {
                    return new Date(createdAt).toLocaleDateString()
                }
            },
            {
                Header: '',
                id: "manage",
                accessor: 'name',
                Cell: ({value}) => {
                    return (
                        <Flex justifyContent="end">
                            <ActionButton
                                onPress={() => onDelete(value)}
                                isQuiet
                                aria-label="delete"
                            >
                                <GarbageBinIcon style={{width: "1.3em"}}/>
                            </ActionButton>
                            <ActionButton
                                onPress={() => onDownload(value)}
                                isQuiet
                                aria-label="download"
                            >
                                <DownloadIcon/>
                            </ActionButton>
                        </Flex>
                    )
                }
            },
        ],
        [onDelete, onDownload]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data: files})

    return (
        <Flex direction="column" {...style}>
            <Flex alignItems="center">
                <Text UNSAFE_style={{fontWeight: 600}}>Attachments</Text>
                {
                    isFetching ? <ProgressCircle aria-label="Loading…" isIndeterminate marginStart="size-200"/> : <></>
                }
                <ActionButton
                    onPress={() => onUpload()}
                    isQuiet
                    marginStart="auto"
                    UNSAFE_style={{
                        padding: "0 0.5em"
                    }}
                >
                    <AddIcon/>
                    <Text>Add</Text>
                </ActionButton>
            </Flex>
            <table className="attachments-panel" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                style={{
                                    maxWidth: column.maxWidth || "100%",
                                }}
                                {...column.getHeaderProps()}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        style={{
                                            overflowX: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "150px",
                                            whiteSpace: "nowrap",
                                        }}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {
                (!isFetching && rows.length === 0) ? <Text>No attachments</Text> : <></>
            }
        </Flex>
    );
}
