import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/pages/Home";
import Platform from "./components/pages/Platform";
import DistributorAdvertising from "./components/pages/DistributorAdvertising";
import RebateAPI from "./components/pages/RebateAPI";
import RebateConnect from "./components/pages/RebateConnect";
import FulfillmentServices from "./components/pages/FulfillmentServices";
import PricingPackages from "./components/pages/PricingPackages";
import Support from "../Common/components/pages/Support";
import PressRoom from "./components/pages/PressRoom";
import Release from "./components/pages/Release";
import { showMessages } from "../Common/components/Message";
import { dismissMessage } from "../Common/ducks/actions";
import Helmet from "react-helmet";
import WixRedirect from "./components/pages/WixRedirect";

const Marketing = (props) => {
  const { messages, dismissMessage, loggedIn } = props;

  const urls = [
    { url: "/", Component: Home, exact: true },
    { url: "/rebate-bus-platform", Component: Platform },
    { url: "/distributor-advertising", Component: DistributorAdvertising },
    { url: "/rebate-bus-api", Component: RebateAPI },
    { url: "/rebate-bus-connect", Component: RebateConnect },
    { url: "/fulfillment-services", Component: FulfillmentServices },
    { url: "/contact-us", Component: Support },
    { url: "/press-room/:date/:title", Component: Release },
    { url: "/press-room", Component: PressRoom },
  ];

  const routes = urls.map(({ url, Component, exact }, i) => (
    <Route
      key={`routes-${i}`}
      exact={exact}
      path={url}
      render={(props) => <Component loggedIn={loggedIn} {...props} />}
    />
  ));

  return (
    <main id="mainContentContainer">
      <Helmet>
        <title>Rebate Bus</title>
      </Helmet>
      <div id="mainContentWindowContainer">
        {showMessages(messages, dismissMessage)}
        <Switch>
          {routes}
          <Redirect to="/wrong-turn" />
        </Switch>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.account.loggedIn,
});

export default connect(mapStateToProps, { dismissMessage })(Marketing);
