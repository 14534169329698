import styled from "styled-components";
import { Link } from "react-router-dom";
import Card from "../../../Common/components/Card";

export const StyledPageHeader = styled.div`
  h1 {
    color: black;
    margin-bottom: 0.25em;
  }
  h2 {
    color: black;
    font-weight: normal;
    font-size: 1.4em;
    margin-top: -0.25em;
    margin-bottom: 0.25em;
  }
  span {
    color: ${props => props.theme.pageTypes[props.type].regular};
  }
  a {
    color: inherit;
    font-style: normal;
    font-size: inherit;
    text-decoration: none;
  }
`;

export const Section = styled.div`
  margin-top: 2em;
  h2 {
    margin-bottom: 0.5em;
    font-size: ${props => (props.subsection ? "1.3em" : "1.7em")};
  }
  a {
    width: ${props => (props.urlLink ? "100%" : "auto")};
  }
  span.advertise {
    margin-bottom: 2em;
  }
`;

const WikiCard = styled(Card)`
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  margin: 1.5em 0;
  height: 13.5em;
  p {
    margin: 0;
  }
  p.wikiCardTitle {
    font-size: 1.2em;
    font-weight: 700;
  }
  p.wikiCardSubtitle {
    color: #16a888;
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  p.wikiCardDescription {
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }
  a {
    margin: 0;
    font-style: normal;
    font-size: 1em;
    text-decoration: none;
    text-align: right;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ContactCard = styled(WikiCard)`
  &.wikiContact p.wikiCardDescription {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    img {
      border-radius: 50%;
      margin-right: 1.5em;
      max-height: 1.25em;
    }
    a {
      color: black;
      font-style: italic;
      text-decoration: underline;
    }
  }
`;

export const ReportCard = styled(WikiCard)`
  justify-content: space-between;
  &.wikiReport {
    p.dangerText {
      font-size: 1.3em;
      color: #ff0053;
      margin: 0.5em 0;
      text-align: center;
    }
  }
`;

export const LogoWrapper = styled.div`
  max-width: 300px;
  max-height: 200px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const GreenSpan = styled.span`
  font-weight: 700;
  color: #18a888;
`;

export const LightSpan = styled.span`
  font-weight: 300;
`;

export const Checkmark = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  img {
    margin-right: 1em;
    max-height: 1.25em;
  }
`;

export const BoldKeyValue = styled.p`
  margin-bottom: 0;
  span {
    font-weight: bold;
    text-transform: ${props => (props.capitalize ? "capitalize" : "none")};
  }
`;

export const Product = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 27em;
  width: 25%;
  padding: 30px;
  margin: 0 0 3em 3em;
  border-radius: 15px;

  -webkit-box-shadow: 1px 1px 20px #e8e8e8;
  -moz-box-shadow: 1px 1px 20px #e8e8e8;
  box-shadow: 1px 1px 20px #e8e8e8;
  @media all and (max-width: 1024px) {
    width: 40%;
  }

  @media all and (max-width: 576px) {
    width: 100%;
    margin: 0 0 1em 1em;
  }

  div.image-container {
    margin-bottom: 1em;
    min-height: 9em;
    height: 9em;
    min-width: 9em;
  }

  div.image-container img {
    max-height: 9em;
    max-width: 9em;
  }

  div.model-number {
    width: 100%;
    height: 23em;
    max-height: 23em;
    overflow-x: scroll;
    p {
      text-align: center;
      font-size: 0.9em;
      font-weight: 700;
      width: 100%;
      word-wrap: break-word;
      line-height: 110%;
    }
  }

  div.product-category {
    width: 100%;
    height: 18em;
    max-height: 18em;
    overflow-x: scroll;
    margin-top: 1.5em;
    p {
      text-align: center;
      line-height: 1;
      font-weight: 300;
      font-size: 0.9em;
    }
  }

  div.button-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 35em;
    height: 35em;
  }
`;

export const ProductList = styled.div`
  display: ${props => (props.expand ? "flex" : "none")};
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-flow: row-wrap;
  border-left: 2.4px solid #0ea788;
  width: 100%;
  padding-top: 1.2em;

  span.see-more-products {
    width: 100%;
    margin: 0 0 1em 3em;
    font-weight: 700;
    color: #18a888;
    cursor: pointer;
  }
`;
export const Button = styled.div`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-self: flex-end;
  border: 1.5px solid #18a888;
  border-radius: 5px;
  padding: 10px;
  font-weight: 700;
  font-size: 1rem;
  width: 100%;
  background-color: ${props => (props.purchaseNow ? "#0ea788" : null)};
  margin-top: auto;
  margin-bottom: ${props => props.purchaseNow};

  span {
    color: ${props => (props.purchaseNow ? "white" : "#0ea788")};
  }
`;

export const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  margin-bottom: 0;
  font-style: initial;
  span {
    color: ${props => (props.purchaseNow ? "white" : "#0ea788")};
  }
`;

export const Collapse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8em;
  margin-bottom: 0.7em;
  span {
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 400;
  }
`;

export const SponsoredOrQualifiedProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 3em;

  h3 {
    margin-bottom: 1.4em;
  }
`;
export const RebateCardFooter = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RebateCardMiddle = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }

  p.description {
    flex: 0 0 27%;
    color: #cacaca;
    font-size: 1em;
    line-height: 120%;
  }
  div {
    flex: 0 0 30%;

    &:last-of-type {
      flex: 0 0 35%;
    }
  }
`;

export const RebateCardFooterId = styled(BoldKeyValue)`
  font-size: 0.9em;
  color: #cacaca;
  @media all and (max-width: 768px) {
    margin-bottom: 1em;
  }
`;

export const RebateRequirementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RebateOffering = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 0 2em;
  img {
    max-height: 4em;
    max-width: 4em;
    margin-bottom: 1em;
  }
  span {
    font-size: 0.7em;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: lowercase;
  }
`;

export const RebateOfferingsWrapper = styled.div`
  display: flex;
  margin-top: 1.5em;
  @media all and (max-width: 768px) {
    flex-wrap: wrap;
  }
  a {
    width: 4em;
  }
`;

export const HtmlSectionWrapper = styled.div`
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
`;
