import React, { Component } from "react";
import styled from "styled-components";
import MainContentWrapper from "../layout/MainContentWindowWrapper";
import GrayBanner from "../GrayBanner";

const ErrorWrapper = styled.div`
  h2 {
    color: black;
  }
`;

const ErrorBoundary = () => (
  <main id="mainContentContainer">
    <div id="mainContentWindowContainer">
      <MainContentWrapper>
        <h1>Oops!</h1>
        <GrayBanner noImg>
          <ErrorWrapper>
            <h2>Looks like the Bus is having some issues...</h2>
            <p>
              Try returning to the previous page and refreshing the
            browser. If the problem persists, please reach out to{" "}
              <a href="mailto:support@rebatebus.com?subject=Web app error">
                support@rebatebus.com
            </a>
            . Please include what page you were trying to access.
          </p>
          </ErrorWrapper>
        </GrayBanner>
      </MainContentWrapper>
    </div>
  </main>
);

export default ErrorBoundary;
