import { combineReducers } from "redux";
import account from "./Account/ducks/reducers";
import dashboard from "./Dashboard/ducks/reducers";
import team from "./Team/ducks/reducers";
import messages from "./Common/ducks/reducers";
import wiki from "./Wiki/ducks/reducers";
import signup from "./Signup/ducks/reducers";
import marketing from "./Marketing/ducks/reducers";

export default combineReducers({
  account,
  dashboard,
  team,
  messages,
  wiki,
  signup,
  marketing
});
