import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSettings } from "../Account/ducks/actions";
import { dismissMessage } from "../Common/ducks/actions";
import { showMessages } from "../Common/components/Message";

import Card from "../Common/components/Card";
import Toggle from "../Common/components/Toggle";
import { PrimaryButton } from "../Common/components/Button";

import "./css/Settings.css";

/**
 * Displays settings for the User account. These are different from the User Profile, as they control the UX
 * -- theme, privacy, etc. Route reachable through TopMenu.js
 */

class Settings extends Component {
  static propTypes = {
    /** The current user object. */
    user: PropTypes.object.isRequired,
    /** Update user action from Redux. */
    updateSettings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {
      user: { settings }
    } = props;
    this.state = {
      ...settings
    };
  }

  handleCheckboxChange = setting => {
    this.setState({ [setting]: !this.state[setting] });
  };

  handleSubmit = () => {
    const { user, updateSettings } = this.props;
    const settings = { ...this.state, userId: user.uuid };
    updateSettings(settings, user);
  };

  generateSettings = () =>
    settings.map(setting => (
      <Toggle
        key={setting.name}
        id={setting.name}
        description={setting.text}
        valueFromState={this.state[setting.name]}
        handleCheckboxChange={this.handleCheckboxChange}
      />
    ));

  render() {
    const { messages, dismissMessage } = this.props;

    return (
      <main id="mainContentContainer">
        <div id="mainContentWindowContainer">
          {showMessages(messages, dismissMessage)}
          <div id="mainContentWindowWrapper">
            <h1>Settings</h1>
            <Card id="settingsContainer">
              {this.generateSettings()}
              <PrimaryButton handleClick={this.handleSubmit}>
                Save Settings
              </PrimaryButton>
            </Card>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(null, { updateSettings, dismissMessage })(Settings);

const settings = [
  {
    name: "isGettingStarted",
    text: "Show help on dashboard and articles"
  }
];
