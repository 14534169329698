import {
    ActionButton,
    AlertDialog,
    DialogContainer,
    Flex,
    Form,
    ProgressCircle,
    Text,
    TextArea,
    TextField,
    View
} from "@adobe/react-spectrum";
import {Controller, useForm} from "react-hook-form";
import Select, {Styles} from "react-select";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {Label} from "@react-spectrum/label";
import {useHistory, useRouteMatch} from "react-router-dom";
import Helmet from "react-helmet";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {v4 as generateUUID} from "uuid";
import orderIdGeneratorFactory from "order-id";
import {
    CREATE_REBATE_APPLICATION,
    CreateRebateApplicationParams,
    DELETE_REBATE_APPLICATION,
    DeleteRebateApplicationParams,
    GET_COMPANY_PROJECT_FORM_OPTIONS,
    GET_REBATE_APPLICATION_BY_ID,
    GET_UTILITY_OPTIONS,
    GetAdminCompanyOptionsResponse,
    GetRebateApplicationByIdParams,
    GetRebateApplicationByIdResponse,
    GetUtilityOptionsParams,
    GetUtilityOptionsResponse,
    MutationResponse,
    UPDATE_REBATE_APPLICATION
} from "./queries";
import {captureMessage} from "@sentry/react";
import {RebateApplication, RebateApplicationInput, RebateApplicationStage} from "./types";
import {CANADIAN_POSTAL_CODE_RE, countries, CountryUUID, moneyRE, US_POSTAL_CODE_RE} from "./constants";
import {of, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {useSnackbar} from "notistack";
import {User, UUID} from "../../types";
import axios from "axios";
import CloseIcon from '@spectrum-icons/workflow/Close';

const orderIdGenerator = orderIdGeneratorFactory("scrt");

export const narrowReactSelectStyles: Styles = {
    indicatorSeparator: (provided) => ({
        ...provided,
        margin: "7px 0",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "4px",
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    control: (provided, {isFocused}) => {
        const borderColor = isFocused ? "var(--accent)" : "var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400))";
        return {
            ...provided,
            minHeight: "32px",
            maxHeight: "32px",
            '&:hover': {borderColor},
            borderColor,
            boxShadow: borderColor,
        }
    },
};

interface FormGroupParams {
    children: any,
    label: string,
    id: string,
    isHidden?: boolean,
    isRequired?: boolean,
}

export const FormGroup = ({children, label, id, isHidden = false, isRequired = false}: FormGroupParams) => (
    <View id={id} isHidden={isHidden}>
        {label !== undefined ? <Label isRequired={isRequired}>{label}</Label> : null}
        {children}
    </View>
);

const ErrorMessageWrapper = ({message}: { message: string }) => (
    <View UNSAFE_style={{color: "var(--spectrum-global-color-red-700)"}}>
        {message}
    </View>
);

interface ErrorMessagesParams {
    errors: any,
    name: string,
}

export const ErrorMessages = ({errors, name}: ErrorMessagesParams) => {
    return (
        <HookErrorMessage
            errors={errors}
            name={name}
            render={({messages, message}) => {
                if (message !== undefined) {
                    return <ErrorMessageWrapper message={message}/>
                }

                if (messages !== undefined) {
                    Object.entries(messages).map(([type, message]) => (
                        <ErrorMessageWrapper key={type} message={message?.toString() || ""}/>
                    ))
                }

                return null;
            }}
        />
    )
}

interface ComponentState {
    utilityQuery: string,
    utilityOffset: number,
    utilityLastBatchSize: number,
}

enum ActionType {
    SET_UTILITY_QUERY,
    SET_UTILITY_OFFSET,
    SET_UTILITY_LAST_BATCH_SIZE,
}

interface SetUtilityQueryAction {
    type: ActionType.SET_UTILITY_QUERY,
    payload: {
        query: string
    }
}

interface SetUtilityOffsetAction {
    type: ActionType.SET_UTILITY_OFFSET,
    payload: {
        offset: number
    }
}

interface SetUtilityLastBatchSizeAction {
    type: ActionType.SET_UTILITY_LAST_BATCH_SIZE,
    payload: {
        size: number
    }
}

type Action =
    | SetUtilityOffsetAction
    | SetUtilityQueryAction
    | SetUtilityLastBatchSizeAction
    ;

function reducer(state: ComponentState, action: Action) {
    switch (action.type) {
        case ActionType.SET_UTILITY_QUERY:
            return {
                ...state,
                utilityQuery: action.payload.query,
            }
        case ActionType.SET_UTILITY_OFFSET:
            return {
                ...state,
                utilityOffset: action.payload.offset,
            }
        case ActionType.SET_UTILITY_LAST_BATCH_SIZE:
            return {
                ...state,
                utilityLastBatchSize: action.payload.size,
            }
    }
}

const initialState: ComponentState = {
    utilityQuery: "",
    utilityOffset: 0,
    utilityLastBatchSize: 0,
};

interface SelectOption {
    value: string,
    label: string,
}

interface NumericSelectOption {
    value: number,
    label: string,
}

interface FormValues {
    customer: string,
    projectName: string,
    country: SelectOption | null,
    usState: SelectOption | null,
    canadianArea: NumericSelectOption | null,
    city: string,
    zipCode: string,
    utility: SelectOption | null,
    projectCost: string,
    notes: string,
}

const countryOptions: Array<FormValues["country"]> = countries.map(({id, name}) => {
    return {
        value: id,
        label: name,
    }
})

export interface CompanyRebateApplicationFormProps {
    user: User,
}

function CompanyRebateApplicationForm({user}: CompanyRebateApplicationFormProps) {
    const userId = user.uuid;
    const history = useHistory();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const manageProjectViewMatch = useRouteMatch<{
        internalID: string
    }>({
        path: '/services/rebates/manage/:internalID'
    });

    const addProjectViewMatch = useRouteMatch({
        path: '/services/rebates/add'
    });

    const internalID = manageProjectViewMatch?.params.internalID;

    const [state, dispatch] = useReducer(reducer, initialState);
    const [applicationId, setApplicationId] = useState<string | null>(null);
    const [originalApplication, setOriginalApplication] = useState<RebateApplication | null>(null);
    const utilityInputValueSubject = useRef(new Subject<string>());
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const {control, errors, formState, getValues, trigger, watch, setValue, reset} = useForm<FormValues>({
        defaultValues: {
            customer: "",
            projectName: "",
            country: null,
            usState: null,
            canadianArea: null,
            city: "",
            zipCode: "",
            utility: null,
            projectCost: "",
            notes: "",
        },
        mode: "onChange",
    });

    const {enqueueSnackbar} = useSnackbar();

    const {
        error: formOptionsError,
        loading: isLoadingFormOptions,
        data: formOptions
    } = useQuery<GetAdminCompanyOptionsResponse>(GET_COMPANY_PROJECT_FORM_OPTIONS);

    useEffect(() => {
        const subscription = utilityInputValueSubject.current.pipe(switchMap((x) => of(x))).subscribe((value) => {
            dispatch({
                type: ActionType.SET_UTILITY_QUERY,
                payload: {query: value}
            })
        });

        return () => subscription.unsubscribe();
    }, [utilityInputValueSubject]);


    const watchCountry = watch("country");
    const watchUsState = watch("usState");
    const stateIds = watchUsState?.value ? [watchUsState.value] : [];
    const {
        data: usUtilitiesData,
        error: usUtilitiesError,
        loading: usUtilitiesLoading
    } = useQuery<GetUtilityOptionsResponse, GetUtilityOptionsParams>(GET_UTILITY_OPTIONS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                name: state.utilityQuery,
                stateIds,
            },
        },
        skip: stateIds.length === 0,
    });

    const [getExistingRebateApplication] =
        useLazyQuery<GetRebateApplicationByIdResponse,
            GetRebateApplicationByIdParams>(GET_REBATE_APPLICATION_BY_ID, {
            onCompleted: ({response: {applications}}) => {
                const app = applications[0];
                if (app === undefined) {
                    alert("Sorry, there was an error");
                    captureMessage("getExistingRebateApplication in AdminRebateApplicationForm")
                }

                const values: Partial<FormValues> = {
                    country: {
                        label: app.country.name,
                        value: app.country.id,
                    },
                    city: app.city,
                    customer: app.customer,
                    projectCost: app.projectCost ? app.projectCost.toFixed(2) : "",
                    notes: app.notes,
                    projectName: app.projectName,
                    zipCode: app.zipCode,
                };

                if (app.country.id === CountryUUID.Canada) {
                    const canadianArea = app.canadianArea;
                    if (canadianArea && canadianArea.name && canadianArea.id) {
                        values.canadianArea = {
                            label: canadianArea.name,
                            value: canadianArea.id,
                        }
                        values.usState = null;
                    }
                } else if (app.country.id === CountryUUID.US) {
                    const usState = app.usState;
                    if (usState && usState.name && usState.id) {
                        values.usState = {
                            label: usState.name,
                            value: usState.id,
                        }
                        values.canadianArea = null;
                    }
                }

                const utility = app.utility;
                if (utility !== null && utility.id && utility.name) {
                    values.utility = {
                        label: utility.name,
                        value: utility.id,
                    };
                }

                setOriginalApplication(app);
                reset(values);
                setApplicationId(app.applicationId);
            }
        });

    useEffect(() => {
        if (userId && internalID !== undefined) {
            getExistingRebateApplication({
                variables: {
                    id: internalID,
                    userIds: [userId],
                },
            });
        }
    }, [internalID, userId, getExistingRebateApplication])

    const [createRebateApplication] =
        useMutation<MutationResponse, CreateRebateApplicationParams>(CREATE_REBATE_APPLICATION)

    const [updateRebateApplication] =
        useMutation<MutationResponse, CreateRebateApplicationParams>(UPDATE_REBATE_APPLICATION)

    const [deleteRebateApplication] =
        useMutation<MutationResponse, DeleteRebateApplicationParams>(DELETE_REBATE_APPLICATION)


    const onDelete = async () => {
        setIsSubmitting(true);
        if (!internalID) {
            return
        }

        const response = await deleteRebateApplication({
            variables: {
                applicationId: internalID,
            }
        })
        if (response?.data?.data.status === "OK") {
            enqueueSnackbar("Successfully deleted", {variant: "success"});
            setTimeout(() => history.push('/services/rebates/list'), 1000);
        } else {
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    const uploadFile = async (file: File, applicationId: UUID) => {
        let formData = new FormData();
        formData.append('attachment', file);

        await axios.post(
            `/api/v2/applications/attachments/${user.uuid}/${applicationId}`,
            formData,
            {
                headers: {
                    "Authorization": localStorage.getItem("token") || "",
                    "Content-type": "multipart/form-data",
                },
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return;
                } else if (res.status === 500) {
                    const text = res.data;
                    console.error(text);
                    captureMessage(text);
                    alert("Sorry, there was an error uploading the file. Please try again later.");
                } else {
                    alert("Sorry, there was an error uploading the file.");
                }
            })
            .catch((error) => {
                console.error(error);
                captureMessage(error.message);
                alert("Sorry, there was an error. Please try again later.")
            })
    }

    const onSubmit = async () => {
        setHasBeenSubmitted(true);
        const isValid = await trigger();
        if (!isValid) {
            return;
        }

        const localInternalId = internalID || generateUUID();

        if (fileInputRef.current?.files && fileInputRef.current?.files.length > 0) {
            const file = fileInputRef.current?.files.item(0)!;
            if (file.size > 20 * 1024 * 1024) {
                return alert("File must not be larger than 20 megabytes");
            }
            setIsSubmitting(true);
            // TODO there's a possibility of a memory leak if the file attachment is successfully
            //  uploaded but the project fails to create.
            await uploadFile(file, localInternalId);
        }

        setIsSubmitting(true);
        try {
            const data = getValues();
            const country = data.country!;

            const transferObject: Partial<RebateApplicationInput> = {
                id: localInternalId,
                applicationId: applicationId || orderIdGenerator.generate(),
                country: {
                    id: country.value,
                    name: country.label,
                },
                city: data.city,
                company: {
                    id: user.uuid,
                    name: user.company_name || "",
                },
                customer: data.customer,
                projectCost: data.projectCost === "" ? null : parseFloat(data.projectCost),
                notes: data.notes,
                projectName: data.projectName,
                zipCode: data.zipCode,
            }

            if (country.value === CountryUUID.Canada) {
                const canadianArea = data.canadianArea!;
                transferObject.canadianArea = {
                    id: canadianArea.value,
                    name: canadianArea.label,
                }
            } else if (country.value === CountryUUID.US) {
                const usState = data.usState!;
                transferObject.usState = {
                    id: usState.value,
                    name: usState.label,
                }
            }

            const utility = data.utility;
            if (utility !== null) {
                transferObject.utility = {
                    id: utility.value,
                    name: utility.label,
                };
            } else {
                transferObject.utility = null;
            }

            if (addProjectViewMatch) {
                const response = await createRebateApplication({
                    variables: {
                        payload: transferObject as RebateApplicationInput,
                    }
                })
                if (response?.data?.data.status === "OK") {
                    enqueueSnackbar("Successfully created", {variant: "success"});
                    setTimeout(() => history.push('/services/rebates/list'), 1000);
                } else {
                    setIsSubmitting(false);
                    setIsErrorDialogOpen(true);
                }
            } else if (manageProjectViewMatch) {
                const response = await updateRebateApplication({
                    variables: {
                        payload: transferObject as RebateApplicationInput,
                    }
                })
                setIsSubmitting(false);
                if (response?.data?.data.status === "OK") {
                    enqueueSnackbar("Successfully updated", {
                        variant: "success"
                    });
                } else {
                    setIsErrorDialogOpen(true);
                }
            }
        } catch (e) {
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    if (formOptionsError) {
        captureMessage(formOptionsError.message);
        console.error(formOptionsError.message);
        return <p>Sorry, there was an error loading form options</p>
    }

    const usStateOptions: Array<FormValues["usState"]> = formOptions?.states.map((state) => {
        return {
            label: state.name,
            value: state.id,
        }
    }) || [];

    const canadianAreasOptions: Array<FormValues["canadianArea"]> = formOptions?.canadianProvinces.map((province) => {
        return {
            label: province.name,
            value: province.id,
        }
    }) || [];

    if (!userId || !("company_name" in user)) {
        return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
    }

    if (addProjectViewMatch && userId && "company_name" in user && user.company_name === null) {
        return <p style={{marginTop: "2em"}}>To create a new project, please change your account type to "Business" in
            your <a href="/profile">profile settings.</a></p>
    }

    return (
        <View
            width="100%"
        >
            <Helmet>
                <meta name="description" content="Rebate Project."/>
                <title>Rebate Project | Rebate Bus</title>
            </Helmet>
            {
                isLoadingFormOptions
                    ? <ProgressCircle aria-label="Loading…" isIndeterminate/>
                    : (
                        <View width="100%" maxWidth="size-5000">
                            <Flex justifyContent="center" width="100%">
                                <Form width="100%">
                                    <Flex direction="column" width="100%" gap="size-100">
                                        <Controller
                                            control={control}
                                            name='customer'
                                            render={(props) => (
                                                <TextField
                                                    {...props}
                                                    label="Customer"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={255}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name='projectName'
                                            render={(props) => (
                                                <TextField
                                                    {...props}
                                                    label="Project name"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={255}
                                                />
                                            )}
                                        />

                                        <FormGroup
                                            label="Country"
                                            id="countrySelect"
                                            isRequired
                                        >
                                            <Controller
                                                control={control}
                                                name="country"
                                                rules={{
                                                    required: "Country is required"
                                                }}
                                                render={({onChange, ...props}) => (
                                                    <Select
                                                        {...props}
                                                        onChange={(value) => {
                                                            setValue("usState", null);
                                                            setValue("canadianArea", null);
                                                            setValue("utility", null);
                                                            onChange(value);
                                                        }}
                                                        menuPlacement="auto"
                                                        placeholder=""
                                                        styles={narrowReactSelectStyles}
                                                        isLoading={isLoadingFormOptions}
                                                        options={countryOptions}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                        {
                                            (!formState.touched.country && !hasBeenSubmitted) ? <></> : (
                                                <ErrorMessages errors={errors} name="country"/>
                                            )
                                        }

                                        <FormGroup
                                            label="State"
                                            id="usStateSelect"
                                            isRequired
                                            isHidden={watchCountry?.value !== CountryUUID.US}
                                        >
                                            <Controller
                                                control={control}
                                                name="usState"
                                                rules={{
                                                    validate: {
                                                        required: ((value) => {
                                                            if (watchCountry?.value === CountryUUID.US && !value) {
                                                                return "State is required";
                                                            }
                                                            return true;
                                                        }),
                                                    }
                                                }}
                                                render={({onChange, ...props}) => (
                                                    <Select
                                                        {...props}
                                                        onChange={(value) => {
                                                            setValue("utility", null);
                                                            onChange(value);
                                                        }}
                                                        placeholder=""
                                                        styles={narrowReactSelectStyles}
                                                        isLoading={isLoadingFormOptions}
                                                        options={usStateOptions}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                        {
                                            (!formState.touched.usState && !hasBeenSubmitted && watchCountry?.value !== CountryUUID.US) ? <></> : (
                                                <ErrorMessages errors={errors} name="usState"/>
                                            )
                                        }

                                        <FormGroup
                                            label="Province/Territory"
                                            id="canadianProvinceSelect"
                                            isHidden={watchCountry?.value !== CountryUUID.Canada}
                                            isRequired
                                        >
                                            <Controller
                                                control={control}
                                                name="canadianArea"
                                                rules={{
                                                    validate: {
                                                        required: ((value) => {
                                                            if (watchCountry?.value === CountryUUID.Canada && !value) {
                                                                return "Province/Territory is required";
                                                            }
                                                            return true;
                                                        }),
                                                    }
                                                }}
                                                render={(props) => (
                                                    <Select
                                                        {...props}
                                                        placeholder=""
                                                        styles={narrowReactSelectStyles}
                                                        isLoading={isLoadingFormOptions}
                                                        options={canadianAreasOptions}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                        {
                                            (!formState.touched.canadianArea && !hasBeenSubmitted && watchCountry?.value !== CountryUUID.Canada) ? <></> : (
                                                <ErrorMessages errors={errors} name="canadianArea"/>
                                            )
                                        }

                                        <Controller
                                            control={control}
                                            name='city'
                                            render={(props) => (
                                                <TextField
                                                    {...props}
                                                    label="City"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={255}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name='zipCode'
                                            rules={{
                                                validate: {
                                                    required: (value) => {
                                                        if (value === "") {
                                                            return "Zip code is required.";
                                                        }

                                                        return true;
                                                    },
                                                    zipCode: (value) => {
                                                        if (watchCountry?.value === CountryUUID.US && !US_POSTAL_CODE_RE.test(value)) {
                                                            return "Zip code must be a valid US zip code"
                                                        }

                                                        if (watchCountry?.value === CountryUUID.Canada && !CANADIAN_POSTAL_CODE_RE.test(value)) {
                                                            return "Zip code must be a valid Canadian zip code. For example, S4T 4L1"
                                                        }

                                                        return true;
                                                    },
                                                },
                                            }}
                                            render={({onChange, ...props}) => (
                                                <TextField
                                                    isHidden={watchCountry === null}
                                                    isRequired
                                                    onChange={(value) => {
                                                        onChange(value.toUpperCase())
                                                    }}
                                                    {...props}
                                                    label="Zip Code"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={255}
                                                />
                                            )}
                                        />
                                        {
                                            (!formState.touched.zipCode && !hasBeenSubmitted)
                                                ? <></>
                                                : watchCountry !== null
                                                ? <ErrorMessages errors={errors} name="zipCode"/>
                                                : <></>

                                        }

                                        <FormGroup
                                            label="Utility"
                                            id="utilitySelect"
                                            isHidden={watchUsState === null}
                                        >
                                            <Controller
                                                control={control}
                                                name="utility"
                                                defaultValue={null}
                                                render={(props) => (
                                                    <Select
                                                        {...props}
                                                        placeholder=""
                                                        menuPlacement="auto"
                                                        styles={narrowReactSelectStyles}
                                                        isLoading={usUtilitiesLoading}
                                                        options={usUtilitiesData?.utilityOptions || []}
                                                    />
                                                )}
                                                noOptionsMessage={() => {
                                                    if (usUtilitiesLoading) {
                                                        return "Loading..."
                                                    }
                                                    return "No options"
                                                }}
                                                onInputChange={(value: string, {action}: { action: "input-change" | "menu-close" }) => {
                                                    if (action === "input-change") {
                                                        utilityInputValueSubject.current.next(value);
                                                    }
                                                    if (action === "menu-close") {
                                                        utilityInputValueSubject.current.next("");
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        {
                                            (!formState.touched.utility && !hasBeenSubmitted && watchUsState === null) ? <></> : (
                                                <ErrorMessages errors={errors} name="utility"/>
                                            )
                                        }
                                        {
                                            usUtilitiesError ? <p>Sorry, there was an error getting utilities</p> : <></>
                                        }

                                        <Controller
                                            control={control}
                                            name='projectCost'
                                            rules={{
                                                validate: (value) => {
                                                    if (value.trim() === "" || moneyRE.test(value)) {
                                                        return true;
                                                    }

                                                    return "Project cost must be a valid dollar amount. For example 2345 or 2345.54"
                                                }
                                            }}
                                            render={({value, ...props}) => {
                                                return (
                                                    <TextField
                                                        {...props}
                                                        value={value === null ? "" : value.toString()}
                                                        label="Project cost ($)"
                                                        UNSAFE_style={{width: "100%"}}
                                                        maxLength={11}
                                                    />
                                                );
                                            }}
                                        />
                                        {
                                            (!formState.touched.projectCost && !hasBeenSubmitted) ? <></> : (
                                                <ErrorMessages errors={errors} name="projectCost"/>
                                            )
                                        }

                                        <Controller
                                            control={control}
                                            name='notes'
                                            render={(props) => (
                                                <TextArea
                                                    {...props}
                                                    label="Notes"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={255}
                                                />
                                            )}
                                        />

                                        {
                                            addProjectViewMatch ? (
                                                <View UNSAFE_style={{ fontSize: "1em" }}>
                                                    <Label>Attachment</Label>
                                                    <Flex alignItems="center" gap="size-100">
                                                        <input type="file" ref={fileInputRef} name="attachment"/>
                                                        <ActionButton aria-label="Clear file input." onPress={() => {
                                                            if (fileInputRef.current) {
                                                                fileInputRef.current.value = ""
                                                            }
                                                        }}>
                                                            <CloseIcon/>
                                                        </ActionButton>
                                                    </Flex>
                                                    <Text>Max file size: 20mb</Text>
                                                </View>
                                            ) : <></>
                                        }

                                        {
                                            isSubmitting ? (
                                                <ProgressCircle aria-label="Loading…" isIndeterminate/>
                                            ) : (
                                                <Flex>
                                                    <ActionButton
                                                        marginTop="size-300"
                                                        onPress={() => onSubmit()}
                                                        width="11em"
                                                    >{addProjectViewMatch ? "Create project" : manageProjectViewMatch ? "Update project" : "Submit"}</ActionButton>
                                                    {
                                                        (
                                                            manageProjectViewMatch &&
                                                            originalApplication &&
                                                            originalApplication.stage === RebateApplicationStage.NEW
                                                        )
                                                            ?
                                                            (
                                                                <ActionButton
                                                                    marginTop="size-300"
                                                                    marginStart="auto"
                                                                    onPress={() => setIsDeleteDialogOpen(true)}
                                                                    width="11em"
                                                                >Delete project</ActionButton>
                                                            )
                                                            : <></>
                                                    }
                                                </Flex>
                                            )
                                        }
                                        <DialogContainer onDismiss={() => {
                                            setIsErrorDialogOpen(false);
                                        }}>
                                            {isErrorDialogOpen && (
                                                <AlertDialog
                                                    title="Error"
                                                    variant="error"
                                                    primaryActionLabel="OK">
                                                    Sorry. There was an error. Please try again later or email at
                                                    support@rebatebus.com.
                                                </AlertDialog>
                                            )}
                                        </DialogContainer>
                                    </Flex>
                                </Form>
                            </Flex>
                        </View>
                    )
            }
            <DialogContainer onDismiss={() => {
                setIsDeleteDialogOpen(false);
            }}>
                {isDeleteDialogOpen && (
                    <AlertDialog
                        variant="destructive"
                        title="Delete project"
                        primaryActionLabel="Delete"
                        cancelLabel="Cancel"
                        onPrimaryAction={onDelete}
                    >
                        Are you sure you want to delete this project?
                    </AlertDialog>
                )}
            </DialogContainer>
        </View>
    )
}

export default CompanyRebateApplicationForm;
