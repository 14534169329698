import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Select as MuiSelect } from "@material-ui/core/";
import MenuItem from "@material-ui/core/MenuItem";

import { Query } from "react-apollo";

import { InputWrapper } from "./StyledFormComponents";

function sortSpecTypes(a, b) {
  return a.value.localeCompare(b.value);
}

const specTypesDisplayNames = {
  'hours': 'Annual Operating Hours',
  'wattOutput': 'Watts',
  'lifetimeHours': 'Lifetime Hours',
  'lumenOutput': 'Lumens',
  'Required': 'Required',
  'efficacy': 'Efficacy',
  'fixtureLength': 'Length (Feet)',
};

/**
 * GraphQL Select is used when the options should be selected from the
 * @param {*} props
 */

const Select = props => {
  // makes the label move into the border like on MUI inputs
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  let TypeSelectLighting = false;
  let LightingUUID = "";
  let LightingName = "";
  let LightingValue = "";
  let defaultValueQuery;
  let SelectElement;

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const isAddPage = window.location.pathname.includes('add');
  const isEditPage = window.location.pathname.includes('edit');

  return (
    <Query query={props.optionsQuery} key={props.name}>
      {({ loading, error, data }) => {
        if (error)
          return (
            <>
              <label htmlFor={props.id}>{props.title}</label>
              <p>Error loading options</p>
            </>
          );
        const options = [<MenuItem value="" key="none"></MenuItem>];
        if (data) {
          // * if the data is returning utilities, set data to just the state
          if (data.state) {
            data = data.state;
          }

          // * get just the first key, otherwise __typename might be grabbed too and key becomes an array
          const key = Object.keys(data)[0];

          if (props.name === "rebateTypeId") {
            data[key].forEach(option =>
              options.push(
                <MenuItem
                  value={`${option.uuid} ${option.value}`}
                  key={option.uuid}
                >
                  {option.name || option.value}
                </MenuItem>
              )
            );
          } else if (props.name === "prescriptiveUnit") {
            data[key]
              .filter(option => option.rebateType.uuid === props.rebateTypeId)
              .forEach(option =>
                options.push(
                  <MenuItem value={option.uuid} key={option.uuid}>
                    {option.name || option.value}
                  </MenuItem>
                )
              );
          } else if (props.name === "requirementUnit" && key === 'productSpecTypes' && isAddPage) {

            const types = data.productSpecTypes
              .filter((spec) => !spec.isHidden)
              .map((type) => {
                return {
                    ...type,
                    value: specTypesDisplayNames[type.value]
                }
            });

            types.sort(sortSpecTypes);

            types.forEach((option) =>
                options.push(
                  <MenuItem value={option.uuid} key={option.uuid}>
                    {option.name || option.value}
                  </MenuItem>
                )
              );
          } else if (props.name === "requirementUnit" && key === 'productSpecTypes' && isEditPage) {

            const types = data.productSpecTypes
              .map((type) => {
                const newValue = specTypesDisplayNames[type.value];

                return {
                    ...type,
                    value: newValue || type.value,
                }
            });

            types.sort(sortSpecTypes);

            types.forEach((option) =>
                options.push(
                  <MenuItem value={option.uuid} key={option.uuid}>
                    {option.name || option.value}
                  </MenuItem>
                )
              );
          } else if (props.name === "technologyTypeId") {
            // Find Technology Type element name in params
            TypeSelectLighting = true;
            data[key].forEach(option => {
              if ((option.name || option.value) === "Lighting" && props.name !== option.uuid) {
                LightingName = "Lighting";
                LightingValue = option.uuid;
                if (props.value === undefined || props.value === "") {
                  // Set Lighting uniq id for selected value for default
                  if(props.isEditPage != true) {
                    props.handleChange(props.id, LightingValue, LightingName);
                  }
                }
              }
              options.push(
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name || option.value}
                </MenuItem>
              )
            });
          } else {
            data[key].forEach(option =>
              options.push(
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name || option.value}
                </MenuItem>
              )
            );
          }
        }

        return (
          <InputWrapper>
            <FormControl fullWidth size="small" error={props.error}>
              <InputLabel ref={inputLabel} id={`${props.id}-label`}>
                {props.title}
                {props.required ? (
                  <span
                    className={`MuiFormLabel-asterisk MuiInputLabel-asterisk ${
                      props.error ? "Mui-error" : ""
                      }`}
                  >
                    *
                  </span>
                ) : (
                    ""
                  )}
              </InputLabel>
              <MuiSelect
                error={props.error}
                required={props.required}
                id={props.id}
                labelId={`${props.id}-label`}
                // MUI throws warning if value !== one of the options so set to "" on intialization
                value={options.length <= 1 ? "" : props.value || ""} // uncomment for default nonvalue select
                onChange={({ target }) => {
                  props.handleChange(props.id, target.value, target.name)
                }
                }
                labelWidth={labelWidth}
              >
                {options}
              </MuiSelect>
              <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl>
          </InputWrapper>
        );
      }}
    </Query>
  );
};

export default Select;

Select.propTypes = {
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** If the input is required. Originally took a string of "required". (Can't remember why I set it up like that...) */
  required: PropTypes.bool,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The value from state. */
  value: PropTypes.string,
  /** The function to update state. */
  handleChange: PropTypes.func.isRequired,
  /** The query to be sent to GraphQL, which is actually a template literal but PropTypes sees it as an object. */
  optionsQuery: PropTypes.object.isRequired
};
