import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import DashboardSection from "./DashboardSection";
import Announcement, { announcements } from "./Announcement.jsx";

/** HomeDashboard is the default component for the Dashboard Route. It loads up the Dashboard Sections automatically. */

const HomeDashboard = ({
  gettingStarted,
  permissions,
  deleting,
  handleDelete,
  dataStories,
  gettingStartedStories,
  ...props
}) => (
  <div id="dashboardWrapper">
    <h1>Dashboard</h1>
    <div>
        {
            announcements.map((announcement, i) =>  <Announcement key={i} {...announcement} />)
        }
    </div>
    {gettingStarted && (
      <DashboardSection
        title="Getting Started"
        articles={gettingStartedStories}
        handleDelete={handleDelete}
        deleting={deleting}
        permissions={permissions}
      >
        The Getting Started section is a great resource for users who are new to
        Rebate Bus or the rebate industry. If you prefer, you can dismiss this
        in <Link to="/settings">Settings</Link>. You can always find it again in
        the Tools Menu.
      </DashboardSection>
    )}
    <DashboardSection
      title="Data Highlights"
      articles={dataStories}
      handleDelete={handleDelete}
      deleting={deleting}
      permissions={permissions}
    >
      With data from programs across the US, we've found some hidden gems. Take
      a look at some highlights from our data.
    </DashboardSection>
  </div>
);

export default HomeDashboard;

HomeDashboard.propTypes = {
  /**
   * GettingStarted comes from the user object and determines whether to show the Getting Started section.
   * When Settings routes are established & connected to user object, this will be required again.
   */
  gettingStarted: PropTypes.bool.isRequired,
  /**
   * Permissions comes from the user object. It determines what a user sees on the ContextMenu and whether they can delete
   * Getting Started stories. Only RB Dev TMs can delete Getting Started at this time.
   */
  permissions: PropTypes.number.isRequired,
  /** Getting Started stories from Redux store. */
  gettingStartedStories: PropTypes.array,
  /** Data stories from Redux store. */
  dataStories: PropTypes.array
};
