import React, { useRef, useState, useEffect } from "react";
// import PropTypes from "prop-types";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useQuery } from '@apollo/react-hooks';

import { InputContainer } from "../../../Wiki/components/wikiForm/MultipleInputs";

import Checkbox from './Checkbox';
import Select from './Select';
import Input from './Input';

const specTypesDisplayNames = {
    'hours': 'Annual Operating Hours',
    'wattOutput': 'Watts',
    'lifetimeHours': 'Lifetime Hours',
    'lumenOutput': 'Lumens',
    'Required': 'Required',
    'efficacy': 'Efficacy',
    'fixtureLength': 'Length (Feet)',
};

const TableComponent = props => {
    const TableColums = props.inputs;
    let RowsCount = 0;
    var options = [];
    const { loading, error, data } = useQuery(props.optionsQuery);

    for (let i = 0; i < TableColums.length; i++) {
        options[i] = TableColums[i];
        if (TableColums[i].optionsGQLType)
            options[i].options = TableColums[i].optionsGQLType;
        else
            options[i].options = TableColums[i].options;
    }

    if (data && typeof data === "object") {
        let dataKeys = Object.keys(data);
        for (let i = 0; i < dataKeys.length; i++) {
            const DataType = dataKeys[i];
            let found = options.find((el) => {
                return el.options === DataType;
            });
            let foundIndex = options.indexOf(found);

            if (foundIndex >= 0) {
                options[foundIndex].options = data[DataType];
            }
        }
    }

    RowsCount = options[0].options.length;
    var generatedTable = null;
    if (props.formValues && props.formValues.length === RowsCount) {
        generatedTable = props.formValues.map((inputSet, index) => {
            // * Get the keys for the input names
            const keys = Object.keys(inputSet);
            const row = (<TableRow key={props.name + index}>
                {
                    keys.map(key => {
                        var newInput = options.filter(input => input.name === key)[0];
                        if (!newInput) {
                            return null;
                        }

                        if (newInput.name === "requirementUnit") {
                            if (newInput.options[0].value != newInput.options[0].uuid) {
                                for (let i = 0; i < newInput.options.length; i++) {
                                    let name = newInput.options[i].value;
                                    let value = newInput.options[i].uuid;
                                    newInput.options[i].value = value;
                                    newInput.options[i].name = specTypesDisplayNames[name] || name;
                                }
                            }
                        }

                        if (newInput.name === "requirementType" && newInput.inputType === "checkbox") {
                            newInput.value = newInput.options[index].value;
                            newInput.title = newInput.value;
                            if (newInput.options[index].uuid) {
                                newInput.uuid = newInput.options[index].uuid;
                            }
                            newInput.productSpecType = newInput.options[index].productSpecType.uuid;
                            newInput.active = props.formValues[index][newInput.name].active ? props.formValues[index][newInput.name].active : false;
                            if (props.formValues[index].requirementValue && props.formValues[index].requirementValue.length > 0 && newInput.active === false) {
                                newInput.active = true;
                                let name = props.name;
                                props.handleCheckboxChange(newInput.name, { name, index })
                            }
                        } else {
                            newInput.value = props.formValues[index][newInput.name];
                        }

                        // * Create the object that helps determine the value
                        newInput.advancedStyles = "table-align";
                        let name = props.name;
                        return (<TableCell align="left">
                            {props.createInput(newInput, { name, index })}
                        </TableCell>);
                    })
                }
            </TableRow>);
            return row;
        });
    } else if (RowsCount > 0 && Array.isArray(options[0].options) && options[0].options.length > 0) {
        if (props.isEditPage) {
            props.handleAddNewMulti(props.name, options, options[0].options.length, props.formValues);
        } else {
            props.handleAddNewMulti(props.name, options, options[0].options.length);
        }
    }

    return (
        <InputContainer>
            <Table className="table-styled" size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {
                            props.tableHeaders.map((cell, i) => {
                                if (i === 1) {
                                    return <TableCell align="left">{cell}</TableCell>
                                } else {
                                    return <TableCell>{cell}</TableCell>
                                }
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {generatedTable}
                </TableBody>
            </Table>
        </InputContainer>
    );
}

export default TableComponent;
