import React from "react";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { InputWrapper } from "./StyledFormComponents";

const DatePicker = ({ value, handleChange, title, ...rest }) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <InputWrapper>
        <KeyboardDatePicker
          value={value}
          onChange={event => handleChange(rest.id, new Date(event).getTime())}
          fullWidth
          format="MM/DD/YYYY"
          label={title}
          {...rest}
          InputAdornmentProps={{ position: "start" }}
        />
      </InputWrapper>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
