import { SIGNIN, SIGNOUT, UPDATE } from "./types";
//import { users } from "../components/testUserInfo";

const initialState = { loggedIn: false, user: {} };

export default (state = initialState, action) => {
  const { user } = action;
  switch (action.type) {
    case SIGNIN:
      return { user, loggedIn: true };
    case SIGNOUT:
      return { user: {}, loggedIn: false };
    case UPDATE:
      return { ...state, user };
    default:
      return state;
  }
};
