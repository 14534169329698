import {
  SORT_REBATES,
  FILTER_REBATES,
  FILTER_BY_TECH_TYPE,
  LOADING,
  CLEAR_FILTERED,
} from "./types";

export const filterByTechType = (techTypeId) => ({
  type: FILTER_BY_TECH_TYPE,
  techTypeId,
});

export const setLoading = (loading) => (dispatch) => {
  dispatch({ type: LOADING, loading });
};

export const clearFiltered = () => (dispatch) =>
  dispatch({ type: CLEAR_FILTERED });

export const sortRebates = (sortBy) => ({
  type: SORT_REBATES,
  sortBy,
});

export const filterRebates = (filter) => ({
  type: FILTER_REBATES,
  filter,
});

export const filterAndSort = (sortBy, filter) => (dispatch) => {
  dispatch(filterRebates(filter));
  dispatch(sortRebates(sortBy));
};
