import {useRouteMatch} from "react-router-dom";
import {Flex, ProgressCircle, Text, View} from "@adobe/react-spectrum";
import React from "react";
import {useQuery} from "react-apollo";
import {
    GET_REBATE_APPLICATION_BY_ID,
    GetRebateApplicationByIdParams,
    GetRebateApplicationByIdResponse
} from "./queries";
import {captureMessage} from "@sentry/react";
import CompanyRebateApplicationForm from "./CompanyRebateApplicationForm";
import {User} from "../../types";
import {RebateApplication, RebateApplicationStage, RebateApplicationStageDisplayNames} from "./types";
import {Label} from "@react-spectrum/label";
import StageTracker from "./tracker";
import {formatUSD} from "../Common/helpers/formateUSD";
import ChevronRightIcon from '@spectrum-icons/workflow/ChevronRight';
import {TableSelfLink} from "./TableLink";
import {useMediaQuery} from "react-responsive";
import RebateAttachmentsPanel from "./RebateAttachmentsPanel";

interface CompanyManageProjectViewProps {
    user: User
}

interface PropertyParams {
    children: any
}

function Property({children}: PropertyParams) {
    return (
        <Flex direction="column">
            {children}
        </Flex>
    )
}

function CompanyManageProjectView({user}: CompanyManageProjectViewProps) {
    const isNarrowLayout = useMediaQuery({query: `(max-width: 1080px)`});

    const match = useRouteMatch<{
        internalID: string
    }>({
        path: '/services/rebates/manage/:internalID'
    });

    const {
        loading,
        data,
        error
    } = useQuery<GetRebateApplicationByIdResponse, GetRebateApplicationByIdParams>(GET_REBATE_APPLICATION_BY_ID, {
        variables: {
            id: match?.params.internalID || "",
            userIds: [user.uuid]
        },
        skip: !user || !user.uuid
    });

    if (error) {
        captureMessage(error.message);
        console.error(error.message);
        return <p>"Sorry there was an error"</p>;
    }

    let app: RebateApplication | null = null;
    if (data) {
        if (data.response.applications[0]) {
            app = data.response.applications[0];
        }
    }

    return (
        <>
            <Flex alignItems="center" gap="size-50">
                <TableSelfLink href="/services/rebates/list" style={{margin: 0}}>Projects</TableSelfLink>
                <ChevronRightIcon/>
                {loading ? <ProgressCircle aria-label="Loading…" isIndeterminate/> : <Text>{app?.applicationId}</Text>}
            </Flex>
            <View
                width="100%"
                marginTop="size-200"
            >
                <Flex width="100%" alignItems="center" direction="column">
                    {
                        (app && !isNarrowLayout)
                            ? <StageTracker selectedStage={app?.stage || RebateApplicationStage.NEW}/>
                            : <></>

                    }
                    <View
                        marginTop="size-200"
                        UNSAFE_style={{height: "calc(100vh - 15em)"}}
                        overflow="auto" width="100%" paddingBottom="size-200">
                        <Flex width="100%" justifyContent="center">
                            <Flex
                                direction={isNarrowLayout ? "column" : "row"}
                                justifyContent={isNarrowLayout ? "start" : "center"}
                                minWidth="300px"
                                width="1000px"
                                maxWidth="1000px"
                                gap="size-600"
                            >
                                <View paddingY="size-200">
                                    <Flex>
                                        <CompanyRebateApplicationForm user={user}/>
                                    </Flex>
                                </View>
                                <View
                                    flex={1}
                                    paddingTop="27px"
                                >
                                    <Flex>
                                        {
                                            app
                                                ? (
                                                    <Flex direction="column" gap="size-200">
                                                        <Flex direction="column">
                                                            <Property>
                                                                <Label>Stage</Label>
                                                                <Text
                                                                    UNSAFE_style={{
                                                                        color: app.stage === RebateApplicationStage.NO_REBATE ? "red" : "inherit",
                                                                        fontWeight: app.stage === RebateApplicationStage.NO_REBATE ? 600 : "inherit",
                                                                    }}
                                                                >{RebateApplicationStageDisplayNames.get(app.stage)}</Text>
                                                            </Property>
                                                        </Flex>
                                                        {
                                                            app?.technologyType ?
                                                                (
                                                                    <Property>
                                                                        <Label>Technology</Label>
                                                                        <Text>{app.technologyType.name}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.rebateEstimate ?
                                                                (
                                                                    <Property>
                                                                        <Label>Rebate Esimate</Label>
                                                                        <Text>{formatUSD(app.rebateEstimate)}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.preApprovedRebateAmount ?
                                                                (
                                                                    <Property>
                                                                        <Label>Pre-Approved Rebate Amount</Label>
                                                                        <Text>{formatUSD(app.preApprovedRebateAmount)}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.finalRebateAmount ?
                                                                (
                                                                    <Property>
                                                                        <Label>Final Rebate Amount</Label>
                                                                        <Text>{formatUSD(app.finalRebateAmount)}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.rebateAmountPaid ?
                                                                (
                                                                    <Property>
                                                                        <Label>Rebate Amount Paid</Label>
                                                                        <Text>{formatUSD(app.rebateAmountPaid)}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.managementFee ?
                                                                (
                                                                    <Property>
                                                                        <Label>Rebate management fee</Label>
                                                                        <Text>{formatUSD(app.managementFee)}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }
                                                        {
                                                            app?.fulfillmentManager ?
                                                                (
                                                                    <Property>
                                                                        <Label>Manager</Label>
                                                                        <Text>{app.fulfillmentManager.name}</Text>
                                                                    </Property>
                                                                )
                                                                : <></>
                                                        }

                                                        <RebateAttachmentsPanel
                                                            style={{
                                                                width: "size-5000",
                                                                marginY: "size-100",
                                                            }}
                                                            clientId={app.company.id}
                                                            applicationId={app.id}
                                                        />
                                                    </Flex>
                                                )
                                                : <ProgressCircle aria-label="Loading…" isIndeterminate/>
                                        }
                                    </Flex>
                                </View>
                            </Flex>
                        </Flex>

                    </View>
                </Flex>
            </View>
        </>
    )
}

export default CompanyManageProjectView;
