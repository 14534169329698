import React from "react";
import PropTypes from "prop-types";

import {
  MultipleInputWrapper,
  AddInputWrapper,
  InputWrapper,
  DeleteWrapper,
  InputContainer
} from "./MultipleInputs.jsx";
import { SecondaryButton } from "../../../Common/components/Button";

const RequirementsInput = ({ name, formValues, inputs, ...props }) => {
  const generateInputs = formValues
    ? formValues.map((inputSet, index) => {
        // * Get the keys for the input names
        const keys = Object.keys(inputSet);

        return (
          <InputWrapper key={name + index}>
            {props.handleDeleteMulti && (
              <DeleteWrapper>
                <img
                  src="https://s3.amazonaws.com/v2.rebatebus/images/icons/removeIcon.svg"
                  alt="Delete Section"
                  onClick={() => props.handleDeleteMulti(name, index)}
                />
              </DeleteWrapper>
            )}
            <InputContainer>
              {keys.map(key => {
                // * Get the object for the input to pass to createInput
                const newInput = inputs.filter(input => input.name === key)[0];
                if (!newInput) {
                  return null;
                }

                if (newInput.name === "requirementType") {
                  newInput.value = formValues[index].requirementType.uuid;
                } else {
                  newInput.value = formValues[index][newInput.name];
                }

                // * Create the object that helps determine the value
                return props.createInput(newInput, { name, index });
              })}
            </InputContainer>
          </InputWrapper>
        );
      })
    : null;

  return (
    <MultipleInputWrapper>
      <p id="label">{props.title}</p>
      {generateInputs}

      <AddInputWrapper>
        <SecondaryButton
          type="button"
          handleClick={() => props.handleAddNewMulti(name, inputs)}
        >
          Add
        </SecondaryButton>
      </AddInputWrapper>
    </MultipleInputWrapper>
  );
};

export default RequirementsInput;

RequirementsInput.propTyeps = {
  id: PropTypes.string.isRequired
};
