import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { submitUser } from "../ducks/userActions";
import { errorMessage } from "../../Common/ducks/actions";

import { PrimaryButton } from "../../Common/components/Button";
import Input from "../../Common/components/formComponents/Input";
import PhoneInput from "../../Common/components/formComponents/PhoneInput";

class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      company: "",
      industry: "-",
      annualRevenue: "0"
    };
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { password, confirmPassword, phone } = this.state;
    const { submitUser, errorMessage } = this.props;

    if (password !== confirmPassword) {
      return errorMessage("Passwords do not match.");
    }

    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

    if (!phoneRegex.test(phone)) {
      return errorMessage("Phone number must be valid");
    }

    const submission = { ...this.state };

    // ! needs to be updated if DB is reset
    const noCompanyId = "aa972dad-22af-11ea-8b5f-06319dbc535a"; // set to salty-taiga's current "None" Company

    submission.companyId = noCompanyId;
    return submitUser(submission);
  };

  generateFields = array =>
    array.map(field => {
      const { type, label, name, inputType, autocomplete, required } = field;

      switch (type) {
        case "phone":
          return (
            <PhoneInput
              key={name}
              inputType="input"
              id={name}
              value={this.state[name]}
              title={label}
              handleChange={this.handleChange}
              required={required}
              helperText={field.helperText}
            />
          );
        default:
          return (
            <Input
              key={name}
              inputType="input"
              type={inputType}
              id={name}
              value={this.state[name]}
              title={label}
              handleChange={this.handleChange}
              autoComplete={autocomplete ? autocomplete : null}
              required={required}
            />
          );
      }
    });

  render() {
    return (
      <>
        <h1>Sign up for Your Account</h1>
        <form onSubmit={this.handleSubmit}>
          {this.generateFields(formFields)}

          <PrimaryButton>Create Your Account</PrimaryButton>
        </form>
        {this.props.signupComplete && <Redirect to="/dashboard" />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  signupComplete: state.signup.signupComplete
});

export default connect(mapStateToProps, {
  submitUser,
  errorMessage
})(SignupForm);

const formFields = [
  { name: "firstName", label: "First Name", required: true },
  { name: "lastName", label: "Last Name", required: true },
  {
    name: "phone",
    label: "Phone",
    type: "phone",
    helperText: "Phone number only used for contact during customer support",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    inputType: "email",
    autocomplete: "new-email",
    required: true
  },
  { name: "company", label: "Company", required: true },
  {
    name: "password",
    label: "Password",
    inputType: "password",
    autocomplete: "new-password",
    required: true
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    inputType: "password",
    autocomplete: "new-password",
    required: true
  }
];
