import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { SecondaryButton } from "../../../Common/components/Button";

const MultipleInputs = props => {
  const { name, formValues, inputs, singleSet } = props;

  // ! Don't run unless formValues exists!
  const generateInputs = formValues
    ? formValues.map((field, index) => {
        // * Get the keys for the input names
        const keys = Object.keys(field);
        return (
          <InputWrapper key={name + index}>
            {props.handleDeleteMulti && (
              <DeleteWrapper>
                <img
                  src="https://s3.amazonaws.com/v2.rebatebus/images/icons/removeIcon.svg"
                  alt="Delete Section"
                  onClick={() => props.handleDeleteMulti(name, index)}
                />
              </DeleteWrapper>
            )}
            <InputContainer noMargin={props.handleDeleteMulti ? false : true}>
              {keys.map(key => {
                // * Get the object for the input to pass to createInput
                const newInput = inputs.filter(input => input.name === key)[0];

                // * Create the object that helps determine the value
                return props.createInput(newInput, { name, index });
              })}
            </InputContainer>
          </InputWrapper>
        );
      })
    : null;

  return (
    <MultipleInputWrapper singleSet={singleSet}>
      <p className="label">{props.title}</p>
      {generateInputs}
      {props.handleAddNewMulti && (
        <AddInputWrapper>
          <SecondaryButton
            type="button"
            handleClick={() => props.handleAddNewMulti(name, inputs)}
          >
            Add
          </SecondaryButton>
        </AddInputWrapper>
      )}
    </MultipleInputWrapper>
  );
};

export default MultipleInputs;

MultipleInputs.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createInput: PropTypes.func.isRequired,
  handleAddNewMulti: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    .isRequired,
  handleDeleteMulti: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    .isRequired,
  singleSet: PropTypes.bool
};

export const MultipleInputWrapper = styled.div`
  p.label {
    color: #16a888;
    font-size: 1.3em;
    margin-bottom: ${props => (props.singleSet ? "-1.5em" : "0.5em")};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;

export const DeleteWrapper = styled.div`
  flex: 0 0 10%;
  border-right: 3px solid #16a888;
  img {
    max-width: 2.5em;
  }
`;

export const InputContainer = styled.div`
  margin-left: ${props => (props.noMargin ? 0 : "3em")};
  margin-bottom: 0;
  width: 100%;
`;

export const AddInputWrapper = styled.div`
  margin-bottom: 2em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
