import React, { Component } from "react";
import { connect } from "react-redux";
import { filterAndSort, sortPrograms } from "../ducks/searchActions";

import { Title, FilterSection, Checkbox } from "./TechTypeFilter";
import { PreviewButton } from "../../Common/components/Button";

const initialState = {
  sorting: "",
  filtering: {
    isProgramStatus: false,
    eligibility: []
  }
};

class ProgramFilter extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSortingChange = ({ target: { value } }) => {
    this.setState({ sorting: value }, () => this.props.sortPrograms(value));
  };

  handleFilterChange = () => {
    console.log(this.state);
    const { filtering, sorting } = this.state;

    this.setState(
      {
        filtering: {
          ...filtering,
          isProgramStatus: !this.state.filtering.isProgramStatus
        }
      },
      () => this.props.filterAndSort(sorting, this.state.filtering)
    );
  };

  handleCategoryChange = ({ target: { value, checked } }) => {
    const {
      sorting,
      filtering,
      filtering: { eligibility }
    } = this.state;

    if (checked) {
      this.setState(
        {
          filtering: { ...filtering, eligibility: [...eligibility, value] }
        },
        () => this.props.filterAndSort(sorting, this.state.filtering)
      );
    } else {
      this.setState(
        {
          filtering: {
            ...filtering,
            eligibility: eligibility.filter(name => name !== value)
          }
        },
        () => this.props.filterAndSort(sorting, this.state.filtering)
      );
    }
  };

  unCheckboxes = () => {
    const checkboxes = eligibilityCategories.length;
    for (let i = 0; i < checkboxes; i++) {
      this.refs[`checkRef_${i}`].checked = false;
    }
  };

  resetFilters = () => {
    this.setState({ ...initialState }, () => {
      (() => this.props.filterAndSort(null, "reset"))();
      (() => this.unCheckboxes())();
    });
  };

  render() {
    const {
      sorting,
      filtering: { isProgramStatus }
    } = this.state;

    const radioProps = name => ({
      name,
      type: "radio",
      onChange: this.handleSortingChange
    });

    return (
      <>
        <PreviewButton onClick={() => this.resetFilters()}>
          Reset Filters
        </PreviewButton>
        <Title>Sorting</Title>
        <FilterSection>
          <p>Name</p>
          <Checkbox>
            <input
              value="az"
              checked={sorting === "az"}
              {...radioProps("sorting")}
            />
            <span></span>
            <span>A → Z</span>
          </Checkbox>
          <Checkbox>
            <input
              value="za"
              checked={sorting === "za"}
              {...radioProps("sorting")}
            />
            <span></span>
            <span>Z → A</span>
          </Checkbox>
        </FilterSection>
        <Title>Filters</Title>
        <FilterSection>
          <p>Program Status</p>
          <Checkbox>
            <input
              name="programStatus"
              type="checkbox"
              value={isProgramStatus}
              onChange={this.handleFilterChange}
            />
            <span></span>
            <span>Open Only</span>
          </Checkbox>
        </FilterSection>
        <FilterSection>
          <p>Eligiblity</p>
          {eligibilityCategories.map((cat, index) => (
            <Checkbox key={cat}>
              <input
                type="checkbox"
                value={cat}
                onChange={this.handleCategoryChange}
                ref={`checkRef_${index}`}
              />
              <span></span>
              <span>{cat}</span>
            </Checkbox>
          ))}
        </FilterSection>
      </>
    );
  }
}

export default connect(null, { filterAndSort, sortPrograms })(ProgramFilter);

const eligibilityCategories = [
  "Commercial",
  "Small Business",
  "Multi-Family",
  "Industrial",
  "Residential",
  "New Construction",
  "Individual"
];
