import ApolloClient from "apollo-boost";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "../../fragmentTypes.json";

const token = localStorage.getItem("token");

// Can't pass empty Authorization bc it returns 401 if not signed in. Instead, make null
const headers = token ? { Authorization: token } : null;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
export default new ApolloClient({
  fetchOptions: {
    credentials: "include",
  },
  headers,
  cache,
  uri: "/api/v2/graphql",
});
