import React from "react";
import {Children} from "../../types";
import { Link } from "react-router-dom"

export interface TableLinkParams {
    href: string,
    children: Children
    target?: "_self" | "_blank",
    style?: React.CSSProperties
}

export function TableLink({href, children, target = "_blank"}: TableLinkParams) {
    return <a href={href}
              style={{color: "#005fb2", textDecoration: "none", fontStyle: "normal"}}
              target={target}
              rel="noopener">{children}</a>
}

export function TableSelfLink({href, children, style}: TableLinkParams) {
    return <Link style={{color: "#005fb2", textDecoration: "none", fontStyle: "normal", ...style}} to={href}>{children}</Link>
}

