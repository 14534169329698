import {gql} from "apollo-boost";
import { QPL } from "./qpl";

export const GET_PRODUCT_OPTIONS = gql`
    query GetProductOptions($query: String!, $type: ProductIdQueryEnum!) {
        productSelectOptions(query: $query, type: $type) {
            uuid
            modelNumber
            QPLIdentifier
        }
    }
`;

export const GET_UTILITY_OPTIONS = gql`
    query GetUtiliyOptions($name: String!, $stateIds: [ID!], $offset: Int, $limit: Int) {
        utilitySelectOptions(where: {
            name: $name,
            stateIds: $stateIds,
        }, offset: $offset, limit: $limit) {
            value: uuid
            label: name
            stateId,
        }
    }
`;

export const GET_PROGRAM_OPTIONS = gql`
    query GetProgramOptions($name: String!, $utilityIds: [ID!], $offset: Int, $limit: Int) {
        programOptions(where: {
            name: $name,
            utilityIds: $utilityIds,
        }, offset: $offset, limit: $limit) {
            value: uuid
            label: name
            utilityIds,
        }
    }
`;

export const GET_STATES = gql`
    query GetState {
        states {
            value: uuid
            label: name
        }
    }
`;

export const GET_SELECTED_OPTIONS_FOR_PANEL = gql`
    query GetSelectedOptionsForPanel($productId: ID, $stateIds: [ID!], $utilityIds: [ID!], $programIds: [ID!], $productIdType: ProductIdEnum) {
        optionsForRebateFilterPanel(where:{
            productId: $productId,
            productIdType: $productIdType,
            stateIds: $stateIds,
            utilityIds: $utilityIds,
            programIds: $programIds,
        }) {
            product {
                uuid
                modelNumber
                QPLIdentifier
            }
            states {
                value
                label
            }
            utilities {
                value
                label
                stateId
            }
            programs {
                value
                label
                utilityIds
            }
        }
    }
`;

export const GET_REBATES = gql`
    query GetRebates(
        $stateIds: [ID!]!,
        $programIds: [ID!]!,
        $utilityIds: [ID!]!,
        $programRequirements: ProgramRequirementsInput!,
        $limit: Int!,
        $offset: Int!,
        $newProduct: ModelIdInput
        $customerTypeIds: [ProgramCustomerTypeEnum!]!,
        $projectTypeIds: [RebateProjectTypeEnum!]!,
        $rebateTypeIds: [RebateTypeEnum!]!,
        $existingProductSpecs: ExistingProductSpecsInput,
        $operatingHours: Int,
    ) {
        response: filteredLightingRebates(
            limit: $limit,
            offset: $offset,
            where: {
                customerTypes: $customerTypeIds,
                projectTypes: $projectTypeIds,
                rebateTypes: $rebateTypeIds,
                programRequirements: $programRequirements,
                stateIds: $stateIds,
                utilityIds: $utilityIds,
                programIds: $programIds,
                newProduct: $newProduct,
                existingProductSpecs: $existingProductSpecs,
                operatingHours: $operatingHours,
            }) {
            rebates {
                uuid
                updatedAt
                notes
                name {
                    value
                }
                type
                program {
                    uuid
                    name
                    description
                    requirements
                    statusNotes
                    startDate
                    endDate
                    projectCap
                    materialCap
                    laborCap
                    maximumAnnualIncentive
                    customerTypes
                    isPreInspectionRequired
                    isPreApprovalRequired
                    isPostAuditRequired
                    applicationURL
                    homePageURL
                    utilities {
                        uuid
                        name
                        state {
                            uuid
                            name
                        }
                    }
                }
                rates {
                    value
                    unit {
                        value
                    }
                }
                requirements {
                    value
                    type {
                        value
                    }
                    productSpec {
                        value
                    }
                }
                qpls
                projectTypes
            }
        }
    }
`;

export const GET_REBATE_COUNT = gql`
    query GetRebateCount(
        $stateIds: [ID!]!,
        $programIds: [ID!]!,
        $utilityIds: [ID!]!,
        $programRequirements: ProgramRequirementsInput!,
        $customerTypeIds: [ProgramCustomerTypeEnum!]!,
        $projectTypeIds: [RebateProjectTypeEnum!]!,
        $rebateTypeIds: [RebateTypeEnum!]!,
        $newProduct: ModelIdInput,
        $existingProductSpecs: ExistingProductSpecsInput,
        $operatingHours: Int,
    ) {
        response: filteredLightingRebates(
            withCount: true
            onlyCount: true
            where: {
                customerTypes: $customerTypeIds,
                projectTypes: $projectTypeIds,
                rebateTypes: $rebateTypeIds,
                programRequirements: $programRequirements,
                stateIds: $stateIds,
                utilityIds: $utilityIds,
                programIds: $programIds,
                newProduct: $newProduct,
                existingProductSpecs: $existingProductSpecs,
                operatingHours: $operatingHours
            }) {
            totalCount
        }
    }
`;


export const DOWNLOAD_REPORT = gql`
    query GetRebates(
        $stateIds: [ID!]!,
        $programIds: [ID!]!,
        $utilityIds: [ID!]!,
        $programRequirements: ProgramRequirementsInput!,
        $customerTypeIds: [ProgramCustomerTypeEnum!]!,
        $projectTypeIds: [RebateProjectTypeEnum!]!,
        $rebateTypeIds: [RebateTypeEnum!]!,
        $newProduct: ModelIdInput,
        $existingProductSpecs: ExistingProductSpecsInput,
        $operatingHours: Int,
    ) {
        response: filteredLightingRebatesExport(
            extension: XLSX,
            where: {
                customerTypes: $customerTypeIds,
                projectTypes: $projectTypeIds,
                rebateTypes: $rebateTypeIds,
                programRequirements: $programRequirements,
                stateIds: $stateIds,
                utilityIds: $utilityIds,
                programIds: $programIds,
                newProduct: $newProduct,
                existingProductSpecs: $existingProductSpecs,
                operatingHours: $operatingHours
            }) {
            base64data
        }
    }
`;

export interface ProductSpec {
    spec: string;
    value: string;
}

export interface Product {
    uuid: string;
    QPL: QPL;
    QPLIdentifier: string;
    modelNumber: string;
    brand: {
        name: string;
    };
    manufacturer: {
        name: string;
    };
    newProductCategory: {
        name: string;
    };
    specs: Array<ProductSpec>;
}

export interface GetStaticOptionsResponse {
    products: Array<Product>;
}

export interface ProductsByIdsInput {
    dlcIds?: Array<string>;
    energyStarIds?: Array<string>;
    rbIds?: Array<string>;
}

export interface GetStaticOptionsVariables {
    ids: ProductsByIdsInput;
}

export const GET_PRODUCTS_QUERY = gql`
    query GetProducts($ids: ProductsByIdsInput!) {
        products: productsByIds(ids: $ids) {
            QPL
            QPLIdentifier
            modelNumber
            brand {
                name
            }
            manufacturer {
                name
            }
            newProductCategory {
                name
            }
            specs {
                spec
                value
            }
            uuid
        }
    }
`;
