import axios from "axios";
import jwt_decode from "jwt-decode";
import { SIGNIN, SIGNOUT, UPDATE } from "./types";
import { successMessage, errorMessage } from "../../Common/ducks/actions";

const url = "/api/v2";

// Needed for getting sitemap
// var LocalStorage = require("node-localstorage").LocalStorage;
// let localStorage = new LocalStorage("./scratch");
// const axiosConfig = {
//   headers: {
//     Authorization: localStorage.getItem("token")
//   }
// };

const axiosConfig = {
  headers: {
    Authorization: localStorage.token
  }
};

const signin = user => ({
  type: SIGNIN,
  user
});

export const attemptSignin = (email, password) => dispatch => {
  return axios
    .post(`${url}/auth/signIn`, {
      signInData: { email, password }
    })
    .then(res => {
      const token = res.data.token;
      localStorage.setItem("token", token);
      return dispatch(authenticateUser(token));
    })
    .catch(error => {
      if (error.response.data.message) {
        return dispatch(errorMessage(error.response.data.message));
      }
      return dispatch(errorMessage("Login attempt failed"));
    });
};

export const authenticateUser = token => async (dispatch) => {
  // get the jwt that starts after "Bearer " and pull the uuid off the object
  const index = token.indexOf("Bearer ");
  const { uuid } = jwt_decode(token.slice(index));
  // see if token is still valid
  const isValid = jwt_decode(token.slice(index)).exp < Date.now();

  if (isValid) {
    return await axios
      .get(`${url}/user`, {
        headers: { Authorization: localStorage.getItem("token") },
        user: { uuid }
      })
      .then(res => {
        let user = { ...res.data };
        const permissions = {
          // ! will have to be updated any time there is a DB reset
          "aa44d7bf-22af-11ea-8b5f-06319dbc535a": 1, // salty-taiga
          "aa44dadd-22af-11ea-8b5f-06319dbc535a": 2, // salty-taiga
          "aa44dc2b-22af-11ea-8b5f-06319dbc535a": 3, // salty-taiga
          "aa44dcbd-22af-11ea-8b5f-06319dbc535a": 4 // salty-taiga
        };
        // get the correct permissions level from the permissionId
        // ? Use switch & default to 0?
        user.permissions = permissions[user.permissionId] || 0;
        return dispatch(getSettings(user));
      })
      .then(user => dispatch(getCompany(user)))
      .then(user => dispatch(signin(user)))
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401 && localStorage.token) {
            return localStorage.removeItem("token");
          }
          return dispatch(errorMessage(error.response.data.message));
        }
        return (
          dispatch(errorMessage("Login attempt failed")) &&
          localStorage.removeItem("token")
        );
      });
  } else {
    localStorage.removeItem("token");
    dispatch(signout());
    return;
  }
};

const getCompany = user => dispatch => {
  return axios
    .get(`${url}/company`, {
      params: { companyId: user.companyId },
      headers: { Authorization: localStorage.getItem("token") }
    })
    .then(res => ({ ...user, company: res.data }))
    .catch(error => {
      if (error.message.data && error.message.data.text) {
        return dispatch(errorMessage(error.message.data.text));
      }
      return dispatch(errorMessage("There was an error getting company name"));
    });
};

const getSettings = user => dispatch => {
  return axios
    .get(`${url}/settings`, {
      headers: { Authorization: localStorage.getItem("token") }
    })
    .then(res => {
      const userWithSettings = { ...user, settings: { ...res.data } };
      return userWithSettings;
    })
    .catch(error => {
      if (error.message.data && error.message.data.text) {
        return dispatch(errorMessage(error.message.data.text));
      }
      return dispatch(errorMessage("There was an error loading settings"));
    });
};

export const signout = () => ({
  type: SIGNOUT
});

export const signoutUser = message => dispatch => {
  localStorage.removeItem("token");
  dispatch(signout());
  dispatch(
    successMessage(message ? message : "You have successfully logged out.")
  );
};

export const updateUser = (user, itemToUpdate) => dispatch => {
  let userToUpdate = { ...user };
  const keys = Object.keys(user);

  keys.forEach(key => {
    if (userToUpdate[key] === "") {
      userToUpdate[key] = null;
    }
  });

  delete userToUpdate.permissions;
  delete userToUpdate.settings;
  delete userToUpdate.creationDate;
  delete userToUpdate.lastLogin;
  delete userToUpdate.company;

  let updateUrl = `${url}/user`;

  if (user.password) {
    updateUrl += "/password";
  }

  return axios
    .put(updateUrl, userToUpdate, axiosConfig)
    .then(() => dispatch(updateUserRedux(user)))
    .then(() =>
      dispatch(
        successMessage(
          `You have successfully updated your ${
            itemToUpdate ? itemToUpdate : "profile"
          }.`
        )
      )
    )
    .catch(error => {
      if (error.message.data && error.message.data.text) {
        return dispatch(errorMessage(error.message.data.text));
      }
      return dispatch(errorMessage("There was an error"));
    });
};

export const updateSettings = (settings, user) => dispatch => {
  const updatedUser = { ...user, settings: { ...user.settings, ...settings } };

  return axios
    .put(`${url}/settings`, settings, axiosConfig)
    .then(() => dispatch(updateUserRedux(updatedUser)))
    .then(() => dispatch(successMessage("Settings updated.")))
    .catch(error => {
      if (error.message.data && error.message.data.text) {
        return dispatch(errorMessage(error.message.data.text));
      }
      return dispatch(errorMessage("There was an error"));
    });
};

export const uploadPhoto = submission => dispatch => {
  return axios
    .post(`${url}/settings/photo`, submission, axiosConfig)
    .then(({ data }) => {
      const { user } = submission;
      const updatedSettings = {
        ...user.settings,
        photo: data.Location
      };

      return dispatch(updateSettings(updatedSettings, user));
    })
    .catch(error => {
      if (error.message.data && error.message.data.text) {
        return dispatch(errorMessage(error.message.data.text));
      }
      return dispatch(errorMessage("There was an error"));
    });
};

export const updateUserRedux = user => ({
  type: UPDATE,
  user
});

export const deactivateUser = userToDeactivate => dispatch => {
  const deleteConfig = {
    ...axiosConfig,
    data: { ...userToDeactivate }
  };

  // TODO: add logic for deactivating a TM
  return axios
    .delete(`${url}/user`, deleteConfig)
    .then(res => {
      setTimeout(() => {
        dispatch(signoutUser("Your account has been deactivated."));
      }, 5000);
    })
    .catch(error => dispatch(errorMessage(error.message.data.text)));
};
