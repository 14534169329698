export const GET_WIKI = "GET_WIKI";

export const SHOW_NEW = "SHOW_NEW";

export const SHOW_OPTIONS = "SHOW_OPTIONS";

export const LOAD_REBATES = "LOAD_REBATES";
export const SORT_REBATES = "SORT_REBATES";
export const FILTER_REBATES = "FILTER_REBATES";
export const FILTER_BY_TECH_TYPE = "FILTER_BY_TECH_TYPE";
export const DELETE_REBATE = "DELETE_REBATE";
export const REBATE_ADDED = "REBATE_ADDED";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const ADD_PRODUCT_ID = "ADD_PRODUCT_ID";
export const ADD_PRODUCT_MODELNUMBER = "ADD_PRODUCT_MODELNUMBER";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";

export const LOAD_PROGRAMS = "LOAD_PROGRAMS";
export const SORT_PROGRAMS = "SORT_PROGRAMS";
export const FILTER_PROGRAMS = "FILTER_PROGRAMS";
export const CLEAR_PROGRAMS = "CLEAR_PROGRAMS";
export const LOADING = "LOADING";
export const CLEAR_FILTERED = "CLEAR_FILTERED";
