import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const StyledToggle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1em 0;

  span {
    font-weight: bold;
  }

  label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
    &::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      top: 1px;
      left: 1px;
      transition: all 0.3s;
    }
  }

  input {
    display: none;
  }

  input:checked + .switch {
    background-color: #16a888;
  }

  input:checked + .switch::after {
    left: 20px;
  }
`;

const Toggle = ({ description, id, valueFromState, handleCheckboxChange }) => {
  return (
    <StyledToggle>
      <span>{description}</span>
      <input
        type="checkbox"
        id={id}
        className="checkbox"
        checked={valueFromState}
        onChange={() => handleCheckboxChange(id)}
        value={id}
      />
      <label htmlFor={id} className="switch"></label>
    </StyledToggle>
  );
};

export default Toggle;

Toggle.propTypes = {
  /** The id of the checkbox. */
  id: PropTypes.string.isRequired,
  /** The description of the setting. */
  description: PropTypes.string.isRequired,
  /** Tells the component what state it should show. */
  valueFromState: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  /** Handles the onChange for the element. */
  handleCheckboxChange: PropTypes.func.isRequired
};
