import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
//import "../css/Card.scss";

const StyledCard = styled.div.attrs(props => ({
  marginBottom: props.marginBottom || 0,
  flex: props.flex || "none"
}))`
  background-color: ${props => props.theme.colors.backgroundMain};
  border-radius: 15px;
  overflow: hidden;
  box-shadow: ${props => props.theme.colors.boxShadow};
  margin-bottom: ${props => props.marginBottom};
  flex: ${props => props.flex};
  padding: ${props => props.padding || "2em"};
`;

/**
 * Cards are used throughout the web app to highlight the user's attention to the important parts of the page.
 */

const Card = props => {
  const { id, children } = props;
  return (
    <StyledCard id={id} {...props}>
      {children}
    </StyledCard>
  );
};

export default Card;

Card.propTypes = {
  /** Gives an ID to the Card, if necessary. */
  id: PropTypes.string,
  /** Adds margin to bottom of card if necessary. Defaults to 0 */
  marginBottom: PropTypes.string,
  /** The content of the Card. */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};
