import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { deleteRebate } from "../../ducks/rebateActions"
import styled from "styled-components";
import {
  StyledPageHeader,
  Section,
  CardWrapper,
  ReportCard,
  ContactCard,
  Checkmark,
  Product,
  ProductList,
  Button,
  ButtonLink,
  Collapse,
  BoldKeyValue,
  RebateOffering,
  RebateOfferingsWrapper,
  RebateRequirementsWrapper,
  HtmlSectionWrapper,
} from "./StyledPageComponents";

import IconWithText from "../../../Common/components/IconWithText";
import { textToUrl } from "../../helpers/urls";
import techTypeIcons from "../../helpers/techTypeIcons";

const UnstyledDeleteRebate = ({className, onDeleteRebate}) => {
  return (
      <span className={className} onClick={onDeleteRebate}>
        {" "}
        Delete
      </span>
  )
}

const DeleteRebate = styled(UnstyledDeleteRebate)`
    color: ${(props) => props.theme.messages.error.icon} !important;
    cursor: pointer;
`;

export const RawPageHeader = ({
  name,
  nickname,
  type,
  permissions,
  url,
  parentProgram,
  programId,
  rebateId,
  deleteRebate,
  technologyType,
  isState
}) => {
  let technology;
  if (type === "Technology Type") {
    technology = "Rebates by Technology Type";
  }

  const handleRebateDelete = () => {
    const answer = window.confirm("Are you sure you want to delete this rebate?");
    if (answer) {
      deleteRebate(rebateId);
      window.location.assign(parentProgram.url);
    }
  }

  return (
    <StyledPageHeader type={technology ? technology : type}>
      <h1>{isState ? `${name} Utility & Rebate Information` : name}</h1>
      {nickname ? (<h2>{nickname}</h2>) : null}
      <span>{type}</span>

      {permissions && type !== "Zip Code" && type !== "Technology Type" && (
        <span>
          {" "}
          | <Link to={`${url}/edit`}>Edit Page</Link>
        </span>
      )}
      {permissions && type === "Rebate" && (
        <span>
          {" "}
          | <Link to={{pathname: '/search/add', search: `pageType=rebate&copyFrom=${rebateId}`}}>Copy</Link>
        </span>
      )}
      {permissions && type === "Rebate" ? (<><span> | </span><DeleteRebate onDeleteRebate={handleRebateDelete}/></>) : null}
      {permissions && programId && (
        <span>
          {" "}
          |{" "}
          <Link to={{ pathname: "/search/add", state: { programId } }}>
            Add Rebate
          </Link>
        </span>
      )}
    </StyledPageHeader>
  );
};

export const PageHeader = connect(null, { deleteRebate })(
  RawPageHeader
);

PageHeader.propTypes = {
  /** The title of the page */
  name: PropTypes.string.isRequired,
  /** The page type, e.g. Country */
  type: PropTypes.string.isRequired,
  /** Does the user have permission to view the edit link? */
  permissions: PropTypes.bool.isRequired,
  /** The link to edit a page */
  url: PropTypes.string.isRequired,
};

const ProgramLink = styled.p`
  color: #cacaca;
  margin-top: -0.25em;
  a {
    text-decoration: underline;
  }
`;

export const ReportsSection = ({ reports }) =>
  reports && reports.length > 0 ? (
    <Section>
      <h2>Reports</h2>
      <CardWrapper>
        {reports.map((report) => {
          const { name, url } = report;

          // * Limit description length if greater than charLimit
          let { description } = report;
          const charLimit = 180;

          if (description.length > charLimit) {
            const nextWhiteSpace = description
              .substring(charLimit)
              .indexOf(" ");
            description =
              description.substring(0, charLimit + nextWhiteSpace) + " ...";
          }

          return (
            <ReportCard key={name} className="wikiReport">
              <p className="wikiCardTitle">{name}</p>
              <HtmlSection
                className="wikiCardDescription"
                longText={description}
              />
              <a href={url} target="_blank" rel="noopener noreferrer">
                view report
              </a>
            </ReportCard>
          );
        })}
      </CardWrapper>
    </Section>
  ) : null;

ReportsSection.propTypes = {
  /** The array of reports */
  reports: PropTypes.array.isRequired,
};

export const ContactsSection = ({ contacts }) =>
  contacts && contacts.length > 0 ? (
    <Section>
      <h2>Contacts</h2>
      <CardWrapper>
        {contacts.map(({ name, title, phone1, phone2, email }) => (
          <ContactCard key={name} className="wikiContact">
            <p className="wikiCardTitle">{name}</p>
            <p className="wikiCardSubtitle">{title}</p>
            {phone1 && (
              <p className="wikiCardDescription">
                <img
                  src="https://s3.amazonaws.com/v2.rebatebus/images/icons/phoneIcon.svg"
                  alt="Phone icon"
                />
                {phone1}
              </p>
            )}
            {phone2 && (
              <p className="wikiCardDescription">
                <img
                  src="https://s3.amazonaws.com/v2.rebatebus/images/icons/phoneIcon.svg"
                  alt="Phone icon"
                />
                {phone2}
              </p>
            )}
            {email && (
              <p className="wikiCardDescription">
                <img
                  src="https://s3.amazonaws.com/v2.rebatebus/images/icons/emailIcon.svg"
                  alt="Email icon"
                />
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </a>
              </p>
            )}
          </ContactCard>
        ))}
      </CardWrapper>
    </Section>
  ) : null;

ContactsSection.propTypes = {
  /** The array of contacts */
  contacts: PropTypes.array.isRequired,
};

export const LinksSection = (props) => {
  const urlLink = props.url;
  const { title, type, links, name } = props;

  if (links && links.length > 0) {
    return (
      <Section subsection key={title}>
        <h2>{title}</h2>
        {links.map(({ linkText, url, SocialMediaPlatform }) => {
          const iconType = type === "link" ? "link" : SocialMediaPlatform.name;
          const link = type === "link" ? linkText : SocialMediaPlatform.name;
          return (
            <IconWithText key={linkText || SocialMediaPlatform.name}>
              <img
                src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/${linkIcons[iconType]}`}
                alt={`${linkText || SocialMediaPlatform.name} Icon`}
              />
              <a href={url} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            </IconWithText>
          );
        })}
      </Section>
    );
  } else if (urlLink) {
    return (
      <Section urlLink={props.name ? true : null} subsection key={title}>
        <IconWithText>
          <img
            src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/${linkIcons["link"]}`}
            alt={name}
          />
          <a href={urlLink} target="_blank" rel="noopener noreferrer">
            {urlLink}
          </a>
        </IconWithText>
      </Section>
    );
  } else {
    return null;
  }
};

LinksSection.propTypes = {
  /** The array of links */
  links: PropTypes.array,
  /** The title of the section, e.g. "Websites" */
  title: PropTypes.string,
  /** Differentiates between "plain" link and social media to get the proper icon */
  type: PropTypes.string,
};

export const linkIcons = {
  link: "linkIcon.svg",
  YouTube: "youtubeIcon.svg",
  Facebook: "facebookIcon.svg",
  Instagram: "instagramIcon.svg",
  Twitter: "twitterIcon.svg",
  LinkedIn: "linkedinIcon.svg",
};

export const CheckmarkSection = ({ title, listOfItems }) => (
  <Section subsection>
    <h2>{title}</h2>
    {listOfItems.map((item) => {
      const imgUrl = `https://s3.amazonaws.com/v2.rebatebus/images/icons/${
        item.xIcon ? "xIcon.svg" : "checkmarkIcon.svg"
      }`;
      return (
        <Checkmark key={item.uuid || item.name}>
          <img src={imgUrl} alt="Checkmark" />
          <span>{item.value}</span>
        </Checkmark>
      );
    })}
  </Section>
);

export const SponsoredOrQualifiedProducts = ({
  product,
  key,
  purchaseNow = null,
}) => {
  useEffect(() => {}, [product]);

  const [seeMore, setSeeMore] = useState(false);
  const [expand, setExpand] = useState(true);

  const handleCollapse = (boolean) => setExpand(boolean);
  const handleSeeMore = (boolean) => setSeeMore(boolean);

  return (
    <div key={`${product.manufacturerName}-products-${key}`}>
      <Collapse onClick={() => handleCollapse(!expand)}>
        <FontAwesomeIcon
          icon={expand ? faAngleDoubleUp : faAngleDoubleDown}
          size="1x"
          color="#0ea788"
        />
        <span>{expand ? "Collapse Section" : "Expand Section"}</span>
      </Collapse>
      <ProductList expand={expand}>
        {(!seeMore ? product.slice(0, 3) : product).map((product, i) => (
          <Product key={`${product.modelNumber}-${key}-${i}`}>
            <div className="image-container">
              {product.imageUrl ? (
                <img alt="Product" src={product.imageUrl} />
              ) : (
                <img
                  alt="Product"
                  src="https://s3.amazonaws.com/v2.rebatebus/images/decorative/productPlaceholder.svg"
                />
              )}
            </div>
            <div className="model-number">
              <p>{product.modelNumber}</p>
            </div>
            <div className="product-category">
              <p>{product.newProductCategory.name}</p>
            </div>
            <div className="button-container">
              {purchaseNow ? (
                <Button purchaseNow>
                  <ButtonLink
                    to={`/search/product/${product.uuid}/${product.modelNumber}`}
                    purchaseNow
                  >
                    <span>purchase now</span>
                  </ButtonLink>
                </Button>
              ) : (
                <Button>
                  <ButtonLink
                    to={`/search/articles/${product.uuid}/product/${product.modelNumber}`}
                  >
                    <span>view product</span>
                  </ButtonLink>
                </Button>
              )}
            </div>
          </Product>
        ))}
        {product.length >= 4 ? (
          <span
            className="see-more-products"
            onClick={() => handleSeeMore(!seeMore)}
          >
            {seeMore ? "See less products" : "See more products..."}
          </span>
        ) : null}
      </ProductList>
    </div>
  );
};

export const RebateRequirements = ({ rebateRequirements }) => {
  const requirements =
    rebateRequirements &&
    rebateRequirements.map(({ rebateRequirementType, value }) => {
      const [operand, start, end] = value.split(" ");
      const requirementValues = {
        "/gt": `Greater than ${start}`,
        "/geq": `Greater than or equal to ${start}`,
        "/lt": `Less than ${start}`,
        "/leq": `Less than or equal to ${start}`,
        "/exactly": `Exactly ${start}`,
        "/between": `Between ${start} and ${end}`,
        "/value": `${start}`,
        "/percent": `${start}%`,
      };

      const specTypes = {
        wattOutput: "watts",
        dlcPremium: "DLC Premium",
        lifetimeHours: "lifetime hours",
        powerFactor: "power factor",
        lumenOutput: "lumen output",
        colorTemperature: "color temperature",
        cri: "CRI",
        warrantyYears: "warranty years",
        fixtureLength: "fixture length",
      };

      return (
        <BoldKeyValue capitalize key={rebateRequirementType.value}>
          <span>{rebateRequirementType.value}: </span>
          {requirementValues[operand]}{" "}
          {specTypes[rebateRequirementType.productSpecType.value] ||
            rebateRequirementType.productSpecType.value}
        </BoldKeyValue>
      );
    });

  return (
    <RebateRequirementsWrapper>
      {requirements ? requirements : ""}
    </RebateRequirementsWrapper>
  );
};

export const RebateOfferings = ({ techTypes, program }) => {
  const offerings = techTypes.map(({ value, uuid }) => {
    const url = `/search/rebates/${value.toLowerCase()}?programIds=${program.uuid}`;
    return (
      <RebateOffering to={url} key={uuid}>
        <img src={techTypeIcons[value].icon || ""} alt={techTypeIcons[value].alt} />
        <span>{techTypeIcons[value].title}</span>
      </RebateOffering>
    );
  });

  return <RebateOfferingsWrapper>{offerings}</RebateOfferingsWrapper>;
};

export const HtmlSection = ({ className, longText }) => {
  if (longText.indexOf("<p>") === 0) {
    return (
      <HtmlSectionWrapper
        className={className}
        dangerouslySetInnerHTML={{ __html: longText }}
      />
    );
  }
  return <p className={className}>{longText}</p>;
};

HtmlSection.propTypes = {
  longText: PropTypes.string.isRequired,
};
