import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import MainContentWrapper from "../layout/MainContentWindowWrapper";

const WrongTurn = () => {
  let text;
  if (document.referrer.indexOf("rebatebus") > -1) {
    text = <p>Uh oh, you took a wrong turn somewhere!</p>;
  } else {
    text = (
      <p>
        It looks like you clicked on an old link. Try using{" "}
        <Link to="/search">Direct Search</Link> or the{" "}
        <Link to="/search/program-map">Program Map</Link> to find what you're
        looking for!
      </p>
    );
  }

  return (
    <main id="mainContentContainer">
      <div id="mainContentWindowContainer">
        <WrongTurnWrapper>
          <img
            src="https://s3.amazonaws.com/v2.rebatebus/images/decorative/wrongTurn.png"
            alt="Wrong Turn Sign"
          />
          {text}
        </WrongTurnWrapper>
      </div>
    </main>
  );
};

export default WrongTurn;

const WrongTurnWrapper = styled(MainContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 1em;
    height: 35em;
    @media all and (max-width: 768px) {
      max-width: 130%;
      height: auto;
    }
  }
`;
