import React, { Component } from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import { signout } from "../../Account/ducks/actions";
import {
  tempSaveCompany,
  getAccountTypes,
  createNewCompanyAdmin,
} from "../ducks/companyActions";
import { errorMessage } from "../../Common/ducks/actions";

import Loading from "../../Common/components/Loading";
import Input from "../../Common/components/formComponents/Input";
import PhoneInput from "../../Common/components/formComponents/PhoneInput";
import GraphQLSelect from "../../Common/components/formComponents/GraphQLSelect";
import Checkbox from "../../Common/components/formComponents/Checkbox";
import { PrimaryButton } from "../../Common/components/Button";
import Signin from "../../Account/Signin";

class CompanySignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      industryType: "",
      hasExistingAccount: false,
      accountVerified: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      loading: false,
      errors: [],
    };
  }

  componentDidMount() {
    const { getAccountTypes, tempCompany } = this.props;

    getAccountTypes();

    // * if user hits back button from Billing, repopulate the company to state
    if (tempCompany.company) {
      const {
        company,
        address,
        city,
        state,
        zipcode,
        industryType,
      } = tempCompany;

      this.setState({
        company,
        address,
        city,
        state,
        zipcode,
        industryType,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, loggedIn, companyResult } = this.props;
    if (prevProps.user !== user && loggedIn && this.state.hasExistingAccount) {
      this.setState({ accountVerified: true, loading: false });
    }

    if (prevProps.companyResult !== companyResult) {
      this.setState({ loading: false });
    }
  }

  handleChange = (id, value) => {
    const { errors } = this.state;

    const newErrors = [...errors];

    if (newErrors.indexOf(id) >= 0 && value.length > 0) {
      newErrors.splice(newErrors.indexOf(id), 1);
    }

    this.setState({
      [id]: value,
      errors: [...newErrors],
    });
  };

  generateFields = (array) =>
    array.map((field) => {
      const { type, label, name, inputType, ...rest } = field;

      const isError = this.state.errors.indexOf(field.name) >= 0;

      const inputProps = {
        key: name,
        id: name,
        inputType: "input",
        type: inputType,
        title: label,
        error: isError,
        helperText: isError ? "This field is required!" : field.helperText,
        value: this.state[name],
        handleChange: this.handleChange,
        ...rest,
      };

      switch (type) {
        case "select":
          return <GraphQLSelect {...inputProps} />;
        case "phone":
          return <PhoneInput {...inputProps} />;
        default:
          return <Input {...inputProps} />;
      }
    });

  showButton = () => {
    if (this.state.accountVerified || this.props.companyResult.adminSuccess) {
      return (
        <PrimaryButton handleClick={this.checkForErrors}>
          Proceed to Payment
        </PrimaryButton>
      );
    } else {
      return null;
    }
  };

  checkForErrors = () => {
    const requiredFields = [
      "company",
      "address",
      "city",
      "state",
      "zipcode",
      "industryType",
    ];

    const newErrors = [];

    requiredFields.forEach((field) => {
      if (this.state[field].length < 1) {
        newErrors.push(field);
      }
    });

    if (newErrors.length > 0) {
      this.setState({ errors: newErrors });
    } else if (this.props.user.uuid) {
      this.handleSubmit();
    } else {
      errorMessage("Please fill out required fields.");
    }
  };

  handleSubmit = () => {
    const { tempSaveCompany, history, plans } = this.props;
    const { company, address, city, state, zipcode, industryType } = this.state;

    const tempCompany = {
      company,
      address,
      city,
      state,
      zipcode,
      industryType,
    };

    tempSaveCompany(tempCompany, plans);
    history.push("/signup/billing");
  };

  addNewUser = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      passwordConfirm,
    } = this.state;

    const { errorMessage, createNewCompanyAdmin } = this.props;

    if (password !== passwordConfirm) {
      return errorMessage("Passwords do not match.");
    }

    const user = {
      firstName,
      lastName,
      email,
      phone,
    };

    this.setState({ loading: true });

    return createNewCompanyAdmin(user, password);
  };

  render() {
    const { hasExistingAccount, accountVerified, loading } = this.state;

    return (
      <div>
        <h1>Create Company Account</h1>

        <h2>Company Info</h2>
        {this.generateFields(formFields.companyInfo)}
        <h2>Billing Contact</h2>
        {!accountVerified && !this.props.companyResult.adminSuccess && (
          // * only show if account
          <Checkbox
            id="hasExistingAccount"
            name="hasExistingAccount"
            description="I already have an account with Rebate Bus."
            valueFromState={hasExistingAccount}
            handleCheckboxChange={() =>
              this.setState({ hasExistingAccount: !hasExistingAccount })
            }
          />
        )}
        {hasExistingAccount && !accountVerified && (
          <>
            <p>Please verify your account to continue.</p>
            <Signin redirectRoute="/signup/company" />
          </>
        )}
        {accountVerified && <p>Your account has been verified.</p>}
        {!hasExistingAccount && !this.props.companyResult.adminSuccess && (
          <>
            {this.generateFields(formFields.billingContact)}
            {!loading && !this.props.companyResult.adminSuccess && (
              <PrimaryButton handleClick={this.addNewUser}>
                Create new account
              </PrimaryButton>
            )}
          </>
        )}
        {this.props.companyResult.adminSuccess && (
          <p>Your account has been created.</p>
        )}
        {loading && <Loading />}
        {this.showButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tempCompany: state.signup.tempCompany,
  companyResult: state.signup.companyResult,
  plans: state.signup.accountTypes,
  loggedIn: state.account.loggedIn,
  user: state.account.user,
});

export default connect(mapStateToProps, {
  signout,
  tempSaveCompany,
  createNewCompanyAdmin,
  getAccountTypes,
  errorMessage,
})(CompanySignup);

export const formFields = {
  companyInfo: [
    { name: "company", label: "Company Name", required: true },
    { name: "address", label: "Company Address", required: true },
    { name: "city", label: "City", required: true },
    {
      name: "state",
      label: "State",
      required: true,
      type: "select",
      optionsQuery: gql`
        {
          states {
            uuid
            name
          }
        }
      `,
    },
    { name: "zipcode", label: "Zip Code", inputType: "number", required: true },
    {
      name: "industryType",
      label: "Industry",
      type: "select",
      required: true,
      optionsQuery: gql`
        {
          industryTypes {
            uuid
            value
          }
        }
      `,
    },
  ],

  billingContact: [
    {
      name: "email",
      label: "Email",
      inputType: "email",
      required: true,
    },
    { name: "firstName", label: "First Name", required: true },
    { name: "lastName", label: "Last Name", required: true },
    { name: "phone", label: "Phone", type: "phone", required: true },
    {
      name: "password",
      label: "Password",
      required: true,
      inputType: "password",
    },
    {
      name: "passwordConfirm",
      label: "Confirm Password",
      required: true,
      inputType: "password",
    },
  ],
};
