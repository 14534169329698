import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { getTeam } from "./ducks/actions";
import { dismissMessage } from "../Common/ducks/actions";

import { showMessages } from "../Common/components/Message";
import ViewTeam from "./components/ViewTeam";
import UserProfile from "../Account/components/UserProfile";
import InviteTeamMember from "./components/InviteTeamMember";
import ProfileDeactivate from "../Account/components/ProfileDeactivate";

/**
 * The wrapper for the Team page. Controls which component to display based on state.
 *
 * _The Route that generates the UserProfile for viewing might be extracted to App.js in the future since it
 * will likely be responsible for rendering UserProfile anytime it's required -- like from the Wiki._
 *
 * */

class Team extends Component {
  static propTypes = {
    /** The current user from Redux. */
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      deactivating: false,
      loading: true,
    };
  }

  componentDidMount() {
    const { getTeam, user } = this.props;
    getTeam(user);
    this.setState({ loading: false });
  }

  render() {
    const { deactivating, loading } = this.state;
    const { team, messages, dismissMessage } = this.props;

    return (
      <main id="mainContentContainer">
        <div id="mainContentWindowContainer">
          {showMessages(messages, dismissMessage)}
          <div id="mainContentWindowWrapper">
            <Route
              exact
              path="/team"
              render={() => (
                <ViewTeam
                  team={team}
                  deactivating={deactivating}
                  handleView={this.viewTeamMemberProfile}
                  loading={loading}
                />
              )}
            />
            <Route
              path="/team/deactivate"
              render={({ location: { state } }) => (
                <ProfileDeactivate
                  user={this.props.user}
                  // handleDeactivate={this.props.deactivate}
                  {...state}
                />
              )}
            />
            <Route
              path="/team/invite"
              render={(props) => <InviteTeamMember {...props} />}
            />
            <Route
              path="/team/user/:userId"
              render={({ location: { state } }) => <UserProfile {...state} />}
            />
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  team: state.team.team,
  user: state.account.user,
});

export default connect(mapStateToProps, {
  dismissMessage,
  getTeam,
})(Team);
