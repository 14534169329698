import React from "react";
import { gql } from "apollo-boost";
import { textToUrl } from "../helpers/urls";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ProgramLink = styled.p`
  color: #000000;
  display: inline;
  font-style: normal;
  font-size: inherit;
  a {
    text-decoration: none;
    color: #00a3d9;
    font-style: normal;
    font-size: inherit;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const BreadcrumbsBlock = styled.div`
    padding-bottom: 10px;
    margin-top: -25px;
`;

const Pipe = styled.span`
    font-style: bold;
    padding: 0px 6px;
    color: rgba(0,0,0,0.6);
    transform: translateY(1px);
    display: inline-block;
`;

const BreadcrumbsQuery = gql`
query SearchStructure($programId: ID!) {
    program(uuid: $programId) {
        name
        uuid
        utilities {
            name 
            uuid 
            state {
                uuid
            }
        }
        technologyTypes {
            value
            uuid
        }
    } 
}`

const Breadcrumb = (props) => {
    const { utility, program, technologyType, rebate } = props;

    var mainLink = (<ProgramLink>
        <Link className="breadcrumb-link" to={"/"}>{window.location.hostname}</Link>
    </ProgramLink>);

    var utilityLink;
    if (utility && utility.name && utility.uuid) {
        let textName = textToUrl(utility.name);
        let url = `/search/articles/${utility.uuid}/utility/${textName}`;
        utilityLink = (<ProgramLink>
            <Link className="breadcrumb-link" to={url}>{utility.name}</Link>
        </ProgramLink>);
    }

    var programLink;
    if (program && program.name && program.uuid) {
        let textName = textToUrl(program.name);
        let url = `/search/articles/${program.uuid}/program/${textName}`;
        programLink = (<ProgramLink>
            <Pipe className="breadcrumb-slashes">{" > "}</Pipe>
            <Link className="breadcrumb-link" to={url}>{program.name}</Link>
        </ProgramLink>);
    }

    var technologyLink;
    if (technologyType && technologyType.value && technologyType.uuid) {
        let textName = textToUrl(technologyType.value);
        let programName = textToUrl(program.name);
        let url = `/search/rebates/${program.uuid}/${technologyType.uuid}/${programName}/${textName}`;
        technologyLink = (<ProgramLink>
            <Pipe className="breadcrumb-slashes">{" > "}</Pipe>
            <Link className="breadcrumb-link" to={url}>{technologyType.value}</Link>
        </ProgramLink>);
    }

    var rebateLink;
    if (rebate && rebate.name && rebate.uuid) {
        let textName = textToUrl(program.name);
        let url = `/search/rebate/${rebate.uuid}/${textName}`;
        rebateLink = (<ProgramLink>
            <Pipe className="breadcrumb-slashes">{" > "}</Pipe>
            <Link className="breadcrumb-link" to={url}>{rebate.name}</Link>
        </ProgramLink>);
    }

    return (<BreadcrumbsBlock>
        {utilityLink}
        {programLink}
        {technologyLink}
        {rebateLink}
    </BreadcrumbsBlock>);
};

export { BreadcrumbsQuery, Breadcrumb };