import React, {useState} from "react";
import {User, UserType, UUID} from "../../../types";
import {Provider as SpectrumProvider} from "@react-spectrum/provider";
import {
    ActionButton,
    AlertDialog,
    defaultTheme,
    DialogContainer,
    Flex,
    Heading,
    Link,
    ProgressCircle,
    Radio,
    RadioGroup,
    TextField,
    View
} from "@adobe/react-spectrum";
import {Label} from "@react-spectrum/label"
import Helmet from "react-helmet";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessages} from "../../RequestEstimateForm/RequestEstimateForm";
// @ts-ignore
import MaskedField from 'react-masked-field';
import "./phone_input.css"
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {captureMessage} from "@sentry/react";
import {Link as RouterLink} from 'react-router-dom';

interface UpdateUserParams {
    id: UUID,
    params: {
        firstName: string,
        lastName: string,
        phone: string,
        phoneExtension: string,
        userType: "INDIVIDUAL" | "BUSINESS",
        companyName: string | null,
    }
}

interface UpdateUserResponseOK {
    status: "OK",
}

interface UpdateUserResponseError {
    status: "ERROR",
    reason: string,
}

type UpdateUserResponsePayload =
    | UpdateUserResponseOK
    | UpdateUserResponseError

interface UpdateUserResponse {
    payload: UpdateUserResponsePayload
}

const UPDATE_USER_QUERY = gql`
    mutation UpdateUser($id: String!, $params: UpdateUserParams) {
        payload: updateUser(id: $id, params: $params) {
            ... on UpdateUserResponseError {
                reason
                status
            }
            ... on UpdateUserResponseOK {
                status
            }
        }
    }
`;

interface FormValues {
    firstName: string,
    lastName: string,
    phone: string,
    phoneExtension: string,
    userType: UserType,
    company: string | null,
}

export interface UserProfileProps {
    user: User
}

const genericErrorMessage = "Sorry, there was an error. Please try again later.";

function UserProfile({user}: UserProfileProps) {
    const [errorMessage, setErrorMessage] = useState(genericErrorMessage);
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const {control, errors, formState, getValues, trigger, watch} = useForm<FormValues>({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            phoneExtension: user.phone_extension,
            userType: user.user_type,
            company: user.company_name || "",
        },
        mode: "onChange",
    });

    const watchUserType = watch('userType');
    const [updateUser] = useMutation<UpdateUserResponse, UpdateUserParams>(UPDATE_USER_QUERY);

    const onSubmit = async () => {
        setHasBeenSubmitted(true);
        const isValid = await trigger();
        if (!isValid) {
            return;
        }
        setIsSubmitting(true);
        try {
            const data = getValues();
            const params: UpdateUserParams["params"] = {
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                phoneExtension: data.phoneExtension,
                userType: data.userType === 1 ? "INDIVIDUAL" : "BUSINESS",
                companyName: data.userType === 1 ? null : data.company,
            }
            const response = await updateUser({
                variables: {
                    id: user.uuid,
                    params,
                },
            })
            setIsSubmitting(false);
            if (response.errors && response.errors.length > 0) {
                console.error(errors);
                captureMessage(JSON.stringify(response.errors));
                setErrorMessage(genericErrorMessage);
                setIsErrorDialogOpen(true);
            }

            if (response.data?.payload.status === "ERROR") {
                setErrorMessage(response.data.payload.reason);
                setIsErrorDialogOpen(true);
            } else {
                setIsSuccessDialogOpen(true);
            }
        } catch (e) {
            setErrorMessage(genericErrorMessage);
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    // @ts-ignore
    return (
        <SpectrumProvider
            theme={defaultTheme}
            colorScheme="light"
            UNSAFE_style={{
                backgroundColor: "white",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}>
            <Helmet>
                <meta name="description" content="Request incentive estimate for your project."/>
                <title>Profile Settings | Rebate Bus</title>
            </Helmet>
            <h1>Profile Settings</h1>
            <form style={{marginBottom: "30px"}}>
                <Flex direction="column" minWidth="200px" maxWidth="400px" gap="size-100">
                    <Controller
                        control={control}
                        name='firstName'
                        rules={{
                            required: "First name is required",
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="First name"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={100}
                            />
                        )}
                    />
                    {
                        (!formState.touched.firstName && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="firstName"/>
                        )
                    }
                    <Controller
                        control={control}
                        name='lastName'
                        rules={{
                            required: "Last name is required",
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                label="Last name"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={100}
                            />
                        )}
                    />
                    {
                        (!formState.touched.lastName && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="lastName"/>
                        )
                    }

                    <View>
                        <Label>Phone number</Label>
                        <Controller
                            control={control}
                            name="phone"
                            rules={{
                                validate: {
                                    required: (value) => {
                                        if (value === "") {
                                            return "Phone number is required";
                                        }

                                        return true;
                                    },
                                    phoneNumber: (value) => {
                                        const re = /^\(\d{3}\) \d{3}-\d{4}$/;
                                        const isValidEmail = re.test(value);

                                        if (!isValidEmail) {
                                            return "Phone number must be valid"
                                        }

                                        return true;
                                    },
                                },
                            }}
                            render={({value, onBlur, onChange}) => (
                                <MaskedField
                                    value={value}
                                    onBlur={onBlur}
                                    className="phone_input"
                                    name="Phone number"
                                    mask="(999) 999-9999"
                                    onChange={({target: {value}}) => {
                                        onChange(value)
                                    }}
                                />
                            )}
                        />
                    </View>
                    {
                        (!formState.touched.phone && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="phone"/>
                        )
                    }

                    <Controller
                        control={control}
                        name='phoneExtension'
                        render={({onChange, ...props}) => (
                            <TextField
                                {...props}
                                onChange={(value) => {
                                    value = value.trim();

                                    if (value === "") {
                                        return onChange(value);
                                    }

                                    const currentValue = getValues().phoneExtension;

                                    if (!(/^\d+$/.test(value))) {
                                        return onChange(currentValue);
                                    }

                                    const newExtension = parseInt(value);

                                    if (newExtension < 0) {
                                        return onChange(currentValue);
                                    }

                                    return onChange(value);
                                }}
                                label="Phone extension"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={10}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="userType"
                        render={({value, onChange}) => (
                            <RadioGroup
                                label="Account type"
                                value={value.toString()}
                                onChange={(value) => onChange(parseInt(value))}
                            >
                                <Radio value={UserType.Individual.toString()}>Individual</Radio>
                                <Radio value={UserType.Business.toString()}>Business</Radio>
                            </RadioGroup>
                        )}
                    />

                    <Controller
                        control={control}
                        name="company"
                        rules={{
                            validate: {
                                requiredIfBusiness: (value) => {
                                    if (getValues().userType === UserType.Business && value === "") {
                                        return "Company name is required.";
                                    }

                                    return true;
                                },
                            },
                        }}
                        render={(props) => (
                            <TextField
                                {...props}
                                isHidden={watchUserType !== UserType.Business}
                                label="Company Name"
                                UNSAFE_style={{width: "100%"}}
                                maxLength={100}
                            />
                        )}
                    />
                    {
                        (!formState.touched.company && !hasBeenSubmitted) ? <></> : (
                            <ErrorMessages errors={errors} name="company"/>
                        )
                    }


                    {
                        isSubmitting ? (
                            <ProgressCircle aria-label="Loading…" isIndeterminate/>
                        ) : (
                            <Flex alignItems="center" marginTop="size-300" justifyContent="center" wrap>
                                <ActionButton
                                    onPress={() => onSubmit()}
                                    width="11em"
                                >Update Profile</ActionButton>
                                <Link margin="1em">
                                    <RouterLink to="/profile/password">Change password</RouterLink>
                                </Link>
                            </Flex>
                        )
                    }
                    <DialogContainer onDismiss={() => {
                        setIsErrorDialogOpen(false);
                    }}>
                        {isErrorDialogOpen && (
                            <AlertDialog
                                title="Error"
                                variant="error"
                                primaryActionLabel="OK">
                                {errorMessage}
                            </AlertDialog>
                        )}
                    </DialogContainer>
                    <DialogContainer onDismiss={() => {
                        window.location.reload();
                    }}>
                        {isSuccessDialogOpen && (
                            <AlertDialog
                                title="Success"
                                variant="confirmation"
                                primaryActionLabel="OK">
                                Your profile has been successfully updated.
                            </AlertDialog>
                        )}
                    </DialogContainer>
                </Flex>
            </form>

        </SpectrumProvider>
    );
}

export default UserProfile;
