import React, { Component } from "react";
import Section, {HomeSection} from "../pageComponents/section";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import ImageLoader from "../../../Common/helpers/imageLoader";

import Announcement, {
  announcements
} from "../../../Dashboard/components/Announcement";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  h1 {
    margin: 0;
  }
  @media (max-width: 540px) {
    h1 {
      font-size: 1.8em;
    }
  }
  div.top-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 2em;
    span {
      font-size: 1.4em;
      font-weight: 200;
    }
  }

  div.image-container {
    width: 100%;
    margin-bottom: 5em;
  }

  div.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.fixed-width {
      width: 23em;

      @media all and (max-width: 768px) {
        width: 100%;
      }
    }

    &.single-button {
      width: auto;
      justify-content: center;
    }
  }
`;

const WixRedirect = props => {

  return (
    <Container>
      <Helmet>
        <meta name="description" content="Rebate Bus Home page" />
        <title>Rebate Bus | Redirect</title>
        <meta http-equiv="refresh" content="0; URL=https://home.rebatebus.com/" />
      </Helmet>
    </Container>
  );
};

export default WixRedirect;
