import {
  CREATE_USER,
  COMPANY_RESULT,
  TEMP_COMPANY,
  VERIFY_V1_USER,
  UPDATE_V1_PASSWORD,
  GET_ACCOUNT_TYPES,
  CONFIRM_EMAIL,
  SIGNUP_COMPLETE,
} from "./types";

const initialState = {
  tempCompany: {},
  companyResult: {},
  user: {},
  signupComplete: null,
  existingV1Account: null,
  plans: [],
  isEmailConfirmed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        plans: action.plans,
      };
    case COMPANY_RESULT:
      return {
        ...state,
        companyResult: {
          ...state.companyResult,
          ...action.companyResult,
        },
      };
    case TEMP_COMPANY:
      return {
        ...state,
        tempCompany: action.company,
      };

    case CREATE_USER:
      return { ...state, signupComplete: true };
    case VERIFY_V1_USER:
      if (action.uuid) {
        return {
          ...state,
          existingV1Account: action.uuid,
        };
      } else {
        return {
          ...state,
          existingV1Account: "unable to reset",
        };
      }
    case UPDATE_V1_PASSWORD:
      return {
        ...state,
        existingV1Account: null,
        signupComplete: true,
      };
    case CONFIRM_EMAIL:
      return {
        ...state,
        isEmailConfirmed: true,
        user: action.user,
      };
    case SIGNUP_COMPLETE:
      return {
        ...state,
        signupComplete: true,
      };
    default:
      return state;
  }
};
