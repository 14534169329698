import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { errorMessage } from "../../Common/ducks/actions";

import styled from "styled-components";
import { PrimaryButton } from "../../Common/components/Button";

/**
 * Renders the component where a user needs to change their password, whether by choice, new account setup, or
 * forgotten passwords
 */

const ChangePassword = ({ handleSubmit, errorMessage, redirect }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = ({ target: { name, value } }) => {
    if (name === "password") {
      setPassword(value);
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const verifyPassword = event => {
    if (password === confirmPassword) {
      handleSubmit(event, password);
    } else {
      errorMessage("Passwords do not match.");
    }
  };

  return (
    <StyledChangePassword>
      {redirect ? <Redirect to="/profile" /> : null }
      <h1>Change Password</h1>
      <div id="changePasswordWrapper" boxShadow="none">
        <form onSubmit={event => verifyPassword(event)}>
          <label htmlFor="password">New Password</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={handleChange}
            required
          />
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={handleChange}
            required
          />

          <PrimaryButton>Change Password</PrimaryButton>
        </form>
      </div>
    </StyledChangePassword>
  );
};

export default connect(null, { errorMessage })(ChangePassword);

ChangePassword.propTypes = {
  /** Function to handle changine of password. */
  handleSubmit: PropTypes.func.isRequired
};

const StyledChangePassword = styled.div`
  max-width: 400px;
  input {
    margin: 0.5em 0 2em;
  }
`;
