import React from "react";
import PropTypes from "prop-types";

import parse from "html-react-parser";

import "../css/Story.css";

/**
 * Displays the stories from the Dashboard. `title` and `storyText` are passed from React Router's `location.state` in
 * Dashboard. `location.state` is destructured in DashboardCard so that only the two props are passed into Story.
 */

const Story = ({ title, storyText }) => {
  return (
    <div id="singleStoryWrapper">
      <h1>{title}</h1>
      <div id="singleStoryText">{parse(storyText)}</div>
    </div>
  );
};

export default Story;

Story.propTypes = {
  /** The title for the Story. */
  title: PropTypes.string.isRequired,
  /** The raw HTML for the story. It is parsed from string into HTML using `html-react-parser`. */
  storyText: PropTypes.string.isRequired
};
