import {Flex, View} from "@adobe/react-spectrum";
import React from "react";
import CompanyRebateApplicationForm from "./CompanyRebateApplicationForm";
import {User} from "../../types";
import AdminRebateApplicationForm from "./AdminRebateApplicationForm";

interface AdminAddProjectViewProps {
    user: User
}

function AdminAddProjectView({}: AdminAddProjectViewProps) {
    return (
        <>
            <View width="100%" overflow="hidden auto" UNSAFE_style={{height: "calc(100vh - 11em)"}}>
                <Flex width="100%" justifyContent="center">
                    <Flex  width="400px" maxWidth="400px">
                        <View flex={1} paddingY="size-200">
                            <Flex>
                                <AdminRebateApplicationForm/>
                            </Flex>
                        </View>
                    </Flex>
                </Flex>
            </View>
        </>
    )
}

export default AdminAddProjectView;
