import {SelectOption, User} from "../../types";
import {
    RebateApplication,
    RebateApplicationCompany,
    RebateApplicationFulfillmentManager,
    RebateApplicationStage,
    RebateApplicationStageDisplayNames,
    stageOptions
} from "./types";
import React, {useEffect, useMemo, useReducer, useRef, useState} from "react";
import {FormGroup, HasChildren} from "../RebateSearch/RebateSearch";
import {LIMIT_20} from "./constants";
import {useApolloClient, useLazyQuery, useQuery} from "@apollo/react-hooks";
import {
    AssignedFulfillmentManagersResponse, DOWNLOAD_REPORT, DownloadReportParams,
    GET_ASSIGNED_FULFILLMENT_MANAGERS_BY_USER_ID,
    GET_REBATE_APPLICATIONS,
    GET_REBATE_APPLICATIONS_COUNT,
    GetAssignedFulfillmentManagersByUserIdParams,
    GetRebateApplicationsCountParams,
    GetRebateApplicationsParams,
    RebateApplicationsCountResponse,
    RebateApplicationsResponse
} from "./queries";
import {captureMessage} from "@sentry/react";
import {of, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ActionButton, Flex, ProgressCircle} from "@adobe/react-spectrum";
import {Body} from "./Body";
import PaginationPanel from "../Common/components/PaginationPanel";
import {Column} from "react-table";
import {Table} from "./Table";
import {TableLink, TableSelfLink} from "./TableLink";
import {TabOption, ToolBar} from "./Toolbar";
import Select from "react-select";
import {formatUSD} from "../Common/helpers/formateUSD";
import Download from "@spectrum-icons/workflow/Download";
import {useMediaQuery} from "react-responsive";

interface MainProps extends HasChildren {
    isNotDesktop: boolean,
}

const MainContainer = ({children, isNotDesktop}: MainProps) => {
    return (
        <main style={{
            display: "flex",
            height: isNotDesktop ? "auto" : "100%",
            // 15em left hand menu width
            minWidth: "calc(100% - 15em)",
            flexDirection: isNotDesktop ? "column-reverse" : "row",
            marginTop: isNotDesktop ? "1em" : "0"
        }}>{children}</main>
    )
}

export interface ManagementProjectListProps {
    user: User,
}

interface State {
    selectedStages: Array<RebateApplicationStage>,
    applicationCount: number,
    page: number,
    companies: Array<RebateApplicationCompany>
    selectedCompanies: Array<RebateApplicationCompany>
    managers: Array<RebateApplicationFulfillmentManager>
    selectedManagers: Array<RebateApplicationFulfillmentManager>
}

const initialState: State = {
    selectedStages: [],
    applicationCount: 0,
    page: 1,
    companies: [],
    selectedCompanies: [],
    managers: [],
    selectedManagers: [],
};

enum ActionType {
    SET_SELECTED_STAGES,
    SET_APPLICATION_COUNT,
    SET_PAGE,
    SET_COMPANIES,
    SET_SELECTED_COMPANIES,
    SET_MANAGERS,
    SET_SELECTED_MANAGERS,
}

interface SET_STAGES_ACTION {
    type: ActionType.SET_SELECTED_STAGES
    payload: {
        stages: State["selectedStages"]
    }
}

interface SET_APPLICATION_COUNT_ACTION {
    type: ActionType.SET_APPLICATION_COUNT
    payload: {
        applicationCount: State["applicationCount"]
    }
}

interface SET_PAGE_ACTION {
    type: ActionType.SET_PAGE
    payload: {
        page: State["page"]
    }
}

interface SET_COMPANIES_ACTION {
    type: ActionType.SET_COMPANIES
    payload: {
        companies: State["companies"]
    }
}

interface SET_SELECTED_COMPANIES_ACTION {
    type: ActionType.SET_SELECTED_COMPANIES
    payload: {
        companies: State["companies"]
    }
}

interface SET_MANAGERS_ACTION {
    type: ActionType.SET_MANAGERS
    payload: {
        managers: State["managers"]
    }
}

interface SET_SELECTED_MANAGERS_ACTION {
    type: ActionType.SET_SELECTED_MANAGERS
    payload: {
        managers: State["selectedManagers"]
    }
}

type Action =
    | SET_STAGES_ACTION
    | SET_APPLICATION_COUNT_ACTION
    | SET_PAGE_ACTION
    | SET_COMPANIES_ACTION
    | SET_SELECTED_COMPANIES_ACTION
    | SET_SELECTED_MANAGERS_ACTION
    | SET_MANAGERS_ACTION

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SET_SELECTED_STAGES:
            return {
                ...state,
                selectedStages: action.payload.stages,
            }
        case ActionType.SET_APPLICATION_COUNT:
            return {
                ...state,
                applicationCount: action.payload.applicationCount,
            }
        case ActionType.SET_PAGE:
            return {
                ...state,
                page: action.payload.page,
            }
        case ActionType.SET_COMPANIES:
            return {
                ...state,
                companies: action.payload.companies,
            }
        case ActionType.SET_SELECTED_COMPANIES:
            return {
                ...state,
                selectedCompanies: action.payload.companies,
            }
        case ActionType.SET_MANAGERS:
            return {
                ...state,
                managers: action.payload.managers,
            }
        case ActionType.SET_SELECTED_MANAGERS:
            return {
                ...state,
                selectedManagers: action.payload.managers,
            }
    }
}

const getGetApplicationsCountVariables = (state: State): GetRebateApplicationsCountParams => {
    return {
        where: {
            userIds: state.selectedCompanies.map(({id}) => id),
            stages: state.selectedStages,
            fulfillmentManagerIds: state.selectedManagers.map(({id}) => id),
        },
    }
}

const getGetApplicationsVariables = (state: State): GetRebateApplicationsParams => {
    return {
        where: getGetApplicationsCountVariables(state).where,
        limit: LIMIT_20,
        offset: LIMIT_20 * (state.page - 1),
    }
}

function CompanyProjectList({user}: ManagementProjectListProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const getApplicationsSubject = useRef(new Subject<GetRebateApplicationsParams>());
    const getApplicationsCountSubject = useRef(new Subject<GetRebateApplicationsCountParams>());
    const [selectedToolbarTab, setSelectedToolbarTab] = useState<TabOption | null>(TabOption.Filters);
    const onSetSelectedToolbarTab = (option: TabOption | null) => {
        if (selectedToolbarTab === null || option !== selectedToolbarTab) {
            setSelectedToolbarTab(option);
        } else {
            setSelectedToolbarTab(null);
        }
    }
    const filterSubject = useRef(new Subject<State>());
    const [isGettingReport, setIsGettingReport] = useState(false);
    const apolloClient = useApolloClient();
    const isNotDesktop = useMediaQuery({ maxWidth: 1024 });

    const userId = user.uuid as string | undefined | null;

    const [
        getApplications,
        {error: getApplicationsError, data: getApplicationsData, loading: isGettingApplications}
    ] = useLazyQuery<RebateApplicationsResponse, GetRebateApplicationsParams>(GET_REBATE_APPLICATIONS, {
        fetchPolicy: "cache-and-network",
        onError: (error) => {
            console.error(error);
            captureMessage(JSON.stringify(error));
        }
    });

    const [
        getApplicationsCount,
        {error: getApplicationsCountError, loading: isGettingApplicationsCount}
    ] = useLazyQuery<RebateApplicationsCountResponse, GetRebateApplicationsCountParams>(GET_REBATE_APPLICATIONS_COUNT, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data.response && Number.isSafeInteger(data.response.totalCount)) {
                dispatch({
                    type: ActionType.SET_APPLICATION_COUNT,
                    payload: {applicationCount: data.response.totalCount}
                });
                const maxPage = Math.ceil((state.applicationCount || 1) / LIMIT_20);
                if (state.page > maxPage) {
                    dispatch({
                        type: ActionType.SET_PAGE,
                        payload: {page: 1}
                    });
                }
            }
        },
        onError: (error) => {
            console.error(error);
            captureMessage(JSON.stringify(error));
        }
    });

    useEffect(() => {
        if (userId) {
            dispatch({
                type: ActionType.SET_SELECTED_COMPANIES,
                payload: {
                    companies: [
                        {
                            id: userId,
                            name: "",
                        }
                    ]
                }
            });
        }
    }, [userId])

    useEffect(() => {
        const subscription = getApplicationsSubject.current
            .pipe(
                switchMap((x) => of(x))
            )
            .subscribe((variables) => {
                if (!variables || !variables.where) {
                    return;
                }
                if (variables.where && variables.where.userIds && variables.where.userIds.length > 0) {
                    getApplications({variables});
                }
            });

        return () => subscription.unsubscribe();
    }, [getApplicationsSubject, getApplications]);

    useEffect(() => {
        const subscription = getApplicationsCountSubject.current
            .pipe(
                switchMap((x) => of(x))
            )
            .subscribe((variables) => {
                if (!variables) {
                    return;
                }
                if (variables.where && variables.where.userIds && variables.where.userIds.length > 0) {
                    getApplicationsCount({
                        variables
                    });
                }
            });

        return () => subscription.unsubscribe();
    }, [getApplicationsCountSubject, getApplicationsCount]);

    useEffect(() => {
        getApplicationsSubject.current.next(getGetApplicationsVariables(state));

        if (state.page === 1) {
            getApplicationsCountSubject.current.next(getGetApplicationsCountVariables(state));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, getApplicationsSubject, getApplicationsCountSubject]);

    useEffect(() => {
        const subscription = filterSubject.current.subscribe((state) => {
            if (state.page > 1) {
                dispatch({
                    type: ActionType.SET_PAGE,
                    payload: {
                        page: 1
                    }
                });
                getApplicationsSubject.current.next(getGetApplicationsVariables(state));
            } else {
                getApplicationsSubject.current.next(getGetApplicationsVariables(state));
                getApplicationsCountSubject.current.next(getGetApplicationsCountVariables(state));
            }
        });

        return () => subscription.unsubscribe();
    }, [filterSubject, getApplicationsSubject, getApplicationsCountSubject]);

    // /services/rebates/manage/:applicationId

    useEffect(() => {
        filterSubject.current.next(state);
    }, [
        filterSubject,
        state.selectedCompanies,
        state.selectedStages,
        state.selectedManagers,
    ]);

    const columns = useMemo<Array<Column<RebateApplication>>>(() => [
        {
            Header: "Project ID",
            accessor: "applicationId",
            minWidth: 150,
            maxWidth: 150,
            Cell: ({row: {original}}) => {
                return (
                    <TableSelfLink
                        href={`/services/rebates/manage/${original.id}`}>
                        {original.applicationId}
                    </TableSelfLink>
                )
            },
        },
        {
            Header: "Customer",
            accessor: "customer",
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: "Project name",
            accessor: "projectName",
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: "Stage",
            accessor: "stage",
            minWidth: 150,
            maxWidth: 150,
            Cell: ({value: stage}) => {
                return <div>{RebateApplicationStageDisplayNames.get(stage)}</div>
            },
        },
        // {
        //     Header: "Country",
        //     accessor: "country",
        //     minWidth: 150,
        //     maxWidth: 150,
        //     Cell: ({value: country}) => {
        //         return <div>{country.name}</div>
        //     },
        // },
        {
            Header: "City",
            accessor: "city",
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: "State",
            accessor: "usState",
            minWidth: 150,
            maxWidth: 150,
            Cell: ({row: {original}}) => {
                if (original.country.name === "United States") {
                    return (
                        <TableLink
                            href={`${window.location.origin}/search/articles/${original.usState?.id}/state/${original.usState?.name?.toLowerCase()}`}>
                            {original.usState?.name}
                        </TableLink>
                    )
                } else {
                    return <div>{original.canadianArea?.name}</div>
                }
            },
        },
        {
            Header: "Utility",
            accessor: "utility",
            minWidth: 250,
            maxWidth: 250,
            Cell: ({value: utility}) => {
                if (utility && utility.id && utility.name) {
                    return (
                        <TableLink
                            href={`${window.location.origin}/search/articles/${utility.id}/utility/${encodeURIComponent(utility.name.toLowerCase())}`}>
                            {utility.name}
                        </TableLink>
                    );
                }

                return "";
            },
        },

        // {
        //     Header: "Zip",
        //     accessor: "zipCode",
        //     minWidth: 150,
        //     maxWidth: 150,
        // },
        // {
        //     Header: "Budget year",
        //     accessor: "budgetYear",
        //     minWidth: 150,
        //     maxWidth: 150,
        // },
        {
            Header: "Technology",
            accessor: "technologyType",
            minWidth: 150,
            maxWidth: 150,
            Cell: ({value: technologyType}) => {
                return <div>{technologyType?.name || ""}</div>
            },
        },
        // {
        //     Header: "Fulfillment charge",
        //     accessor: "fulfillmentCharge",
        //     minWidth: 150,
        //     maxWidth: 150,
        // },
        {
            Header: "Project cost",
            accessor: "projectCost",
            minWidth: 150,
            maxWidth: 150,
            Cell: (({value}) => {
                return value === null ? "" : formatUSD(value);
            }),
        },
        // {
        //     Header: "Project deadline",
        //     accessor: "projectDeadline",
        //     minWidth: 150,
        //     maxWidth: 150,
        //     Cell: (({value}) => {
        //         return value === null ? "" : new Date(value).toLocaleDateString();
        //     }),
        // },
        {
            Header: "Estimated incentive",
            accessor: "rebateEstimate",
            minWidth: 150,
            maxWidth: 150,
            Cell: (({value}) => {
                return value === null ? "" : formatUSD(value);
            }),
        },
        // {
        //     Header: "Approved incentive amount",
        //     accessor: "incentiveAmount",
        //     minWidth: 210,
        //     maxWidth: 210,
        //     Cell: (({value}) => {
        //         return value === null ? "" : formatUSD(value);
        //     }),
        // },
        {
            Header: "Rebate management fee",
            accessor: "managementFee",
            minWidth: 180,
            maxWidth: 180,
            Cell: (({value}) => {
                return value === null ? "" : formatUSD(value);
            }),
        },
        {
            Header: "Manager",
            accessor: "fulfillmentManager",
            minWidth: 150,
            maxWidth: 150,
            Cell: ({value: manager}) => {
                return <div>{manager?.name}</div>
            },
        },
    ], []);

    const {
        loading: isGettingManagers,
        error: getManagersError
    } = useQuery<AssignedFulfillmentManagersResponse, GetAssignedFulfillmentManagersByUserIdParams>(GET_ASSIGNED_FULFILLMENT_MANAGERS_BY_USER_ID, {
        variables: {
            userId: userId || "",
        },
        skip: !userId,
        onCompleted: (data) => {
            if (data && data.response) {
                dispatch({
                    type: ActionType.SET_MANAGERS,
                    payload: {managers: data.response.managers},
                });
            }
        },
        onError: (error) => {
            captureMessage(error.message);
        },
    })

    const selectedStages: Array<SelectOption> = state.selectedStages.map((stage) => ({
        value: stage,
        label: RebateApplicationStageDisplayNames.get(stage) || "",
    }));

    const managers: Array<SelectOption> = state.managers.map((manager) => ({
        value: manager.id,
        label: manager.name,
    }));

    const selectedManagers: Array<SelectOption> = state.selectedManagers.map((managers) => ({
        value: managers.id,
        label: managers.name,
    }));

    if (!userId) {
        return <ProgressCircle aria-label="Loading…" isIndeterminate/>
    }

    function exportData() {
        setIsGettingReport(true);
        apolloClient.query<{ response: { base64data: string } }, DownloadReportParams>({
            query: DOWNLOAD_REPORT,
            fetchPolicy: "network-only",
            variables: {
                where: getGetApplicationsVariables(state).where,
                format: {
                    columns: [
                        "applicationId",
                        "customer",
                        "projectName",
                        "stage",
                        "country.name",
                        "usState.name",
                        "canadianArea.name",
                        "city",
                        "zipCode",
                        "budgetYear",
                        "technologyType.name",
                        "utility.name",
                        "projectCost",
                        "rebateEstimate",
                        "preApprovedRebateAmount",
                        "finalRebateAmount",
                        "rebateAmountPaid",
                        "managementFee",
                        "projectDeadline",
                        "notes",
                        "fulfillmentManager.name",
                        "createdAt",
                        "updatedAt",
                    ],
                },
            }
        }).then(({data: {response}}) => {
            const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const linkSource = `data:${contentType};base64,${response.base64data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `report.xlsx`;
            downloadLink.click();
        }).catch((e) => {
            console.error(e);
            alert("Sorry, there was an error");
        }).finally(() => {
            setIsGettingReport(false);
        });
    }

    return (
        <MainContainer isNotDesktop={isNotDesktop}>
            {
                (getApplicationsCountError || getApplicationsError)
                    ? <p>There was an error getting projects.</p>
                    : (
                        <Body isToolbarCollapsed={selectedToolbarTab === null}>
                            <Flex gap="size-200" alignItems="center" marginBottom="size-200" wrap>
                                {
                                    (!(isGettingApplications || isGettingApplicationsCount) && state.applicationCount === 0)
                                        ? <div>No projects yet</div>
                                        : <></>
                                }
                                {
                                    (!(isGettingApplications || isGettingApplicationsCount) && state.applicationCount > 0)
                                        ? <div>{state.applicationCount} projects</div>
                                        : <></>
                                }
                                {
                                    (isGettingApplications || isGettingApplicationsCount)
                                        ? <ProgressCircle aria-label="Loading…" isIndeterminate/>
                                        : <></>
                                }
                                <Flex marginStart="auto" alignItems="center" gap="size-200" wrap>
                                    <PaginationPanel page={state.page}
                                                     maxPage={Math.ceil((state.applicationCount || 1) / LIMIT_20)}
                                                     onChange={(page) => {
                                                         dispatch({
                                                             type: ActionType.SET_PAGE,
                                                             payload: {page},
                                                         })
                                                     }}/>
                                    <ActionButton onPress={() => exportData()} UNSAFE_style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        display: "flex",
                                        gap: 10
                                    }}>
                                        <Download UNSAFE_style={{ height: "70%" }} /> Export (XLSX)
                                    </ActionButton>
                                </Flex>
                            </Flex>
                            <Table
                                columns={columns}
                                // @ts-ignore
                                data={getApplicationsData?.response.applications || []}
                                isNotDesktop={isNotDesktop}
                            />
                        </Body>
                    )
            }
            <ToolBar selectedOption={selectedToolbarTab}
                     collapse={() => setSelectedToolbarTab(null)}
                     onTabChange={(option) => isNotDesktop ? onSetSelectedToolbarTab(option) : setSelectedToolbarTab(option)}
                     isNotDesktop={isNotDesktop}>
                {
                    selectedToolbarTab === TabOption.Filters
                        ? (
                            <>
                                <FormGroup label="Stages">
                                    <Select
                                        isMulti
                                        value={selectedStages}
                                        isClearable
                                        options={stageOptions}
                                        placeholder=""
                                        onChange={(options) => {
                                            dispatch({
                                                type: ActionType.SET_SELECTED_STAGES,
                                                payload: {
                                                    stages: ((options as Array<SelectOption>) || []).map(({value}) => value as RebateApplicationStage)
                                                }
                                            });
                                        }}
                                    />
                                </FormGroup>

                                {
                                    getManagersError
                                        ? <div>Sorry, there was an error getting managers.</div>
                                        : (
                                            <FormGroup label="Managers">
                                                <Select
                                                    isMulti
                                                    value={selectedManagers}
                                                    isClearable
                                                    options={managers}
                                                    placeholder=""
                                                    isLoading={isGettingManagers}
                                                    onChange={(options) => {
                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_MANAGERS,
                                                            payload: {
                                                                managers: ((options as Array<SelectOption>) || []).map(({
                                                                                                                            value,
                                                                                                                            label
                                                                                                                        }) => ({
                                                                    id: value,
                                                                    name: label,
                                                                }))
                                                            }
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        )
                                }
                            </>
                        )
                        : <></>
                }
            </ToolBar>
        </MainContainer>
    )
}

export default CompanyProjectList;