// User signup
export const CREATE_USER = "CREATE_USER";
export const VERIFY_V1_USER = "VERIFY_V1_USER";
export const UPDATE_V1_PASSWORD = "UPDATE_V1_PASSWORD";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const SIGNUP_COMPLETE = "SIGNUP_COMPLETE";

// Company signup
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
export const TEMP_COMPANY = "TEMP_COMPANY";
export const COMPANY_RESULT = "COMPANY_RESULT";
