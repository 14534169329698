import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import Checkmark from '@spectrum-icons/workflow/Checkmark';

import { Section, LogoWrapper } from "./StyledPageComponents";
import {
  PageHeader,
  ReportsSection,
  ContactsSection,
  LinksSection,
  HtmlSection,
} from "./PageComponents";
import Relationship from "../Relationship";
import HelpfulHints from "../../../Common/components/HelpfulHints";

import alphabetize from "../../helpers/alphabetize";

const Utility = ({ utility, type, url, permissions, showHelp }) => {
  const [showHints, setShowHints] = useState(true);
  const [metaDescription, setMetaDescription] = useState(`We’ve compiled energy rates, utility info and rebate information from over 2500 other utility companies. Search for rebates by product and save thousands of dollars on your next project. We do custom estimates and full service rebate fulfillment services.`);

  useEffect(() => {
      setMetaDescription(`Looking for ${utility.name} information? We’ve compiled energy rates, utility info and rebate information from ${utility.name} and over 2500 other utility companies. Search for rebates by product and save thousands of dollars on your next project. We do custom estimates and full service rebate fulfillment services.`)
  }, [utility])

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDescription}
        />
        <title>{`${utility.name} energy, utility and rebate information | Commercial Rebates`}</title>
      </Helmet>
      <PageHeader
        name={utility.name + " Utility and Rebate Information"}
        type={type}
        url={url}
        permissions={permissions}
      />
      {showHints && showHelp && (
        <HelpfulHints
          title="Find a Rebate Program"
          handleDismiss={() => setShowHints(false)}
        >
          <p>
            Utilities offer rebates through Rebate Programs. Some utilities
            offer different programs for different customer types, others simply
            offer one general program. Check out the programs offered by this
            utility to see available rebates!
          </p>
        </HelpfulHints>
      )}
      <Section>
        <h2>{`${utility.name} Overview`}</h2>
        {utility.logoUrl && utility.logoUrl !== "null" && (
          <LogoWrapper>
            <img src={utility.logoUrl} alt="Logo" />
          </LogoWrapper>
        )}
        {utility.description && (
          <Section subsection>
            <h2>{`About ${utility.name}`}</h2>
            <HtmlSection longText={utility.description} />
          </Section>
        )}

        {/* {utility.utilityOwnershipType &&
          utility.utilityOwnershipType !== "Unknown" && (
            <Section subsection>
              <h2>Ownership Type</h2>
              <p>{utility.utilityOwnershipType.value}</p>
            </Section>
          )} */}
          {utility.programTypes && utility.programTypes.length > 0 && (
              <Section subsection>
                  <h2>Program Types</h2>
                  {
                      utility.programTypes.map(({name, displayName}) => <div key={name} style={{display: "flex", alignItems: "center", gap: "5px"}}><div style={{color: "#16a888"}}><Checkmark/></div><p style={{margin: 0}}>{displayName}</p></div>)
                  }
              </Section>
          )}
        {utility.address && (
          <Section subsection>
            <p>{utility.address}</p>
          </Section>
        )}

        <LinksSection
          title="Utility Websites"
          type="link"
          links={utility.websites}
        />
        <LinksSection
          title="Social Media Links"
          links={utility.socialMediaLinks}
        />
      </Section>

      <ContactsSection contacts={utility.contacts} />
      <ReportsSection reports={utility.reports} />

      <Section>
        <h2>{`Other ${utility.name} info`}</h2>
        <p>
          The diagram below shows the relationship between {utility.name} and
          its parent {utility.state.name} and child programs.
        </p>
        <Relationship type="State/Territory" {...utility.state} topLevel>
          <Relationship type="Utility" {...utility}>
            {utility.programs && utility.programs.length > 0 && (
              <Relationship
                type="Program"
                relations={alphabetize(utility.programs, "name")}
                childRelation
              />
            )}
            {utility.zipCodes && utility.zipCodes.length > 0 && (
              <Relationship
                type="Zip Code"
                title="Zip Code (Service Territory)"
                relations={alphabetize(utility.zipCodes, "value")}
                childRelation
              />
            )}
          </Relationship>
        </Relationship>
      </Section>
    </>
  );
};

export default Utility;
