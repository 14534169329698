import {gql} from "apollo-boost";

export const GET_FORM_OPTIONS = gql`
    query {
        states {
            value: uuid
            label: name
        }
    }
`;

export const GET_UTILITIES_COUNT = gql`
    query GetUtilitiesCount($stateId: String, $name: String) {
        getUtilitiesCount(stateId: $stateId, name: $name) {
            count
        }
    }
`;

export const GET_UTILITIES = gql`
    query GetUtilites($name: String!, $offset: Int!, $limit: Int!, $stateId: ID) {
        utilities: getUtilities(limit: $limit, offset: $offset, stateId: $stateId, name: $name) {
            value: uuid
            label: name
        }
    }
`;

export const GET_ESTIMATOR_VALUES = gql`
    query GetEstimatorValues($utilityId: ID!) {
        estimatorValues(utilityId: $utilityId) {
            __typename
            ... on CannotCalculateOnUtilityLevel {
                utility {
                    id
                }
                reason
            }
            ... on EstimatorResultWithPrograms {
                utility {
                    id
                }
                programs {
                    __typename
                    ... on EstimatorData {
                        program {
                            value: id
                            label: name
                            projectCap {
                                value
                                type
                            }
                        }
                        kw
                        kwh
                    }
                    ... on CannotCalculateOnProgramLevel {
                        program {
                            value: id
                            label: name
                            projectCap {
                                value
                                type
                            }
                        }
                        reason
                    }
                }
            }
        }
    }
`;

export const SUBMIT_SIMPLE_REQUEST = gql`
    mutation CreateSimpleEstimatorRequest($requestType: SimpleEstimatorRequestType!, $userData: UserData!) {
        data: createSimpleEstimatorRequest(
            requestType: $requestType,
            userData: $userData
        ) {
            __typename
            ... on CreateEstimatorRequestResponseOK {
                status
            }
            ... on CreateEstimatorRequestResponseError {
                status
                reason
            }
        }
    }
`;

export const SUBMIT_PROCESS_REBATE_REQUEST = gql`
    mutation createProcessRebateEstimatorRequest($errors: [EstimatorDataErrorInput!]!, $estimates: [EstimatorDataWithEstimateInput!]!, $userData: UserDataWithSelectedProrgam!) {
        data: createProcessRebateEstimatorRequest(
            errors: $errors
            estimates: $estimates
            userData: $userData
        ) {
            __typename
            ... on CreateEstimatorRequestResponseOK {
                status
            }
            ... on CreateEstimatorRequestResponseError {
                status
                reason
            }
        }
    }
`;
