// https://stackoverflow.com/questions/14550526/regex-for-both-integer-and-float
export const moneyRE = /^(\d+(\.\d{2})?)$/;
export const CANADIAN_POSTAL_CODE_RE = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
export const US_POSTAL_CODE_RE = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

// em
export const leftHandMenuWidth = 10.5;
export const toolBarPanelWidth = 20;
export const tabPanelWidth = 2;
export const siteHeaderWidth = 3;


export const LIMIT_20 = 20;
export const LIMIT_100 = 100;

export const fulfillmentManagers = [
    {
        id: "a39f1fe8-57dd-11ea-8b5f-06319dbc535a",
        email: "adam@rebatebus.com",
        name: "Adam Lee",
    },
    {
        id: "35715d27-4f4e-11ea-8b5f-06319dbc535a",
        email: "austin@rebatebus.com",
        name: "Austin Blotch"
    },
    {
        id: "c7fb8ae2-5735-11ea-8b5f-06319dbc535a",
        email: "joel@rebatebus.com",
        name: "Joel Sandersen",
    },
    {
        id: "53f54460-54ea-11ea-8b5f-06319dbc535a",
        email: "mark@rebatebus.com",
        name: "Mark Trotter",
    },
];

export const admins = [
    {
        id: "4158762b-aa6c-11ea-926d-0628d70f81c2",
        email: "ayunoshev@lincolnlabs.co",
        name: "Alexey Yunoshev"
    },
    {
        id: "50478d06-b7bb-11ea-926d-0628d70f81c2",
        email: "pbacho@lincolnlabs.co",
        name: "Polina Bacho",
    },
];

export const usersAllowedToManageApplications = [
    ...fulfillmentManagers,
    ...admins,
];

export const usersAllowedToManageApplicationsEmails = new Set(usersAllowedToManageApplications.map(({email}) => email));

export enum CountryUUID {
    Canada = "bc1bfdd7-f859-440b-acba-68697ee04eb4",
    US = "aa5099b1-22af-11ea-8b5f-06319dbc535a",
}

export const countries = [
    {
        id: CountryUUID.Canada,
        name: "Canada",
    },
    {
        id: CountryUUID.US,
        name: "US",
    }
];