import {Flex, View} from "@adobe/react-spectrum";
import React from "react";
import CompanyRebateApplicationForm from "./CompanyRebateApplicationForm";
import {User} from "../../types";

interface CompanyAddProjectViewProps {
    user: User,
    isMobile: boolean
}

function CompanyAddProjectView({user, isMobile}: CompanyAddProjectViewProps) {
    return (
        <>
            <View width="100%" overflow={isMobile ? "visible" : "hidden auto"} UNSAFE_style={{height: isMobile ? "auto" : "calc(100vh - 11em)", paddingBottom: isMobile ? "5em" : "0"}}>
                <Flex width="100%" justifyContent="start">
                    <Flex width="100%" maxWidth="400px">
                        <View flex={1} paddingY="size-200">
                            <Flex>
                                <CompanyRebateApplicationForm user={user}/>
                            </Flex>
                        </View>
                    </Flex>
                </Flex>
            </View>
        </>
    )
}

export default CompanyAddProjectView;
