import {
  LOAD_REBATES,
  LOAD_PRODUCTS,
  FILTER_REBATES,
  SORT_REBATES,
  FILTER_BY_TECH_TYPE,
  DELETE_REBATE,
  REBATE_ADDED,
  ADD_PRODUCT_ID,
  ADD_PRODUCT_MODELNUMBER,
  CLEAR_PRODUCT,
  LOADING,
  CLEAR_FILTERED,
} from "./types";

const initialState = {
  rebatesByProgram: {},
  rebatesByProduct: {},
  filteredRebates: null,
  techType: "",
  manufacturers: [],
  product: { uuid: null, modelNumber: null },
  loading: true,
};

// kludge
function dedupeByUuid(items) {
  const uuids = new Set();
  const result = [];
  for (const item of items) {
    if (!uuids.has(item.uuid)) {
      uuids.add(item.uuid);
      result.push(item);
    }
  }
  return result;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REBATES: {
      const { rebatesByProgram, rebatesByProduct } = state;
      if (action.rebates.__typename === "RebatesByProgram") {
        if (
          rebatesByProgram.program &&
          rebatesByProgram.program === action.uuid
        ) {
          const newRebates = dedupeByUuid([
            ...action.rebates.rebates,
            ...rebatesByProgram.rebates,
          ]);
          return {
            ...state,
            rebatesByProgram: {
              ...rebatesByProgram,
              rebates: newRebates,
            },
          };
        } else {
          return {
            ...state,
            rebatesByProgram: {
              program: action.uuid,
              rebates: action.rebates.rebates,
              totalCount: action.rebates.totalCount,
            },
          };
        }
      } else {
        if (
          rebatesByProduct.product &&
          rebatesByProduct.product === action.uuid
        ) {
          const newRebates = dedupeByUuid([
            ...action.rebates.rebates,
            ...rebatesByProduct.rebates,
          ]);
          return {
            ...state,
            rebatesByProduct: {
              ...rebatesByProduct,
              rebates: newRebates,
            },
          };
        } else {
          return {
            ...state,
            rebatesByProduct: {
              product: action.uuid,
              rebates: action.rebates.rebates,
              totalCount: action.rebates.totalCount,
            },
          };
        }
      }
    }
    case FILTER_BY_TECH_TYPE:
      if (state.rebatesByProduct || state.rebatesByProgram) {
        return {
          ...state,
          filteredRebates: state.rebatesByProduct.rebates
            ? state.rebatesByProduct.rebates.filter(
                (rebate) => rebate.technologyType.uuid === action.techTypeId
              )
            : state.rebatesByProgram.rebates
            ? state.rebatesByProgram.rebates.filter(
                (rebate) => rebate.technologyType.uuid === action.techTypeId
              )
            : [],
          techType: action.techTypeId,
        };
      }
      break;
    case SORT_REBATES:
      let sortedRebates = [...state.filteredRebates];

      switch (action.sortBy) {
        case "az":
          sortedRebates.sort((a, b) => {
            if (a.rebateName.name < b.rebateName.name) {
              return -1;
            }
            if (a.rebateName.name > b.rebateName.name) {
              return 1;
            }
            return 0;
          });
          break;
        case "za":
          sortedRebates.sort((a, b) => {
            if (a.rebateName.name > b.rebateName.name) {
              return -1;
            }
            if (a.rebateName.name < b.rebateName.name) {
              return 1;
            }
            return 0;
          });
          break;
        default:
          break;
      }

      return {
        ...state,

        filteredRebates: sortedRebates,
      };

    case FILTER_REBATES:
      const {
        isQPLs,
        isProjectType,
        isRebateType,
        categories,
        filters,
      } = action.filter;

      const rebateSource = state.rebatesByProgram.rebates
        ? state.rebatesByProgram.rebates
        : state.rebatesByProduct.rebates;
      if (
        !Array.isArray(rebateSource) ||
        (rebateSource && rebateSource.length < 1)
      ) {
        return { ...state };
      }
      let filteredRebates = rebateSource.filter(
        (rebate) => rebate.technologyType.uuid === state.techType
      );

      if (action.filter === "reset") {
        return {
          ...state,
          filteredRebates,
        };
      }

      if (isQPLs) {
        filteredRebates = filteredRebates.filter((rebate) =>
          rebate.QPLs.some((QPL) => QPL.value === filters.QPLs)
        );
      }

      if (isProjectType) {
        filteredRebates = filteredRebates.filter((rebate) =>
          rebate.projectTypes.some((type) => type.value === filters.projectType)
        );
      }

      if (isRebateType) {
        filteredRebates = filteredRebates.filter((rebate) =>
          filters.rebateType === "Prescriptive"
            ? rebate.rebateType.value === "Prescriptive"
            : rebate.rebateType.value !== "Prescriptive"
        );
      }

      if (categories.length > 0) {
        filteredRebates = filteredRebates.filter((rebate) =>
          categories.some((category) => {
            if (category.indexOf("Exterior") >= 0) {
              const type = category.split(" ")[1];
              return rebate.rebateName.name.indexOf(`Outdoor ${type}`) > -1;
            }
            return rebate.rebateName.name.indexOf(category) > -1;
          })
        );
      }

      return {
        ...state,
        filteredRebates,
      };
    case DELETE_REBATE: {
      const rebateId = action.payload;
      const hasRebatesByProgram = "program" in state.rebatesByProgram;
      if (!hasRebatesByProgram) {
        return { ...state };
      }

      const newRebates = state.rebatesByProgram.rebates.filter(
        (rebate) => rebate.uuid !== rebateId
      );

      const newFilteredRebates = state.filteredRebates.filter(
        (rebate) => rebate.uuid !== rebateId
      );

      const newCount = state.rebatesByProgram.totalCount - 1;

      return {
        ...state,
        filteredRebates: newFilteredRebates,
        rebatesByProgram: {
          ...state.rebatesByProgram,
          rebates: newRebates,
          totalCount: newCount,
        },
      };
    }
    case REBATE_ADDED: {
      const hasRebatesByProgram = "program" in state.rebatesByProgram;
      if (!hasRebatesByProgram) {
        return { ...state };
      }

      const hasThisProgramRebatesInState =
        action.payload.programId === state.rebatesByProgram.program;
      let newRebates = hasThisProgramRebatesInState
        ? [...state.rebatesByProgram.rebates, action.payload]
        : state.rebatesByProgram.rebates;

      let newFilteredRebates = hasThisProgramRebatesInState
        ? [...state.filteredRebates, action.payload]
        : state.filteredRebates;

      const newCount = state.rebatesByProgram.totalCount + 1;

      return {
        ...state,
        filteredRebates: newFilteredRebates,
        rebatesByProgram: {
          ...state.rebatesByProgram,
          rebates: newRebates,
          totalCount: newCount,
        },
      };
    }
    case LOAD_PRODUCTS:
      return {
        ...state,
        manufacturers: action.manufacturers,
      };
    case ADD_PRODUCT_ID:
      return {
        ...state,
        product: { ...state.product, uuid: action.uuid },
      };
    case ADD_PRODUCT_MODELNUMBER:
      return {
        ...state,
        product: { ...state.product, modelNumber: action.modelNumber },
      };
    case CLEAR_PRODUCT:
      return {
        ...state,
        loading: true,
        filteredRebates: null,
        product: { uuid: null, modelNumber: null },
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CLEAR_FILTERED:
      return { ...state, filteredRebates: null };
    default:
      return state;
  }
};
