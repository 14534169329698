import React from "react";

import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10em;
  display: flex;
  justify-content: center;

  span {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 0.9em;
  }
`;

/**
 * The Loading component displays the spinner to indicate a loading screen.
 *
 * @param {bool} loading -- boolean that tells the spinner whether to display
 */
const Loading = ({ loading, message = null }) => (
  <Wrapper>
    {message ? <span>{message}</span> : null}
    <SyncLoader loading={loading} color={"#16a888"} />
  </Wrapper>
);

export default Loading;
