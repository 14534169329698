import apolloClient from "../../helpers/apolloClient";
import { gql } from "apollo-boost";

export const SponsoredOrQualifiedFilter = async rebateId => {
  const releventProducts = await apolloClient
    .query({
      query: gql`{
      manufacturersByRebate(rebateId: "${rebateId}", limit: 10){
        name
        amountPaid
        products{
          uuid
          modelNumber
          imageUrl
          newProductCategory{
            name
          }
        }
    }
  }`
    })
    .then(res => res.data.manufacturersByRebate);

  const paidManufacturers = releventProducts.filter(
    m => m.amountPaid > 0 && m.products.length > 0
  );
  paidManufacturers.sort((a, b) => b.amountPaid - a.amountPaid);

  const qualifiedProducts = releventProducts.filter(
    m => m.amountPaid <= 0 && m.products.length > 0
  );

  return [paidManufacturers, qualifiedProducts];
};
