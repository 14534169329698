import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/react-hooks';
import styled from "styled-components";
import Loading from "../../../Common/components/Loading";
import {
  PageHeader,
  LinksSection,
  RebateRequirements,
  HtmlSection,
} from "./PageComponents";
import {
  Section,
  SponsoredOrQualifiedProductsWrapper,
  BoldKeyValue,
} from "./StyledPageComponents";
import IconWithText from "../../../Common/components/IconWithText";
import Card from "../../../Common/components/Card";
import { SponsoredOrQualifiedProducts } from "../pageTypes/PageComponents";
import { SponsoredOrQualifiedFilter } from "../../helpers/rebatesAndProducts";
import { Helmet } from "react-helmet";
import { textToUrl } from "../../helpers/urls";
import { BreadcrumbsQuery, Breadcrumb } from '../breadcrumb';

const Rebate = ({ rebate, rebate: { program }, uuid, url, permissions }) => {
  const [sponsoredProducts, setSponsoredProducts] = useState();
  const [qualifiedProducts, setQualifiedProducts] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // executed when component mounted
    let isCancelled = false;

    async function fetchData() {
      try {
        const [sponsored, qualified] = await SponsoredOrQualifiedFilter(
          rebate.uuid
        );

        if (!isCancelled) {
          setSponsoredProducts(sponsored);
          setQualifiedProducts(qualified);
        }
      } catch (e) {
        if (!isCancelled) {
          console.log(e);
        }
      }
    }

    fetchData();
    setLoading(true);

    if (qualifiedProducts && qualifiedProducts.length >= 0) {
      setLoading(false);
    }

    return () => {
      // executed when unmount
      isCancelled = true;
    };
  }, [rebate, qualifiedProducts]);

  const { oldProductCategories, newProductCategories } = rebate;

  const showCategories =
    oldProductCategories.length > 0 ||
    (newProductCategories && newProductCategories.length > 0);

  const bothCategories =
    oldProductCategories.length > 0 &&
    newProductCategories &&
    newProductCategories.length > 0;

  const parentProgram = {
    name: program.name,
    url: `/search/articles/${program.uuid}/program/${textToUrl(program.name)}`,
  };
  let programId = program.uuid;

  const Breadcrumbs = useQuery(BreadcrumbsQuery, { variables: { programId } });

  let breadcrumbs;
  if (Breadcrumbs && Breadcrumbs.data && Breadcrumbs.data.program !== null) {
    let data = Breadcrumbs.data;
    let technologyType = data.program.technologyTypes.find((technology) => {
      return technology.uuid == rebate.technologyType.uuid;
    })

    if(!technologyType && sessionStorage.getItem("refresh-rebate") != "false") {
      sessionStorage.setItem('refresh-rebate', 'false');
      window.location.reload();
    } else {
      sessionStorage.removeItem("refresh-rebate");
    }

    breadcrumbs = (<Breadcrumb
      utility={data.program.utilities[0]}
      program={{ name: data.program.name, uuid: data.program.uuid }}
      technologyType={technologyType}
    />);
  }

  if (rebate.isHidden) {
    return (
      <>
        <Helmet>
          <meta name="description" content="Rebate Utility Programs" />
          <title>{`Rebate Bus | ${program.name} Rebate | ${rebate.rebateName.name}`}</title>
        </Helmet>
        {breadcrumbs}
        <PageHeader
          name={rebate.rebateName}
          type="Rebate"
          url={url}
          permissions={permissions}
          parentProgram={parentProgram}
          rebateId={rebate.uuid}
        />
        <p>This page is currently undergoing maintenance.</p>
      </>
    );
  }

  let updatedDate = parseInt(rebate.updated);
  let modifiedDate = new Date(updatedDate).toLocaleDateString() + " " + new Date(updatedDate).toLocaleTimeString();

  return (
    <>
      <Helmet>
        <meta name="description" content="Rebate Utility Programs" />
        <title>{`Rebate Bus | ${program.name} Rebate | ${rebate.rebateName.name}`}</title>
      </Helmet>
      {breadcrumbs}
      <PageHeader
        name={rebate.rebateName.name}
        type="Rebate"
        url={url}
        permissions={permissions}
        parentProgram={parentProgram}
        rebateId={rebate.uuid}
      />
      <Section>
        <h2>Rebate Info</h2>
        {permissions ? (<BoldKeyValue><span>Last Modified:</span> {modifiedDate}</BoldKeyValue>) : null}
        <Section subsection>
          <RebateValue>
            <h2>Rebate Value</h2>
            {rebate.code && (
              <>
                <p>Rebate Code</p>
                <span className="rebateCode">{rebate.code}</span>
              </>
            )}
            <p>{rebate.rebateType.value}</p>
            <IconWithText align="center">
              <img
                src="https://s3.amazonaws.com/v2.rebatebus/images/icons/rebateIcon.svg"
                alt="Rebate Price Tag"
              />
              <span>
                {rebate.rebateRates &&
                  rebate.rebateRates
                    .map((rate) => {
                      const number = Number.isInteger(rate.value)
                        ? rate.value
                        : rate.value.toFixed(2);

                      const rateAmount =
                        rate.rebateRateUnit.value.indexOf("Percentage") >= 0
                          ? `${number}%`
                          : `$${number}`;

                      return `${rateAmount} / ${rate.rebateRateUnit.value}`;
                    })
                    .join(" + ")}
                {rebate.cap && rebate.cap !== null && rebate.cap !== 0 ? (
                  <span>, rebate value capped at ${rebate.cap}</span>
                ) : null }
              </span>
            </IconWithText>
          </RebateValue>
          {rebate.minimumCustomerContribution && rebate.minimumCustomerContribution > 0 ? (
            <BoldKeyValue>
              <span>Minimum Customer Contribution: </span>$
              {rebate.minimumCustomerContribution}
            </BoldKeyValue>
          ) : null}
        </Section>
        {rebate.rebateRequirements && rebate.rebateRequirements.length > 0 ? (
          <Section subsection>
            <h2>Rebate Requirements</h2>
            <RebateRequirements
              rebateRequirements={rebate.rebateRequirements}
            />
          </Section>
        ) : null}
        <Section subsection>
          <h2>Required Product Qualification</h2>
          <span>{rebate.QPLs.map((qpl) => qpl.value).join(", ")}</span>
        </Section>
        <Section subsection>
          <h2>Project Type</h2>
          <span>
            {rebate.projectTypes.map((type) => type.value).join(", ")}
          </span>
        </Section>
        {showCategories && (
          <CategoriesCard
            bothCategories={bothCategories}
            existing={oldProductCategories.length > 0}
            new={newProductCategories && newProductCategories.length > 0}
          >
            <div className="wrapper">
              <div className="existing">
                <h3>Applicable Existing Categories</h3>
                {oldProductCategories.map((category) => (
                  <RebateIconWithText align="center" key={category.name}>
                    <img
                      src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/oldCategoryIcon.svg`}
                      alt="Existing Product Category"
                    />
                    <span>{category.name}</span>
                  </RebateIconWithText>
                ))}
              </div>
              <div className="new">
                <h3>Applicable New Categories</h3>
                {newProductCategories &&
                  newProductCategories.map((category) => (
                    <RebateIconWithText align="center" key={category.name}>
                      <img
                        src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/newCategoryIcon.svg`}
                        alt="New Product Category"
                      />
                      <span>{category.name}</span>
                    </RebateIconWithText>
                  ))}
              </div>
            </div>
          </CategoriesCard>
        )}
        {rebate.notes.length > 0 && rebate.notes !== "undefined" ? (
          <Section subsection>
            <h2>Notes</h2>
            <HtmlSection longText={rebate.notes} />
          </Section>
        ) : null}
      </Section>
      <Section>
        <h2>Application Info</h2>
        <LinksSection
          type="link"
          title="Application Links"
          links={rebate.program.applicationLinks}
        />
      </Section>
      <Section>
        {loading ? (
          <Loading message="Looking for qualified products!" />
        ) : qualifiedProducts && qualifiedProducts.length >= 1 ? (
          <>
            <Section>
              {sponsoredProducts && sponsoredProducts.length > 0 ? (
                <>
                  <h2>Sponsored Products</h2>
                  <span className="advertise">
                    Do you want our audience to buy from you? Learn more about
                    our Advertising Services or contact sales at
                    <a
                      href="mailto:sales@rebatebus.com?Subject=Advertising"
                      target="_top"
                    >
                      sales@rebatebus.com
                    </a>
                    .
                  </span>
                  {sponsoredProducts.map((product, i) => (
                    <SponsoredOrQualifiedProductsWrapper key={product.name}>
                      {product.products.length > 0 ? (
                        <>
                          <h3>{product.name} Products</h3>
                          <SponsoredOrQualifiedProducts
                            product={product.products}
                            key={i}
                            purchaseNow={true}
                          />
                        </>
                      ) : null}
                    </SponsoredOrQualifiedProductsWrapper>
                  ))}
                </>
              ) : null}
            </Section>
            <Section>
              {qualifiedProducts && qualifiedProducts.length > 0 ? (
                <>
                  <h2>Qualified Products</h2>
                  {qualifiedProducts.map((product, i) => (
                    <SponsoredOrQualifiedProductsWrapper key={product.name}>
                      {product.products.length > 0 ? (
                        <>
                          <h3>{product.name} Products</h3>
                          <SponsoredOrQualifiedProducts
                            product={product.products}
                          />
                        </>
                      ) : null}
                    </SponsoredOrQualifiedProductsWrapper>
                  ))}
                </>
              ) : null}
            </Section>
          </>
        ) : (
              <span>*This rebate has no applicable products.</span>
            )}
      </Section>
    </>
  );
};

export default Rebate;

const RebateValue = styled.div`
  p {
    margin: 0;
    font-weight: bold;
  }
  span.rebateCode {
    display: inline-block;
    margin-bottom: 0.5em;
  }
`;

const CategoriesCard = styled(Card)`
  margin-top: 2em;

  div.wrapper {
    display: flex;
    justify-content: space-between;
    background: ${(props) =>
    props.bothCategories
      ? "linear-gradient(#cacaca, #cacaca) no-repeat center/2px 100%"
      : "inherit"};

    @media all and (max-width: 768px) {
      flex-direction: column;
      background: inherit;
    }
  }

  div.existing {
    display: ${(props) => (props.existing ? "block" : "none")};
    flex: ${(props) => (props.bothCategories ? "0 0 45%" : "0 0 100%")};
    h3 {
      color: #dc009c;
    }
    @media all and (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
  div.new {
    display: ${(props) => (props.new ? "block" : "none")};
    flex: ${(props) => (props.bothCategories ? "0 0 45%" : "0 0 100%")};
    h3 {
      color: #16a888;
    }
  }
`;

const RebateIconWithText = styled(IconWithText)`
  margin-bottom: 0.75em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
