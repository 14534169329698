import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import DashboardCard from "./DashboardCard";
import { PreviewButton } from "../../Common/components/Button";
import StyledCardsContainer from "../../Common/components/layout/StyledCardsContainer";

/**
 * DashboardSection generates, yes, a section of the Dashboard. Currently the sections include Getting Started and Data
 * Highlights. A Blog section might also be added, though it would likely link to the external blog that is not an _official_
 * part of V2.
 */

class DashboardSection extends Component {
  static propTypes = {
    /** The section title. */
    title: PropTypes.string.isRequired,
    /** The description of the section for the Dashboard. */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    /** The array of articles to be generated. */
    articles: PropTypes.array.isRequired,
    /** The boolean that makes the Delete Story buttons appear. */
    deleting: PropTypes.bool.isRequired,
    /** Permissions from the current user object. */
    permissions: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      storiesToShow: 4
    };
  }

  showMoreArticles = () => {
    this.setState(prevState => ({
      storiesToShow: prevState.storiesToShow + 4
    }));
  };

  render() {
    const { title, children, articles, deleting, permissions } = this.props;
    const { storiesToShow } = this.state;

    const generateArticles = articles.map(article => {
      let { description } = article;
      const charLimit = 150;

      if (description.length > charLimit) {
        const nextWhiteSpace = description.substring(charLimit).indexOf(" ");
        description =
          description.substring(0, charLimit + nextWhiteSpace) + "...";
      }

      return (
        <DashboardCard
          key={article.id}
          {...article}
          description={description}
          deleting={deleting}
          permissions={permissions}
        />
      );
    });

    const articlesToShow = generateArticles.slice(0, storiesToShow);
    const showMoreArticles =
      articles.length > storiesToShow ? (
        <PreviewButton handleClick={this.showMoreArticles}>
          show more
        </PreviewButton>
      ) : (
        ""
      );

    return (
      <StyledDashboardSection>
        <h2>{title}</h2>
        <p>{children}</p>
        <StyledCardsContainer>
          {articlesToShow}
          {showMoreArticles}
        </StyledCardsContainer>
      </StyledDashboardSection>
    );
  }
}

export default DashboardSection;

export const StyledDashboardSection = styled.div`
  p {
    line-height: 1.15em;
  }
`;
