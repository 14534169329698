import React from "react";
import ChangePassword from "./components/ChangePassword";

/**
 * ForgotPassword uses the ChangePassword component. It will be reachable from an email that is generated whenever a user
 * goes through the Forgot Password steps. See [ChangePassword](/#changepassword) for example.
 *
 * _At this time, there are no props received by this component._
 */

const ForgotPassword = () => {
  return (
    <main id="mainContentContainer">
      <div id="mainContentWindowContainer">
        <div id="mainContentWindowWrapper">
          <ChangePassword />
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
