import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./App/Common/components/ScrollToTop";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { authenticateUser } from "./App/Account/ducks/actions";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "react-apollo";
import apolloClient from "./App/helpers/apolloClient";
import { SnackbarProvider } from 'notistack';
import MediaQuery from "react-responsive";
import styled, { ThemeProvider } from "styled-components";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import {
  ThemeProvider as MuiProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { ToastContainer } from 'react-toastify';

//  unprotected routes
import Marketing from "./App/Marketing/Marketing";
import Signup from "./App/Signup/Signup";
import Signin from "./App/Account/Signin";
import ForgotPassword from "./App/Account/ForgotPassword";
import Wiki from "./App/Wiki/Wiki";
import Support from "./App/Common/components/pages/Support";
import Authenticating from "./App/Common/components/pages/Authenticating";
import ErrorBoundary from "./App/Common/components/pages/ErrorBoundary";
import WrongTurn from "./App/Common/components/pages/WrongTurn";

// protected routes
import AuthRoute from "./App/Account/helpers/AuthRoute";
import Dashboard from "./App/Dashboard/Dashboard";
import Team from "./App/Team/Team";
import Training from "./App/Training/Training";
import Documentation from "./App/DevDocs/Documentation";
import Settings from "./App/Account/Settings";
import Profile from "./App/Account/Profile";
import Services from "./App/Services/index";

import TopMenu from "./App/Common/components/MenuTop.jsx";
import DesktopMenu from "./App/Common/components/MenuDesktop";
import MobileMenu from "./App/Common/components/MenuMobile";
import ReactGA from "react-ga";

/* Reset CSS - Do Not Remove */
import "./css/reset.css";

/* Master CSS - Do Not Remove */
import "./css/master.css";

/* Theme CSS - Changed by User Settings*/
import "./css/themes/light.css";

/* Base UI CSS - Do Not Remove */
import "./css/baseUI.css";
import RebateSearch from "./App/RebateSearch/RebateSearch";
import ProjectConsole from "./App/RebateApplicationConsole/ProjectConsole";
import UserProfileView from "./App/Users/UserProfileView";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",
});

// if sitemap needs to be updated
// export const history = createMemoryHistory();
export const history = createBrowserHistory();

const trackingId = "UA-77964209-1";

ReactGA.initialize(trackingId);

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const reload = () => window.location.reload();

class App extends Component {
  constructor(props) {
    super(props);
    const { settings } = props.account.user;

    this.state = {
      useDarkTheme: settings ? settings.isUsingDarkTheme : false,
      authed: "",
      hasError: false,
    };
  }

  componentDidMount() {
    const { token } = localStorage;
    if (token && typeof token !== "undefined") {
      this.props.authenticateUser(token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      account: { loggedIn, user },
    } = this.props;
    if (prevProps.account.loggedIn !== loggedIn) {
      this.setState({
        authed: loggedIn,
        useDarkTheme: user.settings ? user.settings.isUsingDarkTheme : false,
      });
    }
    if (prevProps.account.user.settings !== user.settings) {
      this.setState({
        useDarkTheme: user.settings ? user.settings.isUsingDarkTheme : false,
      });
    }
  }

  render() {
    const {
      account: { loggedIn, user },
      messages,
    } = this.props;

    const { authed, useDarkTheme } = this.state;

    const routes = unprotectedRoutes.map(({ path, exact, Component }, key) => (
      <Route
        key={key}
        exact={exact}
        path={path}
        render={(props) => <Component messages={messages} {...props} />}
      />
    ));

    const apiDocsPathname = process.env.PUBLIC_URL
      ? `${process.env.PUBLIC_URL}/api-docs`
      : "/api-docs";

    return (
      <Router history={history}>
        <ScrollToTop>
          <SnackbarProvider maxSnack={3}>
            <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
              <MuiProvider theme={theme}>
                <TopMenu isLoggedIn={loggedIn} user={user} history={history} />

                <MainAppContainer>
                  <ToastContainer/>
                  <MainWorkspaceContainer>
                    <MediaQuery minDeviceWidth={769}>
                      <DesktopMenu
                        isLoggedIn={loggedIn}
                        userPermissions={
                          user.permissions ? user.permissions : 0
                        }
                      />
                    </MediaQuery>
                    <Sentry.ErrorBoundary fallback={ErrorBoundary}>
                      <Switch>
                        {/* Authed Routes */}
                        <AuthRoute
                          authed={authed}
                          path="/dashboard"
                          user={user}
                          messages={messages}
                          component={Dashboard}
                        />

                        <AuthRoute
                          authed={authed}
                          path="/training"
                          user={user}
                          messages={messages}
                          component={Training}
                        />
                        <AuthRoute
                          authed={authed}
                          path="/settings"
                          user={user}
                          messages={messages}
                          component={Settings}
                        />
                        <AuthRoute
                          authed={
                            Object.keys(this.props.account.user).length > 1
                          }
                          path="/profile"
                          user={this.props.account.user}
                          history={history}
                          messages={messages}
                          component={Profile}
                        />
                        <AuthRoute
                            authed={authed}
                            path="/services/rebates"
                            user={this.props.account.user}
                            history={history}
                            messages={messages}
                            component={ProjectConsole}
                        />
                        <AuthRoute
                          path="/team"
                          authed={authed && user.permissions >= 2}
                          user={user}
                          messages={messages}
                          component={Team}
                        />
                        <AuthRoute
                          path="/documentation"
                          authed={authed && user.permissions >= 4}
                          messages={messages}
                          component={Documentation}
                        />
                        <Route path={apiDocsPathname} onEnter={reload} />
                        <AuthRoute
                            path="/search/rebates/lighting"
                            user={user}
                            authed={authed && user.permissions >= 1}
                            component={RebateSearch}
                        />
                        <AuthRoute
                            authed={authed}
                            path="/users/details/:userId"
                            messages={messages}
                            component={UserProfileView}
                        />
                        <Route
                            authed={authed}
                            path="/services"
                            user={user}
                            messages={messages}
                            component={Services}
                        />
                        <Route
                          path="/search"
                          render={(props) => (
                            <Wiki
                              messages={messages}
                              user={user}
                              authed={authed}
                              {...props}
                            />
                          )}
                        />
                        {routes}

                        <Route component={WrongTurn} />
                      </Switch>
                    </Sentry.ErrorBoundary>
                  </MainWorkspaceContainer>
                </MainAppContainer>

                <MediaQuery maxDeviceWidth={768}>
                  <MobileMenu
                    userPermissions={user.permissions ? user.permissions : 0}
                    isLoggedIn={loggedIn}
                    {...this.props}
                  />
                </MediaQuery>
              </MuiProvider>
            </ThemeProvider>
          </ApolloProvider>
          </SnackbarProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    ...state.messages,
  };
};

export default connect(mapStateToProps, { authenticateUser })(App);

const unprotectedRoutes = [
  {
    path: "/signup",
    auth: false,
    Component: Signup,
  },
  {
    path: "/signin",
    auth: false,
    history: true,
    Component: Signin,
  },
  {
    path: "/resetpassword",
    auth: false,
    Component: ForgotPassword,
  },
  { path: "/support", auth: false, Component: Support },
  { path: "/authenticating", auth: false, Component: Authenticating },
  { path: "/wrong-turn", auth: false, Component: WrongTurn },
  {
    path: "/",
    auth: false,
    Component: Marketing,
  },
];

const MainAppContainer = styled.div`
  height: calc(100% - 3em);
`;

const MainWorkspaceContainer = styled.div`
  display: flex;
  height: 100%;
`;

const theme = createMuiTheme({
  palette: {
    primary: { 500: "#16a888" },
  },
  typography: {
    fontFamily: "proxima-nova, sans-serif",
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: "#16a888 !important",
      },
    },
  },
});
