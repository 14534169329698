import { GET_STORY } from "./types";

import pressReleases from "../helpers/pressReleases";

const initialState = {
  pressReleases,
  story: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORY:
      const { title, date } = action;
      const story = state.pressReleases.filter(
        release => release.title === title && release.date === date
      )[0];
      return {
        ...state,
        story
      };

    default:
      return state;
  }
};
