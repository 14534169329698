import React from "react";
import {Provider as SpectrumProvider} from "@react-spectrum/provider";
import {defaultTheme, Flex, Heading, ProgressCircle, Text} from "@adobe/react-spectrum";
import {Label} from "@react-spectrum/label"
import Helmet from "react-helmet";
import {gql} from "apollo-boost";
import {useRouteMatch} from 'react-router-dom';
import {UUID} from "../../types";
import {useQuery} from "react-apollo";


const GET_USER_QUERY = gql`
    query GetUser($id: String!) {
        payload: usersV2(where: {userIds: [$id]}) {
            uuid
            firstName
            lastName
            email
            companyName
            phone
            phoneExtension
            userType
        }
    }
`;


interface PropertyParams {
    children: any
}

interface UserV2DTO {
    uuid: UUID,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    phoneExtension: string,
    companyName: string,
    userType: "INDIVIDUAL" | "BUSINESS",
}

function Property({children}: PropertyParams) {
    return (
        <Flex direction="column">
            {children}
        </Flex>
    )
}

function UserProfileView() {
    const routeMatch = useRouteMatch<{ userId: UUID }>('/users/details/:userId');

    const userId = routeMatch?.params.userId;
    const {data, loading, error} = useQuery<{ payload: Array<UserV2DTO> }, { id: UUID }>(GET_USER_QUERY, {
        variables: {
            id: userId!
        },
        skip: typeof userId !== "string"
    })

    if (error) {
        return "Sorry, there was an error";
    }

    const user = data?.payload[0];

    if (!loading && !user) {
        return <p>No user found</p>
    }

    // @ts-ignore
    return (
        <SpectrumProvider
            theme={defaultTheme}
            colorScheme="light"
            UNSAFE_style={{
                backgroundColor: "white",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
            <Helmet>
                <meta name="description" content="Profile View"/>
                <title>Profile View | Rebate Bus</title>
            </Helmet>
            <Heading marginTop="size-200">User profile</Heading>
            {
                loading
                    ? <ProgressCircle aria-label="Loading…" isIndeterminate/>
                    : (
                        <Flex direction="column" gap="size-200">
                            <Property>
                                <Label>First name</Label>
                                <Text>{user?.firstName}</Text>
                            </Property>

                            <Property>
                                <Label>Last name</Label>
                                <Text>{user?.lastName}</Text>
                            </Property>

                            <Property>
                                <Label>Email</Label>
                                <Text>{user?.email}</Text>
                            </Property>

                            <Property>
                                <Label>Phone</Label>
                                <Text>{user?.phone}{(user && user.phoneExtension) ? ` ext. ${user.phoneExtension}` : ''}</Text>
                            </Property>

                            <Property>
                                <Label>Type</Label>
                                <Text>{user?.userType === "INDIVIDUAL" ? "Individual" : "Company"}</Text>
                            </Property>

                            {
                                user?.userType === "BUSINESS"
                                    ? (
                                        <Property>
                                            <Label>Company name</Label>
                                            <Text>{user?.companyName}</Text>
                                        </Property>
                                    )
                                    : <></>
                            }
                        </Flex>
                    )
            }
        </SpectrumProvider>
    );
}

export default UserProfileView;
