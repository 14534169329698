import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Select as MuiSelect } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { InputWrapper } from "./StyledFormComponents";

const Select = props => {
  // makes the label move into the border like on MUI inputs
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const menuOptions = props.options.map(option => {
    if(option.isHidden != null) {
      if(!option.isHidden) {
        return (<MenuItem value={option.value} key={option.value}>
          {option.name}
        </MenuItem>);
      }
    } else {
      return (<MenuItem value={option.value} key={option.value}>
        {option.name}
      </MenuItem>);
    }
  });
  
  let styleClass = props.advancedStyles ? props.advancedStyles : null;
  return (
    <InputWrapper className={styleClass}>
      <FormControl fullWidth size="small">
        <InputLabel ref={inputLabel} id={`${props.id}-label`}>
          {props.title}
        </InputLabel>
        <MuiSelect
          required={props.required}
          id={props.id}
          name={props.id}
          labelId={`${props.id}-label`}
          labelWidth={labelWidth}
          // MUI throws warning if value !== one of the options so set to "" on intialization
          value={menuOptions.length <= 1 ? "" : props.value || ""}
          onChange={({ target }) => props.handleChange(props.id, target.value, props.isParent)}
        >
          {menuOptions}
        </MuiSelect>
      </FormControl>
    </InputWrapper>
  );
};

export default Select;

Select.propTypes = {
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** If the input is required. Originally took a string of "required". (Can't remember why I set it up like that...) */
  required: PropTypes.bool,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The value from state. */
  value: PropTypes.string,
  /** The function to update state. */
  handleChange: PropTypes.func.isRequired,
  /** The options for the select. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};
