import {
  LOAD_REBATES,
  LOAD_PRODUCTS,
  DELETE_REBATE,
  REBATE_ADDED,
  ADD_PRODUCT_ID,
  ADD_PRODUCT_MODELNUMBER,
  CLEAR_PRODUCT,
} from "./types";
import { gql } from "apollo-boost";
import Promise from "bluebird";
import { createMutations, updateMutations } from "../helpers/graphQL";
import sanitizeHtml from "../helpers/htmlSanitization";

import { showNewUpdatePage, deleteObject } from "./wikiActions";
import { successMessage, errorMessage } from "../../Common/ducks/actions";

import client from "../../helpers/apolloClient";

export const deleteRebateAction = (rebateId) => ({
  type: DELETE_REBATE,
  payload: rebateId,
});

export const rebateAdded = (rebate) => ({
  type: REBATE_ADDED,
  payload: rebate,
});

export const deleteRebate = (rebateId) => async (dispatch) => {
  await client.mutate({
    mutation: gql`
      mutation HideRebate($rebateId: String!) {
        hideRebate(rebateId: $rebateId) {
          rebateId
        }
      }
    `,
    variables: {
      rebateId,
    },
  });
  dispatch(deleteRebateAction(rebateId));
};

export const getRebates = (uuid, offset, limit = 50, product = null) => (
  dispatch
) => {
  client
    .query({
      query: !product
        ? gql`
      {
        rebatesByProgram(programId: "${uuid}", offset: ${offset}, limit: ${limit}){
          totalCount
          rebates {
            uuid
            notes
            cap
            code
            rebateName {name}
            technologyType {value uuid}
            projectTypes {value}
            rebateType {value}
            QPLs {value}
            rebateRates {
              value
              rebateRateUnit {value}
            }
            rebateRequirements {
              value
              uuid
              rebateRequirementType {
                value uuid
                productSpecType {value uuid}
              }
            }
            updated
          }
        }
      }
    `
        : gql`
    {
      rebatesByProduct(productId: "${uuid}", offset: ${offset}, limit: ${limit}) {
        totalCount
        rebates {
          uuid
          rebateName {name uuid}
          notes
          cap
          code
          minimumCustomerContribution
          rebateType {value uuid}
          QPLs {value uuid}
          technologyType {uuid}
          projectTypes {value uuid}
          rebateRates {
            value
            uuid
            rebateRateUnit {value uuid}
          }
          rebateRequirements {
            value
            uuid
            rebateRequirementType {
              value uuid
              productSpecType {value uuid}
            }
          }
          newProductCategories {name uuid}
          oldProductCategories {name uuid}
          program {
            name
            uuid
            applicationLinks {url linkText}
          }
          updated
        }
      }
    }
`,
      fetchPolicy: "network-only",
    })
    .then(({ data }) => {
      const result = data.rebatesByProgram
        ? data.rebatesByProgram
        : data.rebatesByProduct;
      if (product) {
        dispatch(addProductId(uuid));
      }
      return dispatch(loadRebates(uuid, result));
    })
    .catch((error) => console.log(error));
};

const loadRebates = (uuid, rebates) => ({
  type: LOAD_REBATES,
  uuid,
  rebates,
});

const addProductId = (uuid) => ({
  type: ADD_PRODUCT_ID,
  uuid,
});

export const addProductModelNumber = (modelNumber) => async (dispatch) => {
  return dispatch({ type: ADD_PRODUCT_MODELNUMBER, modelNumber });
};

export const clearProducts = () => async (dispatch) => {
  return dispatch({ type: CLEAR_PRODUCT });
};

export const addRebate = ({
  requirementsTable,
  newProductCategories,
  oldProductCategories,
  QPLs,
  projectTypes,
  ...rebate
}) => async (dispatch) => {
  const requirements = requirementsTable.filter((requirement) => {
    return (
      requirement.requirementType.active === true &&
      requirement.requirementValue !== ""
    );
  });

  const { prescriptiveRebateValue, calculatedRebateValue } = rebate;

  const [rebateTypeId, rebateType] = rebate.rebateTypeId.split(" ");
  rebate.rebateRates =
    rebateType === "Prescriptive"
      ? [
          {
            value: prescriptiveRebateValue[0].prescriptiveValue,
            rebateRateUnitId: prescriptiveRebateValue[0].prescriptiveUnit,
          },
        ]
      : calculatedRebateValue.map((rate) => ({
          value: rate.value,
          rebateRateUnitId: rate.unit,
        }));

  rebate.rebateType = rebateTypeId;

  rebate.QPLs = Object.keys(QPLs)
    .filter((key) => QPLs[key])
    .map((key) => ({ QPLId: key }));

  rebate.projectTypes = Object.keys(projectTypes)
    .filter((key) => projectTypes[key])
    .map((key) => ({ projectTypeId: key }));

  rebate.newProductCategories =
    newProductCategories.length > 0
      ? newProductCategories.map((category) => ({
          newProductCategoryId: category.id,
        }))
      : [];

  rebate.oldProductCategories =
    oldProductCategories.length > 0
      ? oldProductCategories.map((category) => ({
          oldProductCategoryId: category.id,
        }))
      : [];

  const newRequirementTypes = requirements.filter(
    (requirement) => requirement.requirementType.__isNew__
  );

  const allRequirements = requirements.filter(
    (requirement) => !requirement.requirementType.__isNew__
  );

  if (newRequirementTypes.length > 0) {
    await addRebateRequirementTypes(
      newRequirementTypes,
      rebate.technologyTypeId
    ).then((res) =>
      newRequirementTypes.forEach((type, index) =>
        allRequirements.push({
          ...type,
          requirementType: { value: res[index] },
        })
      )
    );
  }
  rebate.rebateRequirements = allRequirements.map(
    ({ requirementType, requirementOperator, requirementValue }) => {
      const [start, end] = requirementValue.split("-");
      const value = end ? `${start} ${end}` : `${start}`;
      return {
        value: `${requirementOperator} ${value}`,
        rebateRequirementTypeId: requirementType.uuid,
      };
    }
  );

  if (rebate.notes && rebate.notes.length > 0) {
    rebate.notes = sanitizeHtml(rebate.notes).replace(/"/g, "'");
  }

  const mutation = createMutations({
    pageType: "rebate",
    ...rebate,
  });

  return client
    .mutate({ mutation })
    .then(({ data }) => {
      dispatch(
        showNewUpdatePage({
          type: "rebate",
          uuid: data.createRebate.uuid,
          name: data.createRebate.rebateName.name,
        })
      );
      dispatch(rebateAdded(data.createRebate));
    })
    .then(() => dispatch(successMessage("Page saved!")))
    .catch((err) => {
      console.log(err);
      return dispatch(errorMessage("Sorry, there was an error."));
    });
};

export const updateRebate = (
  submittedRebate,
  updatedArrays,
  originalRebate
) => async (dispatch) => {
  submittedRebate.rebateType = submittedRebate.rebateTypeId.split(" ")[0];

  if (updatedArrays.requirementsTable) {
    submittedRebate.requirements = submittedRebate.requirementsTable.filter(
      (requirement) => {
        return (
          requirement.requirementType.active === true &&
          requirement.requirementValue !== ""
        );
      }
    );

    originalRebate.requirements = originalRebate.requirementsTable;
    updatedArrays.requirements = updatedArrays.requirementsTable;
    delete updatedArrays.requirementsTable;
  }

  const itemsToCheck = Object.keys(updatedArrays);

  const itemsToDelete = [];
  const itemsToAddUpdate = [];

  const newRequirementTypes =
    submittedRebate.requirements &&
    submittedRebate.requirements.filter(
      (requirement) => requirement.requirementType.__isNew__
    );

  const allRequirements =
    submittedRebate.requirement &&
    submittedRebate.requirements.filter(
      (requirement) => !requirement.requirementType.__isNew__
    );

  const newRequirements =
    allRequirements &&
    allRequirements.filter((potentialNew) => !potentialNew.uuid);

  if (newRequirementTypes && newRequirementTypes.length > 0) {
    await addRebateRequirementTypes(
      newRequirementTypes,
      submittedRebate.technologyTypeId
    ).then((res) =>
      newRequirementTypes.forEach((type, index) =>
        newRequirements.push({
          ...type,
          requirementType: { value: res[index] },
        })
      )
    );
  }

  submittedRebate.rebateRequirements =
    newRequirements &&
    newRequirements.map(
      ({ requirementType, requirementOperator, requirementValue }) => {
        const [start, end] = requirementValue.split("-");
        const value = end ? `${start} ${end}` : `${start}`;
        return {
          value: `${requirementOperator} ${value}`,
          rebateRequirementTypeId: requirementType.uuid,
        };
      }
    );

  itemsToCheck.forEach((item) => {
    switch (item) {
      case "QPLs":
      case "projectTypes":
        const changedItems = Object.keys(updatedArrays[item].changed);
        const idName = {
          QPLs: "QPLId",
          projectTypes: "projectTypeId",
        };

        const newItems = changedItems
          .filter((key) => updatedArrays[item].changed[key])
          .map((key) => ({ [idName[item]]: key }));

        changedItems
          .filter((key) => !updatedArrays[item].changed[key])
          .forEach((key) =>
            itemsToDelete.push({
              type: item,
              rebateId: submittedRebate.uuid,
              [item]: {
                [idName[item]]: key,
              },
            })
          );

        itemsToAddUpdate.push({
          type: item,
          rebateId: submittedRebate.uuid,
          [item]: newItems,
        });
        break;
      case "oldProductCategories":
      case "newProductCategories":
        const categoryId =
          item === "newProductCategories"
            ? "newProductCategoryId"
            : "oldProductCategoryId";

        const deletedCategories = {
          type: item,
          rebateId: submittedRebate.uuid,
          categories: originalRebate[item]
            .filter(
              (original) =>
                !submittedRebate[item].some(
                  (updated) => original.id === updated.id
                )
            )
            .map((category) => ({
              [categoryId]: category.id,
            })),
        };

        const newCategories = submittedRebate[item]
          .filter(
            (potentialNew) =>
              !originalRebate[item].some(
                (original) => original.id === potentialNew.id
              )
          )
          .map((item) => ({ [categoryId]: item.id }));

        itemsToDelete.push(deletedCategories);
        itemsToAddUpdate.push({
          type: item,
          rebateId: submittedRebate.uuid,
          categories: newCategories,
        });

        break;
      case "prescriptiveRebateValue":
        itemsToAddUpdate.push({
          type: "rebateRates",
          uuid: submittedRebate.prescriptiveRebateValue[0].uuid,
          rebateId: submittedRebate.uuid,
          value: submittedRebate.prescriptiveRebateValue[0].prescriptiveValue,
          unit: submittedRebate.prescriptiveRebateValue[0].prescriptiveUnit,
        });

        break;
      case "calculatedRebateValue":
        const newRates = submittedRebate.calculatedRebateValue.filter(
          (potentialNew) => !potentialNew.uuid
        );

        submittedRebate.rebateRates = newRates.map((rate) => ({
          value: rate.value,
          rebateRateUnitId: rate.unit,
        }));

        const updatedRates = submittedRebate.calculatedRebateValue.filter(
          (potentialUpdate) =>
            originalRebate.calculatedRebateValue.some(
              (original) =>
                original.uuid === potentialUpdate.uuid &&
                JSON.stringify(original) !== JSON.stringify(potentialUpdate)
            )
        );

        if (updatedRates.length > 0) {
          updatedRates.forEach(({ uuid, value, unit }) => {
            itemsToAddUpdate.push({
              type: "rebateRates",
              uuid,
              rebateId: submittedRebate.uuid,
              value,
              unit,
            });
          });
        }
        break;
      case "requirements":
        var updatedRequirements;
        const deletedRequirements = originalRebate.requirements.filter(
          (potentialDelete) =>
            !submittedRebate.requirements.some(
              (original) => original.uuid === potentialDelete.uuid
            )
        );
        if (originalRebate.requirements.length > 0) {
          updatedRequirements = submittedRebate.requirements
            .filter((potentialUpdate) =>
              originalRebate.requirements.some(
                (original) =>
                  original.uuid === potentialUpdate.uuid &&
                  JSON.stringify(original) !== JSON.stringify(potentialUpdate)
              )
            )
            .map(
              ({
                uuid,
                requirementType,
                requirementOperator,
                requirementValue,
              }) => {
                const [start, end] = requirementValue.split("-");
                const value = end ? `${start} ${end}` : `${start}`;
                return {
                  value: `${requirementOperator} ${value}`,
                  rebateRequirementTypeId: requirementType.uuid,
                  uuid,
                  rebateId: submittedRebate.uuid,
                };
              }
            );

          var newRequirements = submittedRebate.requirements.filter(
            (requirement) => {
              return !requirement.uuid;
            }
          );

          if (newRequirements && newRequirements.length > 0) {
            let addedRequirements = newRequirements.map(
              ({
                uuid,
                requirementType,
                requirementOperator,
                requirementValue,
              }) => {
                const [start, end] = requirementValue.split("-");
                const value = end ? `${start} ${end}` : `${start}`;
                return {
                  value: `${requirementOperator} ${value}`,
                  rebateRequirementTypeId: requirementType.uuid,
                  uuid,
                  rebateId: submittedRebate.uuid,
                };
              }
            );

            for (let i = 0; i < addedRequirements.length; i++) {
              updatedRequirements.push(addedRequirements[i]);
            }
          }
        } else {
          updatedRequirements = submittedRebate.requirements.map(
            ({
              uuid,
              requirementType,
              requirementOperator,
              requirementValue,
            }) => {
              const [start, end] = requirementValue.split("-");
              const value = end ? `${start} ${end}` : `${start}`;
              return {
                value: `${requirementOperator} ${value}`,
                rebateRequirementTypeId: requirementType.uuid,
                uuid,
                rebateId: submittedRebate.uuid,
              };
            }
          );
        }

        if (updatedRequirements.length > 0) {
          updatedRequirements.forEach((requirement) =>
            itemsToAddUpdate.push({
              type: "requirements",
              ...requirement,
            })
          );
        }

        if (deletedRequirements.length > 0) {
          deletedRequirements.forEach((requirement) =>
            itemsToDelete.push({
              type: "requirements",
              requirements: {
                uuid: requirement.uuid,
              },
            })
          );
        }

        break;
      default:
        break;
    }
  });

  const deleteItems = async () =>
    itemsToDelete.length > 0
      ? itemsToDelete.map((item) => dispatch(deleteObject(item)))
      : null;

  await deleteItems();

  const addItems = async () =>
    itemsToAddUpdate.length > 0
      ? itemsToAddUpdate.map((item) => dispatch(addToUpdateRebate(item)))
      : null;

  await addItems();

  const mutation = updateMutations({
    pageType: "rebate",
    ...submittedRebate,
  });

  return client
    .mutate({ mutation })
    .then(({ data }) =>
      dispatch(
        showNewUpdatePage({
          type: "rebate",
          uuid: data.updateRebate.uuid,
          name: data.updateRebate.rebateName.name,
        })
      )
    )
    .then(() => dispatch(successMessage("Page updated!")))
    .catch((err) => {
      console.log(err);
      return dispatch(errorMessage("Sorry, there was an error."));
    });
};

const addRebateRequirementTypes = (requirements, technologyTypeId) =>
  Promise.map(requirements, (requirement) => {
    const mutation = createMutations({
      pageType: "rebateRequirementType",
      productSpecTypeId: requirement.requirementUnit,
      technologyTypeId,
      value: requirement.requirementType.value,
    });
    return client
      .mutate({ mutation })
      .then((res) => res.data.addRebateRequirementType.uuid)
      .catch((err) => console.log(err));
  });

const addToUpdateRebate = (objectToAdd) => (dispatch) => {
  const mutation = updateMutations(objectToAdd);
  client.mutate({ mutation }).catch((err) => console.log(err));
};

export const getProducts = () => (dispatch) =>
  client
    .query({
      query: gql`
        {
          manufacturers {
            url
            name
            amountPaid
            products {
              uuid
              imageUrl
              modelNumber
              QPLIdentifier
              QPL {
                value
              }
              newProductCategory {
                uuid
                name
              }
              productSpecs {
                value
                productSpecType {
                  uuid
                  value
                }
              }
            }
          }
        }
      `,
    })
    .then((res) => dispatch(loadProducts(res.data.manufacturers)));

const loadProducts = (manufacturers) => ({
  type: LOAD_PRODUCTS,
  manufacturers,
});
