import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "styled-components";
import GrayBanner from "./GrayBanner.jsx";

const HelpfulHints = ({ title, children, handleDismiss }) => {
  return (
    <GrayBanner
      dismissable={true}
      handleDismiss={handleDismiss}
      imgUrl="https://s3.amazonaws.com/v2.rebatebus/images/decorative/helpfulHints.svg"
      imgAlt="Person looking at paper"
    >
      <Content>
        <div>
          <p className="hints">Helpful Hints</p>
          <h2>{title}</h2>
        </div>
        {children}
        <p className="footer">
          If you're not sure where to go, look for Helpful Hint windows like
          this. Helpful Hints can be turned off in your{" "}
          <Link to="/settings">user settings</Link> at any time.
        </p>
      </Content>
    </GrayBanner>
  );
};

export default HelpfulHints;

HelpfulHints.propTypes = {
  /** The Title for the hints section */
  title: PropTypes.string.isRequired,
  /** The text for the hint, usually in `<p>` tags */
  children: PropTypes.element.isRequired,
  /** THe function to dismiss the elemeent */
  handleDismiss: PropTypes.func.isRequired
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 2em;
  h2 {
    color: black;
  }
  p {
    line-height: 1.2em;
    &.hints {
      color: ${props => props.theme.rbGreen.regular};
      margin-bottom: 0;
      font-weight: 300;
    }
    &.footer {
      font-size: 0.8em;
      color: #686868;
      margin: 0;
      a {
        font-size: inherit;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
`;
