export default (array, searchValue) => {
  let sorted = array
    .map(option => {
      const label =
        option.__typename === "Utility"
          ? `${option.name}`
          : option.name || option.modelNumber || option.value;

      return {
        label,
        value: option.uuid,
        type: option.__typename === "ZipCode" ? "zip code" : option.__typename
      };
    })
    .sort((a, b) => {
      if (a && b && (a.label && b.label) && (typeof a.label === "string" && typeof b.label === "string")) {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        else return 0;
      } else {
        return 0;
      }
    });
  if (searchValue) {
    return sorted.sort((a, b) => {
      if (a.label.toLowerCase().startsWith(searchValue.toLowerCase())) return -1;
      if (b.label.toLowerCase().startsWith(searchValue.toLowerCase())) return 1;
      else return 0;
    });
  }
  return sorted;
};
