import axios from "axios";

import {
  CREATE_USER,
  VERIFY_V1_USER,
  UPDATE_V1_PASSWORD,
  CONFIRM_EMAIL,
  SIGNUP_COMPLETE,
} from "./types";
import { errorMessage, successMessage } from "../../Common/ducks/actions";
import { attemptSignin } from "../../Account/ducks/actions";

const url = "/api/v2";

const createUser = (user) => ({
  type: CREATE_USER,
  user,
});

export const submitUser = (userToAdd) => (dispatch) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    company,
    industry,
    annualRevenue,
    companyId,
    password,
    permissions,
  } = userToAdd;

  const submission = {
    user: {
      firstName,
      lastName,
      email,
      phone: phone ? phone : null,
      companyId,
    },
    password,
    permissions: permissions ? permissions : "Standard",
    dataForSendgrid: {
      name: `${firstName} ${lastName}`,
      email,
      company,
      industry,
      annualRevenue,
      phone,
    },
  };

  return axios
    .post(`${url}/user`, submission)
    .then(() => axios.post(`${url}/settings`, { email }))
    .then(() => dispatch(attemptSignin(email, password)))
    .then(() => dispatch(createUser()))
    .then(() => dispatch(successMessage("Account created.")))
    .catch((error) => {
      if (error.response) {
        return dispatch(errorMessage(error.response.data.message));
      }
      return dispatch(errorMessage(error.message));
    });
};

export const verifyV1User = (email) => (dispatch) => {
  return axios
    .post(`${url}/user/password/resettable`, { email })
    .then((res) => {
      if (res.data.resettable) {
        return dispatch(v1UserVerified(res.data.uuid));
      } else {
        return dispatch(v1UserVerified(false));
      }
    })
    .catch((error) => console.log(error));
};

const v1UserVerified = (uuid) => ({
  type: VERIFY_V1_USER,
  uuid,
});

export const updateV1Password = (user) => (dispatch) => {
  return axios
    .post(`${url}/user/password/reset`, user)
    .then(() => {
      dispatch(successMessage("Password successfully changed!"));
      return dispatch(v1PasswordUpdated());
    })
    .catch(() =>
      dispatch(errorMessage("Sorry, there was an error. Please try again."))
    );
};

const v1PasswordUpdated = () => ({
  type: UPDATE_V1_PASSWORD,
});

export const findUserToComplete = (email, companyId) => (dispatch) => {
  return axios
    .get(`${url}/user/completesignup`, { params: { email, companyId } })
    .then(({ data }) => {
      if (data.error) {
        dispatch(
          errorMessage(
            `No user found. If you believe this is an error, please have your company admin contact team@rebatebus.com.`
          )
        );
        return dispatch(confirmEmail({ user: null }));
      }

      return dispatch(confirmEmail(data));
    })
    .catch((err) => console.log(err));
};

const confirmEmail = (user) => ({
  type: CONFIRM_EMAIL,
  user,
});

export const completeUserSignup = (user) => (dispatch) =>
  axios
    .post(`${url}/user/completesignup`, user)
    .then(() => {
      dispatch(attemptSignin(user.email, user.password));
      dispatch(successMessage("Account created!"));
      dispatch(signupComplete());
    })
    .catch((err) => console.log(err));

const signupComplete = () => ({
  type: SIGNUP_COMPLETE,
});
