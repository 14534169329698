import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";

import styled from "styled-components";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

const GraphQLMultiSelect = props => {
  return (
    <MultiSelectWrapper>
      <label>{props.title}</label>
      <Query query={props.optionsQuery} key={props.name}>
        {({ loading, error, data }) => {
          if (error)
            return (
              <>
                <label htmlFor={props.id}>{props.title}</label>
                <p>Error loading options</p>
              </>
            );

          const options = [];

          if (data) {
            // * get just the first key
            const key = Object.keys(data)[0];

            let QualData = props.QualData;
            if (QualData.length < 1 && props.formValues && props.formValues["QPLs"] && Object.keys(props.formValues["QPLs"]).length > 0) {
              let QPLsValues = [];
              let qplsKeys = Object.keys(props.formValues.QPLs);
              for (let i = 0; i < qplsKeys.length; i++) {
                if (props.formValues.QPLs[qplsKeys[i]]) {
                  QPLsValues.push(qplsKeys[i]);
                }
              }
              QualData = [{ id: QPLsValues }];
              props.handleChangeQPLs(QualData);
            }

            if (props.title == "New Rebate Categories") {
              if (data[key]) {
                data[key].forEach(option => {
                  if ((option.value || option.name) != "") {
                    if (option.isHidden == 0) {
                      if ((QualData.length < 1)) {
                        options.push({
                          label: option.value || option.name,
                          id: option.uuid
                        });
                      } else {
                        if (option.qpluuid != "") {
                          const found = QualData.find((checkbox) => {
                            return checkbox.id.includes(option.qpluuid)
                          });
                          if (found) {
                            options.push({
                              label: option.value || option.name,
                              id: option.uuid
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }
            } else {
              if (data[key]) {
                data[key].forEach(option => {
                  options.push({
                    label: option.value || option.name,
                    id: option.uuid
                  });
                });
              }
            }
          }

          options.sort(function (a, b) {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          })

          return (
            <MultiSelect
              items={options}
              itemHeight={50}
              selectedItems={props.selectedItems}
              onChange={props.handleChange}
              itemRenderer={Item}
              selectedItemRenderer={SelectedItem}
              selectionStatusRenderer={SelectionStatus}
              showSelectAll={false}
            />
          );
        }}
      </Query>
    </MultiSelectWrapper>
  );
};

export default GraphQLMultiSelect;

GraphQLMultiSelect.propTypes = {
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The query to be sent to GraphQL, which is actually a template literal but PropTypes sees it as an object. */
  optionsQuery: PropTypes.object.isRequired,
  /** The selected items from state (the value) */
  selectedItems: PropTypes.array.isRequired,
  /** The function to add items to state */
  handleChange: PropTypes.func.isRequired
};

const MultiSelectWrapper = styled.div`
  margin-top: 2em;
  label {
    display: block;
    margin-bottom: 1em;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.selected ? "black" : "#16a888")};
  justify-content: ${props =>
    props.selected ? "space-between" : "flex-start"};
  padding: 0 1em;
  cursor: pointer;

  &:first-of-type {
    margin-top: 1em;
  }

  svg + span {
    margin-left: 0.3em;
  }

  span {
    color: black;
  }
`;

const Item = ({ item, checked }) => (
  <StyledItem>
    {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    <span>{item.label}</span>
  </StyledItem>
);

const SelectedItem = ({ item }) => (
  <StyledItem selected>
    <span>{item.label}</span>
    <DeleteIcon />
  </StyledItem>
);

const StyledSelectionStatus = styled.div`
  height: 45px;
  color: black;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
`;

const SelectionStatus = props => (
  <StyledSelectionStatus>
    <span>{props.selected.length} Selected</span>
    {props.selected.length > 0 ? <DeleteSweepIcon onClick={props.clearAll} /> : null}
  </StyledSelectionStatus>
);
