import React, { Component } from "react";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { components, WindowedMenuList } from "react-windowed-select";
import { useMediaQuery } from "react-responsive";
import {
  filterAndSort,
  sortRebates,
  setLoading,
  clearFiltered,
} from "../ducks/filterActions";
import { getRebates, addProductModelNumber } from "../ducks/rebateActions";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import _ from "lodash";
import client from "../../helpers/apolloClient";
import convertToSearchOptions from "../helpers/convertToSearchOptions";
import { ASYNC_SEARCH } from "../helpers/graphQL";

import styled from "styled-components";
import { PreviewButton } from "../../Common/components/Button";

const initialState = {
  sorting: "",
  filtering: {
    isProjectType: false,
    isRebateType: false,
    isQPLs: false,
    categories: [],
    filters: {
      projectType: "",
      rebateType: "",
      QPLs: "",
    },
  },
};

const NoOptions = styled.p`
  padding-left: 1em;
`;

const StyledPlaceHolder = styled(components.Placeholder)`
  display: flex;
  align-items: center;
  width: 100%;
  span {
    margin-left: 0.5em;
    flex: 1 1 auto;
  }
  img {
    max-height: 1.5em;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "15px",
    minHeight: "30px",
    border: "#64B6A0",
    cursor: "pointer",
    marginLeft: ".5em",
    marginRight: ".5em",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
    color: "white",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "white",
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "proxima-nova, sans-serif",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: ".5em",
    marginRight: ".5em",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: ".5em",
    paddingLeft: "1em",
    fontFamily: "proxima-nova, sans-serif",
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontFamily: "proxima-nova, sans-serif",
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: "hidden",
  }),
  input: (provided) => ({
    ...provided,
    caretColor: "transparent",
  }),
};

class PureTechTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSortingChange = ({ target: { value } }) => {
    this.setState({ sorting: value }, () => this.props.sortRebates(value));
  };

  handleFilterChange = ({ target: { name, value } }) => {
    const { filtering, sorting } = this.state;

    const filterLookup = {
      QPLs: "isQPLs",
      projectType: "isProjectType",
      rebateType: "isRebateType",
    };

    this.setState(
      {
        filtering: {
          ...filtering,
          [filterLookup[name]]: value !== "" ? true : false,
          filters: {
            ...filtering.filters,
            [name]: value,
          },
        },
      },
      () => this.props.filterAndSort(sorting, this.state.filtering)
    );
  };

  handleCategoryChange = ({ target: { value, checked } }) => {
    const {
      sorting,
      filtering,
      filtering: { categories },
    } = this.state;
    if (checked) {
      this.setState(
        {
          filtering: { ...filtering, categories: [...categories, value] },
        },
        () => this.props.filterAndSort(sorting, this.state.filtering)
      );
    } else {
      this.setState(
        {
          filtering: {
            ...filtering,
            categories: categories.filter((name) => name !== value),
          },
        },
        () => this.props.filterAndSort(sorting, this.state.filtering)
      );
    }
  };

  uncheckBoxes = () => {
    const checkboxes = productCategories.length;
    for (let i = 0; i < checkboxes; i++) {
      this.refs[`checkRef_${i}`].checked = false;
    }
  };

  resetFilters = () => {
    this.setState({ ...initialState }, () => {
      (() => this.props.filterAndSort(null, "reset"))();
      (() => this.uncheckBoxes())();
    });
  };

  updateProductInfo = (e) => {
    const {
      getRebates,
      addProductModelNumber,
      setLoading,
      clearFiltered,
    } = this.props;
    if (e !== null) {
      setLoading(true);
      clearFiltered();
      addProductModelNumber(e.label);
      getRebates(e.value, 0, 50, true);
    }
  };

  render() {
    const {
      sorting,
      filtering: {
        filters: { projectType, rebateType, QPLs },
      },
    } = this.state;

    const radioProps = (name) => ({
      name,
      type: "radio",
      onChange:
        name === "sorting" ? this.handleSortingChange : this.handleFilterChange,
    });

    const getAsyncOptions = _.debounce((inputValue, callback) => {
      const { wiki } = this.props;
      client
        .query({
          query: ASYNC_SEARCH,
          variables: { value: inputValue, limit: 20 },
        })
        .then(({ data: { productsSearchByModelNumber } }) => {
          const products = {
            label: "Product",
            options: convertToSearchOptions(
              productsSearchByModelNumber,
              inputValue
            ),
          };

          return callback([products]);
        })
        .catch((error) => {
          console.log(error);
          return callback(wiki);
        });
    }, 500);

    const Placeholder = (props) => {
      const isMobile = useMediaQuery({ query: "(max-device-width: 768px)" });

      const searchText = isMobile ? "Search ..." : "Product Name";

      const searchIcon = props.selectProps.searchHome
        ? "searchIconGrey"
        : isMobile
        ? "searchIconWhite"
        : "searchIconGrey";

      return (
        <StyledPlaceHolder isMobile={isMobile} {...props}>
          <img
            src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/${searchIcon}.svg`}
            alt="Magnifying Glass"
          />
          <span>{searchText}</span>
        </StyledPlaceHolder>
      );
    };

    const NoOptionsMessage = (props) => {
      return (
        <NoOptions>
          No Results <br /> Please Search
        </NoOptions>
      );
    };

    const DropdownIndicator = (props) => <div></div>;

    return (
      <>
        <PreviewButton handleClick={() => this.resetFilters()}>
          Reset Filters
        </PreviewButton>
        <FilterSection>
          <Title>Product Search</Title>
          <AsyncSelect
            isClearable
            isSearchable={true}
            menuPlacement="auto"
            styles={customStyles}
            components={{ Placeholder, NoOptionsMessage, DropdownIndicator }}
            loadOptions={getAsyncOptions}
            onChange={(e) => this.updateProductInfo(e)}
          />
        </FilterSection>
        <br />
        <Title>Sorting</Title>
        <FilterSection>
          <p>Name</p>
          <Checkbox>
            <input
              value="az"
              checked={sorting === "az"}
              {...radioProps("sorting")}
            />
            <span></span>
            <span>A->Z</span>
          </Checkbox>
          <Checkbox>
            <input
              value="za"
              checked={sorting === "za"}
              {...radioProps("sorting")}
            />
            <span></span>
            <span>Z->A</span>
          </Checkbox>
        </FilterSection>

        <Title>Filters</Title>
        <FilterSection>
          <p>Project Type</p>
          <Checkbox>
            <input
              value=""
              checked={projectType === ""}
              {...radioProps("projectType")}
            />
            <span></span>
            <span>None</span>
          </Checkbox>
          <Checkbox>
            <input
              value="New Construction"
              checked={projectType === "New Construction"}
              {...radioProps("projectType")}
            />
            <span></span>
            <span>New Construction</span>
          </Checkbox>
          <Checkbox>
            <input
              value="Retrofit"
              checked={projectType === "Retrofit"}
              {...radioProps("projectType")}
            />
            <span></span>
            <span>Retrofit</span>
          </Checkbox>
        </FilterSection>
        <FilterSection>
          <p>Rebate Type</p>
          <Checkbox>
            <input
              value=""
              checked={rebateType === ""}
              {...radioProps("rebateType")}
            />
            <span></span>
            <span>None</span>
          </Checkbox>
          <Checkbox>
            <input
              value="Prescriptive"
              checked={rebateType === "Prescriptive"}
              {...radioProps("rebateType")}
            />
            <span></span>
            <span>Prescriptive</span>
          </Checkbox>
          <Checkbox>
            <input
              value="Calculated"
              checked={rebateType === "Calculated"}
              {...radioProps("rebateType")}
            />
            <span></span>
            <span>Calculated</span>
          </Checkbox>
        </FilterSection>
        <FilterSection>
          <p>Qualification</p>
          <Checkbox>
            <input value="" checked={QPLs === ""} {...radioProps("QPLs")} />
            <span></span>
            <span>None</span>
          </Checkbox>
          <Checkbox>
            <input
              value="DLC"
              checked={QPLs === "DLC"}
              {...radioProps("QPLs")}
            />
            <span></span>
            <span>DLC</span>
          </Checkbox>
          <Checkbox>
            <input
              value="DLC Premium"
              checked={QPLs === "DLC Premium"}
              {...radioProps("QPLs")}
            />
            <span></span>
            <span>DLC Premium</span>
          </Checkbox>
          <Checkbox>
            <input
              value="Energy Star"
              checked={QPLs === "Energy Star"}
              {...radioProps("QPLs")}
            />
            <span></span>
            <span>Energy Star</span>
          </Checkbox>
        </FilterSection>
        <FilterSection>
          <p>Product Category</p>
          {productCategories.map((cat, index) => (
            <Checkbox key={cat}>
              <input
                type="checkbox"
                value={cat}
                onChange={this.handleCategoryChange}
                ref={`checkRef_${index}`}
              />
              <span></span>
              <span>{cat}</span>
            </Checkbox>
          ))}
        </FilterSection>
      </>
    );
  }
}

export default connect(null, {
  filterAndSort,
  sortRebates,
  getRebates,
  addProductModelNumber,
  setLoading,
  clearFiltered,
})(PureTechTypeFilter);

const productCategories = [
  "Interior Fixtures",
  "Exterior Fixtures",
  "Linear Lamps",
  "Interior Lamps",
  "Exterior Lamps",
  "Int./Ext. Lamps",
  "Delamping",
  "Controls",
  "Indoor Horticulture",
];

export const Title = styled.p`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0;
  &:not(:first-of-type) {
    margin-top: 1em;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 1em;
    font-weight: bold;
    margin: 0.7em 0 0.5em;
  }
`;

export const Checkbox = styled.label`
  margin-bottom: 0.5em;
  display: inline-flex;
  align-items: center;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    max-width: 3em;
  }
  input:checked ~ span:first-of-type {
    background-color: #16a888;
  }
  span:first-of-type {
    position: relative;
    height: 0.9em;
    width: 0.9em;
    background-color: transparent;
    border-radius: 3px;
    border: 1.5px solid #16a888;
    cursor: pointer;
  }
  span:not(:first-of-type) {
    margin-left: 2em;
    font-weight: normal;
    font-size: 0.8em;
  }
`;
