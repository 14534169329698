import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Card from "../../../Common/components/Card";
import { PreviewButton } from "../../../Common/components/Button";
import StyledCardsContainer from "../../../Common/components/layout/StyledCardsContainer";

import pressReleases from "../../helpers/pressReleases";
import { textToUrl } from "../../../Wiki/helpers/urls";

const PressRoom = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Rebate Bus Press Room: Press Releases"
        />
        <title>Rebate Bus | Press Room</title>
      </Helmet>
      <h1>Press Room</h1>
      <h2>Press Releases</h2>
      <PressCardContainer>
        {pressReleases.map(pressRelease => {
          const url = `${pressRelease.date}/${textToUrl(pressRelease.title)}`;
          return (
            <PressCard
              key={`pressRelease-${pressRelease.date}`}
              {...pressRelease}
            >
              <h3>{pressRelease.title}</h3>
              <p>{pressRelease.description}</p>
              <div className="readMoreLink">
                <Link to={`/press-room/${url}`}>
                  <PreviewButton>read more</PreviewButton>
                </Link>
              </div>
            </PressCard>
          );
        })}
      </PressCardContainer>
    </>
  );
};

export default PressRoom;

const PressCard = styled(Card)`
  flex: 0 0 46%;
  p {
    line-height: 1.2;
  }
  div.readMoreLink {
    width: 100%;
    text-align: right;
  }
`;

const PressCardContainer = styled(StyledCardsContainer)`
  margin-bottom: 2em;
`;
