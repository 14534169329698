import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const StyledButton = styled.button.attrs((props) => ({
  borderRadius: props.borderRadius || "5px",
  textTransform: props.textTransform || "lowercase",
}))`
  margin: ${(props) => props.margin};
  padding: 0.75em;
  border: 2px solid #16a888;
  border-radius: ${(props) => props.borderRadius};
  color: ${(props) => (props.deactivate ? "white" : "#16a888")};
  cursor: pointer;
  background: ${(props) =>
    props.deactivate ? "#16a888" : props.theme.colors.backgroundMain};
  text-transform: ${(props) => props.textTransform};
`;

/**
 * Button.js is *the* reusable Button component.
 */

export const Button = (props) => {
  const { children, handleClick, id, type } = props;
  return (
    <StyledButton type={type} onClick={handleClick} id={id} {...props}>
      {children}
    </StyledButton>
  );
};

export const PrimaryButton = styled(Button)`
  background-color: #16a888;
  color: white;
  font-size: 1.1em;
  padding: 0.7em;
  width: ${(props) => (props.deactivate ? "11em" : "100%")};
  &.destroy {
    margin-top: 1em;
    background: ${(props) =>
      props.deactivate && props.percentage !== 100
        ? `-webkit-linear-gradient(45deg, #ff0053, grey ${props.percentage}%)`
        : "#ff0053"};
    background: ${(props) =>
      props.deactivate && props.percentage !== 100
        ? `-moz-linear-gradient(45deg, #ff0053, grey ${props.percentage}%)`
        : "#ff0053"};
    background: ${(props) =>
      props.deactivate && props.percentage !== 100
        ? `-ms-linear-gradient(45deg, #ff0053, grey ${props.percentage}%)`
        : "#ff0053"};
    background: ${(props) =>
      props.deactivate && props.percentage !== 100
        ? `linear-gradient(45deg, #ff0053, grey ${props.percentage}%)`
        : "#ff0053"};
    border-color: ${(props) =>
      props.deactivate
        ? props.percentage === 100
          ? "#ff0053"
          : "grey"
        : "#ff0053"};
    font-weight: bold;
  }
`;

export const SecondaryButton = styled(Button)`
  font-size: 0.9em;
  font-weight: ${(props) => (props.deactivate ? "700" : "normal")};
`;

export const PreviewButton = styled(Button)`
  border: none;
  color: #16a888;
  padding: 0;
  font-size: 0.9em;
  background-color: inherit;
  &.destroy {
    color: #ff0053;
  }
`;

Button.propTypes = {
  /** Gives an ID to the button, if necessary.  */
  id: PropTypes.string,
  /** Renders the text (or link) of the button. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  /** Passes the onClick handler for the button. Not required as sometimes the Button is part of a form.*/
  handleClick: PropTypes.func,
  /** HTML button defaults to submit, which can be overridden with the `type` prop. */
  type: PropTypes.string,
  /** Defaults to lowercase but can be overridden. */
  textTransform: PropTypes.string,
};
