import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearPrograms } from "../../ducks/searchActions";

import ProgramMap from "./ProgramMap";

const SearchMap = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearPrograms);
  }, [dispatch]);

  return (
    <>
      <h1>Program Map</h1>
      <p>Click on a state below to see available programs.</p>
      <ProgramMap {...props} />
    </>
  );
};

export default SearchMap;
