import {Column, useTable} from "react-table";
import {View} from "@adobe/react-spectrum";
import React from "react";
import "./table.css"

export interface TableParams<T extends object> {
    columns: Array<Column<T>>,
    data: Array<T>,
    isNotDesktop: boolean,
}

export function Table<T extends object>({columns, data, isNotDesktop}: TableParams<T>) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <View overflow={isNotDesktop ? "auto visible" : "auto"} position="relative"
              UNSAFE_style={{
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #cccccc",
                  borderBottom: "1px solid #cccccc",
                  minHeight: "calc(100% - 6em)"
              }}>
            <table {...getTableProps()} style={{
                border: 'solid 1px #cccccc',
                minWidth: "100%",
                fontSize: "0.9em",
                position: "sticky",
                top: 0,
                zIndex: 1
            }} className="rebates-table">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    border: 'solid 1px #cccccc',
                                    verticalAlign: "middle",
                                    fontWeight: 'bold',
                                    minWidth: column.minWidth || undefined,
                                    maxWidth: column.maxWidth || undefined,
                                    width: column.maxWidth || undefined,
                                    height: "3em",
                                    backgroundColor: "#f8f8f8",
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
            </table>
            <table {...getTableProps()} style={{
                border: 'solid 1px #cccccc',
                minWidth: "100%",
                fontSize: "0.9em",
                position: "relative",
                transform: "translateY(-3em)",
                marginBottom: isNotDesktop ? "0" : "-6em",
            }} className="rebates-table">
                <thead style={{opacity: 0}}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    minWidth: column.minWidth || undefined,
                                    maxWidth: column.maxWidth || undefined,
                                    width: column.maxWidth || undefined,
                                    height: "3em",
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td style={{
                                    padding: '10px',
                                    border: 'solid 1px #cccccc',
                                }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </View>
    )
}