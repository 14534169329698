// It would be more correct to pull the data from the database,
// but I believe it should be sufficient for now

import {SelectOption} from "./selectOption";
import {QPL} from "./qpl";

export const mappedQPLs: Map<QPL, string> = new Map([
    [QPL.NONE, 'None'],
    [QPL.DLC_STANDARD, 'DLC Standard'],
    [QPL.DLC_PREMIUM, 'DLC Premium'],
    [QPL.ENERGY_STAR, 'Energy Star'],
]);


export enum ProjectType {
    RETROFIT = 'RETROFIT',
    NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
}

export const projectTypes: Array<SelectOption<ProjectType>> = [
    {
        value: ProjectType.RETROFIT,
        label: "Retrofit",
    },
    {
        value: ProjectType.NEW_CONSTRUCTION,
        label: "New construction",
    }
];

export const mappedProjectTypes: Map<ProjectType, SelectOption<ProjectType>>
    = new Map(projectTypes.map((option) => ([option.value, option])));

export enum RebateType {
    CALCULATED = 'CALCULATED',
    CUSTOM = 'CUSTOM',
    PRESCRIPTIVE = 'PRESCRIPTIVE',
}

export const rebateTypes: Array<SelectOption<RebateType>> = [
    {
        value: RebateType.CALCULATED,
        label: "Calculated",
    },
    {
        value: RebateType.CUSTOM,
        label: "Custom",
    },
    {
        value: RebateType.PRESCRIPTIVE,
        label: "Prescriptive",
    }
];

export const mappedRebateTypes: Map<RebateType, SelectOption<RebateType>>
    = new Map(rebateTypes.map((option) => ([option.value, option])));

export enum CustomerType {
    COMMERCIAL = 'COMMERCIAL',
    SMALL_BUSINESS = 'SMALL_BUSINESS',
    INDUSTRIAL = 'INDUSTRIAL',
    NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
    RESIDENTIAL = 'RESIDENTIAL',
    MULTI_FAMILY = 'MULTI_FAMILY',
    INDIVIDUAL = 'INDIVIDUAL',
}

export const customerTypes: Array<SelectOption<CustomerType>> = [
    {
        value: CustomerType.COMMERCIAL,
        label: "Commercial",
    },
    {
        value: CustomerType.SMALL_BUSINESS,
        label: "Small Business",
    },
    {
        value: CustomerType.INDUSTRIAL,
        label: "Industrial",
    },
    {
        value: CustomerType.NEW_CONSTRUCTION,
        label: "New Construction",
    },
    {
        value: CustomerType.RESIDENTIAL,
        label: "Residential",
    },
    {
        value: CustomerType.MULTI_FAMILY,
        label: "Multi-Family",
    },
    {
        value: CustomerType.INDIVIDUAL,
        label: "Individual",
    }
];

export const mappedCustomerTypes: Map<CustomerType, SelectOption<CustomerType>>
    = new Map(customerTypes.map((option) => ([option.value, option])));


export enum ProgramRequirementsOption {
    isNotPreApprovalRequired = 'isNotPreApprovalRequired',
    isNotPreInspectionRequired = 'isNotPreInspectionRequired',
    isNotPostAuditRequired = 'isNotPostAuditRequired',
}

export interface ProgramRequirement extends SelectOption<ProgramRequirementsOption> {
    databaseField: string
}

export const programRequirements: Array<ProgramRequirement> = [
    {
        databaseField: "isPreApprovalRequired",
        value: ProgramRequirementsOption.isNotPreApprovalRequired,
        label: "Without pre-approval",
    },
    {
        databaseField: "isPreInspectionRequired",
        value: ProgramRequirementsOption.isNotPreInspectionRequired,
        label: "Without pre-inspection",
    },
    {
        databaseField: "isPostAuditRequired",
        value: ProgramRequirementsOption.isNotPostAuditRequired,
        label: "Without post-audit",
    },
];

export enum ProductIdType {
    REBATE_BUS_ID = 'REBATE_BUS_ID',
    DLC_ID = 'DLC_ID',
    ENERGY_STAR_ID = 'ENERGY_STAR_ID',
}

export enum ProductIdQueryType {
    MODEL_NUMBER_QUERY = 'MODEL_NUMBER_QUERY',
    DLC_ID_QUERY = 'DLC_ID_QUERY',
    ENERGY_STAR_ID_QUERY = 'ENERGY_STAR_ID_QUERY',
}

export const productIdQueryTypeOptions: Array<SelectOption<ProductIdQueryType>> = [
    {
        value: ProductIdQueryType.MODEL_NUMBER_QUERY,
        label: "Model Number"
    },
    {
        value: ProductIdQueryType.DLC_ID_QUERY,
        label: "DLC ID"
    },
    {
        value: ProductIdQueryType.ENERGY_STAR_ID_QUERY,
        label: "Energy Star ID"
    }
];

export const mappedProductIdQueryTypeOptions: Map<ProductIdQueryType, SelectOption<ProductIdQueryType>>
    = new Map(productIdQueryTypeOptions.map((option) => ([option.value, option])));

export function convertProgramRequirementsArray(programRequirementValues: Array<ProgramRequirementsOption>): {[p: string]: boolean} {
    const result: { [p: string]: boolean } = {};

    // if no are specified, then we don't filter
    if (programRequirementValues.length === 0) {
        return result;
    }

    for (const requirement of programRequirements) {
        if (programRequirementValues.includes(requirement.value)) {
            result[requirement.databaseField] = false;
        }
    }

    return result;
}

