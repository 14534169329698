import {
  GET_TEAM,
  TEAM_DEACTIVATE,
  TEAM_UPDATE,
  INVITE_COMPLETE,
  INVITE_RESET,
} from "./types";

const initialState = {
  team: [],
  inviteComplete: false,
};

export default (state = initialState, action) => {
  const { user } = action;
  const { team } = state;
  switch (action.type) {
    case GET_TEAM:
      return { ...state, team: action.team };
    case INVITE_RESET:
      return { ...state, inviteComplete: false };
    case INVITE_COMPLETE:
      return { ...state, inviteComplete: true };
    case TEAM_DEACTIVATE:
      return state;
    case TEAM_UPDATE:
      const updatedTeam = team.filter((tm) => tm.uuid !== user.uuid);
      return { ...state, team: [...updatedTeam, user] };
    default:
      return state;
  }
};
