import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signoutUser, updateUser, deactivateUser } from "./ducks/actions";
import { showMessages } from "../Common/components/Message";
import { dismissMessage } from "../Common/ducks/actions";

import UserProfile from "./components/UserProfile2";
import ChangePassword from "./components/ChangePassword";

/**
 * Profile.js is the Route for the Profile page. It controls what component displays and is reachable through TopMenu.js.
 */

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: { ...props.user },
      redirect: false
    };
  }

  handleInputChange = (id, value) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [id]: value
      }
    }));
  };

  changePassword = (event, password) => {
    const { updateUser } = this.props;
    event.preventDefault();
    updateUser({ ...this.state.user, password }, "password");
    this.setState({ redirect: true });
  };

  updateProfile = event => {
    const { updateUser } = this.props;
    event.preventDefault();
    const editableFields = [
        'firstName',
        'lastName',
        'email',
        'phone',
    ];
    for (const field of editableFields) {
      if (this.state.user[field].trim() === "") {
        return alert('All fields are required')
      }
    }

    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(this.state.user.phone)) {
      return alert('Phone number must be valid')
    }

    updateUser(this.state.user, "profile");
    this.setState({ redirect: true });
  };

  deactivateAccount = () => {
    const { deactivateUser, user } = this.props;
    deactivateUser(user);
  };

  render() {
    const { user, redirect } = this.state;
    const { messages, dismissMessage } = this.props;
    if(!this.props.authed) return null;

    return (
      <main id="mainContentContainer">
        <div id="mainContentWindowContainer">
          {showMessages(messages, dismissMessage)}
          <Switch>
            <Route
              exact
              path="/profile"
              render={() => <UserProfile user={user} />}
            />
            <Route
              path="/profile/password"
              render={() => (
                <ChangePassword
                  handleSubmit={this.changePassword}
                  redirect={redirect}
                />
              )}
            />
            <Redirect to="/wrong-turn" />
          </Switch>
        </div>
      </main>
    );
  }
}

export default connect(null, {
  dismissMessage,
  updateUser,
  deactivateUser,
  signoutUser
})(Profile);

Profile.propTypes = {
  /** Dismiss message action from Redux */
  dismissMessage: PropTypes.func.isRequired,
  /** Update user action from Redux. */
  updateUser: PropTypes.func.isRequired,
  /** Deactivate user action from Redux */
  deactivateUser: PropTypes.func.isRequired,
  /** Signout user action from Redux. Called after deactivate. */
  signoutUser: PropTypes.func.isRequired,
  /** The current user */
  user: PropTypes.object.isRequired
};
