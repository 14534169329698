import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import WikiSearch from "../WikiSearch";

const SearchHome = () => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 768px)" });

  return (
    <>
      <div>
        <h1>Direct Search</h1>
        <p>
          Direct search is ideal for anyone who knows what they're looking for.
          Search for states, utilities, programs, products, or zip codes. If
          you're not sure where to start, try exploring rebate programs by state
          with the <Link to="/search/program-map">Program Map</Link>
        </p>
        <SearchContainer>
          <SearchWrapper>
            <WikiSearch searchHome isMobile={isMobile} />
          </SearchWrapper>
        </SearchContainer>
      </div>
    </>
  );
};

export default SearchHome;

const SearchContainer = styled.div`
  height: 30em;
  background-image: url("https://s3.amazonaws.com/v2.rebatebus/images/decorative/wikiSearchGraphic.svg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 3em;
  @media all and (max-width: 768px) {
    height: 20em;
  }
`;

const SearchWrapper = styled.div`
  width: 60%;
  max-width: 690px;
  padding: 0.5em;
  margin-left: 23em;
  margin-top: -12em;
  background-color: ${props => props.theme.colors.backgroundMain};
  border-radius: 50px;
  box-shadow: ${props => props.theme.colors.boxShadow};
  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: -9em;
    margin-left: 0;
  }
`;
