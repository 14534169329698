import React, { useRef, useEffect } from "react";
import Pricing from "../pageComponents/pricing";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const individual = [
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_1.svg",
    title: "Web App",
    price: "Free!",
    button: "sign up"
  },
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_2.svg",
    title: "Distributor Advertising",
    price: "Custom pricing",
  },
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_3.svg",
    title: "Rebate Bus API",
    price: "Custom pricing"
  }
];

export const packages = [
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_4.svg",
    title: "Standard",
    price: "Custom pricing",
    plan: true
  },
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_5.svg",
    title: "Pro",
    price: "Custom pricing",
    plan: true
  },
  {
    image:
      "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Pricing+and+Packages/Pricing_6.svg",
    title: "Enterprise",
    price: "Custom pricing",
    plan: true
  }
];

const PricingPackages = props => {
  const MainRef = useRef(null);

  useEffect(() => {
    MainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <div ref={MainRef}>
      <Helmet>
        <meta name="description" content="Rebate Bus Pricing information" />
        <title>Rebate Bus | Pricing</title>
      </Helmet>
      <h1>Pricing and Packages</h1>
      <div className="individual">
        <h2>Individual Items</h2>
        <CardContainer>
          {individual.map((card, i) => (
            <Pricing key={`individual-pricing-card-${i}`} card={card} />
          ))}
        </CardContainer>
      </div>
      <div className="packages">
        <h2>Packages</h2>
        <CardContainer>
          {packages.map((card, i) => (
            <Pricing key={`packages-pricing-card-${i}`} card={card} />
          ))}
        </CardContainer>
      </div>
    </div>
  );
};

export default PricingPackages;
