import React, { useState } from "react";
import styled from "styled-components";
import { PageHeader, ReportsSection, HtmlSection } from "./PageComponents";
import { Section } from "./StyledPageComponents";
import { Helmet } from "react-helmet";
import Relationship from "../Relationship";
import HelpfulHints from "../../../Common/components/HelpfulHints";
import alphabetize from "../../helpers/alphabetize";

const SubTitle = styled.h3`
    color: #16a888;
    cursor: pointer;
`;

const toDollars = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num).split('.')[0];
}

const State = ({ uuid, state, type, url, permissions, showHelp }) => {
  const [showHints, setShowHints] = useState(true);

  const formatNumber = num => num.toLocaleString();
  const metaDescription = `Looking for ${state.name} Utility & Rebate info? We’re all about efficiency at RebateBus so we’ve aggregated and analyzed the ${state.zipCodes.length} zip codes and ${state.utilities.length} utility providers to save YOU the time & energy. Look up utility and rebate data by state, zip, program, manufacturer, product, and more. We can even help you quote and apply for rebate programs in ${state.name}. Let’s get started today.`

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDescription}
        />
        <title>{`${state.name} Utility Rebate Info | ${state.name} Energy Rebates`}</title>
      </Helmet>
      <PageHeader
        name={state.name}
        type={type}
        url={url}
        permissions={permissions}
        isState={true}
      />
      {showHints && showHelp && (
        <HelpfulHints
          title="Find Your Utility"
          handleDismiss={() => setShowHints(false)}
        >
          <p>
            Because rebates are specific to your utility, you'll want to first
            find your utility in the list below. Then you can explore what
            rebate programs they offer!
          </p>
        </HelpfulHints>
      )}
      <Section>
        <h2>{`${state.name} Utility Overview`}</h2>
        <Section subsection>
          {state.description && <HtmlSection longText={state.description} />}
          <SubTitle>{`${state.name} Region`}</SubTitle>
          <p>{state.region.name || "None"}</p>
        </Section>
      </Section>
      <Section>
        <h2>{`${state.name} Electric Utility Profile`}</h2>
          <div style={{display: "flex", gap: "100px"}}>
              <Section subsection>
                  <SubTitle>Average Retail Price</SubTitle>
                  <p>{formatNumber(state.averageRetailPrice)} cents/kWh</p>
                  <SubTitle>Net Summer Capacity</SubTitle>
                  <p>{formatNumber(state.netSummerCapacity)} MW</p>
                  <SubTitle>Net Generation</SubTitle>
                  <p>{formatNumber(state.netGeneration)} MWh</p>
                  <SubTitle>Total Retail Sales</SubTitle>
                  <p>{formatNumber(state.totalRetailSales)} MWh</p>
              </Section>
              <Section subsection>
                  <SubTitle>Total Efficiency Budget</SubTitle>
                  <p>{toDollars(state.totalEfficiencyBudget)}</p>
                  <SubTitle>Total Incentive Spending</SubTitle>
                  <p>{toDollars(state.totalIncentiveSpending)}</p>
              </Section>
          </div>
      </Section>
      <Section>
      <h2>{`Other ${state.name} Utility & Rebate information`}</h2>
        <p>
          The diagram below shows the relationship between {state.name} and its
          parent country {state.country.name} and child utilities.
        </p>
        <Relationship type="Country" {...state.country}>
          <Relationship type="State/Territory" name={state.name} uuid={uuid}>
            <Relationship
              type="Utility"
              relations={alphabetize(state.utilities, "name")}
              childRelation
            />
            {state.zipCodes && state.zipCodes.length > 0 && (
              <Relationship
                type="Zip Code"
                relations={alphabetize(state.zipCodes, "value")}
                childRelation
              />
            )}
          </Relationship>
        </Relationship>
      </Section>
    </>
  );
};

export default State;
