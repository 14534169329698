import React, { Component } from "react";

class Training extends Component {
  render() {
    return (
      <main id="mainContentContainer">
        <div id="mainContentWindowContainer">
          <div id="mainContentWindowWrapper">
            <h1>Training</h1>
            <p>Descriptive text</p>
          </div>
        </div>
      </main>
    );
  }
}

export default Training;
