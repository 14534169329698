import React from "react";
import Helmet from "react-helmet";
import ProjectEstimator from "./pages/ProjectEstimator/ProjectEstimator";
import {Switch, Route} from "react-router-dom";
import RequestEstimateForm from "../RequestEstimateForm/RequestEstimateForm";

const Services = (props) => {
    return (
        <main id="mainContentContainer" style={{
            overflowY: "auto",
            marginTop: 20,
            display: "flex",
            justifyContent: "center"
        }}>
            <Helmet>
                <meta name="description" content="Calculate incentive estimate for your project."/>
                <title>Project Estimator | Rebate Bus</title>
            </Helmet>
            <div style={{maxWidth: 710, width: 710}}>
                <Switch>
                    <Route
                        path="/services/incentive-estimator"
                        render={(prop) => (
                            <ProjectEstimator
                                {...prop}
                                user={props.user}
                            />
                        )}
                    />
                </Switch>
                <Switch>
                    <Route
                        path="/services/request-estimate"
                        render={(prop) => (
                            <RequestEstimateForm
                                {...prop}
                                user={props.user}
                            />
                        )}
                    />
                </Switch>
            </div>
        </main>
    );
};

export default Services;
