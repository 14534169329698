import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Query } from "react-apollo";
import { GET_PAGE } from "../helpers/graphQL";

import Loading from "../../Common/components/Loading";
import Country from "./pageTypes/Country";
import State from "./pageTypes/State";
import Utility from "./pageTypes/Utility";
import Program from "./pageTypes/Program";
import Product from "./pageTypes/Product";
import ZipCode from "./pageTypes/ZipCode";
import TechnologyType from "./pageTypes/TechnologyType";
import Rebate from "./pageTypes/Rebate";

import { uppercase } from "../helpers/urls";

const componentPageLookup = {
  Country,
  "State/Territory": State,
  State,
  Utility,
  Program,
  Product,
  "Zip Code": ZipCode,
  technologyType: TechnologyType,
  match: TechnologyType,
  rebate: Rebate,
};

const GraphQLPage = ({
  match: { url, params },
  location,
  userPermissions,
  showHelp,
}) => {
  const {
    programId,
    technologyTypeId,
    rebateId,
    wikiPageId,
    pageType,
    databaseId,
    productId,
    matchId,
  } = useParams();

  const modelNumber = location.state && location.state.modelNumber
    ? location.state.modelNumber
    : null;

  const program = useSelector((state) => state.wiki.rebates.program);
  const rebates = useSelector((state) => state.wiki.rebates.rebates);

  const programMatches = program ? program.uuid === programId : false;

  const possibleParams = [
    { type: "Technology", id: programId },
    {
      type: "Rebate",
      id: rebateId,
    },
    { type: "Product", id: productId },
    { type: "Wiki", id: wikiPageId },
    { type: "Match", id: matchId },
  ];

  const queryObject = possibleParams
    .filter((param) => param.id)
    .map((param) => {
      switch (param.type) {
        case "Technology":
          return {
            type: "technologyType",
            programId,
            technologyTypeId,
          };
        case "Rebate":
          return {
            type: "rebate",
            rebateId,
          };
        case "Product":
          return {
            type: "Product",
            uuid: productId,
          };
        case "Match":
          return {
            type: "match",
            uuid: matchId,
            technologyTypeId,
            modelNumber,
          };
        default:
          return {
            name: uppercase(wikiPageId),
            type: uppercase(pageType),
            uuid: databaseId,
          };
      }
    })[0];

  const PageType = componentPageLookup[queryObject.type];

  if (
    (queryObject.type === "technologyType" && programMatches) ||
    queryObject.type === "match"
  ) {
    return (
      <PageType
        permissions={userPermissions >= 3}
        url={url}
        {...queryObject}
        program={program}
        rebates={rebates}
      />
    );
  }

  return (
    <Query query={GET_PAGE(queryObject)} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return `Error! ${error.message}`;
        if (data)
          return (
            <PageType
              permissions={userPermissions >= 3}
              url={url}
              {...queryObject}
              {...data}
              showHelp={showHelp}
            />
          );
      }}
    </Query>
  );
};

export default GraphQLPage;
