import React from "react";
import Section from "../pageComponents/section";
import { Helmet } from "react-helmet";

const FulfillmentServices = props => {
  const sections = [
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Fulfillment+Services/Fulfillment_1.svg",
      title: "Incentive application services",
      content:
      "Are you drowning in rebate applications? The Rebate Bus services team will help you apply to utility rebate programs yourself or on your behalf. Rebate Fulfillment projects will be tracked from the beginning, whether or not pre-approval is required, to the end, upon submission of the final application to the utility.",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Fulfillment+Services/Fulfillment_2.svg",
      title: "Incentive estimate services",
      content:
        "Rebate Bus can be seamlessly integrated with a customer's sales process at the proposal stage to further incentivize buyers with the added value of utility rebates. Rebate Bus guarantees a turnaround time of 2 business days to generate the rebate estimate with the option of expediting any request for the same business day for a fee.",
      flip: false
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Fulfillment+Services/Fulfillment_3.svg",
      title: "Reporting services",
      content:
        "Let us help you design, develop and generate tailored rebate intelligence reports that meet their specific needs for business, market, and industry insights. These reports can include multi-site prioritization reports (to support regional/national sales rollouts), incentive targeting reports (to support identifying lucrative incentive programs and opportunities), and Rebate SKU reports (to assist identifying the best rebates for specific products).",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Fulfillment+Services/Fulfillment_4.svg",
      title: "Consulting services",
      content:
        "Consulting requests can be made to support customers with an array of expertise regarding their engagement in utility rebate programs. For manufacturer customers, Rebate Bus has had experience submitting product specification data to utility rebate programs with their own qualified product lists. For other customers, Rebate Bus has been primarily utilized to train sales teams on how to use utility rebates in their daily work lives.",
      content2: "Contact commercial@rebatebus.com to learn more.",
      flip: false
    }
  ];
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Rebate Bus Fulfilment Services information"
        />
        <title>Rebate Bus | Fulfilment Services</title>
      </Helmet>
      <h1>Fulfillment Services</h1>
      {sections.map((section, i) => (
        <Section key={`fulfillment-section-${i}`} section={section} />
      ))}
    </div>
  );
};

export default FulfillmentServices;
