import React from "react";
import Card from "../../../Common/components/Card";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 26%;
  margin: 2em;

  @media (max-width: 768px) {
    width: 100%;
  }

  h3 {
    font-size: 1em;
  }

  div.image-container {
    width: 100%;
    margin-bottom: 2em;
  }
  div.top-section {
    width: 100%;
    text-align: center;
    span.price2 {
      font-size: 0.9em;
    }
  }
  div.package-info {
    margin-top: 1.5em;
    ul {
      font-weight: 300;
      height: 4em;
      list-style-type: disc;
      border-bottom: 1px solid grey;
      margin-bottom: 1em;
      li {
        margin: 0 0 1em 1em;
        font-size: 0.8em;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${props => (props.plan === null ? "3em" : "0")};
`;

const Button = styled.p`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-self: flex-end;

  border: 1.2px solid #18a888;
  background-color: #0ea788;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  width: 12em;
  color: white;
`;

const NotIncluded = styled.li`
  margin: 0 0 1em 1em;
  font-size: 0.8em;
  color: ${props => (props.show === true ? "red" : "inherit")};
`;

const Pricing = ({
  card: { image, title, price, price2 = null, button = null, plan = null }
}) => {
  const plans = title => {
    switch (title) {
      case "Standard":
        return (
          <>
            <ul>
              <li>
                <span>Standard access to the web app</span>
              </li>
              <li>
                <span>Unlimited company profiles</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>15% rebate fulfilment fee</span>
              </li>
              <li>
                <span>$3,000 cap</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>$75 estimates</span>
              </li>
              <li>
                <span>3 free</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>1 rebate intelligence services report</span>
              </li>
            </ul>
            <ul>
              <li>2 hours rebate consulting</li>
              <li>Additional services $75 / hour</li>
            </ul>
            <ul>
              <NotIncluded show={title.includes("Standard") ? true : false}>
                Rebate Connect
              </NotIncluded>
              <NotIncluded
                show={
                  title.includes("Standard") || title.includes("Pro")
                    ? true
                    : false
                }
              >
                Rebate Connect +
              </NotIncluded>
            </ul>
          </>
        );
      case "Pro":
        return (
          <>
            <ul>
              <li>
                <span>Standard access to the web app</span>
              </li>
              <li>
                <span>Unlimited company profiles</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>12% rebate fulfilment fee</span>
              </li>
              <li>
                <span>$2,500 cap</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>$65 estimates</span>
              </li>
              <li>
                <span>4 free</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>1 rebate intelligence services report</span>
              </li>
            </ul>
            <ul>
              <li>2 hours rebate consulting</li>
              <li>Additional services $75 / hour</li>
            </ul>
            <ul>
              <NotIncluded show={title.includes("Standard") ? true : false}>
                Rebate Connect
              </NotIncluded>
              <NotIncluded
                show={
                  title.includes("Standard") || title.includes("Pro")
                    ? true
                    : false
                }
              >
                Rebate Connect +
              </NotIncluded>
            </ul>
          </>
        );
      case "Enterprise":
        return (
          <>
            <ul>
              <li>
                <span>Standard access to the web app</span>
              </li>
              <li>
                <span>Unlimited company profiles</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>10% rebate fulfilment fee</span>
              </li>
              <li>
                <span>$2,000 cap</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>$55 estimates</span>
              </li>
              <li>
                <span>5 free</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>2 rebate intelligence services report</span>
              </li>
            </ul>
            <ul>
              <li>2 hours rebate consulting</li>
              <li>Additional services $75 / hour</li>
            </ul>
            <ul>
              <NotIncluded show={title.includes("Standard") ? true : false}>
                Rebate Connect
              </NotIncluded>
              <NotIncluded
                show={
                  title.includes("Standard") || title.includes("Pro")
                    ? true
                    : false
                }
              >
                Rebate Connect +
              </NotIncluded>
            </ul>
          </>
        );
      default:
        console.log("Title doesn't exist");
    }
  };

  const cardTitle = title =>
    title.includes("Standard") ||
      title.includes("Pro") ||
      title.includes("Enterprise")
      ? `${title} Plan`
      : title;
  return (
    <StyledCard>
      <div className="image-container">
        <img alt="pricing" src={image} />
      </div>
      <div className="top-section">
        <h3>{cardTitle(title)}</h3>
        <span className="price">{price}</span>
        {price2 ? (
          <>
            <br />
            <span className="price2">{price2}</span>
          </>
        ) : null}
      </div>
      {plan ? <div className="package-info">{plans(title)}</div> : null}
      <ButtonContainer plan={plan}>
        <Link to={button ? "/signup" : "/contact-us#connect-with-us"}>
          <Button>{button ? button : "Contact sales"}</Button>
        </Link>
      </ButtonContainer>
    </StyledCard>
  );
};

export default Pricing;
