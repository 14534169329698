import { gql } from "apollo-boost";

const commonInputs = {
  hidden: { name: "isHidden", title: "Page Hidden?", inputType: "toggle" },
  description: {
    name: "description",
    title: "Description",
    inputType: "textarea"
  },
  logo: {
    name: "logoUrl",
    title: "Logo",
    inputType: "input",
    icon: "Link"
  },
  reports: {
    name: "reports",
    title: "Reports",
    inputType: "multi",
    inputs: [
      {
        name: "name",
        title: "Report Title",
        inputType: "input"
      },
      {
        name: "url",
        title: "Report Link",
        inputType: "input"
      },
      {
        name: "description",
        title: "Report Description",
        inputType: "textarea"
      }
    ]
  },
  websites: {
    name: "websites",
    title: "Website Links",
    inputType: "multi",
    inputs: [
      {
        name: "linkText",
        title: "Website Link Text",
        inputType: "input"
      },
      {
        name: "url",
        title: "Website URL",
        inputType: "input"
      }
    ]
  },
  contacts: {
    name: "contacts",
    title: "Contacts",
    inputType: "multi",
    inputs: [
      {
        name: "name",
        title: "Name",
        inputType: "input"
      },
      {
        name: "title",
        title: "Title",
        inputType: "input"
      },
      {
        name: "email",
        title: "Email",
        inputType: "input"
      },
      {
        name: "phone1",
        title: "Phone Number",
        inputType: "phone"
      },
      {
        name: "phone2",
        title: "Secondary Number",
        inputType: "phone"
      }
    ]
  },
  state: {
    name: "stateId",
    title: "State",
    inputType: "gqlSelect",
    required: true,
    optionsQuery: gql`
      {
        states {
          name
          uuid
        }
      }
    `
  }
};

export const formInputs = {
  country: [
    commonInputs.hidden,
    {
      name: "name",
      title: "Country",
      inputType: "input",
      required: true
    },
    commonInputs.description
  ],
  state: [
    {
      name: "name",
      title: "State / Territory Name",
      inputType: "input",
      required: true
    },
    commonInputs.description,
    {
      name: "efficiencyScore",
      title: "Efficiency Score",
      inputType: "input"
    },
    {
      name: "regionId",
      title: "Region",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          regions {
            name
            uuid
          }
        }
      `
    },
    {
      name: "averageRetailPrice",
      title: "Average Retail Price",
      inputType: "input",
      type: "number",
      adornment: { unit: "cents/kWh", location: "end" }
    },
    {
      name: "netSummerCapacity",
      title: "Net Summer Capacity",
      inputType: "input",
      type: "number",
      adornment: { unit: "MW", location: "end" }
    },
    {
      name: "netGeneration",
      title: "Average Retail Price",
      inputType: "input",
      type: "number",
      adornment: { unit: "MWh", location: "end" }
    },
    {
      name: "totalRetailSales",
      title: "Total Retail Sales",
      inputType: "input",
      type: "number",
      adornment: { unit: "MWh", location: "end" }
    }
  ],
  utility: [
    {
      name: "name",
      title: "Utility Name",
      inputType: "input",
      required: true
    },
    commonInputs.state,
    {
      name: "utilityOwnershipTypeId",
      title: "Ownership Type",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          utilityOwnershipTypes {
            value
            uuid
          }
        }
      `
    },
    commonInputs.logo,
    commonInputs.description,

    // * hiding while figuring out what's up w/ Address on backend
    // {
    //   name: "address",
    //   title: "Address",
    //   inputType: "input",
    //   required: true
    // },
    // {
    //   name: "city",
    //   title: "City",
    //   inputType: "input",
    //   required: true
    // },
    // {
    //   name: "zipCode",
    //   title: "ZipCode",
    //   inputType: "input",
    //   required: true
    // },
    // {
    //   name: "map",
    //   title: "Service Territory Map",
    //   inputType: "input",
    //   icon: "Link"
    // },
    commonInputs.websites,
    {
      name: "socialMediaLinks",
      title: "Social Media Links",
      inputType: "multi",
      inputs: [
        {
          name: "linkText",
          title: "Site Title",
          inputType: "gqlSelect",
          optionsQuery: gql`
            {
              socialMediaPlatforms {
                name
                uuid
              }
            }
          `
        },
        {
          name: "url",
          title: "URL",
          inputType: "input"
        }
      ]
    },
  ],
  program: [
    commonInputs.hidden,
    {
      name: "isActive",
      title: "Program Active?",
      inputType: "toggle",
      default: true
    },
    {
      name: "name",
      title: "Program Name",
      inputType: "input",
      required: true
    },
    commonInputs.state,
    {
      name: "hasMultipleUtilities",
      title: "Program services multiple utilities.",
      inputType: "checkbox"
    },
    {
      name: "singleUtility",
      title: "Utility",
      inputType: "programUtility",
      required: true
    },
    {
      name: "multipleUtilities",
      title: "Multiple Utilities",
      inputType: "programUtility",
      required: true,
      inputs: [
        {
          name: "utility",
          title: "Utility",
          inputType: "gqlSelect",
          required: true
        }
      ]
    },
    {
      name: "statusNotes",
      title: "Status Notes",
      inputType: "input",
      helperText:
        "Program status updates, e.g., how they are handling rebates during Covid-19 pandemic."
    },
    commonInputs.logo,
    {
      name: "startDate",
      title: "Program Start Date",
      inputType: "datepicker"
    },
    {
      name: "endDate",
      title: "Program End Date",
      inputType: "datepicker"
    },
    commonInputs.description,
    {
      name: "requirements",
      title: "Program Requirements",
      inputType: "textarea"
    },
    {
      name: "customerTypes",
      title: "Customer Types",
      inputType: "graphQLMultiCheckbox",
      optionsQuery: gql`
        {
          customerTypes {
            value
            uuid
          }
        }
      `
    },
    {
      name: "offeringTypes",
      title: "Offering Types",
      inputType: "graphQLMultiCheckbox",
      optionsQuery: gql`
        {
          offeringTypes {
            value
            uuid
          }
        }
      `
    },
    {
      name: "downstreamProgram",
      title: "Downstream Requirements",
      inputType: "downstreamProgram",
      options: [
        {
          name: "isPreApprovalRequired",
          description: "Pre-approval is required",
          inputType: "multiCheck"
        },
        {
          name: "isPreInspectionRequired",
          description: "Pre-inspection is required",
          inputType: "multiCheck"
        },
        {
          name: "isPostAuditRequired",
          description: "Post-audit is required",
          inputType: "multiCheck"
        }
      ]
    },
    {
      name: "totalAvailable",
      title: "Total Budget",
      inputType: "input",
      type: "number",
      adornment: { unit: "$", location: "start" }
    },
    {
      name: "totalUtilized",
      title: "Budget Utilized",
      inputType: "input",
      type: "number",
      adornment: { unit: "$", location: "start" }
    },
    {
      name: "maximumAnnualIncentive",
      title: "Maximum Annual Incentive",
      inputType: "input",
      type: "number",
      adornment: { unit: "$", location: "start" }
    },
    {
      name: "projectCap",
      title: "Project Cap",
      inputType: "input",
      type: "number",
      required: true,
      adornment: { unit: "$ or %", location: "start" },
      helperText:
        "Enter percentage (100 or less) or dollar amount (more than 100)"
    },
    {
      name: "laborCap",
      title: "Labor Cap",
      inputType: "input",
      type: "number",
      adornment: { unit: "$ or %", location: "start" },
      helperText:
        "Enter percentage (100 or less) or dollar amount (more than 100)"
    },
    {
      name: "materialCap",
      title: "Material Cap",
      inputType: "input",
      type: "number",
      adornment: { unit: "$ or %", location: "start" },
      helperText:
        "Enter percentage (100 or less) or dollar amount (more than 100)"
    },
    commonInputs.websites,
    {
      name: "applicationLinks",
      title: "Program Application Links",
      inputType: "multi",
      inputs: [
        {
          name: "linkText",
          title: "Website Link Text",
          inputType: "input"
        },
        {
          name: "url",
          title: "Website URL",
          inputType: "input"
        }
      ]
    },
    commonInputs.contacts,
    commonInputs.reports
  ],
  product: [
    commonInputs.hidden,
    {
      name: "modelNumber",
      title: "Model Number",
      inputType: "input",
      required: true
    },
    {
      name: "imageUrl",
      title: "Image Url",
      inputType: "input",
      required: true
    },
    {
      name: "QPLId",
      title: "QPL",
      inputType: "gqlSelect",
      optionsQuery: gql`
        {
          QPLs {
            uuid
            value
          }
        }
      `
    },
    {
      name: "QPLIdentifier",
      title: "QPL Identifier",
      inputType: "input",
      required: true
    },
    {
      name: "manufacturerId",
      title: "Manufacturer",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          manufacturers {
            uuid
            name
          }
        }
      `
    },
    {
      name: "brandId",
      title: "Brand",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          brands {
            uuid
            value
          }
        }
      `
    },
    {
      name: "productTypeId",
      title: "Product Type",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          productTypes {
            uuid
            value
          }
        }
      `
    },
    {
      name: "newProductCategoryId",
      title: "Product Category",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          newProductCategories {
            uuid
            name
          }
        }
      `
    },
    {
      name: "productSubCategoryId",
      title: "Product SubCategory",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          productSubCategories {
            uuid
            value
          }
        }
      `
    },
    {
      name: "technologyTypeId",
      title: "Technology Type",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          technologyTypes {
            uuid
            value
          }
        }
      `
    },
    {
      name: "productSpecs",
      title: "Product Specs",
      inputType: "multi",
      inputs: [
        {
          name: "value",
          title: "Amount",
          inputType: "input",
          required: true
        },
        {
          name: "productSpecTypeId",
          title: "Product Spec Type",
          inputType: "gqlSelect",
          required: true,
          optionsQuery: gql`
            {
              productSpecTypes {
                uuid
                value
              }
            }
          `
        }
      ]
    }
  ],
  zipcode: [
    {
      name: "name",
      title: "Zip Code",
      inputType: "input",
      type: "number",
      required: true
    }
  ],
  rebate: [
    {
      name: "Qualify",
      step: "Step One",
      inputType: "rebate-step"
    },
    {
      name: "programId",
      title: "Program",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          programs {
            name
            uuid
          }
        }
      `
    },
    {
      name: "technologyTypeId",
      title: "Technology Type",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          technologyTypes {
            value
            uuid
          }
        }
      `
    },
    {
      name: "rebateName",
      title: "Rebate Name",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          rebateNames {
            name
            uuid
          }
        }
      `
    },
    {
      name: "Specify Value",
      step: "Step Two",
      inputType: "rebate-step"
    },
    {
      name: "rebateTypeId",
      title: "Rebate Type",
      inputType: "gqlSelect",
      required: true,
      optionsQuery: gql`
        {
          rebateTypes {
            uuid
            value
          }
        }
      `
    },
    {
      name: "prescriptiveRebateValue",
      title: "Rebate Value",
      inputType: "rebateValue",
      required: true,
      inputs: [
        {
          name: "prescriptiveValue",
          title: "Value per Unit",
          inputType: "input",
          type: "number",
          required: true,
          adornment: { unit: "$", location: "start" }
        },
        {
          name: "prescriptiveUnit",
          title: "Unit",
          inputType: "gqlSelect",
          required: true,
          optionsQuery: gql`
            {
              rebateRateUnits {
                value
                uuid
                rebateType {
                  value
                  uuid
                }
              }
            }
          `
        }
      ]
    },
    {
      name: "calculatedRebateValue",
      title: "Rebate Value",
      inputType: "rebateValue",
      required: true,
      inputs: [
        {
          name: "value",
          title: "Value per Unit",
          inputType: "input",
          type: "number",
          required: true,
          adornment: { unit: "$ or %", location: "start" }
        },
        {
          name: "unit",
          title: "Unit",
          inputType: "gqlSelect",
          required: true,
          optionsQuery: gql`
            {
              rebateRateUnits {
                value
                uuid
              }
            }
          `
        }
      ]
    },
    {
      name: "hasMinimumCustomerContribution",
      title: "Rebate has minimum customer contribution",
      inputType: "checkbox"
    },
    {
      name: "minimumCustomerContribution",
      title: "Minimum Customer Contribution",
      inputType: "input",
      type: "number",
      dependent: true,
      dependentOn: "hasMinimumCustomerContribution",
      adornment: { unit: "$", location: "start" }
    },
    {
      name: "hasCap",
      title: "Rebate has cap",
      advancedStyles: "margin-zero",
      inputType: "checkbox"
    },
    {
      name: "cap",
      title: "Rebate Cap",
      inputType: "input",
      type: "number",
      dependent: true,
      dependentOn: "hasCap",
      adornment: { unit: "$", location: "start" }
    },
    {
      name: "projectTypes",
      title: "Project Type",
      inputType: "graphQLMultiCheckbox",
      optionsQuery: gql`
        {
          projectTypes {
            value
            uuid
          }
        }
      `
    },
    {
      name: "QPLs",
      title: "Product Qualification",
      inputType: "graphQLMultiCheckbox",
      advancedStyles: "margin-min-5",
      optionsQuery: gql`
        {
          QPLs {
            uuid
            value
          }
        }
      `
    },
    {
      name: "oldProductCategories",
      title: "Existing Rebate Categories",
      inputType: "multiSelect",
      optionsQuery: gql`
        {
          oldProductCategories {
            uuid
            name
            qpluuid
            isHidden
          }
        }
      `
    },
    {
      name: "newProductCategories",
      title: "New Rebate Categories",
      inputType: "multiSelect",
      optionsQuery: gql`
        {
          newProductCategories {
            uuid
            name
            qpluuid
            isHidden
          }
        }
      `
    },
    {
      name: "code",
      title: "Rebate Code",
      inputType: "input"
    },
    {
      name: "Specify Requirements",
      step: "Step Three",
      inputType: "rebate-step"
    },
    {
      name: "hasRequirements",
      title: "Rebate has additional requirements",
      inputType: "checkbox"
    },
    {
      name: "requirementsTable",
      title: "Requirements table",
      inputType: "table",
      dependent: true,
      dependentOn: "hasRequirements",
      tableHeaders: [
        "Requirements",
        "Operator",
        "Value",
        "Unit"
      ],
      optionsQuery: gql`
      {
        rebateRequirementTypes {
          value
          uuid
          productSpecType {
            value
            uuid
          }
          technologyType {
            value
            uuid
          }
        }
        productSpecTypes {
          value
          uuid
          isHidden
        }
      }`,
      inputs: [
        {
          name: "requirementType",
          title: "Requirement",
          inputType: "checkbox",
          optionsGQLType: "rebateRequirementTypes"
        },
        {
          name: "requirementOperator",
          title: "Operator",
          inputType: "select",
          options: [
            { value: "/gt", name: "Greater than" },
            { value: "/geq", name: "Greater than or equal to" },
            { value: "/lt", name: "Less than" },
            { value: "/leq", name: "Less than or equal to" },
            { value: "/exactly", name: "Exactly" },
            { value: "/between", name: "Between" },
            { value: "/value", name: "Value" },
            { value: "/percent", name: "Percentage" }
          ]
        },
        {
          name: "requirementValue",
          title: "Value",
          inputType: "input"
        },
        {
          name: "requirementUnit",
          title: "Unit",
          inputType: "select",
          optionsGQLType: "productSpecTypes"
        }
      ]
    },
    // {
    //   name: "requirements",
    //   title: "Requirements",
    //   dependent: true,
    //   dependentOn: "hasRequirements",
    //   inputType: "requirements",
    //   inputs: [
    //     {
    //       name: "requirementType",
    //       title: "Requirement",
    //       inputType: "creatableSelect",
    //       optionsQuery: gql`
    //         {
    //           rebateRequirementTypes {
    //             value
    //             uuid
    //             productSpecType {
    //               value
    //               uuid
    //             }
    //             technologyType {
    //               value
    //               uuid
    //             }
    //           }
    //         }
    //       `
    //     },
    //     {
    //       name: "requirementOperator",
    //       title: "Operator",
    //       inputType: "select",
    //       options: [
    //         { value: "/gt", name: "Greater than" },
    //         { value: "/geq", name: "Greater than or equal to" },
    //         { value: "/lt", name: "Less than" },
    //         { value: "/leq", name: "Less than or equal to" },
    //         { value: "/exactly", name: "Exactly" },
    //         { value: "/between", name: "Between" },
    //         { value: "/value", name: "Value" },
    //         { value: "/percent", name: "Percentage" }
    //       ]
    //     },
    //     {
    //       name: "requirementValue",
    //       title: "Value",

    //       inputType: "input"
    //     },
    //     {
    //       name: "requirementUnit",
    //       title: "Unit",

    //       inputType: "gqlSelect",
    //       optionsQuery: gql`
    //         {
    //           productSpecTypes {
    //             value
    //             uuid
    //             isHidden
    //           }
    //         }
    //       `
    //     }
    //   ]
    // },
    {
      name: "hasExpiration",
      title: "Rebate has separate expiration date from program",
      inputType: "checkbox"
    },
    {
      name: "expiration",
      title: "Expiration",
      inputType: "datepicker",
      dependent: true,
      dependentOn: "hasExpiration"
    },
    {
      name: "notes",
      title: "Notes",
      inputType: "textarea"
    }
  ]
};

export const emptyFormState = {
  country: { isHidden: false, name: "", description: "" },
  state: {
    name: "",
    description: "",
    efficiencyScore: "",
    averageRetailPrice: "",
    netSummerCapacity: "",
    netGeneration: "",
    totalRetailSales: "",
    regionId: "",
    countryId: "",
    reports: [{ name: "", url: "", description: "" }]
  },
  utility: {
    name: "",
    logoUrl: "",
    stateId: "",
    description: "",
    utilityOwnershipTypeId: "",
    address: "",
    city: "",
    zipCode: "",
    websites: [{ linkText: "", url: "" }],
    socialMediaLinks: [{ linkText: "", url: "" }],
  },
  program: {
    // * customerTypes & offeringTypes are queried in AddEdit's cdm & passed to WikiForm
    isHidden: false,
    name: "",
    hasMultipleUtilities: false,
    stateId: "",
    singleUtility: "",
    multipleUtilities: [{ utility: "" }],
    startDate: null,
    endDate: null,
    description: "",
    websites: [{ linkText: "", url: "" }],
    contacts: [{ name: "", title: "", email: "", phone1: "", phone2: "" }],
    applicationLinks: [{ linkText: "", url: "" }],
    isActive: true,
    statusNotes: "",
    hasDownstreamProgram: false,
    downstreamProgram: {
      isPreApprovalRequired: false,
      isPreInspectionRequired: false,
      isPostAuditRequired: false
    },
    requirements: "",
    maximumAnnualIncentive: "",
    totalAvailable: "",
    totalUtilized: "",
    projectCap: "",
    laborCap: "",
    materialCap: "",
    reports: [{ name: "", url: "", description: "" }]
  },
  zipcode: {
    isHidden: false,
    name: ""
  },
  rebate: {
    // * QPLs, projectTypes are queried in AddEdit's cdm & passed to WikiForm
    rebateName: "",
    programId: "",
    technologyTypeId: "",
    oldProductCategories: [],
    newProductCategories: [],
    code: "",
    rebateTypeId: "",
    prescriptiveRebateValue: [{ prescriptiveValue: "", prescriptiveUnit: "" }],
    calculatedRebateValue: [{ value: "", unit: "" }],
    hasMinimumCustomerContribution: false,
    minimumCustomerContribution: "",
    hasCap: false,
    cap: "",
    hasRequirements: false,
    requirementsTable: [],
    requirements: [
      {
        requirementType: { value: "", uuid: "", productSpecType: "" },
        requirementOperator: "",
        requirementValue: "",
        requirementUnit: ""
      }
    ],
    notes: "",
    label: {},
    hasExpiration: false,
    expiration: null
  },
  product: {
    isHidden: false,
    modelNumber: "",
    imageUrl: "",
    manufacturerId: "",
    brandId: "",
    QPLIdentifier: "",
    QPLId: "",
    productTypeId: "",
    technologyTypeId: "",
    newProductCategoryId: "",
    productSubCategoryId: "",
    specSheetUrl: "",
    productSpecs: [
      {
        value: "",
        productSpecTypeId: ""
      }
    ]
  },
  manufacturer: {
    name: "",
    description: "",
    brands: [{ brandName: "" }],
    hidden: true,
    website: ""
  }
};
