import AttachmentsPanel, {AttachmentFile} from "../Common/components/AttachmentsPanel/AttachmentsPanel";
import React, {FormEvent, useEffect, useRef, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {
    DELETE_ATTACHMENT,
    DeleteAttachmentParams,
    LIST_ATTACHMENTS,
    ListAttachmentsParams,
    ListAttachmentsResponse,
    MutationResponse
} from "./queries";
import {
    ActionButton,
    AlertDialog,
    ButtonGroup,
    Content,
    Dialog,
    DialogContainer,
    Divider,
    Flex,
    Form,
    Heading, ProgressCircle,
    Text,
    useDialogContainer,
    View
} from "@adobe/react-spectrum";
import {UUID} from "../../types";
import {FlexProps} from "@react-types/layout";
import {captureMessage} from "@sentry/react";
import axios from "axios";

interface RebateAttachmentsPanelParams {
    clientId: UUID,
    applicationId: UUID,
    style?: Partial<FlexProps>,
}

interface WrapperProps {
    style?: Partial<FlexProps>,
    children: any,
}

function Wrapper({style, children}: WrapperProps) {
    return (
        <Flex {...style}>
            {children}
        </Flex>
    )
}

/**
 * @see https://stackoverflow.com/a/57721086
 */
function download(filename: string, blob: Blob, callback: () => void) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);

    a.setAttribute("download", filename);
    callback();
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
}

type Callback = () => void;

interface UploadDialogProps {
    clientId: UUID,
    applicationId: UUID,
    callback: Callback,
}

function UploadDialog({
                          applicationId,
                          clientId,
                          callback,
                      }: UploadDialogProps) {

    const [isUploading, setIsUploading] = useState(false);
    const dialog = useDialogContainer();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const uploadFile = async (event: FormEvent) => {
        event.preventDefault();
        if (!fileInputRef.current?.files || fileInputRef.current?.files.length === 0) {
            return alert("First choose a file to upload.")
        }
        const file = fileInputRef.current?.files.item(0)!;

        if (file.size > 20 * 1024 * 1024) {
            return alert("File must not be larger than 20 megabytes");
        }

        let formData = new FormData();
        formData.append('attachment', file);

        setIsUploading(true);
        axios.post(
            `/api/v2/applications/attachments/${clientId}/${applicationId}`,
            formData,
            {
                headers: {
                    "Authorization": localStorage.getItem("token") || "",
                    "Content-type": "multipart/form-data",
                },
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    dialog.dismiss();
                    callback();
                } else if (res.status === 500) {
                    const text = res.data;
                    console.error(text);
                    captureMessage(text);
                    alert("Sorry, there was an error. Please try again later.");
                } else {
                    alert("Sorry, there was an error.");
                }
            })
            .catch((error) => {
                console.error(error);
                captureMessage(error.message);
                alert("Sorry, there was an error. Please try again later.")
            })
    }

    return (
        <Dialog>
            <Heading>Add attachment</Heading>
            <Divider/>
            <Content>
                <View paddingY="size-200">
                    <Form labelPosition="side" width="100%" onSubmit={uploadFile}>
                        <input type="file" ref={fileInputRef} name="attachment"/>
                        <Text>Max file size: 20mb</Text>
                        {
                            isUploading ? <ProgressCircle aria-label="Loading…" isIndeterminate
                                                          UNSAFE_style={{display: "block"}}/> : (
                                <ActionButton
                                    marginTop="size-200"
                                    type="submit"
                                    UNSAFE_style={{padding: "0 0.5em", display: "block"}}
                                >
                                    Upload
                                </ActionButton>
                            )
                        }
                    </Form>
                </View>
            </Content>
            <ButtonGroup>
                <ActionButton onPress={dialog.dismiss} isQuiet UNSAFE_style={{padding: "0 0.5em", display: "block"}}>
                    Cancel
                </ActionButton>

            </ButtonGroup>
        </Dialog>
    );
}

export default function RebateAttachmentsPanel({
                                                   clientId,
                                                   applicationId,
                                                   style = {},
                                               }: RebateAttachmentsPanelParams) {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = useState<string | null>(null);
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);

    const [getAttachments, {
        loading: isLoadingAttachmentList,
        error: attachmentsError,
        data: attachmentsResponse,
    }] = useLazyQuery<ListAttachmentsResponse, ListAttachmentsParams>(LIST_ATTACHMENTS, {
        fetchPolicy: "cache-and-network",
        variables: {
            userId: clientId,
            applicationId,
        },
    });

    useEffect(() => {
        getAttachments();
    }, [getAttachments]);

    const attachments: Array<AttachmentFile> | null = attachmentsResponse?.data.attachments
        .map((item) => {
            return {
                name: item.key,
                displayName: item.name,
                createdAt: item.createdAt,
            }
        }) || [];

    const [deleteAttachment, {
        loading: isDeleting,
        error: errorDeleting,
    }] = useMutation<MutationResponse, DeleteAttachmentParams>(DELETE_ATTACHMENT, {
        onCompleted: () => {
            getAttachments();
        },
    });

    if (attachmentsError) {
        return <Wrapper style={style}><Text>There was an error loading the attachments.</Text></Wrapper>
    }

    if (errorDeleting) {
        return <Wrapper style={style}><Text>Sorry, there was an error deleting the attachment.</Text></Wrapper>
    }

    return (
        <>
            <AttachmentsPanel
                style={style}
                files={attachments}
                isFetching={isLoadingAttachmentList || isDeleting || isDownloadingFile}
                onUpload={() => setIsUploadDialogOpen(true)}
                onDelete={(key) => {
                    const parts = key.split("/");
                    setAttachmentToDelete(parts[parts.length - 1]);
                    setIsDeleteDialogOpen(true);
                }}
                onDownload={(key) => {
                    setIsDownloadingFile(true);
                    fetch(`/api/v2/applications/attachments/${key}`, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        }
                    }).then(async (res) => {
                        let nameParts = key.split("/");
                        const filename = nameParts[nameParts.length - 1].split(" - ")[1];
                        download(filename, await res.blob(), () => setIsDownloadingFile(false));
                    })
                }}
            />
            <DialogContainer onDismiss={() => {
                setIsUploadDialogOpen(false);
            }}>
                {isUploadDialogOpen &&
                <UploadDialog clientId={clientId} applicationId={applicationId} callback={() => getAttachments()}/>}
            </DialogContainer>
            <DialogContainer onDismiss={() => {
                setIsDeleteDialogOpen(false);
            }}>
                {isDeleteDialogOpen && (
                    <AlertDialog
                        variant="destructive"
                        title="Delete attachment"
                        primaryActionLabel="Delete"
                        cancelLabel="Cancel"
                        onPrimaryAction={() => {
                            if (attachmentToDelete !== null) {
                                deleteAttachment({
                                    variables: {
                                        payload: {
                                            clientId,
                                            applicationId,
                                            objectId: attachmentToDelete,
                                        }
                                    },
                                });
                            }
                        }}
                    >
                        Are you sure you want to delete this attachment?
                    </AlertDialog>
                )}
            </DialogContainer>

        </>
    )
}
