import React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { showMessages } from "../Common/components/Message";
import { dismissMessage } from "../Common/ducks/actions";
import styled from "styled-components";
import MainContentWrapper from "../Common/components/layout/MainContentWindowWrapper";
import SignupForm from "./components/SignupForm";
import CompanySignup from "./components/CompanySignup";
import CompleteSignup from "./components/CompleteSignup";
import V1UserSignup from "./components/V1UserSignup";

import Card from "../Common/components/Card";
import { PrimaryButton } from "../Common/components/Button";
import MediaQuery from "react-responsive";

const Signup = ({ messages, dismissMessage, ...props }) => {
  return (
    <main id="mainContentContainer">
      <div id="mainContentWindowContainer">
        {showMessages(messages, dismissMessage)}
        <WindowWrapper>
          <Switch>
            <Route
              path="/signup/company/:companyId"
              render={(props) => <CompleteSignup {...props} />}
            />
            <Route
              path="/signup/company"
              render={(props) => <CompanySignup {...props} />}
            />
            <Route path="/signup" component={SignupForm} />
          </Switch>
        </WindowWrapper>
      </div>
    </main>
  );
};

export default connect(null, { dismissMessage })(Signup);

const WindowWrapper = styled(MainContentWrapper)`
  .signupOptions {
    margin-top: 4em;
    display: flex;
    justify-content: space-between;
  }
  .imgWrapper {
    margin-bottom: 2em;
  }
`;

const SignupCard = styled(Card)`
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SignupOptions = () => (
  <>
    <h1>Sign Up</h1>
    <p>Are you new to Rebate Bus, or do you have an existing account?</p>
    <div className="signupOptions">
      <MediaQuery minDeviceWidth={769}>
        <SignupCard padding="3em">
          <div className="imgWrapper">
            <img
              src="https://s3.amazonaws.com/v2.rebatebus/images/decorative/signupIndividual.svg"
              alt="New User Signup"
            />
          </div>
          <Link to="/signup/new">
            <PrimaryButton>I'm a new rebate bus user</PrimaryButton>
          </Link>
        </SignupCard>
        <SignupCard padding="3em">
          <div className="imgWrapper">
            <img
              src="https://s3.amazonaws.com/v2.rebatebus/images/decorative/signupCompany.svg"
              alt="Existing User Signup"
            />
          </div>
          <Link to="/signup/existing">
            <PrimaryButton>I already have an account</PrimaryButton>
          </Link>
        </SignupCard>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={768}>
        <MobileWrapper>
          <Link to="/signup/new">
            <PrimaryButton>I'm a new rebate bus user</PrimaryButton>
          </Link>{" "}
          <Link to="/signup/existing">
            <PrimaryButton>I already have an account</PrimaryButton>
          </Link>
        </MobileWrapper>
      </MediaQuery>
    </div>
  </>
);
