import React from "react";
import { Helmet } from "react-helmet";

import { PageHeader, HtmlSection } from "./PageComponents";
import { Section } from "./StyledPageComponents";
import Relationship from "../Relationship";

import alphabetize from "../../helpers/alphabetize";

const Country = ({ country, type, url, permissions }) => {
  if (country.isHidden) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Country Energy Efficiency Information"
          />
          <title>{`Rebate Bus | Country | ${country.name}`}</title>
        </Helmet>
        <PageHeader
          name={country.name}
          type={type}
          url={url}
          permissions={permissions}
        />
        <p>This page is currently undergoing maintenance.</p>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Country Energy Efficiency Information"
        />
        <title>{`Rebate Bus | Country | ${country.name}`}</title>
      </Helmet>
      <PageHeader
        name={country.name}
        type={type}
        url={url}
        permissions={permissions}
      />
      {country.description && (
        <Section>
          <h2>General Info</h2>
          <HtmlSection longText={country.description} />
        </Section>
      )}
      <Section>
        <h2>Relationships</h2>
        <p>
          The diagram below shows the relationship between {country.name} and
          its child states.
        </p>
        <Relationship type="Country" {...country} topLevel>
          <Relationship
            type="State/Territory"
            relations={alphabetize(country.states, "name")}
            childRelation
          />
        </Relationship>
      </Section>
    </>
  );
};

export default Country;
