import { combineReducers } from "redux";
import rebates from "./rebateReducers";
import search from "./searchReducers";
import wiki from "./wikiReducers";

export default combineReducers({
  rebates,
  search,
  wiki
});
