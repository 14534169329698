import React from "react";

export default [
  {
    title: "Rebate Bus Announces New Software Release",
    date: "3-5-2020",
    description:
      "Improved features help centralize information, while amplifying product rebates, and enable sales across our digital platform.",
    text: (
      <>
        <p>
          <span>Madison, March 5, 2020</span> Rebate Bus has deployed a new
          release of its web app that focuses on helping users find rebate
          programs and qualified products at a local and national level.
          Manufacturers, distributors, and program implementers are able to find
          rebates for their qualified products more effectively on a digital,
          cloud-based platform. With an industry that has thousands of
          utilities, each potentially offering hundreds of rebates on energy
          efficient products, it can be taxing to participate in programs across
          a large geographic scope.
        </p>
        <p>
          “The true value of our platform is having the ability for end users to
          quickly and easily connect with Energy Efficiency programs and
          qualified products that are traditionally scattered across utility
          program websites,” said Joe Pater, CEO. “The new version of Rebate Bus
          centralizes everything in one platform. This not only helps end users
          successfully participate in programs, but it also simplifies the
          rebate process and improves qualified product visibility, which is a
          win-win for everyone involved.”
        </p>
        <p>
          <span>New features include:</span>
        </p>
        <ul>
          <li>
            <span>Direct Search</span>, which enables Rebate Bus users to search
            thousands of data points to identify top programs around the US and
            qualified products.
          </li>
          <li>
            <span>Enhanced user exxperience</span>, which makes finding what you
            need easier and now with increased speed and responsive design
          </li>
          <li>
            <span>Improved site functionality</span> that makes it easier for
            users to search utilities and programs while locating content by
            keyword, such as utility or program name
          </li>
          <li>
            <span>Robust GraphQL API</span> which allows users to integrate
            Rebate Bus data to their own website or web application
          </li>
          <li>
            <span>Automation</span>, which in future releases will allow premium
            users to generate reports, estimates, proposals and more with the
            click of a button
          </li>
        </ul>
        <p>
          “With the goal of amplifying the reach of rebate programs and increase
          participation in energy efficiency programs as two of our main
          objectives of our software development, we also further our commitment
          to sales enablement and user experience,” says Joe Baz, Chief
          Technology Officer.
        </p>
        <p>
          “The functionality of our new software continues to evolve,” said Baz.
          “With this latest version of Rebate Bus, we are again improving the
          performance, while at the same time incorporating many new features
          requested by our fast-growing customer base.”
        </p>
        <p>
          The new update also features a complete redesign of the Rebate Bus
          site, giving centralized access to thousands of rebates, hundreds of
          rebate programs, and a plethora of qualified products -- all organized
          in one tool. These optimizations provide greater functionality to the
          web application, and the new API can be quickly integrated into
          customers sites with customization available.
        </p>
        <p>For more information about Rebate Bus, visit RebateBus.com</p>
        <p>
          <span>About Rebate Bus</span>
        </p>
        <p>
          Rebate Bus is a leading data and business intelligence company,
          serving the complete workflow of today's energy efficiency rebate
          programs and product database. Offering the industry's most
          comprehensive data and business intelligence, Rebate Bus enables
          clients to increase sales and capitalize on energy efficient product
          rebates. Headquartered in Madison, Rebate Bus has hundreds of
          customers nationwide and maintains offices in Madison, Sheboygan,
          Denver, and St. Louis. For more information, visit www.RebateBus.com
          or follow{" "}
          <a
            href="https://www.linkedin.com/company/rebate-bus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @RebateBus on Linkedin
          </a>
          .
        </p>
        <p>
          <span>For More Information:</span>
        </p>
        <div className="moreInfo">
          <p>Chrissy Allard</p>
          <p>CMO, Rebate Bus</p>
          <p>
            920.723.0262,{" "}
            <a href="mailto:chrissy@rebatebus.com">Chrissy@RebateBus.com</a>
          </p>
        </div>
      </>
    )
  }
];
