import React, {CSSProperties} from "react";
import {RebateApplicationStage, RebateApplicationStageDisplayNames} from "./types";

function ItemTail({style}: { style?: CSSProperties }) {
    return <div style={{
        clipPath: "polygon(100% 0, 100% 100%, 0% 100%, 90% 50%, 0% 0%)",
        backgroundColor: "#16a888",
        minWidth: "10px",
        maxWidth: "10px",
        minHeight: "21px",
        maxHeight: "21px",
        transform: "translateX(1px)",
        ...style,
    }}/>
}

function ItemHead({style}: { style?: CSSProperties }) {
    return <div style={{
        clipPath: "polygon(15% 0, 100% 50%, 15% 100%, 0% 100%, 0 0)",
        backgroundColor: "#16a888",
        minWidth: "10px",
        maxWidth: "10px",
        minHeight: "21px",
        maxHeight: "21px",
        transform: "translateX(-1px)",
        ...style,
    }}/>
}

function Item({
                  children,
                  wrapperStyle,
                  centerStyle
              }: { children: any, wrapperStyle?: CSSProperties, centerStyle?: CSSProperties }) {
    return (
        <div style={{
            display: "flex",
            ...wrapperStyle,
        }}>
            <ItemTail
                style={centerStyle}
            />
            <div style={{
                color: "white",
                padding: "0 0.5em",
                ...centerStyle,
            }}>{children}</div>
            <ItemHead style={centerStyle}/>
        </div>
    )
}

export interface StageTrackerProps {
    selectedStage: RebateApplicationStage,
}

function StageTracker({selectedStage}: StageTrackerProps) {
    if (selectedStage === RebateApplicationStage.NO_REBATE) {
        return <></>
    }

    return (
        <div style={{
            display: "flex",
        }}>
            {
                Object.values(RebateApplicationStage)
                    .filter((stage) => stage !== RebateApplicationStage.NO_REBATE)
                    .map((stage, i) => (
                    <Item
                        key={stage}
                        wrapperStyle={{transform: `translateX(-${(i + 1) * 8}px)`}}
                        centerStyle={{backgroundColor: selectedStage === stage ? '#ec54a8' : '#16a888'}}
                    >{RebateApplicationStageDisplayNames.get(stage)}</Item>
                ))
            }
        </div>
    )
}

export default StageTracker;
