const iconUrl = "https://s3.amazonaws.com/v2.rebatebus/images/icons/";

export default {
  Lighting: {
    icon: `${iconUrl}lightingIcon.svg`,
    alt: "Lighting Rebates",
    title: "Lighting rebates"
  },
  HVAC: {
    icon: `${iconUrl}HVACIcon.svg`,
    alt: "HVAC Rebates",
    title: "HVAC rebates"
  },
  "Commercial Kitchen": {
    icon: `${iconUrl}commercialKitchenIcon.svg`,
    alt: "Commercial Kitchen rebates",
    title: "Commercial Kitchen rebates"
  },
  "Electric Vehicle": {
    icon: `${iconUrl}electricVehicleIcon.svg`,
    alt: "Electric Vehicle rebates",
    title: "Electric Vehicle rebates"
  },
  "Smart Thermostats": {
    icon: `${iconUrl}smartThermostatIcon.svg`,
    alt: "Smart Thermostats rebates",
    title: "Smart Thermostats rebates"
  },
  Solar: {
    icon: `${iconUrl}solarIcon.svg`,
    alt: "Solar rebates",
    title: "Solar rebates"
  },
  "Charging Stations": {
    icon: `${iconUrl}chargingStationIcon.svg`,
    alt: "Charging Stations rebates",
    title: "Charging Stations rebates"
  }
};
