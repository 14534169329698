import { ADD_STORY, DELETE_STORY } from "./types";

import articles from "../helpers/articles";

const initialState = {
  dataStories: articles["dataHighlights"],
  gettingStartedStories: articles["gettingStarted"]
};

export default (state = initialState, action) => {
  const { story, storyId } = action;
  const { dataStories, gettingStartedStories } = state;

  switch (action.type) {
    case ADD_STORY: {
      const { section } = story;
      const stateToReturn =
        section === "data"
          ? { ...state, dataStories: [...dataStories, story] }
          : {
              ...state,
              gettingStartedStories: [...gettingStartedStories, story]
            };
      return stateToReturn;
    }
    case DELETE_STORY: {
      return {
        ...state,
        dataStories: [...dataStories.filter(story => story.id !== storyId)],
        gettingStartedStories: [
          ...gettingStartedStories.filter(story => story.id !== storyId)
        ]
      };
    }

    default:
      return state;
  }
};
