import React from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "./PageComponents";
import { Section } from "./StyledPageComponents";
import Relationship from "../Relationship";

import alphabetize from "../../helpers/alphabetize";

const ZipCode = ({
  zipCode: { value, uuid, states, utilities },
  type,
  url,
  permissions
}) => {
  const Self =
    utilities && utilities.length > 0 ? (
      <Relationship
        type="Utility"
        childRelation
        relations={alphabetize(utilities, "name")}
      >
        <Relationship type="Zip Code" name={value} uuid={uuid} />
      </Relationship>
    ) : (
      <Relationship type="Zip Code" name={value} uuid={uuid} />
    );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Zip Code Utility Energy Efficiency Information"
        />
        <title>{`Rebate Bus | Zip Code | ${value}`}</title>
      </Helmet>
      <PageHeader
        name={value}
        type={type}
        url={url}
        permissions={permissions}
      />
      <Section>
        <h2>Relationships</h2>
        <p>
          The diagram below shows the relationship between {value} and its
          parent state and the utility that services it.
        </p>
        {states && states.length === 1 ? (
          <Relationship type="State/Territory" {...states[0]}>
            {Self}
          </Relationship>
        ) : states && states.length > 1 ? (
          <Relationship
            type="State/Territory"
            relations={alphabetize(states, "name")}
            childRelation
          >
            {Self}
          </Relationship>
        ) : (
          Self
        )}
      </Section>
    </>
  );
};

export default ZipCode;
