import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { findUserToComplete, completeUserSignup } from "../ducks/userActions";

import Input from "../../Common/components/formComponents/Input";
import PhoneInput from "../../Common/components/formComponents/PhoneInput";
import { PrimaryButton } from "../../Common/components/Button";
import Loading from "../../Common/components/Loading";

class CompleteSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      loading: false,
      errors: [],
      redirect: false,
      invalidUser: false,
    };
    this.redirectTimeout = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, signupComplete } = this.props;
    if (prevProps.user !== user) {
      if (user.uuid) {
        const { firstName, lastName, email, uuid } = user;

        this.setState({
          loading: false,
          firstName,
          lastName,
          email,
          uuid,
        });
      } else {
        this.setState({ invalidUser: true, loading: false });
      }
    }

    if (prevProps.signupComplete !== signupComplete && signupComplete) {
      this.setState({ loading: false });
      this.redirectTimeout = setTimeout(
        () => this.setState({ redirect: true }),
        3000
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeout);
  }

  handleChange = (id, value) => {
    const { errors } = this.state;

    const newErrors = [...errors];

    if (newErrors.indexOf(id) >= 0 && value.length > 0) {
      newErrors.splice(newErrors.indexOf(id), 1);
    }

    this.setState({
      [id]: value,
      errors: [...newErrors],
    });
  };

  generateFields = (array) =>
    array.map((field) => {
      const { type, label, name, inputType, ...rest } = field;

      const isError = this.state.errors.indexOf(field.name) >= 0;

      const inputProps = {
        key: name,
        id: name,
        inputType: "input",
        type: inputType,
        title: label,
        error: isError,
        helperText:
          isError && field.name === "password"
            ? "Passwords do not match!"
            : isError
            ? "This field is required!"
            : field.helperText,
        value: this.state[name],
        handleChange: this.handleChange,
        ...rest,
      };

      switch (type) {
        case "phone":
          return <PhoneInput {...inputProps} />;
        default:
          return <Input {...inputProps} />;
      }
    });

  submitEmail = () => {
    const { email, errors } = this.state;

    const {
      match: { params },
      findUserToComplete,
    } = this.props;

    if (email.length < 1) {
      this.setState({ errors: [...errors, "email"] });
    } else {
      this.setState({ loading: true });
      findUserToComplete(this.state.email, params.companyId);
    }
  };

  checkForErrors = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "password",
      "passwordConfirm",
    ];

    const newErrors = [];

    requiredFields.forEach((field) => {
      if (this.state[field].length < 1) {
        newErrors.push(field);
      }
    });

    if (newErrors.length > 0) {
      this.setState({ errors: newErrors });
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { uuid, firstName, lastName, email, phone, password } = this.state;
    const newUser = {
      uuid,
      firstName,
      lastName,
      email,
      phone,
      password,
    };
    this.setState({ loading: true });
    this.props.completeUserSignup(newUser);
  };

  render() {
    const { loading, email, errors, redirect, invalidUser } = this.state;
    const { isEmailConfirmed, signupComplete } = this.props;

    const showForm =
      !loading && !isEmailConfirmed && !invalidUser && isEmailConfirmed;

    return (
      <>
        <h1>Finish Setting Up Your Account</h1>
        {loading && <Loading />}
        {redirect && <Redirect to="/dashboard" />}
        {invalidUser && (
          <p>
            No account was found that required setting up. If you believe this
            was an error, double check the email address and try again.
            Otherwise have your company admin contact team@rebatebus.com for
            further assistance.
          </p>
        )}
        {!loading && !isEmailConfirmed && (
          <>
            <Input
              id="email"
              inputType="input"
              title="Email Address"
              error={errors.indexOf("email") > -1}
              helperText={
                errors.indexOf("email") > -1
                  ? "This field is required!"
                  : "Enter the email address that was invited to join Rebate Bus."
              }
              value={email}
              handleChange={this.handleChange}
            />
            <PrimaryButton handleClick={this.submitEmail}>
              Submit Email Address
            </PrimaryButton>
          </>
        )}
        {showForm && (
          <>
            {this.generateFields(formFields)}
            <PrimaryButton handleClick={this.checkForErrors}>
              complete Setup
            </PrimaryButton>
          </>
        )}
        {!loading && signupComplete && (
          <p>
            Your account has been set up! You will be redirected to the
            Dashboard momentarily.
          </p>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEmailConfirmed: state.signup.isEmailConfirmed,
  user: state.signup.user,
  signupComplete: state.signup.signupComplete,
});

export default connect(mapStateToProps, {
  findUserToComplete,
  completeUserSignup,
})(CompleteSignup);

const formFields = [
  {
    name: "email",
    label: "Email",
    inputType: "email",
    required: true,
  },
  { name: "firstName", label: "First Name", required: true },
  { name: "lastName", label: "Last Name", required: true },
  { name: "phone", label: "Phone", type: "phone" },
  {
    name: "password",
    label: "Password",
    required: true,
    inputType: "password",
  },
  {
    name: "passwordConfirm",
    label: "Confirm Password",
    required: true,
    inputType: "password",
  },
];
