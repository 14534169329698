import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "styled-components";

/**
 * The UserImage shows the image of a user. It is used in the UserBadge, which displays on the TopMenu and shows the current user.
 * It is also used on Wiki pages to show contributors.
 *
 * _In the future it will also link to the user's profile page._
 * @param {object} Props -- uses the image URL and name of the user.
 * @returns {UserImage Component}
 */

const UserImage = ({ img, name }) => {
  let image;

  if (!img || img.length < 1) {
    // if img is null or an empty string
    let initials = [];
    initials = name.match(/\b(\w)/g);
    image = (
      <div className="userImageGeneric" data-test="no-image">
        <span>{initials}</span>
      </div>
    );
  } else {
    image = <img src={img} alt={name} />;
  }

  return (
    <StyledUserImage>
      <Link to="/profile">{image}</Link>
    </StyledUserImage>
  );
};

export default UserImage;

UserImage.propTypes = {
  /**
   * The URL for the user's image. On the Wiki, if the URL is not supplied, it will default to a div
   * with the user's initials. On the TopMenu, if the current user doesn't have a profile picture, it will
   * default to a generic user icon.
   */
  img: PropTypes.string,
  /** The user's name. Used if there isn't an image URL. */
  name: PropTypes.string.isRequired
};

const StyledUserImage = styled.div`
  margin: 0 1em;
  border-radius: 50%;

  img {
    height: 2em;
  }

  .userImageGeneric {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    background-color: #16a888;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: white;
    }
  }
`;
