import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signoutUser } from "../../Account/ducks/actions";

import styled from "styled-components";
import MenuItem from "./MenuItem.jsx";

const StyledIconMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: "flex-start";
  margin: ${(props) => (props.isDesktop ? 0 : "2em 0 0 3.5em")};
  img {
    width: ${(props) => (props.isDesktop ? "1.3em" : "1.5em")};
  }
`;

const IconMenu = ({ isDesktop, userPermissions, isLoggedIn, signoutUser }) => {
  const menuItemsToRender = isDesktop
    ? menuItems.desktop
    : [...menuItems.desktop, ...menuItems.mobile];

  const generateIcons = menuItemsToRender
    .filter((item) => (isLoggedIn ? item : !item.loggedIn))
    .filter((item) =>
      item.permissionRequired
        ? userPermissions >= item.permissionRequired
        : item
    )
    .map(({ submenu, ...item }) => (
      <MenuItem
        handleClick={item.linkText === "Sign Out" ? () => signoutUser() : null}
        {...item}
        key={item.imgAlt}
        isDesktop={isDesktop}
        hasSubmenu={submenu ? true : false}
      >
        {submenu &&
          submenu.map((item) => (
            <MenuItem submenu isDesktop key={item.linkText} {...item} />
          ))}
      </MenuItem>
    ));

  return <StyledIconMenu isDesktop={isDesktop}>{generateIcons}</StyledIconMenu>;
};

export default connect(null, { signoutUser })(IconMenu);

IconMenu.propTypes = {
  /** Determines whether to show only Desktop options */
  isDesktop: PropTypes.bool,
  /** User permissions to determine what is shown on the menu. */
  userPermissions: PropTypes.number.isRequired,
  /** Logged in status to determine what the menu displays. */
  isLoggedIn: PropTypes.bool.isRequired,
};

const s3Url = "https://s3.amazonaws.com/v2.rebatebus/images/icons";

const menuItems = {
  desktop: [
    {
      imgSrc: `${s3Url}/homeIcon.svg`,
      imgAlt: "Rebate Bus Home",
      linkText: "Home",
      url: "/",
      submenu: [
        { linkText: "Fulfillment Services", url: "/fulfillment-services" },
        { linkText: "Rebate Tracker", url: "/services/rebates/list" },
        { linkText: "Rebate Bus API", url: "/rebate-bus-api" },
        { linkText: "Contact Us", url: "/contact-us" },
      ],
    },
    {
      imgSrc: `${s3Url}/searchIconOrange.svg`,
      imgAlt: "Search",
      linkText: "Search",
      url: "/search",
      submenu: [
        { linkText: "Rebate Search", url: "/search/rebates/lighting" },
        { linkText: "Direct Search", url: "/search" },
        { linkText: "Program Map", url: "/search/program-map" },
      ],
    },
    {
      imgSrc: `${s3Url}/helpAndSupportIcon.svg`,
      imgAlt: "Help and Support",
      linkText: "Help and Support",
      url: "/support",
      location: "desktop",
    },
  ],
  mobile: [
    {
      imgSrc: `${s3Url}/profileIconGreen.svg`,
      imgAlt: "Profile Icon",
      linkText: "Profile",
      url: "/profile",
      loggedIn: true,
    },
    {
      imgSrc: `${s3Url}/signOutIconGreen.svg`,
      imgAlt: "Sign Out",
      linkText: "Sign Out",
      url: "/",
      loggedIn: true,
    },
  ],
};
