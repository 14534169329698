import React, { Component } from "react";

class Documentation extends Component {
  render() {
    return (
      <main id="mainContentContainer">
        <div id="mainContentWindowContainer">
          <div id="mainContentWindowWrapper">
            <h1>Dev Docs</h1>
            <p>Descriptive text</p>
          </div>
        </div>
      </main>
    );
  }
}

export default Documentation;
