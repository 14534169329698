import {gql} from "apollo-boost";
import {
    RebateApplication,
    RebateApplicationCompany,
    RebateApplicationFulfillmentManager, RebateApplicationInput,
    RebateApplicationStage
} from "./types";
import {SelectOption, UUID} from "../../types";

interface RebateApplicationsWhere {
    userIds: Array<UUID>,
    stages: Array<RebateApplicationStage>,
    fulfillmentManagerIds: Array<UUID>,
}

export interface GetRebateApplicationsParams {
    where: RebateApplicationsWhere,
    limit: number,
    offset: number,
}

export interface RebateApplicationsResponse {
    response: {
        applications: Array<RebateApplication>,
    }
}

export const GET_REBATE_APPLICATIONS = gql`
    query GetRebateApplications(
        $where: RebateApplicationsWhere,
        $limit: Int,
        $offset: Int,
    ) {
        response: rebateApplications(where: $where, limit: $limit, offset: $offset) {
            applications {
                applicationId,
                id,
                company {
                    id
                    name
                },
                customer,
                projectName,
                stage,
                fulfillmentManager {
                    id
                    name
                },
                country  {
                    id
                    name
                },
                usState  {
                    id
                    name
                },
                canadianArea  {
                    id
                    name
                },
                city,
                zipCode,
                budgetYear,
                technologyType  {
                    id
                    name
                },
                utility  {
                    id
                    name
                },
                fulfillmentCharge,
                projectCost,
                rebateEstimate,
                managementFee,
                notes,
                createdAt,
                updatedAt,
                projectDeadline,
                finalRebateAmount,
                rebateAmountPaid,
                preApprovedRebateAmount
            }
        }
    }
`;

export interface GetRebateApplicationByIdParams {
    id: UUID,
    userIds?: Array<UUID>,
}

export interface GetRebateApplicationByIdResponse {
    response: {
        applications: Array<RebateApplication>,
    }
}

export const GET_REBATE_APPLICATION_BY_ID = gql`
    query GetRebateApplicationsById($id: String!, $userIds: [String!]) {
        response: rebateApplications(where: { internalIds: [ $id ], userIds: $userIds }) {
            applications {
                applicationId,
                id,
                company {
                    id
                    name
                },
                customer,
                projectName,
                stage,
                fulfillmentManager {
                    id
                    name
                },
                country  {
                    id
                    name
                },
                usState  {
                    id
                    name
                },
                canadianArea  {
                    id
                    name
                },
                city,
                zipCode,
                budgetYear,
                technologyType  {
                    id
                    name
                },
                utility  {
                    id
                    name
                },
                fulfillmentCharge,
                projectCost,
                rebateEstimate,
                managementFee,
                notes,
                createdAt,
                updatedAt,
                projectDeadline,
                finalRebateAmount,
                rebateAmountPaid,
                preApprovedRebateAmount
            }
        }
    }
`;

export interface GetRebateApplicationsCountParams {
    where: RebateApplicationsWhere,
}

export interface RebateApplicationsCountResponse {
    response: {
        totalCount: number,
    }
}

export const GET_REBATE_APPLICATIONS_COUNT = gql`
    query GetRebateApplicationsCount(
        $where: RebateApplicationsWhere,
    ) {
        response: rebateApplications(where: $where, onlyCount: true) {
            totalCount
        }
    }
`;

export interface GetCompaniesResponse {
    response: {
        companies: Array<RebateApplicationCompany>,
    }
}

export const GET_COMPANIES = gql`
    query GetCompanies {
        response: projectCompanies {
            companies {
                id
                name
            }
        }
    }
`;

export interface AssignedFulfillmentManagersResponse {
    response: {
        managers: Array<RebateApplicationFulfillmentManager>,
    }
}

export const GET_ALL_ASSIGNED_FULFILLMENT_MANAGERS = gql`
    query GetAllAssignedFulfillmentManagers {
        response: assignedFulfillmentManagers {
            managers {
                id
                name
            }
        }
    }
`;

export interface GetAssignedFulfillmentManagersByUserIdParams {
    userId: UUID,
}

export const GET_ASSIGNED_FULFILLMENT_MANAGERS_BY_USER_ID = gql`
    query GetAssignedFulfillmentManagersByUserId($userId: String!) {
        response: assignedFulfillmentManagers(where: {
            userIds: [$userId]
        }) {
            managers {
                id
                name
            }
        }
    }
`;

export interface UsState {
    id: UUID,
    name: string,
}

export interface CanadianProvince {
    id: number,
    name: string,
}

export interface ProgramTypes {
    id: number,
    name: string,
}

export interface GetFormOptionsResponse {
    companiesResponse: {
        companies: Array<RebateApplicationCompany>,
    },
    states: Array<UsState>
    canadianProvinces: Array<CanadianProvince>
    technologyTypes: Array<ProgramTypes>
}

export const GET_ADMIN_PROJECT_FORM_OPTIONS = gql`
    query GetFormOptions {
        companiesResponse: allCompanies {
            companies {
                id
                name
            }
        }

        states {
            id: uuid
            name
        }

        canadianProvinces {
            id
            name
        }

        technologyTypes: programTypes {
            id
            name: displayName
        }
    }
`;

export interface GetAdminCompanyOptionsResponse {
    states: Array<UsState>
    canadianProvinces: Array<CanadianProvince>
}

export const GET_COMPANY_PROJECT_FORM_OPTIONS = gql`
    query GetFormOptions {
        states {
            id: uuid
            name
        }

        canadianProvinces {
            id
            name
        }
    }
`;

export interface GetUtilityOptionsResponse {
    utilityOptions: Array<SelectOption>,
}

export interface GetUtilityOptionsParams {
    where: {
        name: string
        stateIds: Array<UUID>,
    }
}


export const GET_UTILITY_OPTIONS = gql`
    query GetUtiliyOptions($where: UtilitySelectOptionsWhere) {
        utilityOptions: utilitySelectOptions(where: $where, limit: 200) {
            value: uuid
            label: name
        }
    }
`;

export interface CreateRebateApplicationParams {
    payload: RebateApplicationInput
}

enum MutationResponseStatus {
    OK = "OK",
    ERROR = "ERROR",
}

interface MutationResponseOK {
    status: MutationResponseStatus.OK
}

interface MutationResponseError {
    status: MutationResponseStatus.ERROR
    reason: string
}

export type MutationResponsePayload =
    | MutationResponseOK
    | MutationResponseError

export interface MutationResponse {
    data: MutationResponsePayload,
}

export const CREATE_REBATE_APPLICATION = gql`
    mutation createRebateApplication($payload: RebateApplicationInput!) {
        data: createRebateApplication(payload: $payload) {
            ... on MutationResponseError {
                reason
                status
            }
            ... on MutationResponseOK {
                status
            }
        }
    }
`;


export const UPDATE_REBATE_APPLICATION = gql`
    mutation updateRebateApplication($payload: RebateApplicationInput!) {
        data: updateRebateApplication(payload: $payload) {
            ... on MutationResponseError {
                reason
                status
            }
            ... on MutationResponseOK {
                status
            }
        }
    }
`;

export interface DeleteRebateApplicationParams {
    applicationId: UUID
}

export const DELETE_REBATE_APPLICATION = gql`
    mutation deleteRebateApplication($applicationId: String!) {
        data: deleteRebateApplication(applicationId: $applicationId) {
            ... on MutationResponseError {
                reason
                status
            }
            ... on MutationResponseOK {
                status
            }
        }
    }
`;

export interface DownloadReportParams extends Pick<GetRebateApplicationsParams, "where"> {
    format: {
        columns: Array<
            | "applicationId"
            | "company.id"
            | "company.name"
            | "customer"
            | "projectName"
            | "stage"
            | "fulfillmentManager.id"
            | "fulfillmentManager.name"
            | "country.id"
            | "country.name"
            | "usState.id"
            | "usState.name"
            | "canadianArea.id"
            | "canadianArea.name"
            | "city"
            | "zipCode"
            | "budgetYear"
            | "technologyType.id"
            | "technologyType.name"
            | "utility.id"
            | "utility.name"
            | "fulfillmentCharge"
            | "projectCost"
            | "rebateEstimate"
            | "preApprovedRebateAmount"
            | "finalRebateAmount"
            | "rebateAmountPaid"
            | "managementFee"
            | "projectDeadline"
            | "notes"
            | "createdAt"
            | "updatedAt"
            >
    }
}

export const DOWNLOAD_REPORT = gql`
    query DownloadReport(
        $where: RebateApplicationsWhere,
        $format: ExportRebateApplicationsFormat,
    ) {
        response: exportRebateApplications(
            extension: XLSX,
            where: $where,
            format: $format
        ) {
            base64data
        }
    }
`;

export interface ListAttachmentsParams {
    userId: string,
    applicationId: string
}

export interface RebateApplicationAttachment {
    createdAt: string,
    key: string,
    name: string,
}

export interface ListAttachmentsResponse {
    data: {
        attachments: Array<RebateApplicationAttachment>
    }
}

export const LIST_ATTACHMENTS = gql`
    query listAttachments($userId: String!, $applicationId: String!) {
        data: listRebateApplicationAttachments(userId: $userId, applicationId: $applicationId) {
            attachments {
                createdAt
                key
                name
            }
        }
    }
`;


export interface DeleteAttachmentParams {
    payload: {
        clientId: UUID,
        applicationId: UUID,
        objectId: string,
    }
}

export interface ListAttachmentsResponse {
    data: {
        attachments: Array<RebateApplicationAttachment>
    }
}

export const DELETE_ATTACHMENT = gql`
    mutation deleteAttachment($payload: deleteRebateApplicationAttachmentInput!) {
        data: deleteRebateApplicationAttachment(payload: $payload) {
            ... on MutationResponseError {
                reason
                status
            }
            ... on MutationResponseOK {
                status
            }
        }
    }
`;
