import { SUCCESS, ERROR, DISMISS } from "./types";

export const successMessage = message => ({
  type: SUCCESS,
  message
});

export const errorMessage = message => ({
  type: ERROR,
  message
});

export const dismissMessage = message => ({
  type: DISMISS,
  message
});
