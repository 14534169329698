import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteStory } from "../ducks/actions";

import styled from "styled-components";
import Card from "../../Common/components/Card";
import { PrimaryButton, PreviewButton } from "../../Common/components/Button";

/**
 * Displays a story on the Dashboard. This includes Getting Started and Data stories. Data stories can be created by
 * Rebate Bus Team Members through AddStory component.
 * @visibleName DashboardCard
 */

export const PureDashboardCard = ({
  id,
  title,
  description,
  storyText,
  permissionToDelete,
  permissions,
  deleting,
  deleteStory
}) => {
  const canDelete = permissionToDelete ? permissions >= 4 : true;

  const whatToRender = deleting
    ? {
        button: canDelete ? (
          <PrimaryButton
            className="destroy"
            handleClick={() => deleteStory(id)}
          >
            Delete?
          </PrimaryButton>
        ) : (
          ""
        ),
        text: canDelete ? (
          <p className="dangerText">This action cannot be undone!</p>
        ) : (
          <p>
            Sorry, you don't have permission to delete this. Talk to a Dev Team
            Member for help.
          </p>
        )
      }
    : {
        button: (
          <Link
            to={{
              pathname: `/dashboard/story/${id}`,
              state: {
                title,
                storyText
              }
            }}
          >
            <PreviewButton>read more</PreviewButton>
          </Link>
        ),
        text: <p>{description}</p>
      };

  return (
    <DashboardCard>
      <h3>{title}</h3>
      {whatToRender["text"]}
      <span>{whatToRender["button"]}</span>
    </DashboardCard>
  );
};

export default connect(null, { deleteStory })(PureDashboardCard);

PureDashboardCard.propTypes = {
  /** The image for the card. */
  img: PropTypes.string.isRequired,
  /** The story title. */
  title: PropTypes.string.isRequired,
  /** The preview text that shows up on the card. */
  description: PropTypes.string.isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

const DashboardCard = styled(Card)`
  flex: 0 0 48%;
  margin: 1em 0 2em 0;
  span {
    display: block;
    text-align: right;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;
