import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  img {
    margin-right: 1em;
    max-height: 1.25em;
  }
  a {
    color: ${props => props.color || "inherit"};
    font-style: normal;
    margin: 0;
  }
`;
