import React, { Component } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  addPage,
  updatePage,
  deleteObject,
  getOptions,
} from "../ducks/wikiActions";
import { errorMessage } from "../../Common/ducks/actions";

import { addRebate, updateRebate } from "../ducks/rebateActions.js";

import { Query } from "react-apollo";
import { GET_PAGE } from "../helpers/graphQL";

import Loading from "../../Common/components/Loading";
import styled from "styled-components";
import WikiForm from "./wikiForm/WikiForm";

import { textToUrl } from "../helpers/urls";

class AddEdit extends Component {
  static propTypes = {
    /** Determines whether to render the Add or Edit component */
    editing: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageType: null,
      redirect: false,
      newPageUrl: "",
      initialProgram: null,
      isCopy: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.programId) {
      this.setState({
        pageType: "rebate",
        initialProgram: this.props.location.state.programId,
      });
    }
    const queryParams = new URLSearchParams(window.location.search);
    for (const [key, value] of queryParams.entries()) {
      if (key === 'pageType') {
        switch (value) {
          case 'rebate':
            this.setState({
              pageType: "rebate",
            });
        }
      }

      if (key === 'copyFrom') {
        this.setState({ isCopy: true, rebateId: value });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // redirect to new page
    const {
      newPage,
      editing,
      match: { url },
      getOptions,
    } = this.props;

    const { pageType } = this.state;

    if (prevProps.newPage !== newPage) {
      if (!editing) {
        const newPageUrl =
          pageType === "rebate"
            ? `/search/rebate/${newPage.uuid}/${newPage.name}`
            : `/search/articles/${newPage.uuid}/${textToUrl(
                newPage.type
              )}/${textToUrl(newPage.name)}`;
        this.setState({
          newPageUrl,
          redirect: true,
        });
      } else if (editing) {
        this.setState({
          newPageUrl: url.replace("/edit", ""),
          redirect: true,
        });
      }
    }

    if (prevState.pageType !== pageType) {
      switch (pageType) {
        case "program":
          getOptions("customerTypes");
          getOptions("offeringTypes");
          break;
        case "rebate":
          getOptions("QPLs");
          getOptions("projectTypes");
          break;
        default:
          return;
      }
    }
  }

  submitPage = (data, updatedArrays, removedItems = null, originalPage) => {
    const {
      editing,
      addPage,
      addRebate,
      updatePage,
      updateRebate,
      deleteObject,
      history,
    } = this.props;

    const submitFunction = (data, updatedArrays, originalPage) => {
      if (data.pageType === "rebate") {
        if (editing) {
          updateRebate(data, updatedArrays, originalPage);
        } else {
          // TODO sometimes it save the valu as a Number but GraphQL expects a String
          if (data.prescriptiveRebateValue && data.prescriptiveRebateValue.length > 0) {
            for (let i = 0; i < data.prescriptiveRebateValue.length; i++) {
              data.prescriptiveRebateValue[i].prescriptiveValue = data.prescriptiveRebateValue[i].prescriptiveValue.toString();
            }
          }
          addRebate(data);
        }
      } else {
        if (editing) {
          updatePage(data, updatedArrays, originalPage);
        } else {
          addPage(data);
        }
      }
    };

    if (!editing) {
      submitFunction(data);
    }

    if (editing) {
      if (!data) {
        return history.goBack();
      }

      if (removedItems !== null) {
        removedItems.map((item) => deleteObject(item));
      }

      submitFunction(data, updatedArrays, originalPage);
    }
  };

  displayError = (message) => this.props.errorMessage(message);

  render() {
    const {
      editing,
      match: { params },
      history,
    } = this.props;
    const { pageType, redirect, newPageUrl, initialProgram } = this.state;

    const addTitle = `Add Page${this.state.isCopy && !this.state.isDirty? ' (Copy)' : ''}`;

    const title = editing ? `Edit Page` : addTitle;

    let queryObject = params.rebateId
      ? {
          pageType: "rebate",
          rebateId: params.rebateId,
          editing,
        }
      : {
          pageType:
            params.pageType === "state-territory" ? "state" : params.pageType,
          uuid: params.databaseId,
          editing,
        };

    if (this.state.pageType === 'rebate' && this.state.isCopy) {
      queryObject = {
        pageType: this.state.pageType,
        rebateId: this.state.rebateId,
        editing: false,
      }
    }

    return (
      <div>
        {redirect ? <Redirect push to={newPageUrl} /> : null}
        <h1>{title}</h1>

        {!editing && !this.state.isCopy && (
          <SelectPageType>
            <p>Page Type</p>
            <div id="selectPageTypeWrapper">
              {newPageTypes.map(({ type, title, icon }) => (
                <PageType
                  key={type}
                  onClick={() => this.setState({ pageType: type })}
                  selected={type === pageType}
                >
                  <img src={icon} alt={`${title} Icon`} />
                  <span>{title}</span>
                </PageType>
              ))}
            </div>
          </SelectPageType>
        )}
        {!editing && !this.state.isCopy && pageType && (
          <WikiForm
            formOptions={this.props.formOptions}
            key={pageType}
            pageType={pageType}
            handleSubmit={this.submitPage}
            handleError={this.displayError}
            initialProgram={initialProgram}
          />
        )}
        {editing && (
          <Query query={GET_PAGE(queryObject)} fetchPolicy="no-cache">
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;

              const dataToPass =
                queryObject.pageType === "program" ||
                queryObject.pageType === "rebate"
                  ? data
                  : data[queryObject.pageType];

              const dataToEdit = formatDataToEdit(
                queryObject.pageType,
                dataToPass
              );
              return (
                <WikiForm
                  editing
                  key={`${queryObject.pageType}-editing`}
                  {...queryObject}
                  wikiPage={dataToEdit}
                  handleSubmit={this.submitPage}
                  history={history}
                  handleError={this.displayError}
                />
              );
            }}
          </Query>
        )}

        {this.state.isCopy && (
          <Query query={GET_PAGE(queryObject)} fetchPolicy="no-cache">
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;

              const dataToEdit = formatDataToEdit(
                queryObject.pageType,
                data
              );

              return (
                <WikiForm
                  isCopy={true}
                  key={`${queryObject.pageType}-copy`}
                  {...queryObject}
                  wikiPage={dataToEdit}
                  handleSubmit={this.submitPage}
                  history={history}
                  handleError={this.displayError}
                />
              );
            }}
          </Query>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  newPage: state.wiki.wiki.newPage,
  formOptions: state.wiki.wiki.formOptions,
});

export default connect(mapStateToProps, {
  addPage,
  updatePage,
  deleteObject,
  getOptions,
  addRebate,
  updateRebate,
  errorMessage,
})(AddEdit);

const checkForPTag = (string) => {
  if (string.indexOf("<p>") < 0) {
    return `<p>${string}</p>`;
  } else return string;
};

const formatDataToEdit = (pageType, data) => {
  const copy = pageType === "program" ? { ...data.program } : { ...data };
  copy.pageType = pageType;

  if (data.description) {
    copy.description = checkForPTag(data.description);
  }

  if (pageType === "program" && data.requirements) {
    copy.requirements = checkForPTag(data.requirements);
  }

  if (data.notes) {
    copy.notes = checkForPTag(data.notes);
  }

  switch (pageType) {
    case "utility":
      copy.stateId = data.state.uuid;
      delete copy.state;
      if (data.utilityOwnershipType) {
        copy.utilityOwnershipTypeId = data.utilityOwnershipType.uuid;
        delete copy.utilityOwnershipType;
      }
      if (data.address) {
        copy.city = data.address.city;
        copy.zipcode = data.address.zipcode;
        copy.address = data.address.address;
        delete copy.address;
      }
      copy.socialMediaLinks.forEach(
        (link) => (link.linkText = link.SocialMediaPlatform.uuid)
      );

      return copy;
    case "program":
      const { program } = data;
      copy.hasMultipleUtilities = false;

      if (program.utilities.length > 0) {
        copy.stateId = program.utilities[0].state.uuid;
        delete copy.state;

        if (program.utilities.length > 1) {
          copy.hasMultipleUtilities = true;
          copy.multipleUtilities = program.utilities.map((utility) => ({
            utility: utility.uuid,
          }));
          copy.singleUtility = "";
          copy.utilities = copy.multipleUtilities;
        } else {
          copy.singleUtility = program.utilities[0].uuid;
          copy.multipleUtilities = [{ utility: "" }];
          copy.utilities = [{ utility: program.utilities[0].uuid }];
        }
      } else {
        copy.singleUtility = "";
        copy.multipleUtilities = [{ utility: "" }];
      }

      copy.logoUrl =
        program.logoUrl === "undefined" || !program.logoUrl
          ? ""
          : program.logoUrl;
      copy.totalAvailable = program.budget ? program.budget.totalAvailable : "";
      copy.totalUtilized = program.budget ? program.budget.totalUtilized : "";
      delete copy.budget;

      copy.startDate = program.startDate;
      copy.endDate = program.endDate;

      // * get all the customerTypes and set to false initially
      copy.customerTypes = {};
      data.customerTypes.forEach(
        (type) => (copy.customerTypes[type.uuid] = false)
      );
      // * then get the true values
      program.customerTypes.forEach(
        (type) => (copy.customerTypes[type.uuid] = true)
      );

      // * repeat for offeringTypes
      copy.offeringTypes = {};

      data.offeringTypes.forEach(
        (type) => (copy.offeringTypes[type.uuid] = false)
      );
      copy.hasDownstreamProgram = false;
      program.offeringTypes.forEach((type) => {
        if (type.value === "Downstream") {
          copy.hasDownstreamProgram = true;
        }
        return (copy.offeringTypes[type.uuid] = true);
      });

      copy.downstreamProgram = copy.downstreamProgram
        ? copy.downstreamProgram
        : {
            isPostAuditRequired: false,
            isPreApprovalRequired: false,
            isPreInspectionRequired: false,
          };

      return copy;
    case "rebate":
      const { rebate } = data;

      copy.uuid = rebate.uuid;
      copy.programId = rebate.program.uuid;
      delete copy.program;
      copy.technologyTypeId = rebate.technologyType.uuid;
      delete copy.technologyType;
      copy.rebateName = rebate.rebateName.uuid;

      // * get all the QPLs and set to false initially
      copy.QPLs = {};
      data.QPLs.forEach((type) => (copy.QPLs[type.uuid] = false));
      // * then get the true values
      rebate.QPLs.forEach((type) => (copy.QPLs[type.uuid] = true));

      // * repeat for projectTypes
      copy.projectTypes = {};
      data.projectTypes.forEach(
        (type) => (copy.projectTypes[type.uuid] = false)
      );
      rebate.projectTypes.forEach(
        (type) => (copy.projectTypes[type.uuid] = true)
      );

      copy.oldProductCategories = rebate.oldProductCategories.map((type) => ({
        id: type.uuid,
        label: type.name,
      }));

      copy.newProductCategories =
        rebate.newProductCategories &&
        rebate.newProductCategories.map((type) => ({
          id: type.uuid,
          label: type.name,
        }));

      if (copy.newProductCategories === null) {
        copy.newProductCategories = [];
      }

      copy.rebateTypeId = `${rebate.rebateType.uuid} ${rebate.rebateType.value}`;

      if (rebate.rebateType.value === "Prescriptive") {
        copy.prescriptiveRebateValue =
          rebate.rebateRates &&
          rebate.rebateRates.map((rate) => ({
            prescriptiveValue: rate.value.toString(),
            prescriptiveUnit: rate.rebateRateUnit.uuid,
            uuid: rate.uuid,
          }));
        copy.calculatedRebateValue = [{ unit: "", value: "" }];
      } else {
        copy.prescriptiveRebateValue = [
          { prescriptiveUnit: "", prescriptiveValue: "" },
        ];
        copy.calculatedRebateValue =
          rebate.rebateRates &&
          rebate.rebateRates.map((rate) => ({
            value: rate.value.toString(),
            unit: rate.rebateRateUnit.uuid,
            uuid: rate.uuid,
          }));
        if (copy.calculatedRebateValue === null) {
          copy.calculatedRebateValue = [{ unit: "", value: "" }];
        }
      }

      copy.hasCap = rebate.cap ? true : false;
      if (copy.hasCap) {
        copy.cap = rebate.cap;
      }
      copy.hasMinimumCustomerContribution = rebate.minimumCustomerContribution
        ? true
        : false;
      copy.minimumCustomerContribution = rebate.minimumCustomerContribution;
      copy.hasExpiration = rebate.expiration > 0 ? true : false;
      copy.hasRequirements =
        rebate.rebateRequirements && rebate.rebateRequirements.length > 0
          ? true
          : false;
      copy.requirementsTable =
        rebate.rebateRequirements &&
        rebate.rebateRequirements.map(
          ({ value, rebateRequirementType, uuid }) => {
            const [operand, start, end] = value.split(" ");

            return {
              requirementType: {
                label: rebateRequirementType.value,
                value: rebateRequirementType.uuid,
                productSpecTypeId: rebateRequirementType.productSpecType.uuid,
                technologyTypeId: rebate.technologyType.uuid,
              },
              requirementOperator: operand,
              requirementValue: end ? `${start}-${end}` : start,
              requirementUnit: rebateRequirementType.productSpecType.uuid,
              uuid,
            };
          }
        );

      if (copy.requirementsTable === null) {
        copy.requirementsTable = [];
      }

      copy.notes = rebate.notes;
      copy.code = rebate.code;

      if (copy.code === null) {
        copy.code = "";
      }

      delete copy.rebate;
      return copy;
    case "state":
      copy.regionId = data.region.uuid;
      delete copy.region;
      copy.countryId = data.country.uuid;
      delete copy.country;
      return copy;
    case "country":
      delete copy.states;
      return copy;
    case "product":
      copy.QPLId = data.QPL.uuid;
      delete copy.QPL;
      copy.brandId = data.brand.uuid;
      delete copy.brand;
      copy.newProductCategoryId = data.newProductCategory.uuid;
      delete copy.newProductCategory;
      copy.productSubCategoryId = data.productSubCategory.uuid;
      delete copy.productSubCategory;
      copy.productTypeId = data.productType.uuid;
      delete copy.productType;
      copy.manufacturerId = data.manufacturer.uuid;
      delete copy.manufacturer;
      copy.technologyTypeId = data.technologyType.uuid;
      delete copy.technologyType;

      // eslint-disable-next-line no-unused-expressions
      copy.productSpecs !== null
        ? copy.productSpecs.forEach(
            (spec) => (spec.productSpecTypeId = spec.productSpecType.uuid)
          )
        : null;

      return copy;
    default:
      return data;
  }
};

const newPageTypes = [
  {
    type: "utility",
    title: "Utility",
    icon: "https://s3.amazonaws.com/v2.rebatebus/images/icons/utilityIcon.svg",
  },
  {
    type: "program",
    title: "Program",
    icon: "https://s3.amazonaws.com/v2.rebatebus/images/icons/programIcon.svg",
  },
  {
    type: "rebate",
    title: "Rebate",
    icon: "https://s3.amazonaws.com/v2.rebatebus/images/icons/rebateIcon.svg",
  },
  {
    type: "product",
    title: "Product",
    icon: "https://s3.amazonaws.com/v2.rebatebus/images/icons/productIcon.svg",
  },
];

const SelectPageType = styled.div`
  p {
    font-weight: bold;
  }
  #selectPageTypeWrapper {
    display: flex;
    justify-content: space-between;
  }
`;

const PageType = styled.div`
  padding: 1.5em;
  width: 7em;
  max-width: 7em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(props) =>
    props.selected ? "2px solid #16a888" : "2px solid #cecece"};
  font-size: 0.8em;
  border-radius: 15px;
  cursor: pointer;
  img {
    height: 4em;
    margin-bottom: 1em;
  }
`;
