import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

import { InputWrapper } from "./StyledFormComponents";

const Input = (props) => {
  const isMultiline =
    props.inputType === "textarea"
      ? {
          multiline: true,
          rows: 5,
          variant: "outlined",
        }
      : null;

  const adornment = (props) => {
    if (props.adornment) {
      const { unit, location } = props.adornment;
      if (location === "end") {
        return {
          endAdornment: (
            <InputAdornment key={props.name} position="end">
              {unit}
            </InputAdornment>
          ),
        };
      } else if (location === "start") {
        return {
          startAdornment: (
            <InputAdornment key={props.name} position="start">
              {unit}
            </InputAdornment>
          ),
        };
      }
    } else if (props.icon) {
      const icons = {
        Link: LinkIcon,
      };

      const IconType = icons[props.icon];

      return {
        endAdornment: (
          <InputAdornment key={props.name} position="end">
            <IconType />
          </InputAdornment>
        ),
      };
    } else {
      return null;
    }
  };

  const readOnlyProps = props.readOnly ? { readOnly: true } : null;

  const inputProps = props.inputComponent
    ? `inputComponent: ${props.inputComponent}`
    : null;

  let styleClass = props.advancedStyles ? props.advancedStyles : null;
  return (
    <InputWrapper className={styleClass}>
      <TextField
        name={props.name}
        required={props.required}
        error={props.error}
        id={props.id}
        label={props.title}
        helperText={props.helperText}
        value={props.value || ""}
        onChange={({ target }) =>
          props.name && props.section
            ? props.handleChange(target, props.section)
            : props.handleChange(target.id, target.value, props.isParent)
        }
        fullWidth
        size="small"
        type={props.type}
        {...isMultiline}
        InputProps={{ ...adornment(props), ...readOnlyProps, ...inputProps }}
      />
    </InputWrapper>
  );
};

export default Input;

Input.propTypes = {
  /** The adornment for the input, such as $ or unit of measure. Includes the location  */
  adornment: PropTypes.shape({
    location: PropTypes.oneOf(["start", "end"]),
    unit: PropTypes.string,
  }),
  /** The icon if the input should have one. It will default to end adornment. */
  icon: PropTypes.string,
  /** If the input is required. Originally took a string of "required". (Can't remember why I set it up like that...) */
  required: PropTypes.bool,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The value from state. Cannot be required as it returns undefined when the component  */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** The function to update state. */
  handleChange: PropTypes.func.isRequired,
  /** Determines whether an input or textarea is rendered. Type of textarea creates a multiline input. */
  inputType: PropTypes.oneOf(["input", "textarea"]).isRequired,
  /** Determines if input should be read only. Passed to InputProps. */
  readOnly: PropTypes.bool,
  /** The type of input to create. */
  type: PropTypes.string,
};
