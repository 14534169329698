import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "styled-components";

/**
 * MenuItem is used by IconMenu to render the menu items.
 */

const MenuItem = ({
  isDesktop,
  isTopMenu,
  url,
  imgSrc,
  imgAlt,
  linkText,
  children,
  handleClick,
  hasSubmenu,
  submenu
}) => {
  if (isDesktop) {
    if (hasSubmenu) {
      return (
        <MenuWithChildren>
          <div className="homeWrapper">
            <Link to={url} onClick={handleClick}>
              {linkText}
            </Link>
          </div>
          {children}
        </MenuWithChildren>
      );
    } else {
      return (
        <DesktopMenuItem submenu={submenu}>
          <Link
            to={url}
            onClick={handleClick}
            className={submenu ? "submenu" : null}
          >
            {linkText}
          </Link>
        </DesktopMenuItem>
      );
    }
  } else if (isTopMenu) {
    return (
      <Link to={url} onClick={handleClick}>
        <img src={imgSrc} alt={imgAlt} />
      </Link>
    );
  } else {
    return (
      <MobileMenuItem onClick={handleClick}>
        <Link to={url}>
          <img src={imgSrc} alt={imgAlt} />
          {linkText}
        </Link>
        {hasSubmenu ? children : null}
      </MobileMenuItem>
    );
  }
};

export default MenuItem;

MenuItem.propTypes = {
  /** The icon url for the img element. */
  imgSrc: PropTypes.string,
  /** Alt tag for the icon. */
  imgAlt: PropTypes.string,
  /** The url for the link, which is handled by `react-router-dom`. */
  url: PropTypes.string.isRequired,
  /** The submenu */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const MobileMenuItem = styled.div`
  border-radius: 10px;
  padding: 0.5em 1em;
  margin: 0.5em 0;

  a {
    display: flex;
    align-items: center;
    margin: 0;
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-style: normal;
  }
  img {
    margin-right: 1em;
  }
`;

const DesktopMenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.submenu ? "0.5em" : "1em")};

  img {
    margin-right: 0.5em;
  }
  a {
    text-decoration: none;
    margin: 0;
    color: black;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    &.submenu {
      font-weight: normal;
      font-size: 0.8em;
      margin-left: 3.5em;

      @media all and (max-width: 768px) {
        margin-bottom: 0.75em;
        &:first-of-type {
          margin-top: 0.75em;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const MenuWithChildren = styled(DesktopMenuItem)`
  flex-direction: column;
  align-items: flex-start;
  div.homeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }
`;
