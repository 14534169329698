import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

/**
 * Toggle styles a checkbox to look like a toggle button and only works with boolean properties. It is used on the Settings page.
 */

const StyledToggle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;

  span {
    font-weight: ${props => (props.formLabel ? "bold" : "normal")};
    font-size: ${props => (props.formLabel ? "1.2em" : "inherit")};
  }

  label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
    &::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      top: 1px;
      left: 1px;
      transition: all 0.3s;
    }
  }

  input {
    display: none;
  }

  input:checked + .switch {
    background-color: #16a888;
  }

  input:checked + .switch::after {
    left: 20px;
  }
`;

const Toggle = ({
  description,
  id,
  valueFromState,
  handleCheckboxChange,
  formLabel
}) => {
  return (
    <StyledToggle formLabel>
      <span>{description}</span>
      <input
        type="checkbox"
        id={id}
        className="checkbox"
        checked={valueFromState}
        onChange={() => handleCheckboxChange(id)}
      />
      <label htmlFor={id} className="switch"></label>
    </StyledToggle>
  );
};

export default Toggle;

Toggle.propTypes = {
  /** The id of the checkbox. */
  id: PropTypes.string.isRequired,
  /** The description of the setting. */
  description: PropTypes.string.isRequired,
  /** Tells the component what state it should show. */
  valueFromState: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  /** Handles the onChange for the element. */
  handleCheckboxChange: PropTypes.func.isRequired
};
