import axios from "axios";
import { gql } from "apollo-boost";
import {
  TEAM_DEACTIVATE,
  TEAM_UPDATE,
  GET_TEAM,
  INVITE_COMPLETE,
  INVITE_RESET,
} from "./types";
import { successMessage } from "../../Common/ducks/actions";

import apolloClient from "../../helpers/apolloClient";
import store from "../../createStore";

export const inviteNewTeamMember = (newUser, actingUser) => async (
  dispatch
) => {
  const submission = {
    newUser,
    actingUser,
  };

  const axiosConfig = {
    headers: {
      Authorization: localStorage.token,
    },
  };

  return await axios
    .post("/api/v2/user/inviteuser", submission, axiosConfig)
    .then(() => {
      dispatch(successMessage("Invite sent!"));
      dispatch(inviteComplete());
    })
    .catch((err) => console.log(err.response));
};

const inviteComplete = () => ({
  type: INVITE_COMPLETE,
});

export const inviteReset = () => ({
  type: INVITE_RESET,
});

export const changePermissions = (user) => async (dispatch) => {
  const actingUser = store.getState().account.user;

  const permissions = await apolloClient.query({
    query: gql`
      {
        permissions {
          uuid
          value
        }
      }
    `,
  });

  let newPermissions;

  switch (user.permissions.value) {
    case "Admin":
      if (actingUser.company.name === "Rebate Bus") {
        newPermissions = permissions.data.permissions.filter(
          (permission) => permission.value === "RebateBusEmployee"
        )[0].uuid;
      } else {
        newPermissions = permissions.data.permissions.filter(
          (permission) => permission.value === "Standard"
        )[0].uuid;
      }
      break;
    case "Standard":
    case "RebateBusEmployee":
      newPermissions = permissions.data.permissions.filter(
        (permission) => permission.value === "Admin"
      )[0].uuid;
      break;
    default:
      break;
  }

  return apolloClient
    .mutate({
      mutation: gql`
      mutation {
        changeUserPermissions(userId: "${user.uuid}", permissionId: "${newPermissions}"){
          uuid
          firstName
          lastName
          isActive
          permissions {
            value
            uuid
          }
        }
      }
    `,
    })
    .then(({ data }) => {
      dispatch(update(data.changeUserPermissions));
      dispatch(successMessage("Permissions successfully changed."));
    });
};

export const update = (user) => ({
  type: TEAM_UPDATE,
  user,
});

const loadTeam = (team) => ({
  type: GET_TEAM,
  team,
});

export const getTeam = (user) => async (dispatch) => {
  const team = await apolloClient.query({
    query: gql`
      {
        usersByCompany(companyId: "${user.companyId}") {
          uuid
          firstName
          lastName
          isActive
          permissions {
            value
            uuid
          }
        }
      }
    `,
  });

  return dispatch(loadTeam(team.data.usersByCompany));
};

// * not used yet

export const deactivate = (user) => ({
  type: TEAM_DEACTIVATE,
  user,
});
