import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import HeightIcon from "@material-ui/icons/Height";
import RelationItem from "./RelationItem";

const RelationWrapper = styled.div`
  margin-top: 1em;
  width: 100%;
  p {
    margin: 0;
    font-size: 1.3em;
    color: ${props => props.theme.pageTypes[props.type].regular};
  }
`;

const RelationshipDiv = styled.div`
  padding-left: 2em;
  padding-top: 1em;
  border-left: ${props =>
    `3px solid ${props.theme.pageTypes[props.type].regular}`};

  a {
    text-decoration: none;
    font-style: normal;
  }

  @media all and (max-width: 768px) {
    padding-left: 1em;
  }
`;

const CollapseArrow = styled.p`
  margin-left: -0.07em !important;
  margin-bottom: 0.1em;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-left: -0.4em;
  }
  span {
    font-size: 0.7em;
    margin-left: 0.5em;
  }
`;

/**
 * The Relationship component generates a div that is styled based on the type (i.e., state, utility, etc). It generates `RelationItems` for each object in the
 * relations array and passes the correct icon to the `RelationItem` based on the type. Relationship components can be nested to generate the left-border color.
 * Each Relationship has a visibility toggle that is controlled by the Page component.
 *
 * @param {object} props
 */

const Relationship = ({
  type,
  title,
  name,
  uuid,
  relations,
  children,
  parentProgram
}) => {
  const [visible, setVisible] = useState(true);

  const generateChildren = relations
    ? relations.map(relation => (
        <RelationItem
          key={relation.uuid}
          uuid={relation.uuid}
          name={relation.name || relation.value}
          type={type}
          parentProgram={parentProgram}
        />
      ))
    : null;

  const expandCollapse = visible ? "Collapse" : "Expand";

  return (
    <RelationWrapper type={type}>
      <p>{title || type}</p>
      <CollapseArrow onClick={() => setVisible(!visible)}>
        <HeightIcon />
        <span>{expandCollapse} Section</span>
      </CollapseArrow>
      {visible && (
        <RelationshipDiv type={type}>
          {name && <RelationItem uuid={uuid} name={name} type={type} />}
          {relations && generateChildren}
          {children}
        </RelationshipDiv>
      )}
    </RelationWrapper>
  );
};

export default Relationship;

Relationship.propTypes = {
  /** The type of the Relationship, i.e. State/Territory, Utility, etc. */
  type: PropTypes.string.isRequired,
  /** In case the title is different than the type */
  title: PropTypes.string,
  /** The name of the relation, if only one. */
  name: PropTypes.string,
  /** An object or array of related items. Parent is an object, children are an array. */
  relations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Potentially nested relationships. */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ])
};
