import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { showMessages } from "../Common/components/Message";
import { dismissMessage } from "../Common/ducks/actions";

import HomeDashboard from "./components/HomeDashboard";
import Story from "./components/Story";

/**
 * This is the wrapper component for the Dashboard route. It controls the logic for which component is displayed.
 *
 * _This will have an API call in `componentDidMount` to fetch dashboard stories._
 * */

class Dashboard extends Component {
  static propTypes = {
    /** The current user object from Redux. */
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      storyToShow: {}
    };
  }

  render() {
    const { isDeleting } = this.state;

    if(!this.props.user || !this.props.user.permissions) return null;
    
    const {
      user: {
        permissions,
        settings: { isGettingStarted }
      },
      dashboard,
      messages,
      dismissMessage
    } = this.props;

    return (
      <main id="mainContentContainer">
        <Helmet>
          <meta name="description" content="Rebate Dashboard" />
          <title>Rebate Bus | Dashboard</title>
        </Helmet>
        <div id="mainContentWindowContainer">
          {showMessages(messages, dismissMessage)}
          <div id="mainContentWindowWrapper">
            <Switch>
              <Route
                exact
                path="/dashboard"
                render={props => (
                  <HomeDashboard
                    gettingStarted={isGettingStarted > 0}
                    permissions={permissions}
                    deleting={isDeleting}
                    {...dashboard}
                    {...props}
                  />
                )}
              />
              <Route
                path="/dashboard/story/:storyId"
                render={({ location: { state } }) => <Story {...state} />}
              />
              <Redirect to="/wrong-turn" />
            </Switch>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return { dashboard: state.dashboard };
};

export default connect(mapStateToProps, { dismissMessage })(Dashboard);
