import React, { useState } from "react";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import { Section, LogoWrapper, BoldKeyValue } from "./StyledPageComponents";
import {
  PageHeader,
  LinksSection,
  CheckmarkSection,
  ContactsSection,
  ReportsSection,
  RebateOfferings,
  HtmlSection,
} from "./PageComponents";
import Relationship from "../Relationship";
import HelpfulHints from "../../../Common/components/HelpfulHints";

import alphabetize from "../../helpers/alphabetize";

const Program = ({ program, type, url, permissions, showHelp }) => {
  const [showHints, setShowHints] = useState(true);

  const formatDate = (date) => {
    date.setDate(date.getDate() + 1);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const hasMidstream = program.offeringTypes.filter(
    (type) => type.value === "Midstream"
  );

  const { projectCap, laborCap, materialCap } = program;

  const capConverter = (number) =>
    number > 100 ? `$${number.toLocaleString()}` : `${number}%`;

  const programCaps = (
    <ProgramCapsSection subsection>
      <h2>Program Caps</h2>
      {projectCap > 0 ? <p>Projects capped at {capConverter(projectCap)}</p> : null}
      {materialCap > 0 ? (
        <p>Materials capped at {capConverter(materialCap)}</p>
      ) : null }
      {laborCap > 0 ? <p>Labor capped at {capConverter(laborCap)}</p> : null}
    </ProgramCapsSection>
  );

  const downstreamValues = {
    isPostAuditRequired: "Post-Audit Required",
    isPreApprovalRequired: "Pre-Approval Required",
    isPreInspectionRequired: "Pre-Inspection Required",
  };

  const downstreamRequirements = () => {
    // * filter out typename and falsey keys then map to object for display
    const requirements = Object.keys(program.downstreamProgram)
      .filter((key) => key !== "__typename" && program.downstreamProgram[key])
      .map((key) => ({ name: key, value: downstreamValues[key] }));

    if (requirements.length > 0) {
      return (
        <CheckmarkSection
          title="Approval Requirements"
          listOfItems={requirements}
        />
      );
    }
  };

  const showRebateInfoRequirements =
    program.requirements ||
    program.maximumAnnualIncentive ||
    projectCap ||
    laborCap ||
    materialCap ||
    program.downstreamProgram;

  if (program.isHidden) {
    return (
      <>
        <Helmet>
          <meta name="description" content="Rebate Utility Programs" />
          <title>{`Rebate Bus | Program | ${program.name}`}</title>
        </Helmet>
        <PageHeader
          name={program.name}
          type={type}
          url={url}
          permissions={permissions}
        />
        <p>This page is currently undergoing maintenance.</p>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Rebate Utility Programs" />
        <title>{`Rebate Bus | Program | ${program.name}`}</title>
      </Helmet>
      <PageHeader
        name={program.name}
        type={type}
        url={url}
        permissions={permissions}
        programId={program.uuid}
      />
      {showHints && showHelp && (
        <HelpfulHints
          title="See Rebate Offerings"
          handleDismiss={() => setShowHints(false)}
        >
          <p>
            Rebate Programs offer rebates for different technology types such as
            lighting, HVAC, or smart thermostats. Click on a specific technology
            type to see available offers!
          </p>
        </HelpfulHints>
      )}
      <Section>
        <h2>General Info</h2>
        {program.logoUrl &&
          program.logoUrl !== "null" &&
          program.logoUrl !== "undefined" && (
            <LogoWrapper>
              <img src={program.logoUrl} alt="Logo" />
            </LogoWrapper>
          )}
        {program.technologyTypes.length > 0 && (
          <Section subsection>
            <h2>Rebate Offerings</h2>

            <RebateOfferings
              techTypes={program.technologyTypes}
              program={{ name: program.name, uuid: program.uuid }}
            />
          </Section>
        )}
        <>
          <CheckmarkSection
            title="Program Status"
            listOfItems={[
              {
                name: "programIsActive",
                value: program.isActive
                  ? "Accepting Applications"
                  : "Not Accepting Applications",
                xIcon: !program.isActive,
              },
            ]}
          />
          {program.statusNotes && (
            <StatusNotes>
              <span>Note: </span>
              {program.statusNotes}
            </StatusNotes>
          )}
        </>

        {program.startDate && (
          <Section subsection>
            <h2>Program Window</h2>
            <BoldKeyValue>
              <span>Opens:</span> {program.startDate}
            </BoldKeyValue>
            <BoldKeyValue>
              <span>Closes:</span> {program.endDate}
            </BoldKeyValue>
          </Section>
        )}
        {program.budget && program.budget.totalAvailable && (
          <Section subsection>
            <h2>Budget</h2>
            <BoldKeyValue>
              <span>Total Available: </span>
              {`$${program.budget.totalAvailable.toLocaleString()}`}
            </BoldKeyValue>
            {program.budget.totalUtilized && (
              <>
                <BoldKeyValue>
                  <span>Total Utilized: </span>
                  {`$${program.budget.totalUtilized.toLocaleString()}`}
                </BoldKeyValue>
                <BoldKeyValue>
                  <span>Percentage Available: </span>
                  {`${
                    (program.budget.totalUtilized /
                      program.budget.totalAvailable) *
                    100
                  }%`}
                </BoldKeyValue>
              </>
            )}
          </Section>
        )}
        {program.customerTypes && program.customerTypes.length > 0 && (
          <CheckmarkSection
            title="Customer Eligibility"
            listOfItems={program.customerTypes}
          />
        )}
        {hasMidstream.length > 0 && (
          <CheckmarkSection
            title="Instant Rebate Offerings"
            listOfItems={hasMidstream}
          />
        )}
        {program.description && (
          <Section subsection>
            <h2>Description</h2>
            <HtmlSection longText={program.description} />
          </Section>
        )}
        <LinksSection
          type="link"
          title="Program Website"
          links={program.websites}
        />
        <LinksSection
          type="link"
          title="Application Links"
          links={program.applicationLinks}
        />
      </Section>
      {showRebateInfoRequirements && (
        <Section>
          <h2>Rebate Info and Requirements</h2>
          {program.requirements && (
            <Section subsection>
              <h2>Program Requirements</h2>
              <HtmlSection longText={program.requirements} />
            </Section>
          )}
          {(projectCap || laborCap || materialCap) && programCaps}
          {program.maximumAnnualIncentive && (
            <Section subsection>
              <h2>Maximum Annual Rebates</h2>
              <p>
                Cannot exceed ${program.maximumAnnualIncentive.toLocaleString()}{" "}
                in rebate value
              </p>
            </Section>
          )}
          {program.downstreamProgram && downstreamRequirements()}
        </Section>
      )}

      <ContactsSection contacts={program.contacts} />
      <ReportsSection reports={program.reports} />
      <Section>
        <h2>Relationships</h2>
        <p>
          The diagram below shows the relationship between {program.name} and
          its parent utility and child technology types.
        </p>
        {program.utilities && program.utilities.length === 1 && (
          <Relationship type="Utility" {...program.utilities[0]}>
            <Self {...program} />
          </Relationship>
        )}
        {program.utilities && program.utilities.length > 1 && (
          <Relationship
            type="Utility"
            relations={alphabetize(program.utilities, "name")}
            childRelation
          >
            <Self {...program} />
          </Relationship>
        )}
      </Section>
    </>
  );
};

export default Program;

const Self = ({ name, uuid, technologyTypes }) => (
  <Relationship type="Program" name={name} uuid={uuid}>
    {technologyTypes.length > 0 && (
      <Relationship
        type="Rebates by Technology Type"
        relations={technologyTypes}
        parentProgram={{ name, uuid }}
      />
    )}
  </Relationship>
);

const ProgramCapsSection = styled(Section)`
  p {
    margin: 0;
  }
`;

const StatusNotes = styled.p`
  margin-top: 1em;
  span {
    font-weight: bold;
  }
`;
