import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { showMessages } from "../Common/components/Message";
import { dismissMessage } from "../Common/ducks/actions";

import AuthRoute from "../Account/helpers/AuthRoute";

import styled from "styled-components";
import GraphQLPage from "./components/GraphQLPage";
import AddEdit from "./components/AddEdit";
import SearchHome from "./components/search/SearchHome";
import SearchMap from "./components/search/SearchMap";
import ContextMenu from "../Common/components/ContextMenu.jsx";
import TechTypeFilter from "./components/TechTypeFilter";
import ProgramFilter from "./components/ProgramFilter";
import ProgramsByState from "./components/search/ProgramsByState";

/**
 * This is the route page for the Wiki. It renders WikiHome anda search bar. When a user begins typing in the
 * search bar, a dro./components/pageTypes/RebateTemptions that are broken down by section,
 * i.e. State, Utility, Program, etc.
 * @visibleName Wiki
 */

const PureWiki = (props) => {
  const {
    messages,
    dismissMessage,
    location: { pathname },
    userPermissions,
    showHelp,
    authed,
  } = props;

  const shouldShowProgramFilter =
    pathname.indexOf("program-map") > 0 && pathname.indexOf("programs") > 0;

  return (
    <StyledWikiContainer>
      <div id="mainContentWindowContainer">
        {showMessages(messages, dismissMessage)}
        <Switch>
          <Route
            exact
            path="/search"
            render={(props) => <SearchHome {...props} />}
          />
          <AuthRoute
            authed={authed}
            path="/search/program-map/:stateId/:stateName/programs"
            component={ProgramsByState}
          />
          <Route
            path="/search/program-map"
            render={(props) => <SearchMap {...props} />}
          />

          <AuthRoute
            authed={authed && userPermissions >= 3}
            path="/search/add"
            renderProps={{ editing: false }}
            component={AddEdit}
          />
          <AuthRoute
            authed={authed && userPermissions >= 3}
            path="/search/articles/:databaseId/:pageType/:wikiPageId/edit"
            renderProps={{ editing: true }}
            component={AddEdit}
          />
          <AuthRoute
            authed={authed && userPermissions >= 3}
            path="/search/rebate/:rebateId/:rebateName/edit"
            renderProps={{ editing: true }}
            component={AddEdit}
          />
          <Route
            path="/search/rebate/:rebateId/:rebateName"
            render={(props) => {
              return (
                <GraphQLPage {...props} userPermissions={userPermissions} />
              );
            }}
          />
          <Route
            path="/search/rebates/:programId/:technologyTypeId/:programName/:technologyType"
            render={(props) => (
              <GraphQLPage {...props} userPermissions={userPermissions} />
            )}
          />

          <Route
            path="/search/applicable-rebates/:matchId/:technologyTypeId"
            render={(props) => <GraphQLPage {...props} userPermissions />}
          />

          <Route
            path="/search/articles/:databaseId/:pageType/:wikiPageId"
            render={(props) => (
              <GraphQLPage
                {...props}
                userPermissions={userPermissions}
                showHelp={showHelp > 0}
              />
            )}
          />
          <Route
            path="/search/product/:productId/:productName"
            render={(props) => (
              <GraphQLPage {...props} userPermissions={userPermissions} />
            )}
          />
          <Redirect to="/wrong-turn" />
        </Switch>
      </div>
      {pathname.indexOf("/rebates/") > 0 && (
        <ContextMenu title="Filter Rebates">
          <TechTypeFilter />
        </ContextMenu>
      )}
      {pathname.indexOf("/applicable-rebates/") > 0 && (
        <ContextMenu title="Filter Rebates">
          <TechTypeFilter />
        </ContextMenu>
      )}
      {shouldShowProgramFilter && (
        <ContextMenu title="Filter Programs">
          <ProgramFilter />
        </ContextMenu>
      )}
    </StyledWikiContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    wikiTopic: state.wiki.wikiTopic,
    userPermissions: state.account.user.permissions,
    showHelp: state.account.user.settings
      ? state.account.user.settings.isGettingStarted
      : null,
  };
};

export default connect(mapStateToProps, { dismissMessage })(PureWiki);

const StyledWikiContainer = styled.main`
  width: calc(100% - 5em);
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;

  div#mainContentWindowContainer {
    width: 100%;
  }

  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 2em;
  }
`;
