import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Card from "../../Common/components/Card";
import { PreviewButton } from "../../Common/components/Button";

import styled from "styled-components";

/**
 * TeamMember.js is rendered in ViewTeam.js to show cards for team members.
 */

const TeamMember = ({
  user,
  deactivating,
  handleDeactivate,
  handleAdjustAdmin,
}) => {
  const currentUser = useSelector((state) => state.account.user);

  const { firstName, lastName, permissions, uuid } = user;

  const permissionsText = {
    RebateBusDeveloper: "Rebate Bus Developer",
    RebateBusEmployee: "Rebate Bus Employee",
    Admin: "Company Admin",
    Standard: "Team Member",
  };

  const isSelf = currentUser.uuid === uuid;

  const isActive = user.isActive > 0;

  const regularRoles = ["Standard", "RebateBusEmployee"];

  const adjustAdminButton =
    regularRoles.indexOf(permissions.value) > -1 ? (
      <PreviewButton handleClick={() => handleAdjustAdmin(user)}>
        make admin
      </PreviewButton>
    ) : permissions.value === "Admin" ? (
      <PreviewButton
        handleClick={() => handleAdjustAdmin(user)}
        className="destroy"
      >
        revoke admin
      </PreviewButton>
    ) : null;

  return (
    <TeamMemberWrapper>
      <div className="teamMemberPicWrapper"></div>
      <div className="teamMemberInfoWrapper">
        <h3>
          {firstName} {lastName} {isSelf && "(me)"}
        </h3>
        {isActive && (
          <span>
            {permissionsText[permissions.value]}
            {adjustAdminButton && isActive && (
              <>
                <span className="spacer">|</span>
                {adjustAdminButton}
              </>
            )}
          </span>
        )}
        {!isActive && (
          <Inactive>
            Team Member has been invited but has not activated their account.
          </Inactive>
        )}
      </div>
      {/* <div className="teamMemberAction">
        {!deactivating && (
          <Link
            to={{
              pathname: `/team/user/${firstName}-${lastName}`,
              state: {
                userToView: { ...user },
              },
            }}
          >
            <PreviewButton>view</PreviewButton>
          </Link>
        )}
        {deactivating && (
          <Link
            to={{
              pathname: "/team/deactivate",
              state: {
                userToDeactivate: { ...user },
              },
            }}
          >
            <PreviewButton
              handleClick={() => handleDeactivate(uuid)}
              className="destroy"
            >
              deactivate
            </PreviewButton>
          </Link>
        )}
      </div> */}
    </TeamMemberWrapper>
  );
};

export default TeamMember;

TeamMember.propTypes = {
  /** The Team Member to be generated.
   * **This property will change in the future to teamMember (or tm) to reduce potential confusion with the user object from the Redux store.**
   * */
  user: PropTypes.object.isRequired,
  /** Boolean that determines whether to show the button that renders DeactivateAccount. */
  deactivating: PropTypes.bool.isRequired,
  /** The function to handle account deactivation. */
  handleDeactivate: PropTypes.func.isRequired,
  /** The function that changes a Team Member's admin level. */
  handleAdjustAdmin: PropTypes.func.isRequired,
};

const TeamMemberWrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;

  .teamMemberPicWrapper img {
    height: 4em;
    border-radius: 50%;
    margin-right: 2em;
  }

  .teamMemberInfoWrapper {
    flex: 1 1 auto;
  }

  .teamMemberInfoWrapper h3 {
    color: #16a888;
  }

  .teamMemberInfoWrapper span.spacer {
    margin: 0 0.5em;
  }
`;

const Inactive = styled.span`
  color: #ff0053;
`;
