import React from "react";
import Section from "../pageComponents/section";
import { Helmet } from "react-helmet";

const DistributorAdvertising = props => {
  const sections = [
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/1.svg",
      title: "Increase sales with Rebate Bus",
      content:
        "We provide manufacturers, distributors and retailers with actionable intelligence, analytics, and tools to reach every consumer with the right product at the right time through the right experience.",
      content2:
        "Your brands are matched with qualified rebates and our audience is looking to buy. Create direct links to your eCommerce platform with our 'purchase now' buttons and direct ready-to-buy customers to your site.",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/2.svg",
      title: "Our audience, your products",
      content:
        "Getting your brand and products in front of the right audience is costly and time-consuming. Search engine keywords in the energy efficiency industry have never been more expensive, and your audience is in multiple places. Let Rebate Bus take the wheel.",
      content2:
        "With over 90% new users, our site is the destination is the hub for all rebate programs, eligible products and details they need to make a purchase. They're ready to buy, are you ready for the sale?",
      flip: false
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/3.svg",
      title: "Generate sales leads",
      content:
        "Generating warm leads is more expensive than ever before. Rebate Bus offers a cost-effective way to get the most out of digital advertising by connecting your brand with thousands of monthly visitors. We've removed the barriers typically seen in digital marketing. Our audience is qualified for rebates, shopping for your products, and ready to make an informed purchase.",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/4.svg",
      title: "Boost your SEO",
      content:
        "Your SEO thrives when you target relevant, valuable keywords at the right time to the right audience. Learning which ones will deliver the right audience to your site is crucial, and yet time-consuming. By back-linking your website on Rebate Bus, not only will you experience an increase in site traffic, your organic search ranking for your website will increase.",
      flip: false
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/5.svg",
      title: "About our audience",
      content:
        "88% of consumers pre-research their buys online before making a purchase either online or in-store. Because Rebate Bus is the central hub for industry data, our visitors are not only researching qualified rebates, but also qualified products. Our site traffic has increased 240% year over year, and 92% of users are new to our site. Let us help connect our users to quality products by connecting them with you!",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Distributor+Advertising/6.svg",
      title: "Affordable Pricing",
      content:
        "Advertising on Rebate Bus is an easy and affordable solution. Working with us involves a flat fee of $300 / month per brand. Each click to your website is then a low fee of $1 / click. We'll provide analytics at the end of every month to help you make an informed strategy in the next month.",
      content2:
        "For more information, contact our marketing team at marketing@rebatebus.com",
      flip: false
    }
  ];
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Rebate Bus Distributor Advertising information"
        />
        <title>Rebate Bus | Distributor Advertising</title>
      </Helmet>
      <h1>Distributor Advertising</h1>
      {sections.map((section, i) => (
        <Section key={`distributor-sections-${i}`} section={section} />
      ))}
    </div>
  );
};

export default DistributorAdvertising;
