import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import styled from "styled-components";

import Card from "../../Common/components/Card";
import { PrimaryButton } from "../../Common/components/Button";
import ProfileField from "./ProfileField.jsx";

/**
 * It is the default component for the Profile route, where it shows the current user's profile.
 */

const UserProfile = ({
  editing,
  user,
  handleChange,
  handleSubmit,
  title,
  newProfile,
  redirect,
}) => {
  const generateProfile = profileFields.map(({ name, title, readOnly }) => {
    const componentType = editing ? "input" : "display";

    return (
      <ProfileField
        key={name}
        id={name}
        title={title}
        value={user[name]}
        componentType={componentType}
        handleChange={handleChange}
        readOnly={readOnly}
        required={true}
      />
    );
  });

  const editLink = editing ? (
    <EditLink to="/profile">Cancel Edit</EditLink>
  ) : (
    <EditLink to="/profile/edit">Edit Profile</EditLink>
  );

  const {
    settings: { photo },
    firstName,
    lastName,
    company
  } = user;

  const showCompany =
    company.name === "None" ? (
      editLink
    ) : (
      <span>
        {company.name} | {editLink}
      </span>
    );

  return (
    <div id="viewProfilePageWrapper">
      {redirect ? <Redirect to="/profile" /> : null}
      <div id="profileWrapper">
        {!newProfile && (
          <ProfileHeader>
            <div className="profileName">
              <h1>{`${firstName} ${lastName}`}</h1>
              {showCompany}
            </div>
          </ProfileHeader>
        )}
        <Card id="userProfileWrapper">
          <form onSubmit={event => handleSubmit(event)}>
            {generateProfile}
            {editing && !newProfile && (
              <PrimaryButton
                handleClick={event => handleSubmit(event)}
                margin="2em 0 0"
              >
                Update Profile
              </PrimaryButton>
            )}
          </form>
        </Card>
        <Wrapper>
          <Link to="/profile/password">Change your password</Link>
        </Wrapper>
      </div>
    </div>
  );
};

export default UserProfile;

UserProfile.propTypes = {
  /** If true, generates input/select elements to edit user's profile, plus the submit button.*/
  editing: PropTypes.bool,
  /** The profile to be viewed. */
  user: PropTypes.object.isRequired,
  /** The function that handles input change. */
  handleChange: PropTypes.func,
  /** The function that will save updates. */
  handleSubmit: PropTypes.func,
  /** Title will default to "User Info" unless passed in another title, such as "Complete Your Profile." */
  title: PropTypes.string,
  /** If true, the profile header and 'Update Profile' button will be hidden. */
  newProfile: PropTypes.bool,
  /**
   * If true, the viewer can see fields that are not visible to the public. The currently logged in user can see their own profile, as can a company admin or a
   * Rebate Bus Team Member. */
  permissionToView: PropTypes.bool.isRequired
};

const profileFields = [
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "email", title: "Email", readOnly: true },
  { name: "phone", title: "Phone", required: true }
];

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3em;

  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      margin: 0 0 0.2em;
    }
    span {
      color: #16a888;
      font-size: 1.5em;
    }
  }
`;

const ProfileImage = styled.div`
  margin-right: 3em;
  position: relative;
  height: 8em;
  width: 8em;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
  }
  div {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(22, 168, 136, 0.5);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    &:hover {
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
    img {
      height: 33%;
    }
  }
`;

const EditLink = styled(Link)`
  font-style: inherit;
  font-size: inherit;
  text-decoration: inherit;
`;

const DeactivateLink = styled(Link)`
  color: red;
`;

const Wrapper = styled.p`
  margin-top: 2em;
  a {
    font-size: inherit;
  }
`;
