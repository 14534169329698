import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "styled-components";

/**
 * RelationItem is generated through the Relationship component. It creates a Link that sends the name, id and type through `location.state`
 * in order to make an API call to get the rest of the data.
 * @param {object} props
 */

const RelationWrapper = styled.div`
  background-color: ${props => props.theme.pageTypes[props.type].light};

  &:nth-child(even) {
    background-color: white;
  }

  a {
    margin: 0;
    padding: 0.7em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-size: 0.9em;

    &:last-of-type {
      color: ${props => props.theme.pageTypes[props.type].regular};
      margin-left: 0.5em;
      flex: 0 0 8%;
      align-self: center;
      font-size: 0.6em;
      font-weight: bold;
      @media all and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const RelationItem = ({ uuid, name, type, parentProgram }) => {
  const textToUrl = text => text.toLowerCase().replace(/[\s/]+/g, "-");
  const url = parentProgram
    // ? `/search/rebates/${parentProgram.uuid}/${uuid}/${textToUrl(
    //     parentProgram.name
    //   )}/${textToUrl(name)}`
    ? `/search/rebates/${name.toLowerCase()}?programIds=${parentProgram.uuid}`
    : `/search/articles/${uuid}/${textToUrl(type)}/${textToUrl(name)}`;

  let technology;
  if (type === "Rebates by Technology Type") {
    technology = `${name} Rebates`;
  }

  return (
    <RelationWrapper type={type}>
      <Link to={url}>
        <span>{technology ? technology : name}</span>
        <span>view page</span>
      </Link>
    </RelationWrapper>
  );
};

export default RelationItem;

RelationItem.propTypes = {
  /** The id send to the Page for the API request. */
  uuid: PropTypes.string.isRequired,
  /** The name of the item. */
  name: PropTypes.string.isRequired,
  /** The function that allows rerendering of the Page component w/ updated props. */
  handleClick: PropTypes.func,
  /** If the URL doesn't follow the same specifications, such as with rebates/tech types, use the passed URL. */
  passedUrl: PropTypes.string
};
