import sanitizeHtml from "sanitize-html";

const allowedTags = ["p", "strong", "em", "u", "s"];
const transformTags = {
  pre: "p"
};

export default dirty => {
  // * make sure it's not empty <p> tags...
  let span = document.createElement("span");
  span.innerHTML = dirty;
  if (span.textContent.length < 1) {
    return "";
  }

  return sanitizeHtml(
    dirty
      .replace(/"/g, "'")
      .replace(/\\/g, "\\\\")
      .replace(/\n/g, ""),
    {
      allowedTags,
      transformTags,
      allowedAttributes: {}
    }
  );
};
