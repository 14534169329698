import React from "react";
import styled from "styled-components";

import { InputWrapper } from "../../../Common/components/formComponents/StyledFormComponents";

var QualDataOrig;
var HandleChangeQualData;
var DLC_UUID;
var DLCPremium_UUID;
var NONE_UUID;
var ES_UUID;

const StyledMultiCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2em;
  label {
    margin-bottom: 0.5em;
    font-size: 1em;
    &:first-of-type {
      margin-top: 1em;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;


function handleChange(event) {
  if(QualDataOrig === undefined && HandleChangeQualData === undefined) return;

  const found = QualDataOrig.find((checkbox) => { 
      return checkbox.id.includes(event.target.id)
  }); 

  if (found) {
    let indexEl = QualDataOrig.indexOf(found);
    QualDataOrig.splice(indexEl, 1);
  }

  if (event.target.checked) {
    if(event.target.id == DLCPremium_UUID) {
      QualDataOrig.push({id: [event.target.id, DLC_UUID]});
    } else if (event.target.id == NONE_UUID) {
      QualDataOrig.push({id: [event.target.id, DLC_UUID, ES_UUID]});
    } else {
      QualDataOrig.push({id: [event.target.id]});
    }
  }

  HandleChangeQualData(QualDataOrig);
}

const MultiCheckbox = ({ name, title, options, createInput, QualData, handleChangeQPLs, advancedStyles }) => {

  const foundNone = options.find((checkbox) => {return checkbox.description === "None"});

  if (foundNone) {
    let indexEl = options.indexOf(foundNone);
    options.splice(indexEl, 1);
    options.push(foundNone);
  }

  const generateCheckboxes = options.map(option =>
    createInput(option, { name })
  );

  if (title == "Product Qualification") {
    QualDataOrig = QualData;
    HandleChangeQualData = handleChangeQPLs;

    const foundDLC = options.find((checkbox) => {return checkbox.description === "DLC"});
    if(foundDLC) {
      DLC_UUID = foundDLC.name;
    }

    const foundDLCP = options.find((checkbox) => {return checkbox.description === "DLC Premium"});
    if(foundDLCP) {
      DLCPremium_UUID = foundDLCP.name;
    }

    const foundNONE = options.find((checkbox) => {return checkbox.description === "None"});
    if(foundNONE) {
      NONE_UUID = foundNONE.name;
    }

    const foundES = options.find((checkbox) => {return checkbox.description === "ENERGY STAR"});
    if(foundES) {
      ES_UUID = foundES.name;
    }
  }
  let styleClass = advancedStyles ? advancedStyles : null;
  let checkboxList;
  if(title === "Product Qualification") {
    checkboxList = <StyledMultiCheckboxes onChange={handleChange.bind(this)} className={styleClass}>{generateCheckboxes}</StyledMultiCheckboxes>;
  } else {
    checkboxList = <StyledMultiCheckboxes>{generateCheckboxes}</StyledMultiCheckboxes>;
  }

  return (
    <InputWrapper className={"inline-select-block " + styleClass} >
      <label>{title}</label>
      {checkboxList}
    </InputWrapper>
  );
};

export default MultiCheckbox;
