import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CloseIcon from "@material-ui/icons/Close";

/**
 * The GrayBanner component is used by HelpfulHints and SingleAnnouncement. The children are styled independently as they could be completely different.
 * If the Announcement has the same format in the future, it would be possible to abstract out more of the component, like the image and text wrappers.
 */

const GrayBanner = ({
  dismissable,
  noImg,
  handleDismiss,
  imgUrl,
  imgAlt,
  children
}) => {
  return (
    <BannerWrapper dismissable={dismissable}>
      <div className="dismissButton">
        <CloseIcon onClick={handleDismiss} />
      </div>

      <ContentWrapper noImg={noImg}>
        <div className="imageWrapper">
          <img src={imgUrl} alt={imgAlt} />
        </div>
        {children}
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default GrayBanner;

GrayBanner.propTypes = {
  /** The content for the banner */
  children: PropTypes.node.isRequired,
  /** Whether to render the dismiss button */
  dismissable: PropTypes.bool,
  /** The function to hide the banner */
  handleDismiss: PropTypes.func,
  /** The image for the banner */
  imgUrl: PropTypes.string,
  /** Alt for the image */
  imgAlt: PropTypes.string,
  /** Whether to render the image container */
  noImg: PropTypes.bool
};

const BannerWrapper = styled.div.attrs(props => ({
  dismiss: props.dismissable ? "block" : "none"
}))`
  padding: 1em 0;
  margin: 1em 0;
  border-radius: 20px;
  position: relative;

  div.dismissButton {
    display: ${props => props.dismiss};
    position: absolute;
    top: 1em;
    right: 1em;
    color: #686868;
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div.attrs(props => ({
  imgWrapper: props.noImg ? "none" : "block",
  padding: props.noImg ? "0 3em" : 0
}))`
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }

  div.imageWrapper {
    display: ${props => props.imgWrapper};
    flex: 0 0 20%;
    align-self: center;

    img {
      max-height: 100%;
      @media all and (max-width: 768px) {
        height: 10em;
      }
    }

  }
`;
