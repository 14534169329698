import {Provider as SpectrumProvider} from "@react-spectrum/provider";
import {defaultTheme, Divider, Flex, Heading, View} from "@adobe/react-spectrum";
import React from "react";
import {Route, Switch} from "react-router-dom";
import Helmet from "react-helmet";
import {useMediaQuery} from "react-responsive";
import {User} from "../../types";
import MenuLink from "./Link";
import ManagementProjectList from "./ManagementProjectList";
import AdminRebateApplicationForm from "./AdminRebateApplicationForm";
import {usersAllowedToManageApplicationsEmails} from "./constants";
import AdminManageProjectView from "./AdminManageProjectView";
import CompanyProjectList from "./CompanyProjectList";
import CompanyManageProjectView from "./CompanyManageProjectView";
import CompanyAddProjectView from "./CompanyAddProjectView";
import AdminAddProjectView from "./AdminAddProjectView";

export interface RebateApplicationConsoleProps {
    user: User
}

function NavigationBar() {
    return (
        <>
            <Flex gap="size-300">
                <MenuLink activeOnlyWhenExact to="/services/rebates/list">Projects</MenuLink>
                <MenuLink activeOnlyWhenExact to="/services/rebates/add">Create Project</MenuLink>
            </Flex>
            <Divider size="S"/>
        </>
    )
}

export function ProjectConsole({
                                   user,
                               }: RebateApplicationConsoleProps) {

    const isAdminOrManager = usersAllowedToManageApplicationsEmails.has(user.email);
    const isMobile = useMediaQuery({query: `(max-width: 768px)`});

    return (
        <SpectrumProvider
            theme={defaultTheme}
            colorScheme="light"
            UNSAFE_style={{backgroundColor: "white", width: isMobile ? "100%" : "calc(100vw - 11em)"}}
        >
            <Helmet>
                <meta name="description" content="Project console."/>
                <title>Projects | Rebate Bus</title>
            </Helmet>
            <View padding={isMobile ? "1em" : "1.6em 0 0 2em"} height="100%" >
                <Heading level={2} UNSAFE_style={{color: "var(--spectrum-global-color-gray-800)"}}>Projects</Heading>

                <Switch>
                    <Route path="/services/rebates/add">
                        <NavigationBar/>
                        {
                            isAdminOrManager
                                ? <AdminAddProjectView user={user}/>
                                : <CompanyAddProjectView user={user} isMobile={isMobile}/>
                        }
                    </Route>
                    <Route path="/services/rebates/manage/:applicationId">
                        {
                            isAdminOrManager
                                ? <AdminManageProjectView/>
                                : <CompanyManageProjectView user={user}/>
                        }
                    </Route>
                    <Route path="/services/rebates/list">
                        <NavigationBar/>
                        {
                            isAdminOrManager
                                ? <ManagementProjectList user={user}/>
                                : <CompanyProjectList user={user}/>
                        }
                    </Route>
                </Switch>
            </View>
        </SpectrumProvider>
    )
}

export default ProjectConsole;
