import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

const ContextMenu = ({ title, children }) => {
  const isNotMobile = useMediaQuery({ query: `(min-width: 769px)` });
  const [visible, setVisible] = useState(isNotMobile);

  return (
    <StyledContextMenu className={visible ? null : "hidden"}>
      <CollapseToolContainer collapse={visible}>
        <div className="collapseMenuIconContainer">
          <img
            className={!visible ? "hidden" : null}
            onClick={() => setVisible(!visible)}
            id="contextMenuCollapse"
            alt="Collapse Menu"
            src="https://rebatebus-assets.s3.amazonaws.com/assets/development/V2/images/icons/collapseMenuIcon.svg"
          />
        </div>
        <div className="collapseMenuIconContainer">
          <img
            className={visible ? "hidden" : null}
            onClick={() => setVisible(!visible)}
            id="contextMenuExpand"
            alt="Expand Menu"
            src="https://rebatebus-assets.s3.amazonaws.com/assets/development/V2/images/icons/expandMenuIcon.svg"
          />
        </div>
      </CollapseToolContainer>
      <MobileHidingTitle className={visible ? "hidden" : null}>
        <h3>{title}</h3>
      </MobileHidingTitle>
      <ContentMenuWrapper className={visible ? "hidden" : null}>
        <div id="contextMenuTitleContainer">
          <h3>{title}</h3>
        </div>
        <div id="contextMenuContent">{children}</div>
      </ContentMenuWrapper>
    </StyledContextMenu>
  );
};

export default ContextMenu;

ContextMenu.propTypes = {
  /** The title of the ContextMenu. Generally it is the Feature/Route name + Tools, such as Rebate Match Tools*/
  title: PropTypes.string,
  /** The content of the ContextMenu, including actions. */
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.node,
  ]),
  /** Whether the Context menu should render visible on component loading. Defaults to true */
  isVisible: PropTypes.bool,
};

const StyledContextMenu = styled.div`
  transition: 0.5s all;
  width: 25em;
  max-width: 269px;
  background-color: #f3fbf9;
  padding: 2em;
  background-image: url("https://rebatebus-assets.s3.amazonaws.com/assets/development/V2/images/decorative/summerFlora.svg");
  background-repeat: no-repeat;
  background-position: left bottom;

  @media (min-width: 769px) and (max-width: 1000px) {
    width: 10em;
    padding: 1em;
  }

  @media all and (max-width: 768px) {
    height: 100%;
    width: 100%;
    max-width: 100%;
    order: -1;
  }

  &.hidden {
    width: 5em;

    @media (min-width: 769px) and (max-width: 1000px) {
      width: 3em;
    }

    @media all and (max-width: 768px) {
      background-position: right bottom;
      width: 100%;
      height: 5em;
    }

    div#contextMenuWrapper {
      opacity: 0;
      transition: all 0.5s;
    }
    div#contextMenuTitleContainer {
      border-bottom: none;
    }
  }
`;

const CollapseToolContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  div.collapseMenuIconContainer {
    img {
      height: 1em;
      cursor: pointer;
      &.hidden {
        display: none;
      }
      @media all and (max-width: 768px) {
        transform: rotate(-90deg);
      }
    }
  }
`;

const ContentMenuWrapper = styled.div`
  height: 100%;
  opacity: 0;
  display: none;
  transition: 0.5s all;
  @media all and (max-width: 768px) {
    height: calc(100% - 5em);
  }

  &.hidden {
    opacity: 1;
    display: block;
  }

  div#contextMenuContent {
    margin-top: 1em;
    height: 90%;
    overflow-y: auto;
    @media all and (max-width: 768px) {
      height: 98%;
    }
  }

  div#contextMenuTitleContainer {
    border-bottom: 2px solid #cecece;
    @media all and (max-width: 768px) {
      display: none;
    }
  }
`;

const MobileHidingTitle = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    margin-top: -1em;
    border: none;
    &.hidden {
      border-bottom: 2px solid #cecece;
    }
  }
`;
