import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getWiki } from "../ducks/wikiActions";

import apolloClient from "../../helpers/apolloClient";

import _ from "lodash";
import { ASYNC_SEARCH } from "../helpers/graphQL";

import { textToUrl } from "../helpers/urls";
import convertToSearchOptions from "../helpers/convertToSearchOptions";

import AsyncSelect from "react-select/async";
import { createFilter } from "react-select";
import { components, WindowedMenuList } from "react-windowed-select";
import styled, { keyframes } from "styled-components";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

const wikiColors = {
  country: "#c43171",
  state: "#ff004e",
  utility: "#ff7800",
  program: "#56dc00",
  product: "#af00dc",
  zipcode: "#ffcd00",
};

const groupStyles = {
  Country: wikiColors.country,
  "State/Territory": wikiColors.state,
  State: wikiColors.state,
  Program: wikiColors.program,
  Utility: wikiColors.utility,
  Product: wikiColors.product,
  "zip code": wikiColors.zipcode,
};

const groupBadgeStyles = {
  all: { fontWeight: "bold", marginBottom: "0.5em" },
  Country: {
    color: wikiColors.country,
  },
  "State/Territory": {
    color: wikiColors.state,
  },
  Program: {
    color: wikiColors.program,
  },
  Utility: {
    color: wikiColors.utility,
  },
  Product: {
    color: wikiColors.product,
  },
  "Zip Code": {
    color: wikiColors.zipcode,
  },
};

const formatGroupLabel = (data) => (
  <div style={groupBadgeStyles.all}>
    <span style={groupBadgeStyles[data.label]}>{data.label}</span>
  </div>
);

const Group = (props) => {
  return (
    <div>
      <components.Group {...props} />
    </div>
  );
};

const Option = (props) => {
  const style = {
    cursor: "pointer",
    height: "100%",
    width: "100%",
    position: "absolute",
    marginLeft: "1em",
    borderLeft: `2px solid ${groupStyles[props.data.type]}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "95%",
  };

  return (
    <span style={style}>
      <components.Option {...props} />
    </span>
  );
};

const StyledControl = styled(components.Control).attrs((props) => ({
  backgroundColor: props.isMobile ? "#16a888" : "white",
  color: props.isMobile ? "white" : "#454545",
}))`
  overflow: hidden;
  * {
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
  }
  .css-b8ldur-Input {
    padding-bottom: 0;
  }
`;

const Control = ({ children, ...props }) => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 768px)" });

  return (
    <StyledControl
      isMobile={props.selectProps.searchHome ? false : isMobile}
      {...props}
    >
      {children}
    </StyledControl>
  );
};

const StyledPlaceHolder = styled(components.Placeholder)`
  display: flex;
  align-items: center;
  width: 100%;
  span {
    margin-left: 0.5em;
    flex: 1 1 auto;
  }
  img {
    max-height: 1.5em;
  }
`;

const Placeholder = (props) => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 768px)" });

  const searchText = isMobile
    ? "Search ..."
    : "Search utilities, programs, products, and zip codes";

  const searchIcon = props.selectProps.searchHome
    ? "searchIconGrey"
    : isMobile
    ? "searchIconWhite"
    : "searchIconGrey";

  return (
    <StyledPlaceHolder isMobile={isMobile} {...props}>
      <img
        src={`https://s3.amazonaws.com/v2.rebatebus/images/icons/${searchIcon}.svg`}
        alt="Magnifying Glass"
      />
      <span>{searchText}</span>
    </StyledPlaceHolder>
  );
};

const LoadingMessage = (props) => (
  <LoadingMessageWrapper
    {...props.innerProps}
    style={props.getStyles("loadingMessage", props)}
  >
    {props.children}
    <HourGlass fontSize="small" />
  </LoadingMessageWrapper>
);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "15px",
    minHeight: "30px",
    border: "#64B6A0",
    cursor: "pointer",
    paddingLeft: "1.5em",
    maxWidth: "748px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
    color: "white",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "white",
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "proxima-nova, sans-serif",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "proxima-nova, sans-serif",
    width: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: "proxima-nova, sans-serif",
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontFamily: "proxima-nova, sans-serif",
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: "hidden",
  }),
};

class WikiSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      selectedOption: {},
      selected: null,
      searchValue: "",
      options: [],
    };
  }

  componentDidMount() {
    this.props.getWiki();
    this.setState({ options: this.props.wiki });
  }

  componentDidUpdate(prevProps, prevState) {
    const { redirect } = this.state;

    if (prevState.redirect !== redirect) {
      this.setState({
        redirect: false,
        selectedOption: {},
      });
    }
  }

  getAsyncOptions = _.debounce((inputValue, callback) => {
    const { wiki } = this.props;
    apolloClient
      .query({
        query: ASYNC_SEARCH,
        variables: { value: inputValue, limit: 20 },
      })
      .then(
        ({ data: { productsSearchByModelNumber, zipCodesSearchByValue } }) => {
          const products = {
            label: "Product",
            options: convertToSearchOptions(
              productsSearchByModelNumber,
              inputValue
            ),
          };
          const zipcodes = {
            label: "Zip Code",
            options: convertToSearchOptions(zipCodesSearchByValue, inputValue),
          };

          return callback([...wiki, zipcodes, products]);
        }
      )
      .catch((error) => {
        console.log(error);
        return callback(wiki);
      });
  }, 500);

  loadWikiPage = (option) => {
    let url;
    if (option.type === "Product") {
      return window.location.assign(`/search/rebates/lighting?productIdType=REBATE_BUS_ID&productId=${option.value}&ft=p`);
    } if (option.type === "State") {
      return window.location.assign(`/search/rebates/lighting?productIdType=REBATE_BUS_ID&stateIds=${option.value}`);
    } else {
      url = `articles/${option.value}/${textToUrl(option.type)}/${textToUrl(
        option.label
      )}`;
    }

    this.setState({
      redirect: true,
      selectedOption: { ...option, url },
      selected: option.label,
    });
  };

  render() {
    const { selectedOption, redirect, selected, options } = this.state;
    let placeHolder = "Search utilities, programs, products, and zip codes...";
    if (this.props.isMobile) {
      customStyles.control = (provided) => ({
        ...provided,
        borderRadius: "15px",
        minHeight: "30px",
        border: "#64B6A0",
        cursor: "pointer",
        paddingLeft: "0.5em",
        maxWidth: "748px",
      });
      placeHolder = "Search by utility, product, or zip";
    } else {
      customStyles.control = (provided) => ({
        ...provided,
        borderRadius: "15px",
        minHeight: "30px",
        border: "#64B6A0",
        cursor: "pointer",
        paddingLeft: "1.5em",
        maxWidth: "748px",
      });
    }

    const filterOptions = ({ label }, input) => {
      this.setState({ searchValue: input });
      return label ? label.toLowerCase().includes(input.toLowerCase()) : null;
    };

    return (
      <>
        <AsyncSelect
          aria-label={"search bar"}
          key={`select-key-${selected}`}
          menuPlacement="auto"
          isSearchable={true}
          formatGroupLabel={formatGroupLabel}
          placeholder={placeHolder}
          components={{
            // Control,
            Group,
            // Placeholder,
            Option,
            LoadingMessage,
            MenuList: WindowedMenuList,
            DropdownIndicator:() => null,
            IndicatorSeparator:() => null
          }}
          styles={customStyles}
          onChange={(event) => {
            this.loadWikiPage(event);
          }}
          cacheOptions
          filterOption={createFilter({ matchFrom: "start" })}
          defaultOptions={this.props.wiki}
          loadOptions={this.getAsyncOptions}
          isLoading={this.props.wiki.length > 0 ? false : true}
          isMobile={this.props.isMobile}
          searchHome={this.props.searchHome}
        />
        {redirect && (
          <Redirect
            push
            to={{
              pathname: `/search/${selectedOption.url}`,
              state: { ...selectedOption },
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { wiki: state.wiki.wiki.wikiArticles };
};

export default connect(mapStateToProps, { getWiki })(WikiSearch);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const HourGlass = styled(HourglassEmptyIcon)`
  animation: ${rotate} 1.2s linear infinite;
  margin-left: 0.5em;
`;

const LoadingMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
