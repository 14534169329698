export const textToUrl = (text) => text.toLowerCase().replace(/[\s/]+/g, "-");

export const uppercase = (text) => {
  if (text === "state-territory") {
    return "State/Territory";
  } else if (text.includes("-")) {
    const array = text.split("-");
    return array
      .map((word) => {
        if (word.length > 0) {
          return word[0].toUpperCase() + word.slice(1);
        }
        return null;
      })
      .join(" ");
  }
  return text[0].toUpperCase() + text.slice(1);
};

export const formatUrl = (text) => {
  if (text.indexOf("http://") < 0 && text.indexOf("https://") < 0) {
    return `http://${text}`;
  }
  return text;
};
