import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import styled from "styled-components";

import IconMenu from "./IconMenu";
import WikiSearch from "../../Wiki/components/WikiSearch";

class MobileMenu extends Component {
  static propTypes = {
    /** Determines what the user can see */
    userPermissions: PropTypes.number.isRequired,
    /** Determines what the user can see */
    isLoggedIn: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ isOpen: false });
    }
  }

  toggleMobileMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { userPermissions, isLoggedIn } = this.props;

    const { isOpen } = this.state;

    const addActiveClass = {
      hamburger: isOpen ? "is-active" : "",
      mobileMenu: isOpen ? "active" : ""
    };

    return (
      <StyledMobileMenuWrapper className={addActiveClass["mobileMenu"]}>
        <MobileMenuTop>
          <button
            aria-label="mobile menu"
            className={`hamburger hamburger--spin ${addActiveClass["hamburger"]}`}
            type="button"
            onClick={this.toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <MobileSearch>
            <WikiSearch isMobile={true} />
          </MobileSearch>
        </MobileMenuTop>
        <MenuWrapper>
          <IconMenu userPermissions={userPermissions} isLoggedIn={isLoggedIn} />
        </MenuWrapper>
      </StyledMobileMenuWrapper>
    );
  }
}

export default withRouter(MobileMenu);

const StyledMobileMenuWrapper = styled.div`
  height: 3.5em;
  position: fixed;
  bottom: 0;
  width: 100%;
  -webkit-transition: height 1s;
  transition: height 0.8s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background-color: ${props => props.theme.colors.backgroundMain};
  box-shadow: ${props => props.theme.colors.boxShadow};

  &.active {
    height: calc(100% - 3em);
    overflow: scroll;
  }

  /*  
    * Hamburgers
    * @description Tasty CSS-animated hamburgers
    * @author Jonathan Suh @jonsuh
    * @site https://jonsuh.com/hamburgers
    * @link https://github.com/jonsuh/hamburgers
    */

  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    &:hover {
      opacity: 0.7;
    }
    &.is-active:hover {
      opacity: 0.7;
    }
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #16a888;
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #16a888;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }

  .hamburger-inner::before {
    top: -10px;
  }

  .hamburger-inner::after {
    bottom: -10px;
  }

  /*
   * Spin
  */
  .hamburger--spin .hamburger-inner {
    -webkit-transition-duration: 0.22s;
    transition-duration: 0.22s;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in,
      -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in,
      -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--spin.is-active .hamburger-inner {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-transition-delay: 0.12s;
    transition-delay: 0.12s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  }

  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out,
      -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out,
      -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const MobileMenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

const MobileSearch = styled.div`
  flex: 1 1 auto;
  padding: 0 1em;
`;

const MenuWrapper = styled.div``;
