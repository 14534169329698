import {
    ActionButton,
    AlertDialog,
    DialogContainer,
    Flex,
    Form,
    ProgressCircle,
    Text,
    TextArea,
    TextField,
    View
} from "@adobe/react-spectrum";
import {Controller, useForm} from "react-hook-form";
import Select, {Styles} from "react-select";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {Label} from "@react-spectrum/label";
import {useHistory, useRouteMatch} from "react-router-dom";
import Helmet from "react-helmet";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {v4 as generateUUID} from "uuid";
import orderIdGeneratorFactory from "order-id";
import {
    CREATE_REBATE_APPLICATION,
    CreateRebateApplicationParams,
    DELETE_REBATE_APPLICATION,
    DeleteRebateApplicationParams,
    GET_ADMIN_PROJECT_FORM_OPTIONS,
    GET_REBATE_APPLICATION_BY_ID,
    GET_REBATE_APPLICATIONS,
    GET_REBATE_APPLICATIONS_COUNT,
    GET_UTILITY_OPTIONS,
    GetFormOptionsResponse,
    GetRebateApplicationByIdParams,
    GetRebateApplicationByIdResponse,
    GetUtilityOptionsParams,
    GetUtilityOptionsResponse,
    MutationResponse,
    UPDATE_REBATE_APPLICATION
} from "./queries";
import {captureMessage} from "@sentry/react";
import {RebateApplicationInput, RebateApplicationStage, RebateApplicationStageDisplayNames} from "./types";
import {
    CANADIAN_POSTAL_CODE_RE,
    countries,
    CountryUUID,
    fulfillmentManagers,
    moneyRE,
    US_POSTAL_CODE_RE
} from "./constants";
import {of, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {useSnackbar} from "notistack";
import {UUID} from "../../types";
import axios from "axios";
import GarbageBinIcon from "../../icons/Remove";

const orderIdGenerator = orderIdGeneratorFactory("scrt");

export const narrowReactSelectStyles: Styles = {
    indicatorSeparator: (provided) => ({
        ...provided,
        margin: "7px 0",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "4px",
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: "0",
        transform: "translateY(-13px)"
    }),
    control: (provided, {isFocused}) => {
        const borderColor = isFocused ? "var(--accent)" : "var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400))";
        return {
            ...provided,
            minHeight: "32px",
            maxHeight: "32px",
            '&:hover': {borderColor},
            borderColor,
            boxShadow: borderColor,
        }
    },
};

interface FormGroupParams {
    children: any,
    label: string,
    id: string,
    isHidden?: boolean,
    isRequired?: boolean,
}

export const FormGroup = ({children, label, id, isHidden = false, isRequired = false}: FormGroupParams) => (
    <View id={id} isHidden={isHidden}>
        {label !== undefined ? <Label isRequired={isRequired}>{label}</Label> : null}
        {children}
    </View>
);

const ErrorMessageWrapper = ({message}: { message: string }) => (
    <View UNSAFE_style={{color: "var(--spectrum-global-color-red-700)"}}>
        {message}
    </View>
);

interface ErrorMessagesParams {
    errors: any,
    name: string,
}

export const ErrorMessages = ({errors, name}: ErrorMessagesParams) => {
    return (
        <HookErrorMessage
            errors={errors}
            name={name}
            render={({messages, message}) => {
                if (message !== undefined) {
                    return <ErrorMessageWrapper message={message}/>
                }

                if (messages !== undefined) {
                    Object.entries(messages).map(([type, message]) => (
                        <ErrorMessageWrapper key={type} message={message?.toString() || ""}/>
                    ))
                }

                return null;
            }}
        />
    )
}

interface ComponentState {
    utilityQuery: string,
    utilityOffset: number,
    utilityLastBatchSize: number,
}

enum ActionType {
    SET_UTILITY_QUERY,
    SET_UTILITY_OFFSET,
    SET_UTILITY_LAST_BATCH_SIZE,
}

interface SetUtilityQueryAction {
    type: ActionType.SET_UTILITY_QUERY,
    payload: {
        query: string
    }
}

interface SetUtilityOffsetAction {
    type: ActionType.SET_UTILITY_OFFSET,
    payload: {
        offset: number
    }
}

interface SetUtilityLastBatchSizeAction {
    type: ActionType.SET_UTILITY_LAST_BATCH_SIZE,
    payload: {
        size: number
    }
}

type Action =
    | SetUtilityOffsetAction
    | SetUtilityQueryAction
    | SetUtilityLastBatchSizeAction
    ;

function reducer(state: ComponentState, action: Action) {
    switch (action.type) {
        case ActionType.SET_UTILITY_QUERY:
            return {
                ...state,
                utilityQuery: action.payload.query,
            }
        case ActionType.SET_UTILITY_OFFSET:
            return {
                ...state,
                utilityOffset: action.payload.offset,
            }
        case ActionType.SET_UTILITY_LAST_BATCH_SIZE:
            return {
                ...state,
                utilityLastBatchSize: action.payload.size,
            }
    }
}

const initialState: ComponentState = {
    utilityQuery: "",
    utilityOffset: 0,
    utilityLastBatchSize: 0,
};

interface SelectOption {
    value: string,
    label: string,
}

interface NumericSelectOption {
    value: number,
    label: string,
}

type DateString = string;

interface FormValues {
    budgetYear: NumericSelectOption | null,
    canadianArea: NumericSelectOption | null,
    city: string,
    company: SelectOption | null,
    country: SelectOption | null,
    customer: string,
    finalRebateAmount: string,
    fulfillmentCharge: string,
    fulfillmentManager: SelectOption | null,
    managementFee: string,
    notes: string,
    preApprovedRebateAmount: string,
    projectCost: string,
    projectDeadline: DateString,
    projectName: string,
    rebateAmountPaid: string,
    rebateEstimate: string,
    stage: SelectOption,
    technologyType: NumericSelectOption | null,
    usState: SelectOption | null,
    utility: SelectOption | null,
    zipCode: string,
}

const stageOptions: Array<FormValues["stage"]> = Array.from(RebateApplicationStageDisplayNames.entries()).map(([value, label]) => {
    return {
        value,
        label,
    }
})

const fulfillmentManagerOptions: Array<FormValues["fulfillmentManager"]> = fulfillmentManagers.map(({id, name}) => {
    return {
        value: id,
        label: name,
    }
})

const countryOptions: Array<FormValues["country"]> = countries.map(({id, name}) => {
    return {
        value: id,
        label: name,
    }
})

const budgetYearOptions: Array<FormValues["budgetYear"]> = [
    2019,
    2020,
    2021,
    2022,
].map((year) => {
    return {
        value: year,
        label: year.toString(),
    }
})

function AdminRebateApplicationForm() {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const manageProjectViewMatch = useRouteMatch<{
        internalID: string
    }>({
        path: '/services/rebates/manage/:internalID'
    });

    const addProjectViewMatch = useRouteMatch({
        path: '/services/rebates/add'
    });

    const history = useHistory();

    const internalID = manageProjectViewMatch?.params.internalID;

    const [state, dispatch] = useReducer(reducer, initialState);
    const [applicationId, setApplicationId] = useState<string | null>(null);
    const utilityInputValueSubject = useRef(new Subject<string>());
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const {control, errors, formState, getValues, trigger, watch, setValue, register, reset} = useForm<FormValues>({
        defaultValues: {
            company: null,
            customer: "",
            projectName: "",
            stage: {
                value: RebateApplicationStage.NEW,
                label: RebateApplicationStageDisplayNames.get(RebateApplicationStage.NEW)
            },
            fulfillmentManager: null,
            country: null,
            usState: null,
            canadianArea: null,
            city: "",
            zipCode: "",
            budgetYear: null,
            technologyType: null,
            utility: null,
            fulfillmentCharge: "",
            projectCost: "",
            rebateEstimate: "",
            managementFee: "",
            rebateAmountPaid: "",
            projectDeadline: "",
            notes: "",
            preApprovedRebateAmount: "",
            finalRebateAmount: "",
        },
        mode: "onChange",
    });

    const {enqueueSnackbar} = useSnackbar();

    const {
        error: formOptionsError,
        loading: isLoadingFormOptions,
        data: formOptions
    } = useQuery<GetFormOptionsResponse>(GET_ADMIN_PROJECT_FORM_OPTIONS);

    useEffect(() => {
        const subscription = utilityInputValueSubject.current.pipe(switchMap((x) => of(x))).subscribe((value) => {
            dispatch({
                type: ActionType.SET_UTILITY_QUERY,
                payload: {query: value}
            })
        });

        return () => subscription.unsubscribe();
    }, [utilityInputValueSubject]);


    const watchCountry = watch("country");
    const watchUsState = watch("usState");
    const watchCompany = watch("company");
    const stateIds = watchUsState?.value ? [watchUsState.value] : [];
    const {
        data: usUtilitiesData,
        error: usUtilitiesError,
        loading: usUtilitiesLoading
    } = useQuery<GetUtilityOptionsResponse, GetUtilityOptionsParams>(GET_UTILITY_OPTIONS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                name: state.utilityQuery,
                stateIds,
            },
        },
        skip: stateIds.length === 0,
    });


    const [getExistingRebateApplication] =
        useLazyQuery<GetRebateApplicationByIdResponse,
            GetRebateApplicationByIdParams>(GET_REBATE_APPLICATION_BY_ID, {
            onCompleted: ({response: {applications}}) => {
                const app = applications[0];
                if (app === undefined) {
                    alert("Sorry, there was an error");
                    captureMessage("getExistingRebateApplication in AdminRebateApplicationForm")
                }

                const values: Partial<FormValues> = {
                    country: {
                        label: app.country.name,
                        value: app.country.id,
                    },
                    city: app.city,
                    company: {
                        label: app.company.name,
                        value: app.company.id,
                    },
                    customer: app.customer,
                    rebateEstimate: app.rebateEstimate ? app.rebateEstimate.toFixed(2) : "",
                    preApprovedRebateAmount: app.preApprovedRebateAmount ? app.preApprovedRebateAmount.toFixed(2) : "",
                    finalRebateAmount: app.finalRebateAmount ? app.finalRebateAmount.toFixed(2) : "",
                    rebateAmountPaid: app.rebateAmountPaid ? app.rebateAmountPaid.toFixed(2) : "",
                    managementFee: app.managementFee ? app.managementFee.toFixed(2) : "",
                    projectCost: app.projectCost ? app.projectCost.toFixed(2) : "",
                    fulfillmentCharge: app.fulfillmentCharge,
                    notes: app.notes,
                    projectDeadline: app.projectDeadline || "",
                    projectName: app.projectName,
                    stage: {
                        label: RebateApplicationStageDisplayNames.get(app.stage) || "",
                        value: app.stage,
                    },
                    zipCode: app.zipCode,
                    technologyType: null,
                    fulfillmentManager: null,
                    utility: null,
                    budgetYear: null,
                };

                if (app.country.id === CountryUUID.Canada) {
                    const canadianArea = app.canadianArea;
                    if (canadianArea && canadianArea.name && canadianArea.id) {
                        values.canadianArea = {
                            label: canadianArea.name,
                            value: canadianArea.id,
                        }
                        values.usState = null;
                    }
                } else if (app.country.id === CountryUUID.US) {
                    const usState = app.usState;
                    if (usState && usState.name && usState.id) {
                        values.usState = {
                            label: usState.name,
                            value: usState.id,
                        }
                        values.canadianArea = null;
                    }
                }

                const technologyType = app.technologyType;
                if (technologyType && technologyType.id && technologyType.name) {
                    values.technologyType = {
                        label: technologyType.name,
                        value: technologyType.id,
                    };
                }

                const fulfillmentManager = app.fulfillmentManager;
                if (fulfillmentManager && fulfillmentManager.id && fulfillmentManager.name) {
                    values.fulfillmentManager = {
                        label: fulfillmentManager.name,
                        value: fulfillmentManager.id,
                    };
                }

                const utility = app.utility;
                if (utility && utility.id && utility.name) {
                    values.utility = {
                        label: utility.name,
                        value: utility.id,
                    };
                }

                const budgetYear = app.budgetYear;
                if (budgetYear) {
                    values.budgetYear = {
                        label: budgetYear.toString(),
                        value: budgetYear,
                    };
                }

                reset(values);
                setApplicationId(app.applicationId);
            }
        });

    useEffect(() => {
        if (internalID !== undefined) {
            getExistingRebateApplication({
                variables: {id: internalID},
            });
        }
    }, [internalID, getExistingRebateApplication])

    const [createRebateApplication] =
        useMutation<MutationResponse, CreateRebateApplicationParams>(CREATE_REBATE_APPLICATION)

    const [updateRebateApplication] =
        useMutation<MutationResponse, CreateRebateApplicationParams>(UPDATE_REBATE_APPLICATION)

    const [deleteRebateApplication] =
        useMutation<MutationResponse, DeleteRebateApplicationParams>(DELETE_REBATE_APPLICATION)

    const onDelete = async () => {
        setIsSubmitting(true);
        if (!internalID) {
            return
        }

        const response = await deleteRebateApplication({
            variables: {
                applicationId: internalID,
            }
        })
        if (response?.data?.data.status === "OK") {
            enqueueSnackbar("Successfully deleted", {variant: "success"});
            setTimeout(() => history.push('/services/rebates/list'), 1000);
        } else {
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    const uploadFile = async (file: File, applicationId: UUID) => {
        let formData = new FormData();
        formData.append('attachment', file);

        await axios.post(
            `/api/v2/applications/attachments/${watchCompany?.value}/${applicationId}`,
            formData,
            {
                headers: {
                    "Authorization": localStorage.getItem("token") || "",
                    "Content-type": "multipart/form-data",
                },
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return;
                } else if (res.status === 500) {
                    const text = res.data;
                    console.error(text);
                    captureMessage(text);
                    alert("Sorry, there was an error uploading the file. Please try again later.");
                } else {
                    alert("Sorry, there was an error uploading the file.");
                }
            })
            .catch((error) => {
                console.error(error);
                captureMessage(error.message);
                alert("Sorry, there was an error. Please try again later.")
            })
    }

    const onSubmit = async () => {
        setHasBeenSubmitted(true);
        const isValid = await trigger();
        if (!isValid) {
            return;
        }

        const localInternalId = internalID || generateUUID();

        if (fileInputRef.current?.files && fileInputRef.current?.files.length > 0) {
            const file = fileInputRef.current?.files.item(0)!;
            if (file.size > 20 * 1024 * 1024) {
                return alert("File must not be larger than 20 megabytes");
            }
            setIsSubmitting(true);
            // TODO there's a possibility of a memory leak if the file attachment is successfully
            //  uploaded but the project fails to create.
            await uploadFile(file, localInternalId);
        }

        setIsSubmitting(true);
        try {
            const data = getValues();
            const country = data.country!;
            const company = data.company!;
            const technologyType = data.technologyType!;

            const transferObject: Partial<RebateApplicationInput> = {
                id: localInternalId,
                applicationId: applicationId || orderIdGenerator.generate(),
                budgetYear: data.budgetYear?.value || null,
                country: {
                    id: country.value,
                    name: country.label,
                },
                city: data.city,
                company: {
                    id: company.value,
                    name: company.label,
                },
                customer: data.customer,
                rebateEstimate: data.rebateEstimate === "" ? null : parseFloat(data.rebateEstimate),
                preApprovedRebateAmount: data.preApprovedRebateAmount === "" ? null : parseFloat(data.preApprovedRebateAmount),
                finalRebateAmount: data.finalRebateAmount === "" ? null : parseFloat(data.finalRebateAmount),
                rebateAmountPaid: data.rebateAmountPaid === "" ? null : parseFloat(data.rebateAmountPaid),
                managementFee: data.managementFee === "" ? null : parseFloat(data.managementFee),
                projectCost: data.projectCost === "" ? null : parseFloat(data.projectCost),
                fulfillmentCharge: data.fulfillmentCharge,
                notes: data.notes,
                projectDeadline: data.projectDeadline === "" ? null : data.projectDeadline,
                projectName: data.projectName,
                stage: data.stage.value as RebateApplicationStage,
                technologyType: {
                    id: technologyType.value,
                    name: technologyType.label,
                },
                zipCode: data.zipCode,
            }

            if (country.value === CountryUUID.Canada) {
                const canadianArea = data.canadianArea!;
                transferObject.canadianArea = {
                    id: canadianArea.value,
                    name: canadianArea.label,
                }
            } else if (country.value === CountryUUID.US) {
                const usState = data.usState!;
                transferObject.usState = {
                    id: usState.value,
                    name: usState.label,
                }
            }

            const fulfillmentManager = data.fulfillmentManager;
            if (fulfillmentManager !== null) {
                transferObject.fulfillmentManager = {
                    id: fulfillmentManager.value,
                    name: fulfillmentManager.label,
                };
            }

            const utility = data.utility;
            if (utility !== null) {
                transferObject.utility = {
                    id: utility.value,
                    name: utility.label,
                };
            }

            if (addProjectViewMatch) {
                const response = await createRebateApplication({
                    variables: {
                        payload: transferObject as RebateApplicationInput,
                    },
                    refetchQueries: [
                        {
                            query: GET_REBATE_APPLICATIONS,
                        },
                        {
                            query: GET_REBATE_APPLICATIONS_COUNT,
                        }
                    ]
                })
                if (response?.data?.data.status === "OK") {
                    enqueueSnackbar("Successfully created", {variant: "success"});
                    setTimeout(() => window.location.assign('/services/rebates/list'), 500);
                } else {
                    setIsSubmitting(false);
                    setIsErrorDialogOpen(true);
                }
            } else if (manageProjectViewMatch) {
                const response = await updateRebateApplication({
                    variables: {
                        payload: transferObject as RebateApplicationInput,
                    }
                })
                setIsSubmitting(false);
                if (response?.data?.data.status === "OK") {
                    enqueueSnackbar("Successfully updated", {
                        variant: "success"
                    });
                } else {
                    setIsErrorDialogOpen(true);
                }
            }
        } catch (e) {
            console.error(e);
            setIsSubmitting(false);
            setIsErrorDialogOpen(true);
        }
    }

    if (formOptionsError) {
        captureMessage(formOptionsError.message);
        console.error(formOptionsError.message);
        return <p>Sorry, there was an error loading form options</p>
    }

    const companyOptions: Array<FormValues["company"]> = formOptions?.companiesResponse.companies.map((company) => {
        return {
            label: company.name,
            value: company.id,
        }
    }) || [];

    const usStateOptions: Array<FormValues["usState"]> = formOptions?.states.map((state) => {
        return {
            label: state.name,
            value: state.id,
        }
    }) || [];

    const canadianAreasOptions: Array<FormValues["canadianArea"]> = formOptions?.canadianProvinces.map((province) => {
        return {
            label: province.name,
            value: province.id,
        }
    }) || [];

    const technologyTypeOptions: Array<FormValues["technologyType"]> = formOptions?.technologyTypes.map((type) => {
        return {
            label: type.name,
            value: type.id,
        }
    }) || [];

    return (
        <View width="size-5000"
        >
            <Helmet>
                <meta name="description" content="Rebate Project."/>
                <title>Rebate Project | Rebate Bus</title>
            </Helmet>
            {
                isLoadingFormOptions
                    ? <ProgressCircle aria-label="Loading…" isIndeterminate/>
                    : (
                        <Flex
                            justifyContent="center"
                            width="100%">
                            <Form maxWidth="400px" width="100%" margin="size-200">
                                <Flex direction="column" maxWidth="650px" width="100%" gap="size-100">
                                    <FormGroup
                                        isHidden={manageProjectViewMatch !== null}
                                        label="Company"
                                        id="companySelect"
                                        isRequired
                                    >
                                        <Controller
                                            control={control}
                                            name="company"
                                            rules={{
                                                required: "Company is required"
                                            }}
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    menuPlacement="auto"
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={companyOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                    {
                                        (!formState.touched.company && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="company"/>
                                        )
                                    }

                                    {
                                        (manageProjectViewMatch !== null) ?
                                            (
                                                <View>
                                                    <Label>Company</Label>
                                                    <Text>{watchCompany?.label}</Text>
                                                </View>
                                            )
                                             : <></>
                                    }

                                    <Controller
                                        control={control}
                                        name='customer'
                                        render={(props) => (
                                            <TextField
                                                {...props}
                                                label="Customer"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name='projectName'
                                        render={(props) => (
                                            <TextField
                                                {...props}
                                                label="Project name"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />

                                    <FormGroup label="Stage" id="stageSelect">
                                        <Controller
                                            control={control}
                                            name="stage"
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    options={stageOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>

                                    <FormGroup label="Fulfillment manager" id="fulfillmentManagerSelect">
                                        <Controller
                                            control={control}
                                            name="fulfillmentManager"
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    isClearable
                                                    menuPlacement="auto"
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={fulfillmentManagerOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>


                                    <FormGroup
                                        label="Country"
                                        id="countrySelect"
                                        isRequired
                                    >
                                        <Controller
                                            control={control}
                                            name="country"
                                            rules={{
                                                required: "Country is required"
                                            }}
                                            render={({onChange, ...props}) => (
                                                <Select
                                                    {...props}
                                                    onChange={(value) => {
                                                        setValue("usState", null);
                                                        setValue("canadianArea", null);
                                                        setValue("utility", null);
                                                        onChange(value);
                                                    }}
                                                    menuPlacement="auto"
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={countryOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                    {
                                        (!formState.touched.country && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="country"/>
                                        )
                                    }

                                    <FormGroup
                                        label="State"
                                        id="usStateSelect"
                                        isRequired
                                        isHidden={watchCountry?.value !== CountryUUID.US}
                                    >
                                        <Controller
                                            control={control}
                                            name="usState"
                                            rules={{
                                                validate: {
                                                    required: ((value) => {
                                                        if (watchCountry?.value === CountryUUID.US && !value) {
                                                            return "State is required";
                                                        }
                                                        return true;
                                                    }),
                                                }
                                            }}
                                            render={({onChange, ...props}) => (
                                                <Select
                                                    {...props}
                                                    onChange={(value) => {
                                                        setValue("utility", null);
                                                        onChange(value);
                                                    }}
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={usStateOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                    {
                                        (!formState.touched.usState && !hasBeenSubmitted && watchCountry?.value !== CountryUUID.US) ? <></> : (
                                            <ErrorMessages errors={errors} name="usState"/>
                                        )
                                    }

                                    <FormGroup
                                        label="Province/Territory"
                                        id="canadianProvinceSelect"
                                        isHidden={watchCountry?.value !== CountryUUID.Canada}
                                        isRequired
                                    >
                                        <Controller
                                            control={control}
                                            name="canadianArea"
                                            rules={{
                                                validate: {
                                                    required: ((value) => {
                                                        if (watchCountry?.value === CountryUUID.Canada && !value) {
                                                            return "Province/Territory is required";
                                                        }
                                                        return true;
                                                    }),
                                                }
                                            }}
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={canadianAreasOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                    {
                                        (!formState.touched.canadianArea && !hasBeenSubmitted && watchCountry?.value !== CountryUUID.Canada) ? <></> : (
                                            <ErrorMessages errors={errors} name="canadianArea"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='city'
                                        render={(props) => (
                                            <TextField
                                                {...props}
                                                label="City"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name='zipCode'
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (value === "") {
                                                        return "Zip code is required.";
                                                    }

                                                    return true;
                                                },
                                                zipCode: (value) => {
                                                    if (watchCountry?.value === CountryUUID.US && !US_POSTAL_CODE_RE.test(value)) {
                                                        return "Zip code must be a valid US zip code"
                                                    }

                                                    if (watchCountry?.value === CountryUUID.Canada && !CANADIAN_POSTAL_CODE_RE.test(value)) {
                                                        return "Zip code must be a valid Canadian zip code. For example, S4T 4L1"
                                                    }

                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({onChange, ...props}) => (
                                            <TextField
                                                isHidden={watchCountry === null}
                                                isRequired
                                                onChange={(value) => {
                                                    onChange(value.toUpperCase())
                                                }}
                                                {...props}
                                                label="Zip code"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />
                                    {
                                        (!formState.touched.zipCode && !hasBeenSubmitted)
                                            ? <></>
                                            : watchCountry !== null
                                            ? <ErrorMessages errors={errors} name="zipCode"/>
                                            : <></>

                                    }

                                    <FormGroup label="Budget year" id="budgetYearSelect">
                                        <Controller
                                            control={control}
                                            name="budgetYear"
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    menuPlacement="auto"
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={budgetYearOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        isRequired
                                        label="Technology Type"
                                        id="technologyTypeSelect"
                                    >
                                        <Controller
                                            control={control}
                                            name="technologyType"
                                            rules={{
                                                required: "Technology Type is required"
                                            }}
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    menuPlacement="auto"
                                                    placeholder=""
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={isLoadingFormOptions}
                                                    options={technologyTypeOptions}
                                                />
                                            )}
                                        />
                                    </FormGroup>

                                    {
                                        (!formState.touched.technologyType && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="technologyType"/>
                                        )
                                    }

                                    <FormGroup
                                        label="Utility"
                                        id="utilitySelect"
                                        isRequired
                                        isHidden={watchUsState === null}
                                    >
                                        <Controller
                                            control={control}
                                            name="utility"
                                            defaultValue={null}
                                            rules={{
                                                validate: {
                                                    required: (value) => {
                                                        if (watchUsState !== null && value === null) {
                                                            return "Utility is required"
                                                        }
                                                        return true;
                                                    }
                                                }
                                            }}
                                            render={(props) => (
                                                <Select
                                                    {...props}
                                                    placeholder=""
                                                    menuPlacement="auto"
                                                    styles={narrowReactSelectStyles}
                                                    isLoading={usUtilitiesLoading}
                                                    options={usUtilitiesData?.utilityOptions || []}
                                                />
                                            )}
                                            noOptionsMessage={() => {
                                                if (usUtilitiesLoading) {
                                                    return "Loading..."
                                                }
                                                return "No options"
                                            }}
                                            onInputChange={(value: string, {action}: { action: "input-change" | "menu-close" }) => {
                                                if (action === "input-change") {
                                                    utilityInputValueSubject.current.next(value);
                                                }
                                                if (action === "menu-close") {
                                                    utilityInputValueSubject.current.next("");
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    {
                                        (!formState.touched.utility && !hasBeenSubmitted && watchUsState === null) ? <></> : (
                                            <ErrorMessages errors={errors} name="utility"/>
                                        )
                                    }
                                    {
                                        usUtilitiesError ? <p>Sorry, there was an error getting utility</p> : <></>
                                    }

                                    <Controller
                                        control={control}
                                        name='fulfillmentCharge'
                                        render={(props) => (
                                            <TextField
                                                {...props}
                                                label="Fulfillment charge"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name='projectCost'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Project cost must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Project cost ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.projectCost && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="projectCost"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='rebateEstimate'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Rebate amount must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Rebate estimate ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.rebateEstimate && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="rebateEstimate"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='preApprovedRebateAmount'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Rebate amount must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Pre-approved rebate amount ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.preApprovedRebateAmount && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="preApprovedRebateAmount"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='finalRebateAmount'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Rebate amount must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Final rebate amount ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.finalRebateAmount && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="finalRebateAmount"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='rebateAmountPaid'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Rebate amount must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Rebate amount paid ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.rebateAmountPaid && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="rebateAmountPaid"/>
                                        )
                                    }

                                    <Controller
                                        control={control}
                                        name='managementFee'
                                        rules={{
                                            validate: (value) => {
                                                if (value.trim() === "" || moneyRE.test(value)) {
                                                    return true;
                                                }

                                                return "Rebate management fee must be a valid dollar amount. For example 2345 or 2345.54"
                                            }
                                        }}
                                        render={({value, ...props}) => {
                                            return (
                                                <TextField
                                                    {...props}
                                                    value={value === null ? "" : value.toString()}
                                                    label="Rebate management fee ($)"
                                                    UNSAFE_style={{width: "100%"}}
                                                    maxLength={11}
                                                />
                                            );
                                        }}
                                    />
                                    {
                                        (!formState.touched.managementFee && !hasBeenSubmitted) ? <></> : (
                                            <ErrorMessages errors={errors} name="managementFee"/>
                                        )
                                    }

                                    <FormGroup
                                        label="Project deadline"
                                        id="projectDeadlineInput"

                                    >
                                        <Flex gap="size-50">
                                            <input style={{
                                                color: "var(--spectrum-alias-text-color)",
                                                fontSize: "var(--spectrum-alias-font-size-default,var(--spectrum-global-dimension-font-size-100))",
                                                height: "var(--spectrum-alias-single-line-height,var(--spectrum-global-dimension-size-400))"
                                            }}
                                                   min="2019-01-01" max="2029-12-31"
                                                   type="date"
                                                   name="projectDeadline"
                                                   ref={register}/>
                                            <ActionButton
                                                aria-label="Reset date"
                                                onPress={() => setValue("projectDeadline", "")}
                                            >
                                                <GarbageBinIcon style={{width: "1.3em"}}/>
                                            </ActionButton>
                                        </Flex>
                                    </FormGroup>

                                    <Controller
                                        control={control}
                                        name='notes'
                                        render={(props) => (
                                            <TextArea
                                                {...props}
                                                label="Notes"
                                                UNSAFE_style={{width: "100%"}}
                                                maxLength={255}
                                            />
                                        )}
                                    />

                                    {
                                        addProjectViewMatch ? (
                                            <View>
                                                <Label>Attachment</Label>
                                                <Flex alignItems="center" gap="size-100">
                                                    <input type="file" ref={fileInputRef} name="attachment"/>
                                                    <ActionButton aria-label="Clear file input." onPress={() => {
                                                        if (fileInputRef.current) {
                                                            fileInputRef.current.value = ""
                                                        }
                                                    }}>
                                                        <GarbageBinIcon style={{width: "1.3em"}} />
                                                    </ActionButton>
                                                </Flex>
                                                <Text>Max file size: 20mb</Text>
                                            </View>
                                        ) : <></>
                                    }

                                    {
                                        isSubmitting ? (
                                            <ProgressCircle aria-label="Loading…" isIndeterminate/>
                                        ) : (
                                            <Flex>
                                                <ActionButton
                                                    marginTop="size-300"
                                                    onPress={() => onSubmit()}
                                                    width="11em"
                                                >{addProjectViewMatch ? "Create project" : manageProjectViewMatch ? "Update project" : "Submit"}</ActionButton>

                                                {
                                                    manageProjectViewMatch ?
                                                        (
                                                            <ActionButton
                                                                marginTop="size-300"
                                                                marginStart="auto"
                                                                onPress={() => setIsDeleteDialogOpen(true)}
                                                                width="11em"
                                                            >Delete project</ActionButton>
                                                        )
                                                        : <></>
                                                }
                                            </Flex>
                                        )
                                    }
                                    <DialogContainer onDismiss={() => {
                                        setIsErrorDialogOpen(false);
                                    }}>
                                        {isErrorDialogOpen && (
                                            <AlertDialog
                                                title="Error"
                                                variant="error"
                                                primaryActionLabel="OK">
                                                Sorry. There was an error. Please <a
                                                href="https://lincolnlabsco.slack.com/archives/C0141253Q7J">tell the dev
                                                team</a>.
                                            </AlertDialog>
                                        )}
                                    </DialogContainer>


                                </Flex>
                            </Form>
                        </Flex>
                    )
            }
            <DialogContainer onDismiss={() => {
                setIsDeleteDialogOpen(false);
            }}>
                {isDeleteDialogOpen && (
                    <AlertDialog
                        variant="destructive"
                        title="Delete project"
                        primaryActionLabel="Delete"
                        cancelLabel="Cancel"
                        onPrimaryAction={onDelete}
                    >
                        Are you sure you want to delete this project?
                    </AlertDialog>
                )}
            </DialogContainer>
        </View>
    )
}

export default AdminRebateApplicationForm;
