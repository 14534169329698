import {
  LOAD_PROGRAMS,
  CLEAR_PROGRAMS,
  FILTER_PROGRAMS,
  SORT_PROGRAMS,
} from "./types";

import apolloClient from "../../helpers/apolloClient";
import { gql } from "apollo-boost";

export const getPrograms = (stateId) => (dispatch) => {
  apolloClient
    .query({
      query: gql`
        {
          state(uuid: "${stateId}"){
            utilities {
              programs {
                name
                uuid
                description
                startDate
                endDate
                isActive
                technologyTypes {value uuid}
                customerTypes {value}
                utilities{ name, uuid }
              }
            }
          }
        }
      `,
    })
    .then(({ data }) => {
      const programs = data.state.utilities
        .filter((utility) => utility.programs && utility.programs.length > 0)
        .reduce((acc, utility) => {
          const uniquePrograms = utility.programs.filter(
            (program) =>
              !acc.some(
                (existingProgram) => existingProgram.uuid === program.uuid
              )
          );
          return [...acc, ...uniquePrograms];
        }, []);

      const programsByState = {
        state: stateId,
        programs,
      };

      return dispatch(loadPrograms(programsByState));
    })
    .catch((error) => console.log(error));
};

export const clearPrograms = () => ({
  type: CLEAR_PROGRAMS,
});

const loadPrograms = (programsByState) => ({
  type: LOAD_PROGRAMS,
  programsByState,
});

export const sortPrograms = (sortBy) => ({
  type: SORT_PROGRAMS,
  sortBy,
});

export const filterPrograms = (filter) => ({
  type: FILTER_PROGRAMS,
  filter,
});

export const filterAndSort = (sortBy, filter) => (dispatch) => {
  dispatch(filterPrograms(filter));
  dispatch(sortPrograms(sortBy));
};
