import React from "react";
import Section from "../pageComponents/section";
import { Helmet } from "react-helmet";

const RebateConnect = props => {
  const sections = [
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Rebate+Connect/Connect_1.svg",
      title: "Increase your sales with rebates",
      content:
        "Rebate Connect + is a powerful combination of our product catalog web tool and API subscription. Our web tool is a brand-customized catalog of all your online products displayed alongside our rebate data. Advertise products at a discounted price and apply rebates at checkout, all with Rebate Connect + on your website!",
      flip: true
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Rebate+Connect/Connect_2.svg",
      title: "Built for eCommerce",
      content:
        "Rebate Connect + is for distributors and eCommerce vendors who want a full-service solution for their clients - from browsing to buying. Whether you’re using Magento, Shopify, or Big Commerce, you’re able to showcase your company’s products online, enhancing your sales and marketing pipeline.",
      flip: false
    },
    {
      image:
        "https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Rebate+Connect/Connect_3.svg",
      title: "Affordable pricing",
      content:
        "When purchased individually, Rebate Connect is $500 / month on a month-to-month contract. This includes five hours of customer support and installation. Rebate Connect +, which includes an API subscription, costs $700 / month. The API subscription included is only valid for instant/midstream programs. For more information, contact sales@rebatebus.com.",
      flip: true
    }
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Rebate Connect information" />
        <title>Rebate Bus | Connect +</title>
      </Helmet>
      <h1>Rebate Connect +</h1>
      {sections.map((section, i) => (
        <Section key={`rebate-connect-section-${i}`} section={section} />
      ))}
    </div>
  );
};

export default RebateConnect;
