import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStory } from "../../ducks/actions";

import styled from "styled-components";
import Loading from "../../../Common/components/Loading";

import { uppercase } from "../../../Wiki/helpers/urls";

const Release = () => {
  const { title, date } = useParams();
  const formattedTitle = uppercase(title);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStory(formattedTitle, date));
  }, [formattedTitle, date, dispatch]);

  const [loading, setLoading] = useState(true);
  const story = useSelector(state => state.marketing.story);

  useEffect(() => {
    if (story) {
      setLoading(false);
    }
  }, [story]);

  return (
    <>
      <Helmet>
        <meta name="description" content={story.description} />
        <title>Rebate Bus | {formattedTitle}</title>
      </Helmet>
      <h1>{formattedTitle}</h1>
      {loading ? <Loading /> : null}
      {!loading ? (
        <StoryWrapper>
          <p className="description">{story.description}</p>
          {story.text}
        </StoryWrapper>
      ) : null}
    </>
  );
};

export default Release;

const StoryWrapper = styled.div`
  p.description {
    font-style: italic;
  }

  ul {
    list-style: disc;
    li {
      list-style-position: outside;
      margin: 0 0 0.5em 2em;
      line-height: 1.2;

      &:last-of-type {
        margin-bottom: 0.9em;
      }
    }
  }

  a {
    font-size: inherit;
  }

  span {
    font-weight: bold;
  }

  div.moreInfo {
    p {
      margin-bottom: 0;
    }
  }
`;
