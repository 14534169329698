import {ActionButton, Flex, View} from "@adobe/react-spectrum";
import Remove from "@spectrum-icons/workflow/Remove";
import React from "react";
import {HasChildren} from "../RebateSearch/RebateSearch";
import {ViewProps} from "@react-types/view";
import Filter from "@spectrum-icons/workflow/Filter";
import {Children} from "../../types";
import {siteHeaderWidth, tabPanelWidth, toolBarPanelWidth} from "./constants";

export enum TabOption {
    Filters = "Filters",
}

enum Direction {
    Row = "row",
    Column = "column"
}

interface TabsProps extends ViewProps {
    selectedOption: TabOption | null,
    onClick: (option: TabOption) => void,
    isNotDesktop: boolean,
}

interface TabProps {
    children: Children,
    selectedOption: TabOption | null,
    option: TabOption,
    onClick: (option: TabOption) => void,
    direction: String | Direction.Row,
}

const Tab = ({
                 children,
                 option,
                 selectedOption,
                 onClick,
                 direction,
             }: TabProps) => {
    const style = {
        paddingBottom: "var(--spectrum-global-dimension-size-100)",
        paddingTop: "var(--spectrum-global-dimension-size-100)",
    };

    return (
        <div
            onClick={() => onClick(option)}
            style={direction === Direction.Row ? {...style, paddingLeft: "var(--spectrum-global-dimension-size-100)", paddingRight: "var(--spectrum-global-dimension-size-100)"} : {...style}}
            className={selectedOption === option ? "toolbar-tab toolbar-tab__selected" : "toolbar-tab"}
        >
            <Flex alignItems="center" justifyContent="center" width="100%" direction={direction} gap="size-100">
                {/* @ts-ignore */}
                {children}
            </Flex>
        </div>
    );
}

const Tabs = ({selectedOption, onClick, isNotDesktop}: TabsProps) => {
    return (
        <Flex direction={isNotDesktop ? "row" : "column"}>
            <Tab direction={isNotDesktop ? Direction.Row : Direction.Column} selectedOption={selectedOption} option={TabOption.Filters} onClick={onClick}>
                <Filter size="S"/>
                <View UNSAFE_className="tab-text">{TabOption.Filters}</View>
            </Tab>
        </Flex>
    );
}

interface ToolBarProps extends HasChildren {
    selectedOption: TabOption | null,
    onTabChange: (option: TabOption) => void,
    collapse: () => void,
    isNotDesktop: boolean,
}

export const ToolBar = ({selectedOption, onTabChange, children, collapse, isNotDesktop}: ToolBarProps) => {

    return (
        <View
            // minHeight="100%"
        >
            <Flex
                // minHeight="100%"
                direction={isNotDesktop ? "column-reverse" : "row"}
            >
                {
                    selectedOption !== null ? (
                        <View
                            borderStartWidth="thin"
                            borderStartColor="gray-400"
                        >
                            <Flex
                                direction="column"
                                minWidth={`${toolBarPanelWidth}em`}
                                maxWidth={isNotDesktop ? '100%' : `${toolBarPanelWidth}em`}
                                position="relative"
                                UNSAFE_style={!isNotDesktop && {
                                    minHeight: `calc(100vh - ${siteHeaderWidth + 0.5}em)`,
                                    maxHeight: `calc(100vh - ${siteHeaderWidth + 0.5}em)`,
                                }}
                            >
                                <View
                                    paddingX="size-200"
                                    paddingY="size-100"
                                >
                                    <Flex>
                                        <div style={{fontSize: "1.5em"}}>
                                            {selectedOption}
                                        </div>
                                        <ActionButton
                                            aria-label="collapse"
                                            isQuiet={true}
                                            marginStart="auto"
                                            UNSAFE_style={{
                                                cursor: "pointer"
                                            }}
                                            onPress={() => collapse()}
                                        >
                                            <Remove/>
                                        </ActionButton>
                                    </Flex>
                                </View>

                                <View
                                    borderTopWidth="thin"
                                    borderTopColor="gray-400"
                                    overflow="hidden auto"
                                    paddingTop="size-200"
                                    paddingX="size-200"
                                    UNSAFE_style={!isNotDesktop && {
                                        minHeight: "calc(100vh - 3.5em - 3em)",
                                    }}
                                >
                                    <Flex
                                        direction="column"
                                        gap="size-200"
                                        width="100%"
                                    >
                                        {/* @ts-ignore */}
                                        {children}
                                    </Flex>
                                </View>

                            </Flex>
                        </View>
                    ) : <></>
                }

                <View
                    borderStartColor="gray-400"
                    borderStartWidth="thin"
                    minHeight="100%"
                    minWidth={`${tabPanelWidth}em`}
                    maxWidth={`${tabPanelWidth}em`}
                >
                    <Tabs isNotDesktop={isNotDesktop} selectedOption={selectedOption} onClick={(option) => onTabChange(option)}/>
                </View>
            </Flex>
        </View>
    )
}