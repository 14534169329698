import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({
  component: Component,
  authed,
  user,
  messages,
  renderProps,
  location,
  path,
  ...props
}) => {
  return (
  <Route
    {...props}
    render={props => {
      if(path && path === "/profile") {
        if (localStorage.token) {
          const UserLoaded = Object.keys(user).length != 0;
          if(authed && UserLoaded) {
            return (<Component
            user={user}
            authed={UserLoaded}
            messages={messages}
            {...props}
            {...renderProps}
          />)
          }
        } else {
          return (<Redirect
            to={{
              pathname: "/authenticating",
              state: { prevPath: `${location.pathname}${location.search}` }
            }}
            {...props}
          />)
        }
      } else {
        return (authed || localStorage.token) ? (
         (<Component
            user={user}
            authed={authed}
            messages={messages}
            {...props}
            {...renderProps}
          />)
        ) : (
          (<Redirect
            to={{
              pathname: "/authenticating",
              state: { prevPath: `${location.pathname}${location.search}` }
            }}
            {...props}
          />)
        )
      }
    }
    }
  />
)};

export default AuthRoute;
