import React from "react";
import { PageHeader, LinksSection } from "./PageComponents";
import { Helmet } from "react-helmet";
import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { LightSpan } from "../pageTypes/StyledPageComponents";
import YouTube from "react-youtube-embed";
import styled from "styled-components";
import client from "../../../helpers/apolloClient";
import { PrimaryButton } from "../../../Common/components/Button";
import {useRouteMatch} from "react-router-dom";

const NONE_QPL = "1c23bfdd-bfa2-11ea-8d09-0242ac160002";
const ENERGY_STAR_QPL = "aa7fc624-22af-11ea-8b5f-06319dbc535a";
const DLC_STANDARD_QPL = "aa7fc92f-22af-11ea-8b5f-06319dbc535a";
const DLC_PREMIUM_QPL = "aa7fca8c-22af-11ea-8b5f-06319dbc535a";

const DLC_QPLS = new Set([
  DLC_STANDARD_QPL,
  DLC_PREMIUM_QPL,
]);

const names = {
  [NONE_QPL]: "None",
  [ENERGY_STAR_QPL]: "Energy Star",
  [DLC_STANDARD_QPL]: "DLC Standard",
  [DLC_PREMIUM_QPL]: "DLC Premium",
}

function getQplIdLabel(uuid) {
  if (uuid === ENERGY_STAR_QPL) {
    return "Energy Star ID"
  }
  if (DLC_QPLS.has(uuid)) {
    return "DLC ID"
  }
  return "QPL ID"
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  widht: 100%;
  margin-top: 2em;

  .product-info {
    width: 80%;
  }
  .rebate-info {
    width: 35%;
  }
`;

const ProductContent = styled.div`
  margin-bottom: 2em;
  h2 {
    margin-bottom: 0.5em;
  }

  .identification {
    margin-top: 2em;
  }

  h3 {
    color: #18a888;
    margin-bottom: 0.5em;
  }

  span.content {
    font-weight: ${(props) => (props.rebate ? null : "bold")};
  }

  span.product-category {
    font-weight: bold;
    font-size: 0.8em;
  }

  p {
    margin-bottom: 0;
  }

  button {
    margin-top: 1em;
  }
`;

const Download = styled.button`
  border: 1.5px solid #18a888;
  border-radius: 5px;
  color: #18a888;
  padding: 10px;
  font-weight: 700;
  font-size: 1rem;

  margin-bottom: 40px;
`;

const Categories = styled.div`
  border-left: 2.5px solid #18a888;
  padding-left: 10px;

  p {
    font-weight: 300;
    margin-bottom: -3px;
  }
  .category {
    border-left: 2.5px solid #18a888;
    margin: 20px 0 0 10px;
    padding-left: 10px;
  }
`;

const ImageContainer = styled.img`
  max-width: 200px;
  max-height: 200px;
`;

// const ContributorsContainer = styled.div`
//   margin-top: 30px;
// `;

const Product = (props) => {
  let match = useRouteMatch("/search/articles/:uuid/product/:modelNumber");

  window.location.assign(`${window.location.origin}/search/rebates/lighting?ft=p&productIdType=REBATE_BUS_ID`);

  return (<>Loading...</>)

  const {
    uuid,
    modelNumber,
    imageUrl,
    QPLIdentifier,
    specSheetUrl,
    QPL,
    brand,
    newProductCategory,
    productSubCategory,
    productType,
    manufacturer,
    technologyType,
    productSpecs,
    youtubeUrl,
    isHidden,
    // contributors
  } = props.product;


  const specs = () => {
    const specs = [
      ["Lumen Output", "lumenOutput"],
      ["Efficacy", "efficacy"],
      ["Power Factor", "powerFactor"],
      ["Input Wattage", "wattOutput"],
      ["Color Temperature", "colorTemperature"],
      ["CRI", "cri"],
    ];

    const initialSpecs = specs.map((spec) => ({
      [`${spec[0]}`]:
        productSpecs !== null
          ? productSpecs.find(
              (productSpec) => productSpec.productSpecType.value === spec[1]
            )
          : undefined,
    }));

    return initialSpecs;
  };

  const initialSpecs = specs();

  const { loading, data } = useQuery(PRODUCT_REBATES_COUNT, {
    client,
    variables: { productId: uuid },
  });

  const totalRebates = !loading ? data && data.response && data.response.totalCount : 0;

  // Returns the keyValue for product spec.
  // intialSpecs is passed as a parameter
  const keyValue = (specObject) =>
    specObject[Object.keys(specObject)] !== undefined ? (
      <p key={Object.keys(specObject)}>
        <span className="content">
          {Object.keys(specObject)}:{" "}
          <LightSpan>{specObject[Object.keys(specObject)].value}</LightSpan>
        </span>
      </p>
    ) : (
      <p key={Object.keys(specObject)}>
        <span className="content">
          {Object.keys(specObject)}: <LightSpan>--</LightSpan>
        </span>
      </p>
    );

  if (isHidden) {
    return (
      <>
        <Helmet>
          <meta name="description" content="Energy Efficient Products" />
          <title>{`Rebate Bus | Product | ${modelNumber}`}</title>
        </Helmet>
        <PageHeader
          name={modelNumber}
          type={props.type}
          url={props.url}
          permissions={props.permissions}
        />
        <p>This page is currently undergoing maintenance.</p>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Energy Efficient Products" />
        <title>{`Rebate Bus | Product | ${modelNumber}`}</title>
      </Helmet>
      <PageHeader
        name={modelNumber}
        type={props.type}
        url={props.url}
        permissions={props.permissions}
      />
      <Container>
        <div className="product-info">
          <h2>Product Info</h2>
          <div>
            {imageUrl ? <ImageContainer alt="product" src={imageUrl} /> : null}
          </div>
          <ProductContent>
            <h3 className="identification">Identification</h3>
            <p>
              <span className="content">
                Model Number: <LightSpan>{modelNumber}</LightSpan>
              </span>
            </p>
            <p>
              <span className="content">
                Qualification: <LightSpan>{names[QPL.uuid]}</LightSpan>
              </span>
            </p>
            <p>
              <span className="content">
                {getQplIdLabel(QPL.uuid)}: <LightSpan>{QPLIdentifier}</LightSpan>
              </span>
            </p>
          </ProductContent>
          <ProductContent>
            <h3>Manufacturer</h3>
            <p>
              <span className="content">
                Name: <LightSpan>{manufacturer.name}</LightSpan>
              </span>
            </p>
            <p>
              <span className="content">
                Brand: <LightSpan>{brand.value}</LightSpan>
              </span>
            </p>
            {LinksSection(manufacturer)}
          </ProductContent>
          <ProductContent>
            <h3>Product Category</h3>
            <Categories>
              <p>{productType.value}</p>
              <span className="product-category">Product Type</span>
              <div className="category">
                <p>{newProductCategory.name}</p>
                <span className="product-category">Category</span>
                <div className="category">
                  <p>{productSubCategory.value}</p>
                  <span className="product-category">Sub Category</span>
                </div>
              </div>
            </Categories>
          </ProductContent>
          <ProductContent>
            <h3>Product Specs</h3>
            {initialSpecs.map((spec) => keyValue(spec))}
            {specSheetUrl ? <Download>Download Spec Sheet</Download> : null}
          </ProductContent>
          <ProductContent>
            {youtubeUrl ? <YouTube id="vrMWHmPg0h8" /> : null}
          </ProductContent>
        </div>
        <div className="rebate-info">
          <h2>Rebates Info</h2>
          <p>
            {!loading
              ? totalRebates
                ? `We found ${totalRebates} applicable rebates for this product.`
                : "Unfortunately, we haven't found any applicable rebates for this product."
              : "Gathering applicable rebates."}
          </p>
          {!loading ? (
            totalRebates ? (
              <>
                <a href={`${window.location.origin}/search/rebates/lighting?productId=${uuid}&productIdType=REBATE_BUS_ID&ft=p`} target="_blank" rel="noopener">
                  <PrimaryButton>Show Rebates</PrimaryButton>
                </a>
              </>
            ) : null
          ) : null}
        </div>
      </Container>
    </>
  );
};

export const PRODUCT_REBATES_COUNT = gql`
  query GetRebateCount(
    $productId: String!,
  ) {
    response: filteredLightingRebates(
      withCount: true
      where: {
        newProduct:  {
          type: REBATE_BUS_ID
          value: $productId
        },
      }) {
      totalCount
    }
  }
`;

export default Product;
