import {useRouteMatch, Link} from "react-router-dom";
import React from "react";
import "./menu_link.css";

export interface MenuLinkProps {
    to: string,
    activeOnlyWhenExact: boolean,
    children: any
}

function MenuLink({to, activeOnlyWhenExact, children}: MenuLinkProps) {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <div className={match ? "menu_link menu_link--active" : "menu_link"}>
            <Link to={to}>{children}</Link>
        </div>
    );
}

export default MenuLink;
