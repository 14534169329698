import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { errorMessage, successMessage } from "../../Common/ducks/actions";
import { PrimaryButton } from "../../Common/components/Button";
import styled from "styled-components";
import Deactivate from "./Deactivate";

/**
 * In the sad event a user wants to leave us, or a team admin needs to remove a user, this component will render.
 * It changes the user's active status to false, which allows their Wiki contributions to remain. It also means an
 * account could be restored to active status by a Rebate Bus employee.
 */

const Input = styled.input`
  border: 0;
  border-bottom: 1px solid grey;
  border-radius: 0;
  outline: none;
`;

const RedSpan = styled.span`
  color: #ff0053;
`;

const H2 = styled.h2`
  margin: 1.5em 0 0.2em 0;
`;

class DeactivateAccount extends Component {
  static propTypes = {
    /**
     * The function to deactivate an account, which is handled differently based on how the component is rendered.
     * It can either be handled from the user's side (Profile) or a company admin (Team).
     */
    handleDeactivate: PropTypes.func.isRequired,
    /** The user object for the account to deactivate. */
    userToDeactivate: PropTypes.object.isRequired,
    /** The current user who is deactivating an account. `user` _could_ be the same as `userToDeactivate`, but it could be a team admin. */
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmDeactivate: "",
      personalDeletion: false,
      redirect: false
    };
  }

  handleChange = ({ target: { value } }) => {
    this.setState({
      confirmDeactivate: value
    });
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleClick = () => {
    const { confirmDeactivate } = this.state;
    const { handleDeactivate, userToDeactivate, errorMessage } = this.props;

    if (confirmDeactivate === "DEACTIVATE") {
      handleDeactivate(userToDeactivate);
      if (window.location.pathname.indexOf("/team/") >= 0) {
        this.setState({ redirect: true });
      }
      this.setState({ personalDeletion: true });
    } else {
      errorMessage("Please type 'DEACTIVATE' to continue.");
    }
  };

  // similarity and editDistance functions meant to calculate percentage of correct character for Button background fill
  similarity = (s1, s2) => {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      ((longerLength - this.editDistance(longer, shorter)) /
        parseFloat(longerLength)) *
      100
    );
  };

  editDistance = (s1, s2) => {
    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  };

  percentageCorrect = () => {
    return this.similarity(this.state.confirmDeactivate, "DEACTIVATE");
  };

  render() {
    const {
      user,
      userToDeactivate,
      userToDeactivate: { firstName, lastName }
    } = this.props;
    const { deactivate, redirect, personalDeletion } = this.state;

    const userHash =
      user.userId === userToDeactivate.userId
        ? {
            name: `${firstName} ${lastName}`,
            pronoun: "you",
            possessive: "your"
          }
        : {
            name: `${firstName} ${lastName}`,
            pronoun: "they",
            possessive: "their"
          };

    if (personalDeletion) {
      return <Deactivate />;
    }

    return (
      <div id="deactivateAccountWrapper">
        {redirect && !personalDeletion ? <Redirect to="/team" /> : null}
        <h1>Deactivate Account</h1>
        <H2>Confirm your deactivation</H2>
        <p className="deactivateInstructions">
          Please type <RedSpan>DEACTIVATE</RedSpan> below to confirm you would
          like to delete {userHash.possessive + " "}account. If you delete{" "}
          {userHash.possessive + " "} account now, you can always reactivate it
          by emailing{" "}
          <a
            target="_top"
            href="mailto:support@rebatebus.com?Subject=Reactivate account"
          >
            support@rebatebus.com
          </a>
          .
        </p>
        <Input
          type="text"
          id="deactivateAccount"
          name="deactivateAccount"
          placeholder="Type DEACTIVATE here"
          value={deactivate}
          onChange={this.handleChange}
        />
        <PrimaryButton
          className="destroy"
          handleClick={this.handleClick}
          deactivate
          textTransform="capitalize"
          percentage={this.percentageCorrect()}
          disabled={this.percentageCorrect() !== 100 ? true : false}
        >
          Deactivate Account
        </PrimaryButton>
      </div>
    );
  }
}

export default connect(null, { successMessage, errorMessage })(
  DeactivateAccount
);
