import React, {ReactNode, useEffect, useMemo, useReducer, useRef, useState} from "react";
import "./styles.css"
import "./tab-style.css"
import {
    ActionButton,
    Checkbox,
    CheckboxGroup,
    defaultTheme,
    Flex,
    ProgressCircle,
    Provider as SpectrumProvider, TextField,
    View,
} from "@adobe/react-spectrum";
import Download from '@spectrum-icons/workflow/Download';
import Filter from '@spectrum-icons/workflow/Filter';
import Box from '@spectrum-icons/workflow/Box';
import Remove from '@spectrum-icons/workflow/Remove';
import {Column, useTable} from 'react-table'
import {useHistory, useLocation} from "react-router-dom"
import {Label} from '@react-spectrum/label';
import Select from "react-select";
import {useApolloClient, useLazyQuery, useQuery} from "@apollo/react-hooks";
import {of, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {
    DOWNLOAD_REPORT,
    GET_PRODUCT_OPTIONS, GET_PRODUCTS_QUERY,
    GET_PROGRAM_OPTIONS,
    GET_REBATE_COUNT,
    GET_REBATES,
    GET_SELECTED_OPTIONS_FOR_PANEL,
    GET_STATES,
    GET_UTILITY_OPTIONS, GetStaticOptionsResponse, GetStaticOptionsVariables
} from "./queries";

import {
    convertProgramRequirementsArray,
    CustomerType,
    customerTypes,
    mappedCustomerTypes,
    mappedProductIdQueryTypeOptions,
    mappedProjectTypes,
    mappedQPLs,
    mappedRebateTypes,
    ProductIdQueryType,
    productIdQueryTypeOptions,
    ProductIdType,
    programRequirements,
    ProgramRequirementsOption,
    ProjectType,
    projectTypes,
    RebateType,
    rebateTypes
} from "./referenceData";
import {DOMProps} from "@react-types/shared";
import {SelectOption} from "./selectOption";
import PaginationPanel from "../Common/components/PaginationPanel";
import {Rebate} from "../../../analytics-tables";
import {ViewProps} from "@react-types/view";
import ProductSpecCard from "./ProductSpecCard";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import {useMediaQuery} from "react-responsive";

const INT_REGEX = /^\d*$/;
const URL_PREFIX = '/search/rebates/lighting';
const LIMIT_10 = 10;
const LIMIT_100 = 100;

// em
const leftHandMenu = 10.5;
const toolBarPanel = 28;
const toolBarPanelMobile = 20;
const tabPanel = 2;
const siteHeader = 3;

const productNote = `
Model number should be written the way it's found in DLC and Energy Star lists.

Currently rebates are filtered by the following product specs:
1. Category
2. Wattage
3. Lifetime
4. Lumen output
5. Efficacy
6. Qualifying Product List (QPL)
`;

enum TabOption {
    Filters = "Filters",
    Product = "Product",
}

enum Direction {
    Row = "row",
    Column = "column"
}

interface TabsProps extends ViewProps {
    selectedOption: TabOption | null,
    onClick: (option: TabOption) => void,
    isNotDesktop: boolean,
}

interface TabProps {
    children: Children,
    selectedOption: TabOption | null,
    option: TabOption,
    onClick: (option: TabOption) => void,
    direction: String | Direction.Row,
}

const Tab = ({
                 children,
                 option,
                 selectedOption,
                 onClick,
                 direction,
             }: TabProps) => {

    const style = {
        paddingBottom: "var(--spectrum-global-dimension-size-100)",
        paddingTop: "var(--spectrum-global-dimension-size-100)",
    };

    return (
        <div
            onClick={() => onClick(option)}
            style={direction === Direction.Row ? {...style, paddingLeft: "var(--spectrum-global-dimension-size-100)", paddingRight: "var(--spectrum-global-dimension-size-100)"} : {...style}}
            className={selectedOption === option ? "toolbar-tab toolbar-tab__selected" : "toolbar-tab"}
        >
            <Flex alignItems="center" justifyContent="center" width="100%" direction={direction} gap="size-100">
                {/* @ts-ignore */}
                {children}
            </Flex>
        </div>
    );
}

const Tabs = ({selectedOption, onClick, isNotDesktop}: TabsProps) => {
    return (
        <Flex direction={isNotDesktop ? "row" : "column"}>
            <Tab direction={isNotDesktop ? Direction.Row : Direction.Column} selectedOption={selectedOption} option={TabOption.Filters} onClick={onClick}>
                <Filter size="S"/>
                <View UNSAFE_className="tab-text">{TabOption.Filters}</View>
            </Tab>

            <Tab direction={isNotDesktop ? Direction.Row : Direction.Column} selectedOption={selectedOption} option={TabOption.Product} onClick={onClick}>
                <Box size="S"/>
                <View UNSAFE_className="tab-text">{TabOption.Product}</View>
            </Tab>

        </Flex>
    );
}


interface TableParams {
    columns: Array<Column<Rebate>>,
    data: Array<Rebate>,
    isNotDesktop: boolean,
}

function formatCap(cap: number | null) {
    if (!cap) {
        return "";
    }

    if (cap <= 100) {
        return `${cap}%`
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(cap)
}

function Table({columns, data, isNotDesktop}: TableParams) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <View position="relative"
              UNSAFE_style={{
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #cccccc",
                  minHeight: "calc(100% - 6em)",
                  overflowX: "auto",
                  overflowY: isNotDesktop ? "visible" : "auto",
              }}>
            <table {...getTableProps()} style={{
                border: 'solid 1px #cccccc',
                minWidth: "100%",
                fontSize: "0.9em",
                position: "sticky",
                top: 0,
                zIndex: 1
            }} className="rebates-table">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    border: 'solid 1px #cccccc',
                                    verticalAlign: "middle",
                                    fontWeight: 'bold',
                                    minWidth: column.minWidth || undefined,
                                    height: "3em",
                                    backgroundColor: "#f8f8f8",
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
            </table>
            <table {...getTableProps()} style={{
                border: 'solid 1px #cccccc',
                minWidth: "100%",
                fontSize: "0.9em",
                position: "relative",
                // 6 em = 3em (header height) * 2
                transform: "translateY(-6em)",
                marginBottom: "-6em",
            }} className="rebates-table">
                <thead style={{opacity: 0}}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    minWidth: column.minWidth || undefined,
                                    maxWidth: column.maxWidth || undefined,
                                    height: "3em",
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            padding: '10px',
                                            border: 'solid 1px #cccccc',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </View>
    )
}

export const FormGroupNote = ({note, style}: { note: string, style?: React.CSSProperties }) => {
    return (
        <div style={{
            transform: "translateY(-2px)",
            padding: "0px 3px",
            borderRadius: "50%",
            fontSize: "10px",
            borderColor: "#a7a7a7",
            borderWidth: "thin",
            borderStyle: "solid",
            color: "#a7a7a7",
            lineHeight: "1.1em",
            ...style
        }}
             title={note}>?</div>
    )
}

type Children = Array<ReactNode> | ReactNode;

export interface HasChildren {
    children: Children
}

export interface FormGroupParams extends React.RefAttributes<import("@react-types/shared").DOMRefValue<HTMLDivElement>>, DOMProps {
    note?: string
    label: string
    children: Children | null
}

export const FormGroup = ({children, label, id, note}: FormGroupParams) => (
    <View id={id} width="100%">
        <Flex alignItems="center" gap={"size-50"}>
            {label ? <Label>{label}</Label> : <></>}
            {note ? <FormGroupNote note={note}/> : <></>}
        </Flex>
        {children}
    </View>
);

interface MainProps extends HasChildren {
    isNotDesktop: boolean,
}

const MainContainer = ({children, isNotDesktop}: MainProps) => {
    return (
        <main style={{
            display: "flex",
            height: isNotDesktop ? "auto" : "100%",
            // 15em left hand menu width
            minWidth: "calc(100% - 15em)",
            flexDirection: isNotDesktop ? "column-reverse" : "row",
        }}>{children}</main>
    )
}

interface TitleProps extends HasChildren {
    isNotDesktop: boolean,
}

const Title = ({children, isNotDesktop}: TitleProps) => {
    return <h1 style={{
        color: "#4a4a4a",
        fontSize: "1.8em",
        margin: isNotDesktop ? "1rem" : 0,
    }}>{children}</h1>
}

interface BodyProps extends HasChildren {
    isToolbarCollapsed: boolean,
    isNotDesktop: boolean,
}

const Body = ({children, isToolbarCollapsed, isNotDesktop}: BodyProps) => {
    const isShowLeftHandMenu = useMediaQuery({ query: `(min-width: 769px)` });
    const widthToSubtract = (isShowLeftHandMenu ? leftHandMenu : 0) + (isNotDesktop ? 0 : (isToolbarCollapsed ? tabPanel : (toolBarPanel + tabPanel)) + 2.1);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            minHeight: isNotDesktop ? "unset" : "100%",
            maxWidth: `calc(100vw - ${widthToSubtract}em)`,
            padding: isNotDesktop ? "1em 1em 7em 1em" : "44px",
            width: "100%",
        }}>
            {children}
        </div>
    )
}

interface ToolBarProps extends HasChildren {
    selectedOption: TabOption | null,
    onTabChange: (option: TabOption) => void,
    collapse: () => void,
    isNotDesktop: boolean,
}

const ToolBar = ({selectedOption, onTabChange, children, collapse, isNotDesktop}: ToolBarProps) => {

    return (
        <View
            // minHeight="100%"

        >
            <Flex
                // minHeight="100%"
                direction={isNotDesktop ? "column-reverse" : "row"}
            >
                {
                    selectedOption !== null ? (
                        <View
                            borderStartWidth="thin"
                            borderStartColor="gray-400"
                        >
                            <Flex
                                direction="column"
                                minWidth={`${isNotDesktop ? toolBarPanelMobile : toolBarPanel}em`}
                                maxWidth={isNotDesktop ? '100%' : `${toolBarPanel}em`}
                                position="relative"
                                UNSAFE_style={!isNotDesktop && {
                                    minHeight: `calc(100vh - ${siteHeader + 0.5}em)`,
                                    maxHeight: `calc(100vh - ${siteHeader + 0.5}em)`,
                                }}
                            >
                                <View
                                    paddingX="size-200"
                                    paddingY="size-100"
                                >
                                    <Flex>
                                        <div style={{fontSize: "1.5em"}}>
                                            {selectedOption}
                                        </div>
                                        <ActionButton
                                            aria-label="collapse"
                                            isQuiet={true}
                                            marginStart="auto"
                                            UNSAFE_style={{
                                                cursor: "pointer"
                                            }}
                                            onPress={() => collapse()}
                                        >
                                            <Remove/>
                                        </ActionButton>
                                    </Flex>
                                </View>

                                <View
                                    borderTopWidth="thin"
                                    borderTopColor="gray-400"
                                    overflow="hidden auto"
                                    paddingTop="size-200"
                                    paddingX="size-200"
                                    UNSAFE_style={!isNotDesktop && {
                                        minHeight: "calc(100vh - 3.5em - 3em)",
                                    }}
                                >
                                    <Flex
                                        direction="column"
                                        gap="size-200"
                                        width="100%"
                                    >
                                        {/* @ts-ignore */}
                                        {children}
                                    </Flex>
                                </View>

                            </Flex>
                        </View>
                    ) : <></>
                }

                <View
                    borderStartColor="gray-400"
                    borderStartWidth="thin"
                    minHeight="100%"
                    minWidth="2em"
                    maxWidth="2em"
                >
                    <Tabs isNotDesktop={isNotDesktop} selectedOption={selectedOption} onClick={(option) => onTabChange(option)}/>
                </View>
            </Flex>
        </View>
    )
}

interface Product {
    uuid: string,
    modelNumber: string,
    QPLIdentifier: string,
}

type Utility = SelectOption<string> & { stateId: string }
type Program = SelectOption<string> & { utilityIds: Array<string> }
type UsaState = SelectOption<string>

type Status = "idle" | "loading" | "error";

interface State {
    existingProductSpecs: {
        wattage: number | null,
    },
    operatingHours: number | null,
    products: {
        query: string,
        status: Status,
        options: Array<Product>,
        selectedProduct: Product | null,
        selectedProductIdType: ProductIdType
        selectedProductQueryType: ProductIdQueryType
    },
    states: {
        selectedStates: Array<UsaState>
    },
    utilities: {
        query: string,
        status: Status,
        options: Array<Utility>,
        selectedUtilities: Array<Utility>,
        offset: number,
        lastBatchSize: number,
    },
    programs: {
        query: string,
        status: Status,
        options: Array<Program>,
        selectedPrograms: Array<Program>,
        offset: number,
        lastBatchSize: number,
    },
    customerTypeIds: Array<CustomerType>,
    projectTypeIds: Array<ProjectType>,
    rebateTypeIds: Array<RebateType>,
    programRequirementValues: Array<ProgramRequirementsOption>,
    page: number,
    rebateCount: number,
}

const getGetRebatesVariables = (state: State): GetRebatesQueryVariables => {
    return {
        programRequirements: convertProgramRequirementsArray(state.programRequirementValues || []),
        stateIds: (state.states.selectedStates || []).map(({value}) => value),
        utilityIds: (state.utilities.selectedUtilities || []).map(({value}) => value),
        programIds: (state.programs.selectedPrograms || []).map(({value}) => value),
        customerTypeIds: (state.customerTypeIds || []),
        projectTypeIds: (state.projectTypeIds || []),
        rebateTypeIds: (state.rebateTypeIds || []),
        newProduct: state.products.selectedProduct
            ? {
                type: state.products.selectedProductIdType || ProductIdType.REBATE_BUS_ID,
                value: state.products.selectedProductIdType === ProductIdType.REBATE_BUS_ID ? state.products.selectedProduct.uuid : state.products.selectedProduct.QPLIdentifier
            }
            : null,
        operatingHours: state.operatingHours,
        existingProductSpecs: {
            wattage: state.existingProductSpecs.wattage,
        },
        limit: LIMIT_10,
        offset: LIMIT_10 * (state.page - 1),
    }
}

const getGetRebatesCountVariables = (state: State): GetRebatesQueryVariablesCount => {
    return {
        programRequirements: convertProgramRequirementsArray(state.programRequirementValues || []),
        stateIds: (state.states.selectedStates || []).map(({value}) => value),
        utilityIds: (state.utilities.selectedUtilities || []).map(({value}) => value),
        programIds: (state.programs.selectedPrograms || []).map(({value}) => value),
        customerTypeIds: (state.customerTypeIds || []),
        projectTypeIds: (state.projectTypeIds || []),
        rebateTypeIds: (state.rebateTypeIds || []),
        newProduct: state.products.selectedProduct
            ? {
                type: state.products.selectedProductIdType || ProductIdType.REBATE_BUS_ID,
                value: state.products.selectedProductIdType === ProductIdType.REBATE_BUS_ID ? state.products.selectedProduct.uuid : state.products.selectedProduct.QPLIdentifier
            }
            : null,
        operatingHours: state.operatingHours,
        existingProductSpecs: {
            wattage: state.existingProductSpecs.wattage,
        },
    }
}

const initialState: State = {
    products: {
        query: "",
        status: "idle",
        options: [],
        selectedProduct: null,
        selectedProductQueryType: ProductIdQueryType.MODEL_NUMBER_QUERY,
        selectedProductIdType: ProductIdType.REBATE_BUS_ID,
    },
    states: {
        selectedStates: [],
    },
    utilities: {
        query: "",
        status: "idle",
        options: [],
        selectedUtilities: [],
        offset: 0,
        lastBatchSize: 0,
    },
    programs: {
        query: "",
        status: "idle",
        options: [],
        selectedPrograms: [],
        offset: 0,
        lastBatchSize: 0,
    },
    customerTypeIds: [],
    projectTypeIds: [],
    rebateTypeIds: [],
    programRequirementValues: [],
    page: 1,
    rebateCount: 0,
    existingProductSpecs: {
        wattage: null,
    },
    operatingHours: null,
}

enum ActionType {
    SET_PRODUCTS_QUERY = 'SET_PRODUCTS_QUERY',
    SET_PRODUCTS = 'SET_PRODUCTS',
    SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
    SET_PRODUCTS_STATUS = 'SET_PRODUCTS_STATUS',
    SET_SELECTED_PRODUCT_QUERY_TYPE = 'SET_SELECTED_PRODUCT_QUERY_TYPE',
    SET_SELECTED_PRODUCT_ID_TYPE = 'SET_SELECTED_PRODUCT_ID_TYPE',
    SET_SELECTED_STATES = 'SET_SELECTED_STATES',
    SET_UTILITIES = 'SET_UTILITIES',
    SET_UTILITIES_STATUS = 'SET_UTILITIES_STATUS',
    SET_UTILITIES_QUERY = 'SET_UTILITIES_QUERY',
    SET_UTILITIES_OFFSET = 'SET_UTILITIES_OFFSET',
    SET_SELECTED_UTILITIES = 'SET_SELECTED_UTILITIES',
    SET_PROGRAMS = 'SET_PROGRAMS',
    SET_PROGRAMS_STATUS = 'SET_PROGRAMS_STATUS',
    SET_PROGRAMS_QUERY = 'SET_PROGRAMS_QUERY',
    SET_PROGRAMS_OFFSET = 'SET_PROGRAMS_OFFSET',
    SET_SELECTED_PROGRAMS = 'SET_SELECTED_PROGRAMS',
    SET_CUSTOMER_TYPE_IDS = 'SET_CUSTOMER_TYPE_IDS',
    SET_PROJECT_TYPE_IDS = 'SET_PROJECT_TYPE_IDS',
    SET_REBATE_TYPE_IDS = 'SET_REBATE_TYPE_IDS',
    SET_PROGRAM_REQUIREMENT_VALUES = 'SET_PROGRAM_REQUIREMENT_VALUES',
    SET_PAGE = 'SET_PAGE',
    SET_REBATE_COUNT = 'SET_REBATE_COUNT',
    SET_EXISTING_PRODUCT_WATTAGE = 'SET_EXISTING_PRODUCT_WATTAGE',
    SET_OPERATING_HOURS = 'SET_OPERATING_HOURS',
}

interface SET_PRODUCTS_QUERY_ACTION {
    type: ActionType.SET_PRODUCTS_QUERY
    payload: {
        query: string
    }
}

interface SET_PRODUCTS_ACTION {
    type: ActionType.SET_PRODUCTS
    payload: {
        options: Array<Product>
    }
}

interface SET_SELECTED_PRODUCT_ACTION {
    type: ActionType.SET_SELECTED_PRODUCT
    payload: {
        product: Product | null
    }
}

interface SET_PRODUCTS_STATUS_ACTION {
    type: ActionType.SET_PRODUCTS_STATUS
    payload: {
        status: Status
    }
}

interface SET_SELECTED_PRODUCT_QUERY_TYPE_ACTION {
    type: ActionType.SET_SELECTED_PRODUCT_QUERY_TYPE
    payload: {
        type: ProductIdQueryType
    }
}

interface SET_SELECTED_PRODUCT_ID_TYPE_ACTION {
    type: ActionType.SET_SELECTED_PRODUCT_ID_TYPE
    payload: {
        type: ProductIdType
    }
}

interface SET_SELECTED_STATES_ACTION {
    type: ActionType.SET_SELECTED_STATES
    payload: {
        states: Array<UsaState>
        utilities?: Array<Utility>
        programs?: Array<Program>
    }
}

interface SET_UTILITIES_ACTION {
    type: ActionType.SET_UTILITIES
    payload: {
        options: Array<Utility>
    }
}


interface SET_UTILITIES_STATUS_ACTION {
    type: ActionType.SET_UTILITIES_STATUS
    payload: {
        status: Status
    }
}

interface SET_UTILITIES_QUERY_ACTION {
    type: ActionType.SET_UTILITIES_QUERY
    payload: {
        query: string
    }
}

interface SET_UTILITIES_OFFSET_ACTION {
    type: ActionType.SET_UTILITIES_OFFSET
    payload: {
        offset: number
    }
}

interface SET_SELECTED_UTILITIES_ACTION {
    type: ActionType.SET_SELECTED_UTILITIES
    payload: {
        utilities: Array<Utility>
        programs?: Array<Program>
    }
}

interface SET_PROGRAMS_ACTION {
    type: ActionType.SET_PROGRAMS
    payload: {
        options: Array<Program>
    }
}

interface SET_PROGRAMS_STATUS_ACTION {
    type: ActionType.SET_PROGRAMS_STATUS
    payload: {
        status: Status
    }
}

interface SET_PROGRAMS_QUERY_ACTION {
    type: ActionType.SET_PROGRAMS_QUERY
    payload: {
        query: string
    }
}

interface SET_PROGRAMS_OFFSET_ACTION {
    type: ActionType.SET_PROGRAMS_OFFSET
    payload: {
        offset: number
    }
}

interface SET_SELECTED_PROGRAMS_ACTION {
    type: ActionType.SET_SELECTED_PROGRAMS
    payload: {
        programs: Array<Program>
    }
}

interface SET_CUSTOMER_TYPE_IDS_ACTION {
    type: ActionType.SET_CUSTOMER_TYPE_IDS
    payload: {
        customerTypeIds: Array<CustomerType>
    }
}

interface SET_PROJECT_TYPE_IDS_ACTION {
    type: ActionType.SET_PROJECT_TYPE_IDS
    payload: {
        projectTypeIds: Array<ProjectType>
    }
}

interface SET_REBATE_TYPE_IDS_ACTION {
    type: ActionType.SET_REBATE_TYPE_IDS
    payload: {
        rebateTypeIds: Array<RebateType>
    }
}

interface SET_PROGRAM_REQUIREMENT_VALUES_ACTION {
    type: ActionType.SET_PROGRAM_REQUIREMENT_VALUES
    payload: {
        programRequirementValues: Array<ProgramRequirementsOption>
    }
}

interface SET_PAGE_ACTION {
    type: ActionType.SET_PAGE
    payload: {
        page: number
    }
}

interface SET_REBATE_COUNT_ACTION {
    type: ActionType.SET_REBATE_COUNT
    payload: {
        rebateCount: number
    }
}

interface SET_EXISTING_PRODUCT_WATTAGE_ACTION {
    type: ActionType.SET_EXISTING_PRODUCT_WATTAGE
    payload: {
        wattage: number | null
    }
}

interface SET_OPERATING_HOURS_ACTION {
    type: ActionType.SET_OPERATING_HOURS
    payload: {
        hours: number | null
    }
}

type Action =
    | SET_PRODUCTS_QUERY_ACTION
    | SET_PRODUCTS_ACTION
    | SET_SELECTED_PRODUCT_ACTION
    | SET_PRODUCTS_STATUS_ACTION
    | SET_SELECTED_PRODUCT_QUERY_TYPE_ACTION
    | SET_SELECTED_PRODUCT_ID_TYPE_ACTION
    | SET_SELECTED_STATES_ACTION
    | SET_UTILITIES_ACTION
    | SET_UTILITIES_STATUS_ACTION
    | SET_UTILITIES_QUERY_ACTION
    | SET_UTILITIES_OFFSET_ACTION
    | SET_SELECTED_UTILITIES_ACTION
    | SET_PROGRAMS_ACTION
    | SET_PROGRAMS_STATUS_ACTION
    | SET_PROGRAMS_QUERY_ACTION
    | SET_PROGRAMS_OFFSET_ACTION
    | SET_SELECTED_PROGRAMS_ACTION
    | SET_CUSTOMER_TYPE_IDS_ACTION
    | SET_PROJECT_TYPE_IDS_ACTION
    | SET_REBATE_TYPE_IDS_ACTION
    | SET_PROGRAM_REQUIREMENT_VALUES_ACTION
    | SET_PAGE_ACTION
    | SET_REBATE_COUNT_ACTION
    | SET_EXISTING_PRODUCT_WATTAGE_ACTION
    | SET_OPERATING_HOURS_ACTION

const productIdQueryMap = new Map<ProductIdType, ProductIdQueryType>([
    [ProductIdType.REBATE_BUS_ID, ProductIdQueryType.MODEL_NUMBER_QUERY],
    [ProductIdType.DLC_ID, ProductIdQueryType.DLC_ID_QUERY],
    [ProductIdType.ENERGY_STAR_ID, ProductIdQueryType.ENERGY_STAR_ID_QUERY],
]);

const productQueryIdMap = new Map<ProductIdQueryType, ProductIdType>([
    [ProductIdQueryType.MODEL_NUMBER_QUERY, ProductIdType.REBATE_BUS_ID],
    [ProductIdQueryType.DLC_ID_QUERY, ProductIdType.DLC_ID],
    [ProductIdQueryType.ENERGY_STAR_ID_QUERY, ProductIdType.ENERGY_STAR_ID],
]);

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SET_PRODUCTS_QUERY: {
            const result: State = {
                ...state,
                products: {
                    ...state.products,
                    query: action.payload.query,
                    options: [],
                }
            }

            if (action.payload.query.trim() !== "") {
                result.products.status = "loading";
            }

            return result;
        }
        case ActionType.SET_PRODUCTS: {
            return {
                ...state,
                products: {
                    ...state.products,
                    status: "idle",
                    options: action.payload.options,
                }
            };
        }
        case ActionType.SET_SELECTED_PRODUCT: {
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedProduct: action.payload.product
                }
            };
        }
        case ActionType.SET_PRODUCTS_STATUS: {
            return {
                ...state,
                products: {
                    ...state.products,
                    status: action.payload.status,
                }
            };
        }
        case ActionType.SET_SELECTED_PRODUCT_QUERY_TYPE: {
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedProductQueryType: action.payload.type,
                }
            };
        }
        case ActionType.SET_SELECTED_PRODUCT_ID_TYPE: {
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedProductIdType: action.payload.type,
                }
            };
        }
        case ActionType.SET_SELECTED_STATES: {
            return {
                ...state,
                states: {
                    ...state.states,
                    selectedStates: action.payload.states
                },
                utilities: {
                    ...state.utilities,
                    selectedUtilities: action.payload.utilities || [],
                },
                programs: {
                    ...state.programs,
                    selectedPrograms: action.payload.programs || [],
                }
            };
        }
        case ActionType.SET_UTILITIES: {
            const isFirstBatch = state.utilities.offset === 0;
            const newUtilities = isFirstBatch
                ? action.payload.options
                : [...state.utilities.options, ...action.payload.options]

            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    status: "idle",
                    options: newUtilities,
                    lastBatchSize: action.payload.options.length,
                }
            };
        }
        case ActionType.SET_UTILITIES_STATUS: {
            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    status: action.payload.status,
                }
            };
        }
        case ActionType.SET_UTILITIES_QUERY: {
            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    query: action.payload.query,
                    offset: 0,
                }
            };
        }
        case ActionType.SET_UTILITIES_OFFSET: {
            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    offset: action.payload.offset,
                }
            };
        }
        case ActionType.SET_SELECTED_UTILITIES: {
            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    selectedUtilities: action.payload.utilities,
                    offset: 0,
                },
                programs: {
                    ...state.programs,
                    selectedPrograms: action.payload.programs || [],
                }
            };
        }
        case ActionType.SET_PROGRAMS: {
            const isFirstBatch = state.programs.offset === 0;
            const newPrograms = isFirstBatch
                ? action.payload.options
                : [...state.programs.options, ...action.payload.options]

            return {
                ...state,
                programs: {
                    ...state.programs,
                    status: "idle",
                    options: newPrograms,
                    lastBatchSize: action.payload.options.length,
                }
            };
        }
        case ActionType.SET_PROGRAMS_STATUS: {
            return {
                ...state,
                programs: {
                    ...state.programs,
                    status: action.payload.status,
                }
            };
        }
        case ActionType.SET_PROGRAMS_QUERY: {
            return {
                ...state,
                programs: {
                    ...state.programs,
                    query: action.payload.query,
                    offset: 0,
                }
            };
        }
        case ActionType.SET_PROGRAMS_OFFSET: {
            return {
                ...state,
                programs: {
                    ...state.programs,
                    offset: action.payload.offset,
                }
            };
        }
        case ActionType.SET_SELECTED_PROGRAMS: {
            return {
                ...state,
                programs: {
                    ...state.programs,
                    selectedPrograms: action.payload.programs,
                    offset: 0,
                }
            };
        }
        case ActionType.SET_CUSTOMER_TYPE_IDS: {
            return {
                ...state,
                customerTypeIds: action.payload.customerTypeIds,
            };
        }
        case ActionType.SET_PROJECT_TYPE_IDS: {
            return {
                ...state,
                projectTypeIds: action.payload.projectTypeIds,
            };
        }
        case ActionType.SET_REBATE_TYPE_IDS: {
            return {
                ...state,
                rebateTypeIds: action.payload.rebateTypeIds,
            };
        }
        case ActionType.SET_PROGRAM_REQUIREMENT_VALUES: {
            return {
                ...state,
                programRequirementValues: action.payload.programRequirementValues,
            };
        }
        case ActionType.SET_PAGE: {
            return {
                ...state,
                page: action.payload.page,
            };
        }
        case ActionType.SET_REBATE_COUNT: {
            return {
                ...state,
                rebateCount: action.payload.rebateCount,
            };
        }
        case ActionType.SET_EXISTING_PRODUCT_WATTAGE: {
            return {
                ...state,
                existingProductSpecs: {
                    ...state.existingProductSpecs,
                    wattage: action.payload.wattage
                }
            };
        }
        case ActionType.SET_OPERATING_HOURS: {
            console.log('state.operatingHours', state.operatingHours)
            return {
                ...state,
                operatingHours: action.payload.hours,
            };
        }
    }
}

interface TableLinkParams {
    href: string,
    children: Children
}

function TableLink({href, children}: TableLinkParams) {
    // eslint-disable-next-line react/jsx-no-target-blank
    return <a href={href} style={{color: "#005fb2", textDecoration: "none", fontStyle: "normal"}} target="_blank"
              rel="noopener">{children}</a>
}

interface GetRebatesQueryVariables {
    programRequirements: { [p: string]: boolean },
    stateIds: Array<string>,
    utilityIds: Array<string>,
    programIds: Array<string>,
    customerTypeIds: Array<CustomerType>,
    projectTypeIds: Array<ProjectType>,
    rebateTypeIds: Array<RebateType>,
    newProduct: {
        type: ProductIdType,
        value: string
    } | null,
    existingProductSpecs: {
        wattage: number | null
    },
    operatingHours: number | null,
    limit: number,
    offset: number
}

type GetRebatesQueryVariablesCount = Omit<GetRebatesQueryVariables, "limit" | "offset">;

const roles = {
    'Standard': 'aa44d7bf-22af-11ea-8b5f-06319dbc535a',
    'Admin': 'aa44dadd-22af-11ea-8b5f-06319dbc535a',
    'RebateBusEmployee': 'aa44dc2b-22af-11ea-8b5f-06319dbc535a',
    'RebateBusDeveloper': 'aa44dcbd-22af-11ea-8b5f-06319dbc535a',
}

const rolesAllowedToDownloadFullRebateReport = new Set([
    roles.Admin,
    roles.RebateBusDeveloper,
    roles.RebateBusEmployee,
]);

interface RebateSearchParams {
    user?: {
        permissionId?: string
    },
    authed?: boolean,
}

enum SearchParam {
    FilterTab = 'ft',
    ExistingWattage = 'ew',
    OperatingHours = 'oh',
}

interface GetSelectedOptionsVariables {
    productId?: string,
    productIdType?: ProductIdType,
    stateIds?: Array<string>,
    utilityIds?: Array<string>,
    programIds?: Array<string>,
}

const RebateSearch = ({user, authed}: RebateSearchParams) => {
    const userRole = (user && user.permissionId) || null;
    const userCanExport = userRole && rolesAllowedToDownloadFullRebateReport.has(userRole);

    const history = useHistory();
    const apolloClient = useApolloClient();
    const location = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [isRebatesLoading, setIsRebatesLoading] = useState(false);

    const [state, dispatch] = useReducer(reducer, initialState);
    const productInputValueSubject = useRef(new Subject<string>());
    const utilityInputValueSubject = useRef(new Subject<string>());
    const programInputValueSubject = useRef(new Subject<string>());
    const filterSubject = useRef(new Subject<State>());
    const rebatesSubject = useRef(new Subject<GetRebatesQueryVariables>());
    const rebateCountSubject = useRef(new Subject<GetRebatesQueryVariablesCount>());

    const isNotDesktop = useMediaQuery({ maxWidth: 1024 });

    const [selectedToolbarTab, setSelectedToolbarTab] = useState<TabOption | null>(isNotDesktop ? null : TabOption.Filters);
    const onSetSelectedToolbarTab = (option: TabOption | null) => {
        if (selectedToolbarTab === null || option !== selectedToolbarTab) {
            setSelectedToolbarTab(option);
        } else {
            setSelectedToolbarTab(null);
        }
    }
    const [isProductSpecShown, setIsProductSpecShown] = useState(false);

    
    
    useQuery<{ productSelectOptions: Array<Product> }, { query: string, type: ProductIdQueryType }>(
        GET_PRODUCT_OPTIONS,
        {
            variables: {
                query: state.products.query,
                type: state.products.selectedProductQueryType,
            },
            skip: state.products.query.trim() === "",
            onCompleted: (response) => {
                // It will be undefined if not network request is made
                // due to skip condition
                if (!response) {
                    return;
                }
                dispatch({
                    type: ActionType.SET_PRODUCTS,
                    payload: {options: response.productSelectOptions}
                })
            },
            onError: (error) => {
                console.error(error);
                dispatch({
                    type: ActionType.SET_PRODUCTS_STATUS,
                    payload: {status: "error"}
                })
            },
            fetchPolicy: "network-only",
        }
    );

    const {
        error: statesError,
        loading: statesLoading,
        data: statesResponse
    } = useQuery<{ states: Array<UsaState> }>(GET_STATES);
    const states = statesResponse?.states || [];
    const selectedStateIds = state.states.selectedStates.map((item) => item.value);

    useQuery<{ utilitySelectOptions: Array<Utility> },
        {
            name: string,
            stateIds: Array<string>,
            offset: number,
            limit: number,
        }>(
        GET_UTILITY_OPTIONS,
        {
            variables: {
                name: state.utilities.query,
                stateIds: selectedStateIds,
                offset: state.utilities.offset,
                limit: LIMIT_100,
            },
            onCompleted: ({utilitySelectOptions: options}) => {
                dispatch({
                    type: ActionType.SET_UTILITIES,
                    payload: {options}
                })
            },
            onError: (error) => {
                console.error(error);
                dispatch({
                    type: ActionType.SET_UTILITIES_STATUS,
                    payload: {status: "error"}
                })
            },
            fetchPolicy: "network-only",
        }
    );

    const selectedUtilityIds = state.utilities.selectedUtilities.map((item) => item.value);
    useQuery<{ programOptions: Array<Program> },
        {
            name: string,
            utilityIds: Array<string>,
            offset: number,
            limit: number,
        }>(
        GET_PROGRAM_OPTIONS,
        {
            variables: {
                name: state.programs.query,
                utilityIds: selectedUtilityIds,
                offset: state.programs.offset,
                limit: LIMIT_100,
            },
            onCompleted: ({programOptions: options}) => {
                dispatch({
                    type: ActionType.SET_PROGRAMS,
                    payload: {options}
                })
            },
            onError: (error) => {
                console.error(error);
                dispatch({
                    type: ActionType.SET_PROGRAMS_STATUS,
                    payload: {status: "error"}
                })
            },
            fetchPolicy: "network-only",
        }
    );
    // $productId: ID, $stateIds: [ID!], $utilityIds: [ID!], $programIds: [ID!]
    const [
        getSelectedOptions,
        {loading: getSelectedOptionsLoading}
    ] = useLazyQuery<{
        optionsForRebateFilterPanel: {
            product: Product,
            states: Array<UsaState>,
            utilities: Array<Utility>,
            programs: Array<Program>,
        }
    },
        GetSelectedOptionsVariables>(GET_SELECTED_OPTIONS_FOR_PANEL, {
        onCompleted: ({optionsForRebateFilterPanel: data}) => {
            const product = data.product;
            if (product) {
                dispatch({
                    type: ActionType.SET_SELECTED_PRODUCT,
                    payload: {product}
                });
            }

            const states = data.states;
            if (states) {
                dispatch({
                    type: ActionType.SET_SELECTED_STATES,
                    payload: {states}
                });
            }

            const utilities = data.utilities;
            if (utilities) {
                dispatch({
                    type: ActionType.SET_SELECTED_UTILITIES,
                    payload: {utilities}
                });
            }

            const programs = data.programs;
            if (programs) {
                dispatch({
                    type: ActionType.SET_SELECTED_PROGRAMS,
                    payload: {programs}
                });
            }
        },
        onError: (error) => {
            console.error(error);
        }
    });

    // hydrate state from url once
    useEffect(() => {
        const variables: { [p: string]: string } = {};

        // GET INFO FROM SERVER
        const singleValues: Array<keyof GetSelectedOptionsVariables> = ["productId", "productIdType"];

        for (const value of singleValues) {
            const param = searchParams.get(value);
            if (param !== null) {
                // @ts-ignore
                variables[value] = param;
            }
        }

        const multiValues: Array<keyof GetSelectedOptionsVariables> = ["stateIds", "utilityIds", "programIds"];

        for (const value of multiValues) {
            const param = searchParams.getAll(value);
            if (param.length > 0) {
                // @ts-ignore
                variables[value] = param;
            }
        }

        if (Object.keys(variables).length > 0) {
            getSelectedOptions({variables})
        }

        // GET INFO FROM referenceData
        const customerTypeIds = searchParams.getAll("customerTypeIds")
            .filter((item) => item in CustomerType) as Array<CustomerType>;
        dispatch({
            type: ActionType.SET_CUSTOMER_TYPE_IDS,
            payload: {customerTypeIds}
        })

        const projectTypeIds = searchParams.getAll("projectTypeIds")
            .filter((item) => item in ProjectType) as Array<ProjectType>;
        dispatch({
            type: ActionType.SET_PROJECT_TYPE_IDS,
            payload: {projectTypeIds}
        })

        const rebateTypeIds = searchParams.getAll("rebateTypeIds")
            .filter((item) => item in RebateType) as Array<RebateType>;
        dispatch({
            type: ActionType.SET_REBATE_TYPE_IDS,
            payload: {rebateTypeIds}
        })

        const programRequirementValues = searchParams.getAll("programRequirementValues")
            .filter((item) => item in ProgramRequirementsOption) as Array<ProgramRequirementsOption>;
        dispatch({
            type: ActionType.SET_PROGRAM_REQUIREMENT_VALUES,
            payload: {programRequirementValues}
        })

        const selectedModelIdType = searchParams.get("productIdType") || "";
        if (selectedModelIdType in ProductIdType) {
            dispatch({
                type: ActionType.SET_SELECTED_PRODUCT_ID_TYPE,
                payload: {type: selectedModelIdType as ProductIdType}
            });
            dispatch({
                type: ActionType.SET_SELECTED_PRODUCT_QUERY_TYPE,
                payload: {type: productIdQueryMap.get(selectedModelIdType as ProductIdType)!}
            })
        } else {
            searchParams.set("productIdType", ProductIdType.REBATE_BUS_ID);
        }

        history.replace({
            pathname: URL_PREFIX,
            search: `?${searchParams.toString()}`
        });

        const tab = searchParams.get(SearchParam.FilterTab);
        if (tab === "p") {
            setSelectedToolbarTab(TabOption.Product);
            setIsProductSpecShown(true);
        }

        const operatingHours = parseInt(searchParams.get(SearchParam.OperatingHours) || "");

        if (Number.isSafeInteger(operatingHours)) {
            dispatch({
                type: ActionType.SET_OPERATING_HOURS,
                payload: {
                    hours: operatingHours,
                }
            })
        }

        const existingWattage = parseInt(searchParams.get(SearchParam.ExistingWattage) || "");

        if (Number.isSafeInteger(existingWattage)) {
            dispatch({
                type: ActionType.SET_EXISTING_PRODUCT_WATTAGE,
                payload: {
                    wattage: existingWattage,
                }
            })
        }


    }, [getSelectedOptions, history, searchParams]);

    useEffect(() => {
        const subscription = productInputValueSubject.current.pipe(switchMap((x) => of(x))).subscribe((value) => {
            dispatch({
                type: ActionType.SET_PRODUCTS_QUERY,
                payload: {query: value}
            })
        });

        return () => subscription.unsubscribe();
    }, [productInputValueSubject]);

    useEffect(() => {
        const subscription = utilityInputValueSubject.current.pipe(switchMap((x) => of(x))).subscribe((value) => {
            dispatch({
                type: ActionType.SET_UTILITIES_QUERY,
                payload: {query: value}
            })
        });

        return () => subscription.unsubscribe();
    }, [utilityInputValueSubject]);

    useEffect(() => {
        const subscription = programInputValueSubject.current.pipe(switchMap((x) => of(x))).subscribe((value) => {
            dispatch({
                type: ActionType.SET_PROGRAMS_QUERY,
                payload: {query: value}
            })
        });

        return () => subscription.unsubscribe();
    }, [programInputValueSubject]);


    const [
        getRebates,
        {error: getRebatesError, data: rebatesData, loading: rebatesLoading}
    ] = useLazyQuery<{
        response: {
            rebates: Array<Rebate>
        }
    }, GetRebatesQueryVariables>(GET_REBATES, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            setIsRebatesLoading(false);
        },
        onError: (error) => {
            console.error(error);
            setIsRebatesLoading(false);
        }
    });

    const [
        getRebateCount,
        {loading: rebateCountLoading}
    ] = useLazyQuery<{
        response: {
            totalCount: number
        }
    },
        GetRebatesQueryVariablesCount>(GET_REBATE_COUNT, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data.response && Number.isSafeInteger(data.response.totalCount)) {
                dispatch({
                    type: ActionType.SET_REBATE_COUNT,
                    payload: {rebateCount: data.response.totalCount}
                });
                const maxPage = Math.ceil((state.rebateCount || 1) / LIMIT_10);
                if (state.page > maxPage) {
                    dispatch({
                        type: ActionType.SET_PAGE,
                        payload: {page: 1}
                    });
                }
            }
            setIsRebatesLoading(false);
        },
        onError: (error) => {
            console.error(error);
            setIsRebatesLoading(false);
        }
    });


    useEffect(() => {
        const subscription = rebatesSubject.current.pipe(switchMap((x) => of(x))).subscribe((variables) => {
            if (!variables) {
                return;
            }
            getRebates({variables});
        });

        return () => subscription.unsubscribe();
    }, [getRebates, rebatesSubject]);

    useEffect(() => {
        const subscription = rebateCountSubject.current.pipe(switchMap((x) => of(x))).subscribe((variables) => {
            if (!variables) {
                return;
            }
            getRebateCount({
                variables
            });
        });

        return () => subscription.unsubscribe();
    }, [getRebateCount, rebateCountSubject]);

    useEffect(() => {
        const subscription = filterSubject.current.subscribe((state) => {
            if (state.page > 1) {
                dispatch({
                    type: ActionType.SET_PAGE,
                    payload: {
                        page: 1
                    }
                });
                rebatesSubject.current.next(getGetRebatesVariables(state));
            } else {
                rebatesSubject.current.next(getGetRebatesVariables(state));
                rebateCountSubject.current.next(getGetRebatesCountVariables(state));
            }
        });

        return () => subscription.unsubscribe();
    }, [filterSubject]);


    useEffect(() => {
        rebatesSubject.current.next(getGetRebatesVariables(state));

        if (state.page === 1) {
            rebateCountSubject.current.next(getGetRebatesCountVariables(state));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page]);

    useEffect(() => {
        setIsRebatesLoading(true);

        if (!authed) {
            return;
        }

        filterSubject.current.next(state);
        // eslint-disable-next-line
    }, [
        authed,
        state.states.selectedStates,
        state.utilities.selectedUtilities,
        state.programs.selectedPrograms,
        state.products.selectedProduct,
        state.customerTypeIds,
        state.projectTypeIds,
        state.rebateTypeIds,
        state.programRequirementValues,
        state.operatingHours,
        state.existingProductSpecs.wattage,
    ]);

    const {
        data: productSpecsData,
        error: productSpecsError,
        loading: productSpecsLoading,
    } = useQuery<GetStaticOptionsResponse, GetStaticOptionsVariables>(GET_PRODUCTS_QUERY, {
        skip: state.products.selectedProduct === null,
        variables: {
            ids: {
                rbIds: [state.products.selectedProduct?.uuid || "1"],
            }
        }
    });

    const exportData = async () => {
        const programRequirements = convertProgramRequirementsArray(state.programRequirementValues || []);

        setIsRebatesLoading(true);

        apolloClient.query<{ response: { base64data: string } }, GetRebatesQueryVariablesCount>({
            query: DOWNLOAD_REPORT,
            variables: {
                programRequirements,
                stateIds: (state.states.selectedStates || []).map(({value}) => value),
                utilityIds: (state.utilities.selectedUtilities || []).map(({value}) => value),
                programIds: (state.programs.selectedPrograms || []).map(({value}) => value),
                customerTypeIds: (state.customerTypeIds || []),
                projectTypeIds: (state.projectTypeIds || []),
                rebateTypeIds: (state.rebateTypeIds || []),
                newProduct: state.products.selectedProduct
                    ? {
                        type: state.products.selectedProductIdType,
                        value: state.products.selectedProductIdType === ProductIdType.REBATE_BUS_ID ? state.products.selectedProduct.uuid : state.products.selectedProduct.QPLIdentifier
                    }
                    : null,
                operatingHours: state.operatingHours,
                existingProductSpecs: {
                    wattage: state.existingProductSpecs.wattage,
                },
            },
            fetchPolicy: "network-only",
        }).then(({data: {response}}) => {
            const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const linkSource = `data:${contentType};base64,${response.base64data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `report.xlsx`;
            downloadLink.click();
        }).catch((e) => {
            console.error(e);
            alert("Sorry, there was an error");
        }).finally(() => {
            setIsRebatesLoading(false);
        });
    }

    const columns = React.useMemo<Array<Column<Rebate>>>(
        () => [
            {
                Header: 'State',
                columns: [
                    {
                        Header: 'Name',
                        maxWidth: 168,
                        minWidth: 168,
                        accessor: 'program',
                        Cell: ({value: program}) => {
                            const seenStates = new Set<string>();
                            const states: Array<{ uuid: string, name: string }> = [];

                            for (const utility of program.utilities) {
                                if (!seenStates.has(utility.state.uuid)) {
                                    states.push(utility.state);
                                    seenStates.add(utility.state.uuid);
                                }
                            }

                            return (<ul>
                                {states.map(({uuid, name}) => {
                                    return (
                                        <li key={uuid}>
                                            <TableLink
                                                href={`${window.location.origin}/search/articles/${uuid}/state/${name.toLowerCase()}`}>
                                                {name}
                                            </TableLink>
                                        </li>
                                    )
                                })}
                            </ul>)
                        }
                    },
                ],
            },
            {
                Header: 'Utility',
                columns: [
                    {
                        id: 'utilities_name',
                        Header: 'Name',
                        maxWidth: 300,
                        minWidth: 300,
                        accessor: 'program',
                        Cell: ({value: program}) => {

                            return (<ul>
                                {program.utilities.map(({uuid, name}) => {
                                    return (
                                        <li key={uuid}>
                                            <TableLink
                                                href={`${window.location.origin}/search/articles/${uuid}/utility/${name.toLowerCase()}`}>{name}</TableLink>
                                        </li>
                                    )
                                })}
                            </ul>)
                        }
                    },
                ],
            },
            {
                Header: "Rebate",
                columns: [
                    {
                        id: 'rebate_type',
                        Header: 'Type',
                        accessor: 'type',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: type}) => mappedRebateTypes.get(type)?.label || "",
                    },
                    {
                        id: 'rebate_value',
                        Header: 'Value',
                        accessor: 'rates',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: rates}) => {
                            if (!rates || rates.length === 0) {
                                return "";
                            }

                            if (rates[0].value && rates[0].unit.value.toLowerCase().includes("percent")) {
                                return `${rates[0].value}%`
                            }

                            return rates[0].value ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).format(rates[0].value) : ""
                        }
                    },
                    {
                        id: 'rebate_per',
                        Header: 'Per',
                        accessor: 'rates',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: rates}) => {
                            if (!rates || rates.length === 0) {
                                return "";
                            }

                            return rates[0].unit.value
                        }
                    },
                    {
                        id: 'rebate_name',
                        Header: 'Name',
                        accessor: 'name',
                        maxWidth: 250,
                        minWidth: 250,
                        Cell: ({value: name, row}) => {
                            return <TableLink
                                href={`${window.location.origin}/search/rebate/${row.original.uuid}/${name.value.toLowerCase()}`}>{name.value}</TableLink>
                        }
                    },
                    {
                        id: 'rebate_project_types',
                        Header: 'Project types',
                        accessor: 'projectTypes',
                        maxWidth: 151,
                        minWidth: 151,
                        Cell: ({value: projectTypes}) => {
                            return (<ul>
                                {projectTypes.map((type) => {
                                    return <li key={type}>{mappedProjectTypes.get(type)?.label || ""}</li>
                                })}
                            </ul>)
                        }
                    },
                    {
                        id: 'rebate_end_date',
                        Header: 'End date',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            if (program.endDate) {
                                return (new Date(program.endDate)).toLocaleDateString();
                            }
                            return ""
                        }
                    },
                    {
                        id: 'rebate_qpls',
                        Header: 'QPLs',
                        accessor: 'qpls',
                        minWidth: 200,
                        Cell: ({value: qpls}) => {
                            return (
                                <div>
                                    {
                                        qpls && qpls.length > 0 && (
                                            <p>{qpls.map((qpl) => mappedQPLs.get(qpl) || "").join(', ')}</p>
                                        )

                                    }
                                </div>
                            );
                        }
                    },
                    {
                        id: 'rebate_requirements',
                        Header: 'Requirements',
                        accessor: 'requirements',
                        minWidth: 200,
                        Cell: ({value: requirements, row}) => {
                            // Controls Required
                            // Existing Wattage
                            // Minimum Watts Saved
                            // Minimum Watts Saved (percentage)
                            // Motion Sensors Required
                            // New Efficacy
                            // New Length
                            // New Lifetime
                            // New Lumens
                            // New Wattage
                            // New Watts per Foot
                            // Operating Hours
                            // Watts Controlled

                            const list = requirements.map((requirement) => {
                                const operandMap: { [p: string]: string } = {
                                    "/gt": ">",
                                    "/geq": "≥",
                                    "/lt": "<",
                                    "/leq": "≤",
                                    "/exactly": "=",
                                };

                                if (requirement.type.value === "Controls Required") {
                                    return requirement.type.value;
                                } else {
                                    const [operand, start, end] = requirement.value.split(" ");

                                    if (operand === "/between") {
                                        return `${requirement.type.value}: between ${start} and ${end}`
                                    } else if (operand === "/value") {
                                        return `${requirement.type.value}: ${start}`
                                    } else if (operand === "/percent") {
                                        return `${requirement.type.value}: ${start}%`
                                    } else {
                                        return `${requirement.type.value}: ${operandMap[operand]} ${start}`
                                    }
                                }
                            });

                            const notes = row.original.notes;
                            return (
                                <div>
                                    {
                                        list.length > 0 && (
                                            <ul>
                                                {list.map((requirement, i) => {
                                                    return <li key={i}>{requirement}</li>
                                                })}
                                            </ul>
                                        )
                                    }
                                    {
                                        notes && (notes.length < 85 ? notes : (
                                            <details>
                                                <summary>Show notes</summary>
                                                <p>{notes}</p>
                                            </details>
                                        ))
                                    }
                                </div>
                            );
                        }
                    },
                    {
                        id: 'rebate_updated_at',
                        Header: 'Last updated',
                        accessor: 'updatedAt',
                        maxWidth: 200,
                        minWidth: 200,
                        Cell: ({value: updatedAt}) => {
                            return (new Date(updatedAt)).toLocaleDateString();
                        }
                    },
                ]
            },
            {
                Header: 'Program',
                columns: [
                    {
                        id: 'program_name',
                        Header: 'Name',
                        accessor: 'program',
                        maxWidth: 300,
                        minWidth: 300,
                        Cell: ({value: program}) => {
                            return <TableLink
                                href={`${window.location.origin}/search/articles/${program.uuid}/program/${program.name.toLowerCase()}`}>{program.name}</TableLink>
                        }
                    },
                    {
                        id: 'application_url',
                        Header: 'Application URL',
                        accessor: 'program',
                        maxWidth: 120,
                        minWidth: 120,
                        Cell: ({value: { applicationURL: url }}) => {
                            if (url) {
                                return (
                                    <div style={{
                                        maxWidth: 100 - 1,
                                        overflowX: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        display: "block",
                                    }}>
                                        <TableLink
                                            href={url}>{url}</TableLink>
                                    </div>
                                );
                            }
                            return <span> </span>
                        }
                    },
                    {
                        id: 'home_page_url',
                        Header: 'Home page',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: { homePageURL: url }}) => {
                            if (url) {
                                return (
                                    <div style={{
                                        maxWidth: 80 - 1,
                                        overflowX: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        display: "block",
                                    }}>
                                        <TableLink href={url}>{url}</TableLink>
                                    </div>
                                );
                            }

                            return <span> </span>
                        }
                    },
                    {
                        id: 'program_description',
                        Header: 'Description',
                        accessor: 'program',
                        maxWidth: 300,
                        minWidth: 300,
                        Cell: ({value: program}) => {
                            let render = false;
                            if (program.description && program.description.trim() !== "") {
                                render = true;
                            }
                            if (program.statusNotes && program.statusNotes.trim() !== "") {
                                render = true;
                            }
                            if (program.isPreApprovalRequired) {
                                render = true;
                            }
                            if (program.isPreInspectionRequired) {
                                render = true;
                            }
                            if (program.isPostAuditRequired) {
                                render = true;
                            }
                            if (render) {
                                return (
                                    <details>
                                        <summary>Show description</summary>
                                        {
                                            program.description && (
                                                <div style={{marginBottom: 10}}>
                                                    <p>{program.description}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            program.statusNotes && (
                                                <div style={{marginBottom: 10}}>
                                                    <p>Status notes:</p>
                                                    <p>{program.statusNotes}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            (program.isPreApprovalRequired || program.isPreInspectionRequired || program.isPostAuditRequired) && (
                                                <div style={{marginBottom: 10}}>
                                                    <p>Requirements:</p>
                                                    {program.isPreApprovalRequired && <p>Requires pre-approval</p>}
                                                    {program.isPreInspectionRequired && <p>Requires pre-inspection</p>}
                                                    {program.isPostAuditRequired && <p>Requires post-audit</p>}
                                                    <p>{program.requirements}</p>
                                                </div>
                                            )
                                        }
                                    </details>)
                            } else {
                                return ""
                            }
                        }
                    },
                    {
                        id: 'program_customer_types',
                        Header: 'Customer types',
                        accessor: 'program',
                        maxWidth: 151,
                        minWidth: 151,
                        Cell: ({value: program}) => {

                            return (<ul>
                                {program.customerTypes.map((type) => {
                                    return <li key={type}>{mappedCustomerTypes.get(type)?.label || ""}</li>
                                })}
                            </ul>)
                        }
                    },
                    {
                        id: 'program_start_date',
                        Header: 'Start date',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            if (program.startDate) {
                                return (new Date(program.startDate)).toLocaleDateString();
                            }
                            return ""
                        }
                    },
                    {
                        id: 'program_end_date',
                        Header: 'End date',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            if (program.endDate) {
                                return (new Date(program.endDate)).toLocaleDateString();
                            }
                            return ""
                        }
                    },
                    {
                        id: 'program_project_cap',
                        Header: 'Project cap',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            return formatCap(program.projectCap)
                        }
                    },
                    {
                        id: 'program_labor_cap',
                        Header: 'Labor cap',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            return formatCap(program.laborCap)
                        }
                    },
                    {
                        id: 'program_material_cap',
                        Header: 'Material cap',
                        accessor: 'program',
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: ({value: program}) => {
                            return formatCap(program.materialCap)
                        }
                    },
                    {
                        id: 'program_maximum_annual_incentive',
                        Header: 'Maximum annual incentive',
                        accessor: 'program',
                        maxWidth: 170,
                        minWidth: 170,
                        Cell: ({value: program}) => {
                            return formatCap(program.maximumAnnualIncentive)
                        }
                    },
                ],
            },
        ],
        []);

    if (!authed) {
        return <div>Loading...</div>;
    }

    return (
        <SpectrumProvider theme={defaultTheme}
                          colorScheme="light"
                          UNSAFE_style={{backgroundColor: "white"}}
                          width="100%">
            {isNotDesktop && <Title isNotDesktop={isNotDesktop}>Lighting Rebates</Title>}
            <MainContainer isNotDesktop={isNotDesktop}>
                {
                    getRebatesError ?
                        <p>Error getting rebates</p>
                        : <Body isToolbarCollapsed={selectedToolbarTab === null} isNotDesktop={isNotDesktop}>
                            <Flex gap="size-200" alignItems="center" marginBottom="size-200" wrap>
                                {!isNotDesktop && <Title isNotDesktop={isNotDesktop}>Lighting Rebates</Title>}
                                {
                                    (!(isRebatesLoading || rebateCountLoading) && state.rebateCount === 0) ?
                                        (<div>No rebates found</div>) : <></>
                                }
                                {
                                    (!(isRebatesLoading || rebateCountLoading) && state.rebateCount > 0) ?
                                        (<div>Found {state.rebateCount} rebates</div>) : <></>
                                }
                                {
                                    (isRebatesLoading || rebateCountLoading || rebatesLoading) ?
                                        (<ProgressCircle aria-label="Loading…" isIndeterminate/>) : <></>
                                }
                                <Flex marginStart="auto" alignItems="center">
                                    <PaginationPanel page={state.page}
                                                     maxPage={Math.ceil((state.rebateCount || 1) / LIMIT_10)}
                                                     onChange={(page) => {
                                                         setIsRebatesLoading(true);
                                                         dispatch({
                                                             type: ActionType.SET_PAGE,
                                                             payload: {page},
                                                         })
                                                     }}/>
                                    {
                                        userCanExport ?
                                            (
                                                <ActionButton onPress={() => exportData()} UNSAFE_style={{
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    display: "flex",
                                                    gap: 10
                                                }}>
                                                    <Download/> Export
                                                </ActionButton>
                                            )
                                            : <></>
                                    }
                                </Flex>
                            </Flex>
                            {
                                authed && (
                                    <Table
                                        columns={columns}
                                        data={rebatesData?.response.rebates || []}
                                        isNotDesktop={isNotDesktop}
                                    />
                                )
                            }
                        </Body>
                }
                {
                    authed &&
                    (
                        <ToolBar selectedOption={selectedToolbarTab}
                                 collapse={() => setSelectedToolbarTab(null)}
                                 onTabChange={(option) => isNotDesktop ? onSetSelectedToolbarTab(option) : setSelectedToolbarTab(option)}
                                 isNotDesktop={isNotDesktop}>
                            {
                                selectedToolbarTab !== null && getSelectedOptionsLoading && (
                                    <Flex alignItems="center" gap={"size-100"}>
                                        <ProgressCircle aria-label="Loading…" isIndeterminate/>
                                        <div>Loading selected options...</div>
                                    </Flex>
                                )
                            }
                            {
                                selectedToolbarTab === TabOption.Product && (
                                    <>
                                        <TextField
                                            aria-label="Operating hours"
                                            label="Operating hours"
                                            inputMode="decimal"
                                            width="100%"
                                            value={state.operatingHours?.toString() || ""}
                                            onChange={(rawValue) => {
                                                if (rawValue.length > 4) {
                                                    return
                                                }
                                                const value = rawValue.trim();
                                                if (value === "") {
                                                    searchParams.delete(SearchParam.OperatingHours);
                                                    history.replace({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });
                                                    dispatch({
                                                        type: ActionType.SET_OPERATING_HOURS,
                                                        payload: {hours: null}
                                                    });
                                                    return;
                                                }

                                                if (INT_REGEX.test(value)) {
                                                    searchParams.set(SearchParam.OperatingHours, value)
                                                    history.replace({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });
                                                    dispatch({
                                                        type: ActionType.SET_OPERATING_HOURS,
                                                        payload: {hours: parseInt(value)},
                                                    })
                                                    return;

                                                }


                                            }}
                                        />
                                        <TextField
                                            aria-label="Existing product wattage"
                                            label="Existing product wattage"
                                            inputMode="decimal"
                                            width="100%"
                                            value={state.existingProductSpecs.wattage?.toString() || ""}
                                            onChange={(rawValue) => {
                                                if (rawValue.length > 5) {
                                                    return
                                                }
                                                const value = rawValue.trim();
                                                if (value === "") {
                                                    searchParams.delete(SearchParam.ExistingWattage);
                                                    history.replace({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });
                                                    dispatch({
                                                        type: ActionType.SET_EXISTING_PRODUCT_WATTAGE,
                                                        payload: {wattage: null}
                                                    });
                                                    return;
                                                }

                                                if (INT_REGEX.test(value)) {
                                                    searchParams.set(SearchParam.ExistingWattage, value)
                                                    history.replace({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });
                                                    dispatch({
                                                        type: ActionType.SET_EXISTING_PRODUCT_WATTAGE,
                                                        payload: {wattage: parseInt(value)},
                                                    })
                                                    return;

                                                }


                                            }}
                                        />
                                        <FormGroup label="New Product" note={productNote}>
                                            <Flex gap="size-100">
                                                <Select
                                                    value={mappedProductIdQueryTypeOptions.get(state.products.selectedProductQueryType)}
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            minWidth: 160,
                                                            maxWidth: 160,
                                                        }),
                                                    }}
                                                    options={productIdQueryTypeOptions}
                                                    onChange={(option) => {
                                                        const type = option as SelectOption<ProductIdQueryType>;

                                                        searchParams.delete("productId");
                                                        searchParams.set("productIdType", productQueryIdMap.get(type.value)!);

                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_PRODUCT_QUERY_TYPE,
                                                            payload: {type: type.value}
                                                        });

                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_PRODUCT_ID_TYPE,
                                                            payload: {type: productQueryIdMap.get(type.value)!}
                                                        });

                                                        dispatch({
                                                            type: ActionType.SET_PRODUCTS_QUERY,
                                                            payload: {query: ""}
                                                        });

                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_PRODUCT,
                                                            payload: {product: null}
                                                        });

                                                        history.replace({
                                                            pathname: URL_PREFIX,
                                                            search: `?${searchParams.toString()}`
                                                        });
                                                    }}

                                                />
                                                <Select
                                                    isClearable
                                                    value={state.products.selectedProduct ? {
                                                        data: state.products.selectedProduct,
                                                        value: state.products.selectedProduct.uuid,
                                                        label: state.products.selectedProductQueryType === ProductIdQueryType.MODEL_NUMBER_QUERY ? state.products.selectedProduct.modelNumber : state.products.selectedProduct.QPLIdentifier
                                                    } : null}
                                                    // @ts-ignore
                                                    options={state.products.options.map((option): { data: Product, label: string, value: string } => ({
                                                        data: option,
                                                        value: option.uuid,
                                                        label: state.products.selectedProductQueryType === ProductIdQueryType.MODEL_NUMBER_QUERY ? option.modelNumber : option.QPLIdentifier,
                                                    }))}
                                                    onChange={(option) => {

                                                        let product: Product;
                                                        if (!option) {
                                                            searchParams.delete('productId');
                                                        } else {
                                                            // @ts-ignore
                                                            product = option.data;
                                                            if (state.products.selectedProductIdType === ProductIdType.REBATE_BUS_ID) {
                                                                searchParams.set('productId', product.uuid);
                                                            } else {
                                                                searchParams.set('productId', product.QPLIdentifier);
                                                            }
                                                        }

                                                        history.replace({
                                                            pathname: URL_PREFIX,
                                                            search: `?${searchParams.toString()}`
                                                        });
                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_PRODUCT,
                                                            // @ts-ignore
                                                            payload: {product: option?.data || null}
                                                        });
                                                    }}
                                                    placeholder={mappedProductIdQueryTypeOptions.get(state.products.selectedProductQueryType)?.label}
                                                    isLoading={state.products.status === "loading"}
                                                    noOptionsMessage={({inputValue}) => {
                                                        if (inputValue.trim() === "") {
                                                            return `Type ${mappedProductIdQueryTypeOptions.get(state.products.selectedProductQueryType)?.label}`
                                                        }
                                                        if (state.products.status === "loading") {
                                                            return "Loading..."
                                                        }
                                                        return "No options"
                                                    }}

                                                    onInputChange={(value, {action}) => {
                                                        if (action === "input-change") {
                                                            productInputValueSubject.current.next(value);
                                                        }
                                                    }}
                                                    onMenuClose={() => {
                                                        dispatch({
                                                            type: ActionType.SET_PRODUCTS_STATUS,
                                                            payload: {status: "idle"}
                                                        })
                                                    }}
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: "100%",
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            maxWidth: 'calc(100% - 8px)',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                />
                                            </Flex>
                                            {state.products.status === "error" && <div>Sorry, there was an error.</div>}
                                        </FormGroup>

                                        {
                                            productSpecsLoading ?
                                                <ProgressCircle aria-label="Loading…" isIndeterminate/> : <></>
                                        }
                                        {
                                            productSpecsError ? "There was an error getting product specs" : <></>
                                        }
                                        {/*isProductSpecShown*/}
                                        {
                                            (productSpecsData && productSpecsData.products.length > 0)
                                                ? (
                                                    <Flex>
                                                        {
                                                            isProductSpecShown ? (
                                                                <ActionButton
                                                                    aria-label="hide product details"
                                                                    isQuiet={true}
                                                                    UNSAFE_style={{
                                                                        cursor: "pointer",
                                                                        paddingRight: "20px",
                                                                    }}
                                                                    onPress={() => setIsProductSpecShown(false)}
                                                                >
                                                                    <ChevronDown/> Product details
                                                                </ActionButton>
                                                            ) : (
                                                                <ActionButton
                                                                    aria-label="show product details"
                                                                    isQuiet={true}
                                                                    UNSAFE_style={{
                                                                        cursor: "pointer",
                                                                        paddingRight: "20px",
                                                                    }}
                                                                    onPress={() => setIsProductSpecShown(true)}
                                                                >
                                                                    <ChevronRight/> Product details
                                                                </ActionButton>
                                                            )
                                                        }
                                                    </Flex>
                                                )
                                                : <></>
                                        }
                                        {
                                            (productSpecsData && productSpecsData.products.length > 0 && isProductSpecShown)
                                                ? <ProductSpecCard product={productSpecsData.products[0]}/>
                                                : <></>
                                        }

                                    </>
                                )
                            }
                            {
                                selectedToolbarTab === TabOption.Filters && (
                                    <>
                                        {
                                            statesError
                                                ? <div>Sorry, there was an error getting states.</div>
                                                : (
                                                    <FormGroup label="States">
                                                        <Select
                                                            isMulti
                                                            value={state.states.selectedStates}
                                                            isClearable
                                                            options={states || []}
                                                            placeholder=""
                                                            isLoading={statesLoading}
                                                            onChange={(options) => {

                                                                // update states
                                                                searchParams.delete('stateIds');
                                                                const states = options || [];
                                                                // @ts-ignore
                                                                for (const option of states) {
                                                                    searchParams.append('stateIds', option.value);
                                                                }

                                                                // update utilities
                                                                // @ts-ignore
                                                                const stateIds = new Set(states.map((option) => option.value));
                                                                const utilities = (state.utilities.selectedUtilities || [])
                                                                    .filter((utility) => stateIds.has(utility.stateId));

                                                                searchParams.delete('utilityIds');
                                                                for (const utility of utilities) {
                                                                    searchParams.append('utilityIds', utility.value);
                                                                }

                                                                // update programs
                                                                const utilityIds = new Set(utilities.map((option) => option.value));
                                                                const programs = (state.programs.selectedPrograms || [])
                                                                    .filter((option) => {
                                                                        for (const utilityId of option.utilityIds || []) {
                                                                            if (utilityIds.has(utilityId)) {
                                                                                return true
                                                                            }
                                                                        }
                                                                        return false
                                                                    });
                                                                searchParams.delete('programIds');
                                                                for (const program of programs) {
                                                                    searchParams.append('programIds', program.value);
                                                                }

                                                                history.push({
                                                                    pathname: URL_PREFIX,
                                                                    search: `?${searchParams.toString()}`
                                                                });

                                                                dispatch({
                                                                    type: ActionType.SET_SELECTED_STATES,
                                                                    payload: {
                                                                        // @ts-ignore
                                                                        states,
                                                                        utilities,
                                                                        programs
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )
                                        }

                                        <FormGroup label="Utilities">
                                            <Select
                                                isClearable
                                                isMulti
                                                value={state.utilities.selectedUtilities}
                                                options={state.utilities.options}
                                                onChange={(options) => {
                                                    const utilities = options || [];
                                                    searchParams.delete('utilityIds');
                                                    // @ts-ignore
                                                    for (const option of utilities) {
                                                        searchParams.append('utilityIds', option.value);
                                                    }

                                                    // update programs
                                                    // @ts-ignore
                                                    const utilityIds = new Set(utilities.map((option) => option.value));
                                                    const programs = (state.programs.selectedPrograms || [])
                                                        .filter((option) => {
                                                            for (const utilityId of option.utilityIds || []) {
                                                                if (utilityIds.has(utilityId)) {
                                                                    return true
                                                                }
                                                            }
                                                            return false
                                                        });
                                                    searchParams.delete('programIds');
                                                    for (const program of programs) {
                                                        searchParams.append('programIds', program.value);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_SELECTED_UTILITIES,
                                                        payload: {
                                                            // @ts-ignore
                                                            utilities,
                                                            programs
                                                        }
                                                    });
                                                }}
                                                placeholder=""
                                                isLoading={state.utilities.status === "loading"}
                                                noOptionsMessage={() => {
                                                    if (state.utilities.status === "loading") {
                                                        return "Loading..."
                                                    }
                                                    return "No options"
                                                }}
                                                onInputChange={(value, {action}) => {
                                                    if (action === "input-change") {
                                                        utilityInputValueSubject.current.next(value);
                                                    }
                                                    if (action === "menu-close") {
                                                        utilityInputValueSubject.current.next("");
                                                    }
                                                }}
                                                onMenuScrollToBottom={() => {
                                                    const maybeHasMore = state.utilities.lastBatchSize === LIMIT_100;
                                                    if (!(state.utilities.status === "loading") && maybeHasMore) {
                                                        dispatch({
                                                            type: ActionType.SET_UTILITIES_OFFSET,
                                                            payload: {offset: state.utilities.offset + LIMIT_100}
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    multiValueLabel: (provided) => {
                                                        return {...provided, whiteSpace: "normal"};
                                                    }
                                                }}
                                            />
                                            {state.utilities.status === "error" &&
                                            <div>Sorry, there was an error.</div>}
                                        </FormGroup>

                                        <FormGroup label="Programs">
                                            <Select
                                                isClearable
                                                isMulti
                                                value={state.programs.selectedPrograms}
                                                options={state.programs.options}
                                                onChange={(options) => {
                                                    searchParams.delete('programIds');
                                                    // @ts-ignore
                                                    for (const option of (options || [])) {
                                                        searchParams.append('programIds', option.value);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_SELECTED_PROGRAMS,
                                                        payload: {
                                                            // @ts-ignore
                                                            programs: options || []
                                                        }
                                                    });
                                                }}
                                                placeholder=""
                                                isLoading={state.programs.status === "loading"}
                                                noOptionsMessage={() => {
                                                    if (state.programs.status === "loading") {
                                                        return "Loading..."
                                                    }
                                                    return "No options"
                                                }}
                                                onInputChange={(value, {action}) => {
                                                    if (action === "input-change") {
                                                        programInputValueSubject.current.next(value);
                                                    }
                                                    if (action === "menu-close") {
                                                        programInputValueSubject.current.next("");
                                                    }
                                                }}
                                                onMenuScrollToBottom={() => {
                                                    const maybeHasMore = state.programs.lastBatchSize === LIMIT_100;
                                                    if (!(state.programs.status === "loading") && maybeHasMore) {
                                                        dispatch({
                                                            type: ActionType.SET_PROGRAMS_OFFSET,
                                                            payload: {offset: state.programs.offset + LIMIT_100}
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    multiValueLabel: (provided) => {
                                                        return {...provided, whiteSpace: "normal"};
                                                    }
                                                }}
                                            />
                                            {state.programs.status === "error" && <div>Sorry, there was an error.</div>}
                                        </FormGroup>

                                        <View position="relative">
                                            <CheckboxGroup
                                                aria-details="hello"
                                                label="Customer Types"
                                                value={state.customerTypeIds}
                                                onChange={(customerTypeIds) => {
                                                    searchParams.delete('customerTypeIds');
                                                    for (const id of customerTypeIds) {
                                                        searchParams.append('customerTypeIds', id);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_CUSTOMER_TYPE_IDS,
                                                        payload: {customerTypeIds: customerTypeIds as Array<CustomerType>}
                                                    });
                                                }}
                                            >
                                                {
                                                    customerTypes.map(({value, label}) => {
                                                        return <Checkbox key={value} value={value}>{label}</Checkbox>;
                                                    })
                                                }
                                            </CheckboxGroup>
                                        </View>

                                        <View position="relative">
                                            <CheckboxGroup

                                                label="Project Types"
                                                value={state.projectTypeIds}
                                                onChange={(projectTypeIds) => {
                                                    searchParams.delete('projectTypeIds');
                                                    for (const id of projectTypeIds) {
                                                        searchParams.append('projectTypeIds', id);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_PROJECT_TYPE_IDS,
                                                        payload: {projectTypeIds: projectTypeIds as Array<ProjectType>}
                                                    });
                                                }}
                                            >
                                                {
                                                    projectTypes.map(({value, label}) => {
                                                        return <Checkbox key={value} value={value}>{label}</Checkbox>;
                                                    })
                                                }
                                            </CheckboxGroup>
                                        </View>

                                        <View position="relative">
                                            <CheckboxGroup
                                                label="Rebate Types"
                                                value={state.rebateTypeIds}
                                                onChange={(rebateTypeIds) => {
                                                    searchParams.delete('rebateTypeIds');
                                                    for (const id of rebateTypeIds) {
                                                        searchParams.append('rebateTypeIds', id);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_REBATE_TYPE_IDS,
                                                        payload: {rebateTypeIds: rebateTypeIds as Array<RebateType>}
                                                    });
                                                }}
                                            >
                                                {
                                                    rebateTypes.map(({value, label}) => {
                                                        return <Checkbox key={value} value={value}>{label}</Checkbox>;
                                                    })
                                                }
                                            </CheckboxGroup>
                                        </View>

                                        <View position="relative">
                                            <CheckboxGroup
                                                label="Program requirements"
                                                value={state.programRequirementValues}
                                                onChange={(programRequirementValues) => {
                                                    searchParams.delete('programRequirementValues');
                                                    for (const value of programRequirementValues) {
                                                        searchParams.append('programRequirementValues', value);
                                                    }

                                                    history.push({
                                                        pathname: URL_PREFIX,
                                                        search: `?${searchParams.toString()}`
                                                    });

                                                    dispatch({
                                                        type: ActionType.SET_PROGRAM_REQUIREMENT_VALUES,
                                                        payload: {programRequirementValues: programRequirementValues as Array<ProgramRequirementsOption>}
                                                    });
                                                }}
                                            >
                                                {
                                                    programRequirements.map(({value, label}) => {
                                                        return <Checkbox key={value} value={value}>{label}</Checkbox>;
                                                    })
                                                }
                                            </CheckboxGroup>
                                        </View>
                                    </>
                                )
                            }

                        </ToolBar>
                    )
                }
            </MainContainer>
        </SpectrumProvider>
    );
}

export default RebateSearch;
