import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import MultiCheckbox from "./MultiCheckbox";

const GraphQLMultiCheckbox = props => {

  return (
    <Query query={props.optionsQuery} key={props.name}>
      {({ loading, error, data }) => {
        if (error)
          return (
            <>
              <label htmlFor={props.id}>{props.title}</label>
              <p>Error loading options</p>
            </>
          );

        const options = [];

        if (data) {
          // * get just the first key
          const key = Object.keys(data)[0];

          data[key].forEach(option =>
            options.push({
              description: option.value,
              name: option.uuid,
              inputType: "multiCheck"
            })
          );
        }
        return <MultiCheckbox {...props} options={options} key={props.id} />;
      }}
    </Query>
  );
};

export default GraphQLMultiCheckbox;

GraphQLMultiCheckbox.propTypes = {
  /** The title for the input. */
  title: PropTypes.string.isRequired,
  /** The id of the input, used for settings state. */
  id: PropTypes.string.isRequired,
  /** The query to be sent to GraphQL, which is actually a template literal but PropTypes sees it as an object. */
  optionsQuery: PropTypes.object.isRequired
};
