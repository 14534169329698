import React from "react";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../Common/components/Button";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  margin-left: 3em;
`;

const Deactivate = props => {
  if (!props.signIn) {
    return (
      <>
        <h1>Deactivation Successful</h1>
        <p>
          Thank you for exploring Rebate Bus. As a reminder, you can always
          reactivate your account by emailing{" "}
          <a
            target="_top"
            href="mailto:support@rebatebus.com?Subject=Reactivate account"
          >
            support@rebatebus.com
          </a>
          .
        </p>
        <p>We hope to see you again!</p>

        <Link to="/">
          <SecondaryButton deactivate textTransform="capitalize">
            Return to Home Page
          </SecondaryButton>
        </Link>
      </>
    );
  } else {
    return (
      <Container>
        <h1>Deactivated Account</h1>
        <p>
          This account has been deactivated. If you wish to reactivate this
          account please email{" "}
          <a
            target="_top"
            href="mailto:support@rebatebus.com?Subject=Reactivate account"
          >
            support@rebatebus.com
          </a>
          .
        </p>

        <Link to="/">
          <SecondaryButton deactivate textTransform="capitalize">
            Return to Home Page
          </SecondaryButton>
        </Link>
      </Container>
    );
  }
};

export default Deactivate;
