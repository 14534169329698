import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import styled from "styled-components";
import { PreviewButton } from "./Button";
import { connect } from "react-redux";
import { deleteRebate } from "../../Wiki/ducks/rebateActions"

const CardlessCard = ({
  name,
  headline,
  children,
  permissions,
  rebateId,
  type,
  deleteRebate,
  url = null
}) => {
  const title = url ? (
    <Link to={url}>
      <PreviewButton textTransform="none">{name}</PreviewButton>
    </Link>
  ) : (
    <span>{name}</span>
  );

  const handleRebateDelete = () => {
    const answer = window.confirm('Are you sure you want to delete this rebate?');
    if (answer) {
      deleteRebate(rebateId);
    }
  }

  return (
    <Wrapper>
      <Header>{title}</Header>
      <Content>
        {
          headline &&
          type === 'Rebate'
          ? <Headline
            rebateId={rebateId}
            headline={headline}
            showIcons={permissions}
            handleDelete={handleRebateDelete}
          />
          : <Headline headline={headline}/>
        }
        {children}
      </Content>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.account.user.permissions >= 3,
});

export default connect(mapStateToProps, { deleteRebate })(CardlessCard);

CardlessCard.propTypes = {
  /** The card name, could be nonexistant! */
  name: PropTypes.string,
  /** The headline for the card, i.e. the H2, if there is one! */
  headline: PropTypes.string,
  /** The children for the middle of the card. The elements should be formatted independently. */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  /** The url for the title, if necessary */
  url: PropTypes.string
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cacaca;
  margin: 2em 0;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  background-color: rgba(86, 220, 0, 0.1);
  padding: 0.5em 1em;

  * {
    font-weight: 500;
    color: ${props => props.theme.rbGreen.regular};
  }
`;

const Content = styled.div`
  padding: 2em 1em 1em 1em;
`;

function UnstyledHeadline({className, headline, showIcons, handleDelete, rebateId}) {
  return (
    <div className={className}>
      <h2>{headline}</h2>
      {
        showIcons &&
        <div className="icons">
          <Link to={{pathname: '/search/add', search: `pageType=rebate&copyFrom=${rebateId}`}}>
            <FileCopyIcon titleAccess="Copy" className="copy"/>
          </Link>
          <DeleteIcon titleAccess="Delete" onClick={handleDelete}/>
        </div>
      }
    </div>
  );
}

const Headline = styled(UnstyledHeadline)`
  align-items: center;
  display: flex;

  .icons {
    color: ${props => props.theme.rbGreen.regular};
    margin-bottom: 0.9em;
    margin-left: auto;
  };

  .icons > * {
    cursor: pointer;
  }

  .copy {
    height: 0.8em;
    transform: translateY(-2px);
  }
`;
