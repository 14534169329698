import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

/**
 * The Message component can be added to any route that might need to show a user either a success or error. Use it by calling the 
 * helper function `showMessages`, which is exported from the Message.js file.
 *
 * Messages have `setTimeout` logic to disappear after 3 seconds, [using the `useEffect` hook](https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks).
 
 * There is a helper function `showMessages` that is exported from the Message.js file in order to DRY the code, 
 * which automatically creates the Messages based on the store state. `showMessages` takes the messages array and the 
 * `dismissMessage` action as arguments. Instead of writing a function for each view/route, simply import `showMessages` 
 * into the route file as in the example below.
 */

const Message = ({ alertObject, dismiss, children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => dispatch(dismiss(alertObject)), 3000);
    return () => clearTimeout(timer);
  });

  const iconLookup = {
    error: <CloseIcon />,
    success: <CheckIcon />,
    info: <InfoIcon />,
  };

  const Icon = iconLookup[alertObject.type];

  return (
    <StyledMessage alertType={alertObject.type}>
      <MessageWrapper>
        <IconWrapper alertType={alertObject.type}>
          {Icon} <p>{children}</p>
        </IconWrapper>
        <div>
          <span onClick={() => dispatch(dismiss(alertObject))}>clear</span>
        </div>
      </MessageWrapper>
    </StyledMessage>
  );
};

export default Message;

Message.propTypes = {
  /** The message from Redux -- includes id, text and type of message. */
  alertObject: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  /** The dismiss function that is passed through the `showMessages` helper function.  */
  dismiss: PropTypes.func.isRequired,
  /** The text of the message to display. */
  children: PropTypes.string.isRequired,
};

const StyledMessage = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.25em 0;
  font-size: 0.8em;
  font-weight: bold;
  background-color: ${(props) =>
    props.theme.messages[props.alertType].background};
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  max-width: 60em;
  margin: 0 auto;
  span {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  svg {
    color: ${(props) => props.theme.messages[props.alertType].icon};
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 50%;
    margin-right: 0.25em;
    font-size: 1.6em;
  }
  p {
    margin: 0;
    font-weight: bold;
  }
`;

export const showMessages = (messageArray, dismiss) =>
  messageArray && messageArray.length > 0
    ? messageArray.map((message) => {
        const { type, text, id } = message;

        return (
          <Message
            key={id}
            dismiss={dismiss}
            alertType={type}
            alertObject={message}
          >
            {text}
          </Message>
        );
      })
    : null;
