import {ReactNode} from "react";
import {strict} from "assert";

export enum Flag {
    False = 0,
    True = 1,
}

export enum Permission {
    TODO = "aa44dcbd-22af-11ea-8b5f-06319dbc535a",
}

export enum UserType {
    Individual = 1,
    Business = 2,
}

export type UUID = string;
export type EmptyString = string;

export interface User {
    isActive: Flag
    email: string
    firstName: string
    lastName: string
    phone: string
    phone_extension: string
    user_type: UserType
    uuid: UUID
    company_name: string | null
}

export type Children = Array<ReactNode> | ReactNode;

export interface HasChildren {
    children: Children
}

export interface SelectOption {
    value: string,
    label: string,
};