import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Container = styled.div`
  div.image-container {
    margin-bottom: 2em;
  }

  div.section {
    margin-bottom: 3em;
    p {
      font-size: 1em;
      line-height: 130%;
    }
    ul {
      list-style-position: inside;
      list-style-type: disc;

      li {
        margin-bottom: 1em;
      }
    }
  }
`;
const RebateAPI = props => {
  return (
    <Container>
      <Helmet>
        <meta name="description" content="Rebate Bus API information" />
        <title>Rebate Bus | API</title>
      </Helmet>
      <h1>Rebate Bus API</h1>
      <div className="image-container">
        <img
          alt="API"
          src="https://s3.amazonaws.com/v2.rebatebus/images/decorative/Marketing/Rebate+Bus+API/API_1.svg"
        />
      </div>
      <div className="section">
        <h2>About our API</h2>
        <p>
          Simply put, an API allows one software program to exchange data with
          another software program. This means that the power of Rebate Bus can
          be integrated into your own software platform in any way you choose.
          The Rebate Bus API delivers utility, incentive program, rebate, and
          product data that you can use to build apps and websites to reach new
          clients in creative new ways. Our Data Team continuously verifies the
          accuracy of rebates and programs to ensure the highest quality of
          data.
        </p>
        <p>
          We’ve given our old API a boost by switching to a new GraphQL API
          offering. Querying data with GraphQL means you get exactly what you
          need, nothing more and nothing less. GraphQL queries always return
          predictable results, which means that data will be easier to retrieve
          and your possibilities for integration are limitless.
        </p>
      </div>
      <div className="section">
        <h2>Endless possibilities</h2>
        <p>
          The possibilities are endless! Think of our API as a box of LEGOs — we
          provide the building blocks, you build your desired solution.{" "}
        </p>
        <p>Using Rebate Bus’s data, our API clients have built:</p>
        <ul>
          <li>
            Commerce integrations that pull rebate data to show customers what
            they could save when they buy products.
          </li>
          <li>
            Instant validation widgets for midstream rebates to ensure a
            customer matches the program requirements and get extra sales.
          </li>
          <li>
            Proposal tools that utilize information about new and existing
            products, as well as program and rebate data, to calculate estimated
            rebates for new and retrofit construction projects.
          </li>
        </ul>
        <p>
          Whether you're looking for a minimal integration or a full-fledged,
          custom solution, our API can serve up exactly what you're looking for.
        </p>
      </div>
      <div className="section">
        <h2>Pricing and purchasing</h2>
        <p>
          For more information about pricing, contact support@rebatebus.com. After
          set up, we will provide you with your own unique API key. Happy
          building!
        </p>
      </div>
    </Container>
  );
};

export default RebateAPI;
